const BadgeCheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_16_980)">
      <path
        d="M2.5668 5.74666C2.46949 5.30834 2.48443 4.85256 2.61023 4.42155C2.73604 3.99055 2.96863 3.59829 3.28644 3.28114C3.60425 2.96399 3.997 2.73222 4.42827 2.60732C4.85953 2.48242 5.31535 2.46844 5.75346 2.56666C5.9946 2.18953 6.3268 1.87917 6.71943 1.66418C7.11206 1.4492 7.55249 1.33652 8.00013 1.33652C8.44776 1.33652 8.8882 1.4492 9.28083 1.66418C9.67346 1.87917 10.0057 2.18953 10.2468 2.56666C10.6856 2.46801 11.1422 2.48193 11.5741 2.60714C12.0061 2.73234 12.3994 2.96475 12.7174 3.28276C13.0354 3.60077 13.2678 3.99404 13.393 4.42599C13.5182 4.85795 13.5321 5.31455 13.4335 5.75333C13.8106 5.99447 14.121 6.32666 14.3359 6.71929C14.5509 7.11193 14.6636 7.55236 14.6636 7.99999C14.6636 8.44763 14.5509 8.88806 14.3359 9.28069C14.121 9.67333 13.8106 10.0055 13.4335 10.2467C13.5317 10.6848 13.5177 11.1406 13.3928 11.5719C13.2679 12.0031 13.0361 12.3959 12.719 12.7137C12.4018 13.0315 12.0096 13.2641 11.5786 13.3899C11.1476 13.5157 10.6918 13.5306 10.2535 13.4333C10.0126 13.8119 9.68018 14.1236 9.28688 14.3395C8.89358 14.5555 8.45215 14.6687 8.00346 14.6687C7.55478 14.6687 7.11335 14.5555 6.72004 14.3395C6.32674 14.1236 5.99429 13.8119 5.75346 13.4333C5.31535 13.5316 4.85953 13.5176 4.42827 13.3927C3.997 13.2678 3.60425 13.036 3.28644 12.7188C2.96863 12.4017 2.73604 12.0094 2.61023 11.5784C2.48443 11.1474 2.46949 10.6916 2.5668 10.2533C2.18677 10.0128 1.87374 9.68011 1.65683 9.28614C1.43992 8.89216 1.32617 8.44973 1.32617 7.99999C1.32617 7.55026 1.43992 7.10782 1.65683 6.71385C1.87374 6.31988 2.18677 5.98717 2.5668 5.74666Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7.99999L7.33333 9.33332L10 6.66666"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_16_980">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BadgeCheckIcon;

import { gql, useMutation } from "@apollo/client";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroModal from "../shared/CiroModal";
import {
  CrmIntegrationEnum,
  RemoveCrmIntegrationModal_RemoveCrmIntegrationMutation,
  RemoveCrmIntegrationModal_RemoveCrmIntegrationMutationVariables,
} from "../../__generated__/graphql";
import { useState } from "react";
import classNames from "classnames";
import toast from "react-hot-toast";
import Loading from "../shared/Loading";

const RemoveCiroNumbersIntegrationModal_RemoveCiroNumbersIntegration = gql`
  mutation RemoveCrmIntegrationModal_RemoveCrmIntegration($integration: CrmIntegrationEnum!) {
    removeCrmIntegration(integration: $integration) {
      success
      message
    }
  }
`;

interface IRemoveCrmIntegrationModalProps {
  crmIntegration?: CrmIntegrationEnum;
  onClose: () => void;
  refetchUserAccount: () => void;
}

const RemoveCrmIntegrationModal = ({
  crmIntegration,
  onClose,
  refetchUserAccount
}: IRemoveCrmIntegrationModalProps) => {
  const [loading, setLoading] = useState(false);

  const [removeCrmIntegration] = useMutation<
    RemoveCrmIntegrationModal_RemoveCrmIntegrationMutation,
    RemoveCrmIntegrationModal_RemoveCrmIntegrationMutationVariables
    >(RemoveCiroNumbersIntegrationModal_RemoveCiroNumbersIntegration);

  if (!crmIntegration) {
    return null;
  }

  const handleSubmit = () => {
    setLoading(true);
    removeCrmIntegration({
      variables: {
        integration: crmIntegration,
      },
      onCompleted: async (data) => {  
        await refetchUserAccount();
        setLoading(false);
        onClose();
        if (data?.removeCrmIntegration?.success) {
          toast.success("Integration removed");
        } else {
          toast.error(data?.removeCrmIntegration?.message || "Integration couldn't be removed");
        }
      },
      onError: async () => {
        await refetchUserAccount();
        setLoading(false);
        onClose();
        toast.error("Integration couldn't be removed");
      }
    });
  };

  return (
    <CiroModal isOpen={true} onClose={onClose}>
      {loading && <Loading size="SMALL"/>}
      {!loading && <div className={classNames("ciro-v1-text-gray-700")}>
        <div>
          Are you sure you want to remove the{" "}
          <span className={"ciro-v1-font-semibold"}>{crmIntegration}</span>
          {" "}integration?
        </div>
        <div className={classNames("ciro-v1-text-sm", "ciro-v1-pt-4")}>
          This action cannot be undone.
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-justify-end",
            "ciro-v1-pt-4",
          )}
        >
          <span
            className={classNames({
              "ciro-v1-pr-2": true,
            })}
          >
            <CiroButton
              analyticsField="Cancel remove CRM integration"
              onClick={onClose}
            >
              Cancel
            </CiroButton>
          </span>
          <CiroButton
            analyticsField="Removed CRM integration"
            onClick={handleSubmit}
            style={CiroButtonStyleEnum.LOUD}
          >
            Remove
          </CiroButton>
        </div>
      </div>}
    </CiroModal>
  );
};

export default RemoveCrmIntegrationModal;


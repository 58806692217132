import { useCallback, useState } from "react";
import { SetURLSearchParams } from "react-router-dom";
import { EnrichmentFlowFilterInputTypeEnum } from "../../../__generated__/graphql";

export interface IEnrichmentFlowFilters {
  [stepId: number]: {
    [method: string]: string[];
  };
}

const parseFilters = (params: URLSearchParams) => {
  const filters = {} as IEnrichmentFlowFilters;

  params.forEach((value, key) => {
    if (key.startsWith("filter")) {
      const index = parseInt(key.replace("filter", ""), 10);
      const filterData = value.split(";");
      filters[index] = {};

      filterData.forEach((pair) => {
        const [method, values] = pair.split(":");
        filters[index][method] = values.split(",");
      });
    }
  });

  return filters;
};

export const setFiltersToArray = (filters: IEnrichmentFlowFilters) => {
  const finalArray = [] as {
    stepId: number;
    method: EnrichmentFlowFilterInputTypeEnum;
    values: string[];
  }[];
  Object.entries(filters).forEach(([stepId, filter]) => {
    Object.entries(filter as { [method: string]: string[] }).forEach(
      ([method, values]) => {
        finalArray.push({
          stepId: parseInt(stepId, 10),
          method: method as EnrichmentFlowFilterInputTypeEnum,
          values,
        });
      },
    );
  });
  return finalArray;
};

export const getFiltersAppliedCount = (filters: IEnrichmentFlowFilters) => {
  return Object.values(filters).reduce(
    (acc, filter) => acc + Object.keys(filter).length,
    0,
  );
};

const useEnrichmentFlowFilters = ({
  searchParams,
  setSearchParams,
}: {
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
}) => {
  const [enrichmentFlowFilters, setEnrichmentFlowFilters] =
    useState<IEnrichmentFlowFilters>(parseFilters(searchParams));

  const updateEnrichmentFilter = useCallback(
    ({
      stepId,
      method,
      values,
    }: {
      stepId: number;
      method: EnrichmentFlowFilterInputTypeEnum;
      values: string[];
    }) => {
      const newFilters = { ...enrichmentFlowFilters };
      if (values.length === 0) {
        delete newFilters[stepId][method];

        if (Object.keys(newFilters[stepId]).length === 0) {
          delete newFilters[stepId];
        }
      } else {
        newFilters[stepId] = {
          ...newFilters[stepId],
          [method]: values,
        };
      }

      setEnrichmentFlowFilters(newFilters);

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("filter" + stepId);

      Object.entries(newFilters).forEach(([key, value]) => {
        const filterString = Object.entries(value)
          .map(([method, values]) => `${method}:${values.join(",")}`)
          .join(";");
        newSearchParams.set("filter" + key, filterString);
      });

      setSearchParams(newSearchParams);
    },
    [enrichmentFlowFilters, searchParams, setSearchParams],
  );

  const resetEnrichmentFlowFilters = useCallback(() => {
    setEnrichmentFlowFilters({});
    setSearchParams(new URLSearchParams());
  }, [setSearchParams, setEnrichmentFlowFilters]);

  return {
    enrichmentFlowFilters,
    updateEnrichmentFilter,
    resetEnrichmentFlowFilters,
  };
};

export default useEnrichmentFlowFilters;

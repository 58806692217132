import { IEnrichmentExecutionCellProps } from ".";
import CiroMarkdown from "../../../shared/CiroMarkdown";

const ExecutionCell_GetEmail = ({
  value,
  setCellDetailsValue,
}: IEnrichmentExecutionCellProps) => {
  const parsedValue = value?.output && JSON.parse(value.output);
  // Need this check to handle legacy case of emails being returned as a string.
  const returnValue = Array.isArray(parsedValue)
    ? parsedValue.map((email: any) => email.email).join(", ")
    : parsedValue;

  return (
    <div
      onClick={() => {
        setCellDetailsValue(parsedValue);
      }}
    >
      <CiroMarkdown markdown={returnValue} />
    </div>
  );
};

export default ExecutionCell_GetEmail;

import { useCallback, useEffect, useRef, useState } from "react";
import { gql } from "@apollo/client";
import {
  ApolloIntegrationApiKeyForm_ApolloIntegrationFragment,
  CrmChooseIntegration_UserAccountFragment,
  LinkCrmButton_UserAccountFragment,
  MergeIntegrationEnum,
} from "../../__generated__/graphql";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { useMergeLink } from "@mergeapi/react-merge-link";
import CrmChooseIntegration, {
  CrmChooseIntegration_UserAccount,
} from "./crmIntegrationModal/CrmChooseIntegration";
import ApolloIntegrationApiKeyForm, {
  ApolloIntegrationApiKeyForm_ApolloIntegration,
} from "./crmIntegrationModal/apollo/ApolloIntegrationApiKeyForm";
import { toast } from "react-hot-toast";
import classNames from "classnames";
import HubspotIcon from "../../assets/img/icons/HubspotIcon";
import ApolloIcon from "../../assets/img/icons/ApolloIcon";
import SalesforceIcon from "../../assets/img/icons/SalesforceIcon";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import CiroTitle from "../shared/CiroTitle";

export const LinkCRMButton_UserAccount = gql`
  fragment LinkCRMButton_UserAccount on UserAccount {
    id
    mergeLinkTokens {
      linkToken
      integration
    }
    org {
      crmIntegration
      apolloIntegration {
        ...ApolloIntegrationApiKeyForm_ApolloIntegration
      }
    }
    ...CrmChooseIntegration_UserAccount
  }
  ${CrmChooseIntegration_UserAccount}
  ${ApolloIntegrationApiKeyForm_ApolloIntegration}
`;

interface ILinkCRMButtonProps {
  userAccount: LinkCrmButton_UserAccountFragment | null | undefined;
  refreshUserAccount?: () => void;
  homeButton?: boolean;
}

export const enum CrmIntegrationPhases {
  GET_STARTED = "GET_STARTED",
  CHOOSE_INTEGRATION = "CHOOSE_INTEGRATION",
  MERGE_MODAL = "MERGE_MODAL",
  APOLLO_API_KEY = "APOLLO_API_KEY",
}

export function LinkCRMButton({
  userAccount,
  refreshUserAccount,
  homeButton,
}: ILinkCRMButtonProps) {
  // Need to save the initial prop value in a ref because the prop value will change,
  // and changing the value will reload the mergeLink component, creating an error
  const initialLinkTokens = useRef(userAccount?.mergeLinkTokens);
  const [targetMergeIntegration, setTargetMergeIntegration] =
    useState<MergeIntegrationEnum | null>(null);
  const [crmIntegrationPhase, setCrmIntegrationPhase] = useState(
    CrmIntegrationPhases.GET_STARTED,
  );

  useEffect(() => {
    if (initialLinkTokens.current == null) {
      initialLinkTokens.current = userAccount?.mergeLinkTokens;
    }
  }, [userAccount?.mergeLinkTokens]);

  const onSuccess = useCallback(async () => {
    refreshUserAccount?.();
    setCrmIntegrationPhase(CrmIntegrationPhases.GET_STARTED);
    toast.success("Successfully connected CRM");
  }, [refreshUserAccount]);

  const { open: openMergeModal, isReady } = useMergeLink({
    linkToken: String(
      initialLinkTokens.current?.find(
        (token) => token?.integration === targetMergeIntegration,
      )?.linkToken,
    ),
    onSuccess,
    onExit: () => {
      refreshUserAccount?.();
      setCrmIntegrationPhase(CrmIntegrationPhases.GET_STARTED);
    },
  });

  const crmIntegration = userAccount?.org?.crmIntegration;
  const apolloIntegration = userAccount?.org?.apolloIntegration;

  useEffect(() => {
    if (crmIntegrationPhase === CrmIntegrationPhases.MERGE_MODAL) {
      openMergeModal();
    }
  }, [crmIntegrationPhase, openMergeModal]);

  return (
    <div className={homeButton ? classNames("ciro-v1-w-full") : ""}>
      {crmIntegrationPhase === CrmIntegrationPhases.GET_STARTED &&
        (homeButton ? (
          <CiroButton
            analyticsField="Homepage link crm"
            onClick={() =>
              setCrmIntegrationPhase(CrmIntegrationPhases.CHOOSE_INTEGRATION)
            }
            customFlexClass={classNames(
              "ciro-v1-justify-start",
              "ciro-v1-items-start",
              "ciro-v1-w-full",
            )}
          >
            <div className={classNames("ciro-v1-w-full")}>
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-pb-5",
                  "ciro-v1-justify-between",
                  "ciro-v1-w-full",
                )}
              >
                <div
                  className={classNames(
                    "ciro-v1-flex",
                    "ciro-v1-p-1",
                    "ciro-v1-relative",
                    "ciro-v1-w-full",
                  )}
                >
                  <div
                    className={classNames(
                      "ciro-v1-border-2",
                      "ciro-v1-p-2",
                      "ciro-v1-rounded-full",
                      "ciro-v1-bg-white",
                      "ciro-v1-z-10",
                    )}
                  >
                    <HubspotIcon width={20} height={20} />
                  </div>
                  <div
                    className={classNames(
                      "ciro-v1-border-2",
                      "ciro-v1-p-2",
                      "ciro-v1-rounded-full",
                      "ciro-v1-bg-white",
                      "ciro-v1-z-20",
                      "ciro-v1--ml-3",
                    )}
                  >
                    <ApolloIcon />
                  </div>
                  <div
                    className={classNames(
                      "ciro-v1-border-2",
                      "ciro-v1-p-2",
                      "ciro-v1-rounded-full",
                      "ciro-v1-bg-white",
                      "ciro-v1-z-30",
                      "ciro-v1--ml-3",
                    )}
                  >
                    <SalesforceIcon width={20} height={20} />
                  </div>
                </div>
                <ChevronRightIcon
                  className={classNames(
                    "ciro-v1-text-orange-500",
                    "ciro-v1-w-5",
                  )}
                />
              </div>
              <div className={classNames("ciro-v1-mt-auto")}>
                <CiroTitle
                  title="Connect CRM"
                  size="small"
                  subtitle="Connect your CRM to keep your contact data fresh & up-to-date."
                  subtitleClassName="ciro-v1-text-left"
                />
              </div>
            </div>
          </CiroButton>
        ) : (
          <CiroButton
            style={CiroButtonStyleEnum.LOUD}
            onClick={() =>
              setCrmIntegrationPhase(CrmIntegrationPhases.CHOOSE_INTEGRATION)
            }
            disabled={!isReady}
            analyticsField="Connect CRM"
          >
            {crmIntegration ? "Manage CRM Integration" : "Connect CRM"}
          </CiroButton>
        ))}
      {crmIntegrationPhase === CrmIntegrationPhases.CHOOSE_INTEGRATION && (
        <CrmChooseIntegration
          userAccount={userAccount as CrmChooseIntegration_UserAccountFragment}
          setCrmIntegrationPhase={setCrmIntegrationPhase}
          setTargetMergeIntegration={setTargetMergeIntegration}
        />
      )}
      {crmIntegrationPhase === CrmIntegrationPhases.APOLLO_API_KEY && (
        <ApolloIntegrationApiKeyForm
          apolloIntegration={
            apolloIntegration as ApolloIntegrationApiKeyForm_ApolloIntegrationFragment
          }
          refetchUserAccount={refreshUserAccount ?? (() => {})}
          onSuccess={() => {
            toast.success("Successfully connected Apollo");
            setCrmIntegrationPhase(CrmIntegrationPhases.GET_STARTED);
            refreshUserAccount?.();
          }}
          onClose={() => {
            setCrmIntegrationPhase(CrmIntegrationPhases.GET_STARTED);
            refreshUserAccount?.();
          }}
        />
      )}
    </div>
  );
}

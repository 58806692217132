import React from "react";
import classNames from "classnames";

interface ICiroModalProps {
  isOpen: boolean;
  children: any;
  onClose: () => void;
  size?: "SMALL" | "LARGE" | "XLARGE" | "FIXED_RIGHT" | "FITTED";
  hideScrollbar?: boolean;
}

const CiroModal = ({
  isOpen,
  children,
  onClose,
  size = "SMALL",
  hideScrollbar = false,
}: ICiroModalProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={classNames("ciro-v1-fixed", "ciro-v1-inset-0", "ciro-v1-z-20")}
    >
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-h-screen",
          "ciro-v1-items-center",
          "ciro-v1-justify-center",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-fixed",
            "ciro-v1-inset-0",
            "ciro-v1-transition-opacity",
          )}
        >
          <div
            className={classNames(
              "ciro-v1-absolute",
              "ciro-v1-inset-0",
              "ciro-v1-bg-gray-500",
              "ciro-v1-opacity-75",
            )}
            onClick={onClose}
          />
        </div>
        <div
          className={classNames(
            "ciro-v1-bg-white",
            "ciro-v1-shadow-xl",
            "ciro-v1-overflow-auto",
            {
              "ciro-v1-fixed": size === "FIXED_RIGHT",
              "ciro-v1-h-screen": size === "FIXED_RIGHT",
              "ciro-v1-m-5": size !== "FIXED_RIGHT",
              "ciro-v1-max-w-3xl": size === "XLARGE",
              "ciro-v1-max-w-md": size === "SMALL",
              "ciro-v1-max-w-xl": size === "LARGE",
              "ciro-v1-max-w-[90%]": size === "FITTED",
              "ciro-v1-max-h-[90%]": size === "FITTED",
              "ciro-v1-p-5": size !== "FIXED_RIGHT",
              "ciro-v1-right-0": size === "FIXED_RIGHT",
              "ciro-v1-rounded-lg": size !== "FIXED_RIGHT",
              "ciro-v1-top-0": size === "FIXED_RIGHT",
              "ciro-v1-transform": size !== "FIXED_RIGHT",
              "ciro-v1-w-3/4": size === "FIXED_RIGHT",
              "ciro-v1-w-full": size !== "FIXED_RIGHT",
              "ciro-v1-z-20": size === "FIXED_RIGHT",
              "lg:ciro-v1-w-96": size === "FIXED_RIGHT",
              "ciro-v1-overflow-hidden": hideScrollbar,
            },
          )}
        >
          <div>
            <div className={classNames("ciro-v1-text-left")}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CiroModal;

const SquareSparkleIcon = ({
  height = "32",
  width = "32",
}: {
  height?: string;
  width?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 21.3357C16 18.3889 18.3888 16.0001 21.3355 16.0001C18.3888 16.0001 16 13.6113 16 10.6646C16 13.6113 13.6112 16.0001 10.6644 16.0001C13.6112 16.0001 16 18.3889 16 21.3357Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.2531 10.7475C25.2531 8.53742 27.0447 6.74581 29.2548 6.74581C27.0447 6.74581 25.2531 4.9542 25.2531 2.74414C25.2531 4.9542 23.4615 6.74581 21.2515 6.74581C22.3128 6.74581 23.3306 7.16741 24.0811 7.91787C24.8315 8.66833 25.2531 9.68617 25.2531 10.7475V10.7475Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.005 14.6667V22.6701C28.005 25.6168 25.6162 28.0056 22.6694 28.0056H9.33055C6.3838 28.0056 3.995 25.6168 3.995 22.6701V9.33116C3.995 6.38442 6.3838 3.99561 9.33055 3.99561H17.3339"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SquareSparkleIcon;

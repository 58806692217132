const SelectionListIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.1665 10.0002H17.4998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.34479 8.82162C5.99563 9.47245 5.99563 10.5275 5.34479 11.1783C4.69396 11.8291 3.63896 11.8291 2.98813 11.1783C2.33729 10.5275 2.33729 9.47245 2.98813 8.82162C3.63896 8.17079 4.69396 8.17079 5.34479 8.82162"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1665 4.16667H17.4998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5166 3.75667L3.8941 4.99667L6.6666 2.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1665 15.8332H17.4998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.34479 14.6551C5.99563 15.306 5.99563 16.361 5.34479 17.0118C4.69396 17.6626 3.63896 17.6626 2.98813 17.0118C2.33729 16.361 2.33729 15.306 2.98813 14.6551C3.63896 14.0043 4.69396 14.0043 5.34479 14.6551"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SelectionListIcon;

import { gql } from "@apollo/client";
import CiroDropDown from "../shared/CiroDropdown";
import { ZipCodesFilter_ZipCodesFragment } from "../../__generated__/graphql";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

export const ZipCodesFilter_zipCodes = gql`
  fragment ZipCodesFilter_zipCodes on Query {
    zipCodes
  }
`;

interface IZipCodesFilterProps {
  blurred?: boolean;
  notZipCodesFilter: string[];
  resetOffset: () => void;
  zipCodesData: ZipCodesFilter_ZipCodesFragment;
  zipCodesFilter: string[];
  setZipCodesFilter: (v: string[]) => void;
  setNotZipCodesFilter: (v: string[]) => void;
}

function ZipCodesFilter({
  blurred = false,
  notZipCodesFilter,
  resetOffset,
  zipCodesFilter,
  setZipCodesFilter,
  setNotZipCodesFilter,
  zipCodesData,
}: IZipCodesFilterProps) {
  const { zipCodes } = zipCodesData;
  const zipCodesOptionsFromServer = (zipCodes || []).map((zipCode) => {
    return {
      value: String(zipCode),
      label: String(zipCode),
    };
  });
  const missingOptions = zipCodesFilter
    .concat(notZipCodesFilter)
    .filter((zipCode) => !zipCodes?.includes(zipCode))
    .map((zipCode) => {
      return {
        value: String(zipCode),
        label: String(zipCode),
      };
    });
  const zipCodesOptions = missingOptions
    ? zipCodesOptionsFromServer.concat(missingOptions)
    : zipCodesOptionsFromServer;

  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={
        (zipCodesFilter.length ? 1 : 0) + (notZipCodesFilter.length ? 1 : 0)
      }
      sectionTitle="Zip Code"
      filterOptions={[
        {
          label: "Include any of:",
          filter: (
            <CiroDropDown
              blurred={blurred}
              creatable={true}
              isMulti
              options={zipCodesOptions}
              onChange={(newValue) => {
                // Support copy-pasting comma-separated list of zips
                let splitValues: string[] = newValue.flatMap((val) =>
                  val.includes(",")
                    ? val.split(",").map((zip) => zip.trim())
                    : val,
                );
                resetOffset();
                setZipCodesFilter(splitValues);
              }}
              value={zipCodesFilter}
            />
          ),
        },
        {
          label: "Exclude:",
          filter: (
            <CiroDropDown
              blurred={blurred}
              creatable={true}
              isMulti
              options={zipCodesOptions}
              onChange={(newValue) => {
                resetOffset();
                setNotZipCodesFilter(newValue);
              }}
              value={notZipCodesFilter}
            />
          ),
        },
      ]}
    />
  );
}

export default ZipCodesFilter;

import CiroModal from "../../CiroModal";
import FilterContext from "../../../../contexts/FilterContext";
import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import XIcon from "../../../../assets/img/icons/XIcon";
import Loading from "../../Loading";
import {
  CiroConfirmFromFiltersModal_FiltersFromSemanticSearchQuery,
  CiroConfirmFromFiltersModal_FiltersFromSemanticSearchQueryVariables,
  CiroConfirmFromFiltersModal_StatesQuery,
  CiroConfirmFromFiltersModal_StatesQueryVariables,
} from "../../../../__generated__/graphql";
import SpecialtiesFilter from "../../../filterOptions/SpecialtiesFilter";
import VerticalFilter from "../../../filterOptions/VerticalFilter";
import { useContext } from "react";
import useConfirmFiltersFromSemanticSearch from "./useConfirmFiltersFromSemanticSearch";
import CitiesFilter from "../../../filterOptions/CitiesFilter";
import RangeFilter from "../../../filterOptions/RangeFilter";
import StatesFilter from "../../../filterOptions/StatesFilter";
import StarsIcon from "../../../../assets/img/icons/StarsIcon";
import CiroButton, { CiroButtonStyleEnum } from "../../CiroButton";
import {
  IAccountFilters,
} from "../../../../reactHooks/filters/accounts/useAccountFilters";
import toast from "react-hot-toast";

interface IConfirmFiltersFromSemanticSearchModal {
  accountFilters: IAccountFilters;
  show: boolean;
  onClose: () => void;
}

const CiroConfirmFromFiltersModal_FiltersFromSemanticSearch = gql`
  query CiroConfirmFromFiltersModal_FiltersFromSemanticSearch(
    $searchText: String
  ) {
    filtersFromSemanticSearch(searchText: $searchText) {
      success
      error
      filters {
        citiesFilter
        industriesFilter
        numEmployeesFilter {
          lowerBound
          upperBound
        }
        specialtiesFilter
        statesFilter
      }
    }
  }
`;

const CiroConfirmFromFiltersModal_States = gql`
  query CiroConfirmFromFiltersModal_States {
    states
  }
`;

const ConfirmFiltersFromSemanticSearchModal = ({
  accountFilters,
  show,
  onClose,
}: IConfirmFiltersFromSemanticSearchModal) => {
  const { semanticSearchQuery } = useContext(FilterContext);

  const {
    data: filtersFromSemanticSearchData,
    loading: filtersFromSemanticSearchLoading,
  } = useQuery<
    CiroConfirmFromFiltersModal_FiltersFromSemanticSearchQuery,
    CiroConfirmFromFiltersModal_FiltersFromSemanticSearchQueryVariables
  >(CiroConfirmFromFiltersModal_FiltersFromSemanticSearch, {
    variables: { searchText: semanticSearchQuery },
    skip: !semanticSearchQuery,
  });

  const { data: statesData } = useQuery<
    CiroConfirmFromFiltersModal_StatesQuery,
    CiroConfirmFromFiltersModal_StatesQueryVariables
  >(CiroConfirmFromFiltersModal_States);

  const filters =
    filtersFromSemanticSearchData?.filtersFromSemanticSearch?.filters;

  const {
    setIndustryFilter,
    setNotIndustryFilter,
    setCitiesFilter,
    setNotCitiesFilter,
    setSpecialtiesFilter,
    setAllSpecialtiesFilter,
    setNotSpecialtiesFilter,
    setStatesFilter,
    setNotStatesFilter,
    setNumEmployeesFilter
  } = accountFilters;

  const {
    confirmAllSpecialtiesFilter,
    confirmCitiesFilter,
    confirmIndustriesFilter,
    confirmNotCitiesFilter,
    confirmNotIndustriesFilter,
    confirmNotSpecialtiesFilter,
    confirmNotStatesFilter,
    confirmNumEmployeesFilter,
    confirmSpecialtiesFilter,
    confirmStatesFilter,
    setConfirmAllSpecialtiesFilter,
    setConfirmCitiesFilter,
    setConfirmIndustriesFilter,
    setConfirmNotCitiesFilter,
    setConfirmNotIndustriesFilter,
    setConfirmNotSpecialtiesFilter,
    setConfirmNotStatesFilter,
    setConfirmNumEmployeesFilter,
    setConfirmSpecialtiesFilter,
    setConfirmStatesFilter,
  } = useConfirmFiltersFromSemanticSearch(filtersFromSemanticSearchData);

  const applyFilters = () => {
    setIndustryFilter(confirmIndustriesFilter);
    setNotIndustryFilter(confirmNotIndustriesFilter);
    setCitiesFilter(confirmCitiesFilter);
    setNotCitiesFilter(confirmNotCitiesFilter);
    setSpecialtiesFilter(confirmSpecialtiesFilter);
    setAllSpecialtiesFilter(confirmAllSpecialtiesFilter);
    setNotSpecialtiesFilter(confirmNotSpecialtiesFilter);
    setStatesFilter(confirmStatesFilter);
    setNotStatesFilter(confirmNotStatesFilter);
    setNumEmployeesFilter(confirmNumEmployeesFilter);
    toast(
      "Your filters are being applied. You can edit them by clicking the filters button at the top.",
      {
        icon: "🚀",
        style: {
          textAlign: "left",
        },
        duration: 6000,
      },
    );
  };

  return (
    <CiroModal isOpen={show} onClose={onClose} size="XLARGE">
      <div className={classNames("ciro-v1-max-h-screen-minus-4")}>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-font-medium",
            "ciro-v1-items-center",
            "ciro-v1-justify-between",
            "ciro-v1-mb-8",
          )}
        >
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-flex-row",
              "ciro-v1-gap-1",
              "ciro-v1-items-center",
            )}
          >
            <div>
              <StarsIcon />
            </div>
            <div>Parsing your search</div>
          </div>
          <span
            onClick={onClose}
            className={classNames("ciro-v1-cursor-pointer")}
          >
            <XIcon />
          </span>
        </div>
        {filtersFromSemanticSearchLoading && (
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-flex-col",
              "ciro-v1-gap-6",
              "ciro-v1-items-center",
            )}
          >
            <Loading size="SMALL" longLoad />
            <div className={classNames("ciro-v1-text-neutral-600")}>
              Query: {semanticSearchQuery}
            </div>
          </div>
        )}

        {
          <div>
            <div
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-flex-col",
                "ciro-v1-max-w-screen-minus-16",
                "ciro-v1-overflow-auto",
                "ciro-v1-max-h-[75vh]",
                "md:ciro-v1-flex-row",
                "ciro-v1-gap-6",
                // Using hidden instead of rendering the component conditionally
                // to ensure the component is mounted and the child queries (industry + specialties lists) 
                // are executed when the modal is opened
                { "ciro-v1-hidden": !filters },
              )}
            >
              <div
                className={classNames(
                  "ciro-v1-w-72",
                  "md:ciro-v1-w-96",
                  "md:ciro-v1-flex",
                  "md:ciro-v1-flex-col",
                  "md:ciro-v1-gap-4",
                )}
              >
                <div>
                  <VerticalFilter
                    defaultOpen
                    industryFilter={confirmIndustriesFilter}
                    notIndustryFilter={confirmNotIndustriesFilter}
                    setIndustryFilter={setConfirmIndustriesFilter}
                    setNotIndustryFilter={setConfirmNotIndustriesFilter}
                  />
                </div>
                <div>
                  <SpecialtiesFilter
                    allSpecialtiesFilter={confirmAllSpecialtiesFilter}
                    defaultOpen
                    industryFilter={confirmIndustriesFilter}
                    notSpecialtiesFilter={confirmNotSpecialtiesFilter}
                    setAllSpecialtiesFilter={setConfirmAllSpecialtiesFilter}
                    setNotSpecialtiesFilter={setConfirmNotSpecialtiesFilter}
                    setSpecialtiesFilter={setConfirmSpecialtiesFilter}
                    specialtiesFilter={confirmSpecialtiesFilter}
                  />
                </div>
              </div>
              <div
                className={classNames(
                  "ciro-v1-w-72",
                  "md:ciro-v1-w-96",
                  "md:ciro-v1-flex",
                  "md:ciro-v1-flex-col",
                  "md:ciro-v1-gap-4",
                )}
              >
                <div>
                  <StatesFilter
                    defaultOpen
                    notStatesFilter={confirmNotStatesFilter}
                    setNotStatesFilter={setConfirmNotStatesFilter}
                    setStatesFilter={setConfirmStatesFilter}
                    statesData={statesData?.states || []}
                    statesFilter={confirmStatesFilter}
                    setCitiesFilter={setConfirmCitiesFilter}
                  />
                </div>
                <div>
                  <CitiesFilter
                    citiesFilter={confirmCitiesFilter}
                    defaultOpen
                    notCitiesFilter={confirmNotCitiesFilter}
                    setCitiesFilter={setConfirmCitiesFilter}
                    setNotCitiesFilter={setConfirmNotCitiesFilter}
                    statesFilter={confirmStatesFilter}
                  />
                </div>
                <div>
                  <RangeFilter
                    defaultOpen
                    min={0}
                    rangeFilter={confirmNumEmployeesFilter}
                    setRangeFilter={setConfirmNumEmployeesFilter}
                    step={1}
                    title={"Number of Employees"}
                  />
                </div>
              </div>
            </div>
            <div
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-justify-end",
                "ciro-v1-mt-8",
              )}
            >
              <CiroButton
                style={CiroButtonStyleEnum.LOUD}
                analyticsField="Apply filters from semantic search modal"
                onClick={() => {
                  applyFilters();
                  onClose();
                }}
              >
                Apply filters
              </CiroButton>
            </div>
          </div>
        }
      </div>
    </CiroModal>
  );
};

export default ConfirmFiltersFromSemanticSearchModal;

import classNames from "classnames";
import { IEnrichmentExecutionCellProps } from ".";

const ExecutionCell_FooterCount = ({
  value,
}: IEnrichmentExecutionCellProps) => {
  const displayedValue = value == null ? "" : `${value.output} rows enriched`;

  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-justify-end",
        "ciro-v1-items-center",
        "ciro-v1-text-xs",
        "ciro-v1-text-neutral-400",
        "ciro-v1-w-full",
        "ciro-v1-h-full",
        "ciro-v1-h-full",
      )}
    >
      {displayedValue}
    </div>
  );
};

export default ExecutionCell_FooterCount;

import { useAuth0 } from "@auth0/auth0-react";
import ProfilePhotoIcon from "../../assets/img/icons/ProfilePhotoIcon";
import CiroTitle from "../shared/CiroTitle";
import classNames from "classnames";

const HomeProfileCard = () => {
  const { user } = useAuth0();

  const getGreeting = (): string => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good morning";
    } else if (currentHour < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  const getFirstName = (): string => {
    return user?.name?.split(" ")[0] || "";
  };

  return (
    <div
      className={classNames("ciro-v1-flex", "ciro-v1-flex-row", "ciro-v1-p-5")}
    >
      <ProfilePhotoIcon width={75} height={75} />
      <div className={classNames("ciro-v1-pl-4")}>
        <CiroTitle
          title={`${getGreeting()}, ${getFirstName()}`}
          subtitle={`Enrich your data with Ciro, then take over the world!`}
          size="medium"
        />
      </div>
    </div>
  );
};

export default HomeProfileCard;

import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import CiroLink from "./CiroLink";

interface ICiroBradCrumbsProps {
  values: string[];
  href: string;
}

const CiroBreadCrumbs = ({ values, href }: ICiroBradCrumbsProps) => {
  return (
    <div className={classNames("ciro-v1-pb-4")}>
      <CiroLink href={href} shouldOpenInNewTab={false}>
        <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
          <div className={classNames("ciro-v1-pr-2")}>
            <ArrowLeftIcon
              className={classNames(
                "ciro-v1-w-3",
                "ciro-v1-h-3",
                "ciro-v1-text-neutral-500",
              )}
            />
          </div>
          {values.map((value, index) => {
            return (
              <div
                key={index}
                className={classNames("ciro-v1-flex", "ciro-v1-items-center")}
              >
                <div
                  className={classNames(
                    "ciro-v1-text-neutral-500",
                    "ciro-v1-text-sm",
                  )}
                >
                  {value}
                </div>
                {index !== values.length - 1 && (
                  <div
                    className={classNames(
                      "ciro-v1-text-neutral-500",
                      "ciro-v1-text-sm",
                      "ciro-v1-mx-2",
                    )}
                  >
                    /
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </CiroLink>
    </div>
  );
};

export default CiroBreadCrumbs;

import React from "react";
import { organizationStatusOptions } from "../../utils/table-settings";
import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

interface ICustomerRelationshipFilterProps {
  organizationStatusFilter: string[];
  setOrganizationStatusFilter: (v: string[]) => void;
  resetOffset: () => void;
  blurred?: boolean;
}

function CustomerRelationshipFilter({
  organizationStatusFilter,
  setOrganizationStatusFilter,
  resetOffset,
  blurred = false,
}: ICustomerRelationshipFilterProps) {
  return (
    <CiroFilterModalDropdownSection
      btnClassName="test-organization-filter-dropdown"
      numFiltersApplied={organizationStatusFilter.length ? 1 : 0}
      sectionTitle="CRM Status"
      filterOptions={[
        {
          label: "Include any of:",
          filter: (
            <CiroDropDown
              className="test-organization-status-filter"
              isMulti
              blurred={blurred}
              options={organizationStatusOptions}
              value={organizationStatusFilter}
              onChange={(newValue) => {
                setOrganizationStatusFilter(newValue);
                resetOffset();
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default CustomerRelationshipFilter;

import { IEnrichmentExecutionCellProps } from ".";
import CiroMarkdown from "../../../shared/CiroMarkdown";

const ExecutionCell_Default = ({
  value,
  setCellDetailsValue,
}: IEnrichmentExecutionCellProps) => {
  const parsedValue = value?.output && JSON.parse(value.output);
  const returnValue =
    typeof parsedValue === "string"
      ? parsedValue
      : Boolean(value?.output)
        ? String(value?.output)
        : "";

  return (
    <div
      onClick={() => {
        setCellDetailsValue(parsedValue);
      }}
    >
      <CiroMarkdown markdown={returnValue} />
    </div>
  );
};

export default ExecutionCell_Default;

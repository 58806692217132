import { IEnrichmentExecutionCellProps } from ".";

const ExecutionCell_CrmAccount = ({
  value,
  selectedInput,
  setCellDetailsValue,
}: IEnrichmentExecutionCellProps) => {
  let parsedValue = value?.output && JSON.parse(value.output);
  const originalParsedValue = parsedValue;

  if (selectedInput) {
    const inputSegments = selectedInput.split(".");
    parsedValue = inputSegments.reduce((acc, key) => acc?.[key], parsedValue);
  }

  const returnValue =
    typeof parsedValue === "string" ? parsedValue : value?.output;
  return (
    <div>
      <div
        onClick={() => {
          setCellDetailsValue(originalParsedValue);
        }}
      >
        {returnValue}
      </div>
    </div>
  );
};

export default ExecutionCell_CrmAccount;

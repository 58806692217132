import classNames from "classnames";
import ApolloIcon from "../../../assets/img/icons/ApolloIcon";
import CrmIntegrationOption from "./CrmIntegrationOption";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SalesforceIcon from "../../../assets/img/icons/SalesforceIcon";
import { gql } from "@apollo/client";
import { CrmChooseIntegration_UserAccountFragment, MergeIntegrationEnum } from "../../../__generated__/graphql";
import HubspotIcon from "../../../assets/img/icons/HubspotIcon";
import CiroModal from "../../shared/CiroModal";
import { CrmIntegrationPhases } from "../LinkCRMButton";

interface ICrmChooseIntegrationProps {
  userAccount: CrmChooseIntegration_UserAccountFragment;
  setCrmIntegrationPhase: (phase: CrmIntegrationPhases) => void;
  setTargetMergeIntegration: (integration: MergeIntegrationEnum | null) => void;
}

export const CrmChooseIntegration_UserAccount = gql`
  fragment CrmChooseIntegration_UserAccount on UserAccount {
    id
    mergeLinkTokens {
      linkToken
      integration
    }
    hasCrmIntegrationEnabled
    org {
      organizationMergeIntegration {
        integration
        last_matched_at
      }
    }
  }
`;

const CrmChooseIntegration = ({
  userAccount,
  setCrmIntegrationPhase,
  setTargetMergeIntegration: setTargetIntegration,
}: ICrmChooseIntegrationProps) => {

  const onClose = () => {
    setCrmIntegrationPhase(CrmIntegrationPhases.GET_STARTED);
  };

  return (
    <CiroModal isOpen={true} onClose={onClose}>
      <div>
        <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-between",
          "ciro-v1-items-center",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-text-xl",
            "ciro-v1-font-bold",
            "ciro-v1-pb-2",
            "ciro-v1-text-neutral-900",
          )}
        >
          Choose a CRM integration
        </div>
        <div>
          <CiroButton
            style={CiroButtonStyleEnum.UNSTYLED}
            analyticsField="Close Apollo Integration Setup Modal"
            onClick={() => onClose()}
          >
            <XMarkIcon className={classNames("ciro-v1-h-6", "ciro-v1-w-6")} />
          </CiroButton>
        </div>
      </div>
      <div
        className={classNames("ciro-v1-text-md", "ciro-v1-text-neutral-900")}
      >
        Choose a CRM integration to connect to Ciro.
      </div>
      <div
        className={classNames(
          "ciro-v1-text-md",
          "ciro-v1-font-bold",
          "ciro-v1-text-neutral-600",
          "ciro-v1-mt-4",
        )}
      >
        Get Started
      </div>
      <CrmIntegrationOption
        icon={<ApolloIcon />}
        title="Continue with Apollo"
        onClick={() => setCrmIntegrationPhase(CrmIntegrationPhases.APOLLO_API_KEY)}
      />
      {userAccount?.org?.organizationMergeIntegration?.integration !==
        "HubSpot" && (
        <CrmIntegrationOption
          icon={<SalesforceIcon />}
          title="Continue with Salesforce"
          onClick={async () => {
            setTargetIntegration(MergeIntegrationEnum.Salesforce);
            setCrmIntegrationPhase(CrmIntegrationPhases.MERGE_MODAL);
          }}
        />
      )}
      {userAccount?.org?.organizationMergeIntegration?.integration !==
        "Salesforce" && (
        <CrmIntegrationOption
          icon={<HubspotIcon />}
          title="Continue with HubSpot"
          onClick={async () => {
            setTargetIntegration(MergeIntegrationEnum.HubSpot);
            setCrmIntegrationPhase(CrmIntegrationPhases.MERGE_MODAL);
          }}
        />
      )}
      </div>
      </CiroModal>
  );
};

export default CrmChooseIntegration;

import classNames from "classnames";

interface ICiroCardProps {
  children: any;
  size?: "tiny" | "small" | "medium" | "large";
  customClassName?: string;
}

const CiroCard = ({
  children,
  size = "medium",
  customClassName,
}: ICiroCardProps) => {
  return (
    <div
      className={classNames(
        "ciro-v1-bg-white",
        "ciro-v1-border-1",
        "ciro-v1-border-gray-200",
        "ciro-v1-flex-col",
        "ciro-v1-flex",
        "ciro-v1-rounded-xl",
        "ciro-v1-text-left",
        {
          "ciro-v1-p-2": size === "tiny",
          "ciro-v1-p-4": size === "small",
          "ciro-v1-p-8": size === "medium",
          "ciro-v1-p-12": size === "large",
        },
        customClassName,
      )}
    >
      {children}
    </div>
  );
};

export default CiroCard;

import classNames from "classnames";
import { FieldValues, UseFormRegister } from "react-hook-form";
import CiroErrorMsg from "./forms/CiroErrorMsg";
import { Maybe } from "../../__generated__/graphql";

interface ICiroTextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string;
  error?: Maybe<string>;
  register?: UseFormRegister<FieldValues> | {};
  showErrorAsBorder?: boolean;
}

const CiroBaseTextArea = ({
  error,
  register = {},
  showErrorAsBorder,
  ...inputProps
}: ICiroTextAreaProps) => {
  return (
    <textarea
      className={classNames(
        "ciro-v1-border-1",
        "ciro-v1-border-gray-300",
        "ciro-v1-min-h-48",
        "ciro-v1-p-3.5",
        "ciro-v1-rounded-lg",
        "ciro-v1-text-sm",
        "ciro-v1-w-full",
        {
          "ciro-v1-border-gray-300": !error,
          "ciro-v1-border-red-500": error && showErrorAsBorder,
        },
      )}
      {...inputProps}
      {...register}
    />
  );
};

const CiroTextArea = ({
  error,
  label,
  showErrorAsBorder,
  ...inputProps
}: ICiroTextAreaProps) => {
  return (
    <div>
      {label && (
        <div className={classNames("ciro-v1-pb-2", "ciro-v1-text-gray-500")}>
          {label}
        </div>
      )}
      <CiroBaseTextArea showErrorAsBorder={showErrorAsBorder} {...inputProps} />
      {!showErrorAsBorder && <CiroErrorMsg error={error} />}
    </div>
  );
};

export default CiroTextArea;

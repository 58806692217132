import classNames from "classnames";
import CiroTable from "../shared/CiroTable/CiroTable";
import CiroTableRow from "../shared/CiroTable/CiroTableRow";
import CiroTableHeader from "../shared/CiroTable/CiroTableHeader";
import CiroTableCell from "../shared/CiroTable/CiroTableCell";
import { gql } from "@apollo/client";
import {
  DeleteEnrichmentFlowModal_EnrichmentFlowFragment,
  EnrichmentFlowsTable_EnrichmentFlowsFragment,
  Maybe,
} from "../../__generated__/graphql";
import { TrashIcon } from "@heroicons/react/24/outline";
import DeleteEnrichmentFlowModal, {
  DeleteEnrichmentFlowModal_EnrichmentFlow,
} from "./DeleteEnrichmentFlowModal";
import { useState } from "react";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";

export const EnrichmentFlowsTable_EnrichmentFlows = gql`
  fragment EnrichmentFlowsTable_EnrichmentFlows on EnrichmentFlows {
    flows {
      id
      name
      rowCount
      creator {
        email
      }
      ...DeleteEnrichmentFlowModal_EnrichmentFlow
    }
  }
  ${DeleteEnrichmentFlowModal_EnrichmentFlow}
`;

interface IEnrichmentTableProps {
  enrichments?: Maybe<EnrichmentFlowsTable_EnrichmentFlowsFragment>;
  itemsPerPage?: number;
}

const EnrichmentsTable = ({
  enrichments,
  itemsPerPage,
}: IEnrichmentTableProps) => {
  const [confirmDeleteFlow, setConfirmDeleteFlow] =
    useState<Maybe<DeleteEnrichmentFlowModal_EnrichmentFlowFragment>>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const totalItems = enrichments?.flows?.length || 0;
  const totalPages = itemsPerPage ? Math.ceil(totalItems / itemsPerPage) : 1;

  const paginatedFlows = itemsPerPage
    ? enrichments?.flows?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage,
      )
    : enrichments?.flows;

  return (
    <div className={classNames("ciro-v1-pb-8", "test-collections-table-main")}>
      <CiroTable
        pagination={
          itemsPerPage
            ? {
                currentPage,
                totalPages,
                onPageChange: (page: number) => setCurrentPage(page),
              }
            : undefined
        }
      >
        <thead className={classNames("ciro-v1-table-header-group")}>
          <CiroTableRow clickable={false} sticky={true}>
            <CiroTableHeader isFirst>Name</CiroTableHeader>
            <CiroTableHeader className={classNames("sm:ciro-v1-table-cell")}>
              Row count
            </CiroTableHeader>
            <CiroTableHeader className={classNames("sm:ciro-v1-table-cell")}>
              Created by
            </CiroTableHeader>
            <CiroTableHeader isLast>{""}</CiroTableHeader>
          </CiroTableRow>
        </thead>
        <tbody className={classNames("ciro-v1-table-row-group")}>
          {paginatedFlows?.map((enrichment, i) => {
            if (!enrichment?.id) {
              return null;
            }
            return (
              <CiroTableRow key={enrichment.id}>
                <CiroTableCell
                  href={`/lists/${enrichment.id}`}
                  lastLeft={i === (paginatedFlows?.length || 1) - 1}
                >
                  {enrichment.name}
                </CiroTableCell>
                <CiroTableCell href={`/lists/${enrichment.id}`}>
                  {enrichment.rowCount}
                </CiroTableCell>
                <CiroTableCell href={`/lists/${enrichment.id}`}>
                  {enrichment.creator?.email || "Unknown"}
                </CiroTableCell>
                <CiroTableCell
                  lastRight={i === (paginatedFlows?.length || 1) - 1}
                >
                  <div
                    className={classNames(
                      "ciro-v1-flex",
                      "ciro-v1-justify-end",
                      "ciro-v1-pr-4",
                      "ciro-v1-w-full",
                    )}
                  >
                    <CiroButton
                      analyticsField="Begin Delete Enrichment Flow"
                      onClick={() => {
                        setConfirmDeleteFlow(enrichment);
                      }}
                      style={CiroButtonStyleEnum.UNSTYLED}
                    >
                      <TrashIcon
                        className={classNames("ciro-v1-w-5", "ciro-v1-h-5")}
                      />
                    </CiroButton>
                  </div>
                </CiroTableCell>
              </CiroTableRow>
            );
          })}
        </tbody>
      </CiroTable>
      <DeleteEnrichmentFlowModal
        enrichmentFlow={confirmDeleteFlow}
        onClose={() => {
          setConfirmDeleteFlow(null);
        }}
      />
    </div>
  );
};

export default EnrichmentsTable;

import { useCallback, useContext } from "react";
import {
  AccountFilterEnum,
  CiroFilterSectionAccountFilters_AccountFiltersFragment,
  CollectionsFilter_CollectionsFragmentDoc,
  ExistsInCrmFilter_UserAccountFragmentDoc,
  LastActivityDateFilter_UserAccountFragmentDoc,
  Maybe,
  OpenOpportunityFilter_UserAccountFragmentDoc,
  PmsProviderFilter_PmsProvidersFragmentDoc,
  StatesFilter_StatesFragmentDoc,
  ZipCodesFilter_ZipCodesFragmentDoc,
} from "../../../__generated__/graphql";
import VerticalFilter from "../../filterOptions/VerticalFilter";
import StatesFilter, {
  StatesFilter_states,
} from "../../filterOptions/StatesFilter";
import CitiesFilter from "../../filterOptions/CitiesFilter";
import ZipCodesFilter, {
  ZipCodesFilter_zipCodes,
} from "../../filterOptions/ZipCodesFilter";
import SpecialtiesFilter from "../../filterOptions/SpecialtiesFilter";
import MultilocationFilter from "../../filterOptions/MultilocationFilter";
import TechnologiesFilter from "../../filterOptions/TechnologiesFilter";
import PmsProviderFilter, {
  PmsProviderFilter_pmsProviders,
} from "../../filterOptions/PmsProviderFilter";
import IndustryAssociationsFilter from "../../filterOptions/IndustryAssociationsFilter";
import CustomerRelationshipFilter from "../../filterOptions/CustomerRelationshipFilter";
import { useFragment } from "../../../__generated__";
import CollectionsFilter, {
  CollectionsFilter_collections,
} from "../../filterOptions/CollectionsFilter";
import ExportedCompaniesFilter from "../../filterOptions/ExportedCompaniesFilter";
import LastActivityDateFilter, {
  LastActivityDateFilter_userAccount,
} from "../../filterOptions/LastActivityDateFilter";
import OpenOpportunityFilter, {
  OpenOpportunityFilter_userAccount,
} from "../../filterOptions/OpenOpportunityFilter";
import { IAccountFilters } from "../../../reactHooks/filters/accounts/useAccountFilters";
import { gql } from "@apollo/client";
import CiroFilterGroup from "./CiroFilterGroup";
import RangeFilter from "../../filterOptions/RangeFilter";
import FilterContext from "../../../contexts/FilterContext";
import OpenDaysFilter from "../../filterOptions/OpenDaysFilter";
import RequiredFieldFilter from "../../filterOptions/RequiredFieldFilter";
import ExistsInCrmFilter, {
  ExistsInCrmFilter_userAccount,
} from "../../filterOptions/ExistsInCrmFilter";
import CiroSmbFilterToggle from "./CiroFilterSmbToggle";

export const CiroFilterSectionAccountFilters_AccountFilters = gql`
  fragment CiroFilterSectionAccountFilters_AccountFilters on Query {
    ...CollectionsFilter_collections
    ...ExistsInCrmFilter_userAccount
    ...LastActivityDateFilter_userAccount
    ...OpenOpportunityFilter_userAccount
    ...PmsProviderFilter_pmsProviders
    ...StatesFilter_states
    ...ZipCodesFilter_zipCodes
    permissions {
      accountFilters
    }
  }
  ${CollectionsFilter_collections}
  ${ExistsInCrmFilter_userAccount}
  ${LastActivityDateFilter_userAccount}
  ${OpenOpportunityFilter_userAccount}
  ${PmsProviderFilter_pmsProviders}
  ${StatesFilter_states}
  ${ZipCodesFilter_zipCodes}
`;

interface ICiroFilterSectionAccountFiltersProps {
  accountFilterData?: Maybe<CiroFilterSectionAccountFilters_AccountFiltersFragment>;
  accountFilters: IAccountFilters;
  resetOffset: () => void;
}

const CiroFilterSectionAccountFilters = ({
  accountFilterData,
  accountFilters,
  resetOffset,
}: ICiroFilterSectionAccountFiltersProps) => {
  const {
    allIndustryAssociationsFilter,
    allSpecialtiesFilter,
    allTechnologiesFilter,
    businessTypeFilter,
    citiesFilter,
    collectionsFilter,
    existsInCrmFilter,
    exportedCompaniesFilter,
    googleNumReviewsFilter,
    googleStarRatingFilter,
    industryAssociationsFilter,
    industryFilter,
    instagramFollowerCountFilter,
    lastActivityDateAfterFilter,
    lastActivityDateBeforeFilter,
    locationsFilter,
    multilocationFilter,
    notCitiesFilter,
    notCollectionsFilter,
    notIndustryAssociationsFilter,
    notIndustryFilter,
    notSpecialtiesFilter,
    notStatesFilter,
    notTechnologiesFilter,
    notZipCodesFilter,
    parentChildTypeFilter,
    numEmployeesFilter,
    openDaysFilter,
    openOpportunityFilter,
    organizationStatusFilter,
    pmsProviderFilter,
    requiredFieldFilter,
    specialtiesFilter,
    statesFilter,
    technologiesFilter,
    yelpNumReviewsFilter,
    yelpStarRatingFilter,
    zipCodesFilter,

    setAllIndustryAssociationsFilter,
    setAllSpecialtiesFilter,
    setAllTechnologiesFilter,
    setCitiesFilter,
    setCollectionsFilter,
    setExistsInCrmFilter,
    setExportedCompaniesFilter,
    setGoogleNumReviewsFilter,
    setGoogleStarRatingFilter,
    setIndustryAssociationsFilter,
    setIndustryFilter,
    setInstagramFollowerCountFilter,
    setLastActivityDateAfterFilter,
    setLastActivityDateBeforeFilter,
    setLocationsFilter,
    setBusinessTypeFilter,
    setMultilocationFilter,
    setParentChildTypeFilter,
    setNotCitiesFilter,
    setNotCollectionsFilter,
    setNotIndustryAssociationsFilter,
    setNotIndustryFilter,
    setNotSpecialtiesFilter,
    setNotStatesFilter,
    setNotTechnologiesFilter,
    setNotZipCodesFilter,
    setNumEmployeesFilter,
    setOpenDaysFilter,
    setOpenOpportunityFilter,
    setOrganizationStatusFilter,
    setPmsProviderFilter,
    setRequiredFieldFilter,
    setSpecialtiesFilter,
    setStatesFilter,
    setTechnologiesFilter,
    setYelpNumReviewsFilter,
    setYelpStarRatingFilter,
    setZipCodesFilter,
  } = accountFilters;

  const { page, showSmbFilters } = useContext(FilterContext);

  const isAccountFilterIncluded = useCallback(
    (filter: AccountFilterEnum) => {
      const accountFilters =
        accountFilterData?.permissions?.accountFilters || [];
      return (
        accountFilters[0] === AccountFilterEnum.All ||
        accountFilters.includes(filter)
      );
    },
    [accountFilterData?.permissions?.accountFilters],
  );

  const pmsProviderFilterData = useFragment(
    PmsProviderFilter_PmsProvidersFragmentDoc,
    accountFilterData,
  );

  const statesFilterData = useFragment(
    StatesFilter_StatesFragmentDoc,
    accountFilterData,
  );
  const zipCodesFilterData = useFragment(
    ZipCodesFilter_ZipCodesFragmentDoc,
    accountFilterData,
  );
  const collectionsFilterData = useFragment(
    CollectionsFilter_CollectionsFragmentDoc,
    accountFilterData,
  );
  const lastActivityDateFilterData = useFragment(
    LastActivityDateFilter_UserAccountFragmentDoc,
    accountFilterData,
  );

  const openOpportunityFilterData = useFragment(
    OpenOpportunityFilter_UserAccountFragmentDoc,
    accountFilterData,
  );

  const existsInCrmFilterData = useFragment(
    ExistsInCrmFilter_UserAccountFragmentDoc,
    accountFilterData,
  );

  if (!accountFilterData) {
    return null;
  }

  const filterSections = [
    {
      title: "General",
      filters: [
        <VerticalFilter
          blurred={!isAccountFilterIncluded(AccountFilterEnum.IndustryFilter)}
          industryFilter={industryFilter}
          notIndustryFilter={notIndustryFilter}
          resetOffset={resetOffset}
          setIndustryFilter={setIndustryFilter}
          setNotIndustryFilter={setNotIndustryFilter}
        />,
        <SpecialtiesFilter
          allSpecialtiesFilter={allSpecialtiesFilter}
          blurred={
            !isAccountFilterIncluded(AccountFilterEnum.SpecialtiesFilter)
          }
          industryFilter={industryFilter}
          notSpecialtiesFilter={notSpecialtiesFilter}
          resetOffset={resetOffset}
          setAllSpecialtiesFilter={setAllSpecialtiesFilter}
          setNotSpecialtiesFilter={setNotSpecialtiesFilter}
          setSpecialtiesFilter={setSpecialtiesFilter}
          specialtiesFilter={specialtiesFilter}
        />,
        <StatesFilter
          blurred={!isAccountFilterIncluded(AccountFilterEnum.StatesFilter)}
          notStatesFilter={notStatesFilter}
          resetOffset={resetOffset}
          setNotStatesFilter={setNotStatesFilter}
          setStatesFilter={setStatesFilter}
          statesData={statesFilterData?.states || []}
          statesFilter={statesFilter}
          setCitiesFilter={setCitiesFilter}
        />,
        <CitiesFilter
          blurred={!isAccountFilterIncluded(AccountFilterEnum.CitiesFilter)}
          citiesFilter={citiesFilter}
          notCitiesFilter={notCitiesFilter}
          resetOffset={resetOffset}
          setCitiesFilter={setCitiesFilter}
          setNotCitiesFilter={setNotCitiesFilter}
          statesFilter={statesFilter}
        />,
        <ZipCodesFilter
          blurred={!isAccountFilterIncluded(AccountFilterEnum.ZipCodeFilter)}
          notZipCodesFilter={notZipCodesFilter}
          resetOffset={resetOffset}
          setZipCodesFilter={setZipCodesFilter}
          zipCodesData={zipCodesFilterData!}
          setNotZipCodesFilter={setNotZipCodesFilter}
          zipCodesFilter={zipCodesFilter}
        />,
      ],
    },
    {
      title: "Advanced",
      filters: [
        <MultilocationFilter
          blurred={
            !isAccountFilterIncluded(AccountFilterEnum.MultilocationFilter)
          }
          businessTypeFilter={businessTypeFilter}
          multilocationFilter={multilocationFilter}
          parentChildTypeFilter={parentChildTypeFilter}
          setParentChildTypeFilter={setParentChildTypeFilter}
          setMultilocationFilter={setMultilocationFilter}
          setBusinessTypeFilter={setBusinessTypeFilter}
          resetOffset={resetOffset}
        />,
        <RangeFilter
          blurred={
            !isAccountFilterIncluded(AccountFilterEnum.NumLocationsFilter)
          }
          min={0}
          rangeFilter={locationsFilter}
          resetOffset={resetOffset}
          setRangeFilter={setLocationsFilter}
          step={1}
          title={"Number of Locations"}
          smbFilter
        />,
        <RangeFilter
          blurred={
            !isAccountFilterIncluded(AccountFilterEnum.NumEmployeesFilter)
          }
          min={0}
          rangeFilter={numEmployeesFilter}
          resetOffset={resetOffset}
          setRangeFilter={setNumEmployeesFilter}
          step={1}
          title={"Number of Employees"}
        />,
        <OpenDaysFilter
          blurred={!isAccountFilterIncluded(AccountFilterEnum.OpenDaysFilter)}
          openDaysFilter={openDaysFilter}
          setOpenDaysFilter={setOpenDaysFilter}
          resetOffset={resetOffset}
        />,
        <RequiredFieldFilter
          blurred={
            !isAccountFilterIncluded(AccountFilterEnum.RequiredFieldFilter)
          }
          requiredFieldFilter={requiredFieldFilter}
          setRequiredFieldFilter={setRequiredFieldFilter}
          resetOffset={resetOffset}
        />,
      ],
    },
    {
      title: "Web Presence & Tech Stack",
      filters: showSmbFilters
        ? [
            <TechnologiesFilter
              allTechnologiesFilter={allTechnologiesFilter}
              blurred={
                !isAccountFilterIncluded(AccountFilterEnum.TechnologiesFilter)
              }
              notTechnologiesFilter={notTechnologiesFilter}
              resetOffset={resetOffset}
              setAllTechnologiesFilter={setAllTechnologiesFilter}
              setNotTechnologiesFilter={setNotTechnologiesFilter}
              setTechnologiesFilter={setTechnologiesFilter}
              technologiesFilter={technologiesFilter}
            />,
            <PmsProviderFilter
              pmsProviderFilter={pmsProviderFilter}
              pmsProviderOptions={pmsProviderFilterData}
              resetOffset={resetOffset}
              blurred={
                !isAccountFilterIncluded(
                  AccountFilterEnum.PremiumDataPmsSorftwareProviderFilter,
                )
              }
              setPmsProviderFilter={setPmsProviderFilter}
            />,
            <IndustryAssociationsFilter
              allIndustryAssociationsFilter={allIndustryAssociationsFilter}
              blurred={
                !isAccountFilterIncluded(
                  AccountFilterEnum.IndustryAssociationsFilter,
                )
              }
              industryAssociationsFilter={industryAssociationsFilter}
              notIndustryAssociationsFilter={notIndustryAssociationsFilter}
              resetOffset={resetOffset}
              setAllIndustryAssociationsFilter={
                setAllIndustryAssociationsFilter
              }
              setIndustryAssociationsFilter={setIndustryAssociationsFilter}
              setNotIndustryAssociationsFilter={
                setNotIndustryAssociationsFilter
              }
            />,
            <RangeFilter
              blurred={
                !isAccountFilterIncluded(AccountFilterEnum.YelpNumReviewsFilter)
              }
              min={1}
              rangeFilter={yelpNumReviewsFilter}
              resetOffset={resetOffset}
              setRangeFilter={setYelpNumReviewsFilter}
              step={1}
              title="Yelp — Number of Reviews"
              smbFilter
            />,
            <RangeFilter
              blurred={
                !isAccountFilterIncluded(AccountFilterEnum.YelpStarRatingFilter)
              }
              max={5}
              min={1}
              rangeFilter={yelpStarRatingFilter}
              resetOffset={resetOffset}
              setRangeFilter={setYelpStarRatingFilter}
              step={0.1}
              title="Yelp — Star Rating"
              smbFilter
            />,
            <RangeFilter
              blurred={
                !isAccountFilterIncluded(
                  AccountFilterEnum.GoogleNumReviewsFilter,
                )
              }
              min={1}
              rangeFilter={googleNumReviewsFilter}
              resetOffset={resetOffset}
              setRangeFilter={setGoogleNumReviewsFilter}
              step={1}
              title="Google — Number of Reviews"
              smbFilter
            />,
            <RangeFilter
              blurred={
                !isAccountFilterIncluded(
                  AccountFilterEnum.GoogleStarRatingFilter,
                )
              }
              max={5}
              min={1}
              rangeFilter={googleStarRatingFilter}
              resetOffset={resetOffset}
              setRangeFilter={setGoogleStarRatingFilter}
              step={0.1}
              title="Google — Star Rating"
              smbFilter
            />,
            <RangeFilter
              blurred={
                !isAccountFilterIncluded(
                  AccountFilterEnum.InstagramFollowerCountFilter,
                )
              }
              min={1}
              rangeFilter={instagramFollowerCountFilter}
              resetOffset={resetOffset}
              setRangeFilter={setInstagramFollowerCountFilter}
              step={1}
              title="Instagram — Follower Count"
              smbFilter
            />,
          ]
        : [],
    },
    {
      title: "Filters For You",
      filters: [
        page !== "COLLECTION_SEARCH" && (
          <CollectionsFilter
            blurred={
              !isAccountFilterIncluded(AccountFilterEnum.CollectionsFilter)
            }
            collectionsData={collectionsFilterData!}
            collectionsFilter={collectionsFilter}
            notCollectionsFilter={notCollectionsFilter}
            setCollectionsFilter={setCollectionsFilter}
            setNotCollectionsFilter={setNotCollectionsFilter}
            resetOffset={resetOffset}
          />
        ),
        <ExistsInCrmFilter
          blurred={
            !isAccountFilterIncluded(AccountFilterEnum.ExistsInCrmFilter)
          }
          existsInCrmFilter={existsInCrmFilter}
          resetOffset={resetOffset}
          setExistsInCrmFilter={setExistsInCrmFilter}
          userAccountData={existsInCrmFilterData!}
        />,
        <CustomerRelationshipFilter
          blurred={
            !isAccountFilterIncluded(
              AccountFilterEnum.CustomerRelationshipFilter,
            )
          }
          organizationStatusFilter={organizationStatusFilter}
          setOrganizationStatusFilter={setOrganizationStatusFilter}
          resetOffset={resetOffset}
        />,
        <ExportedCompaniesFilter
          blurred={
            !isAccountFilterIncluded(AccountFilterEnum.ExportedCompaniesFilter)
          }
          exportedCompaniesFilter={exportedCompaniesFilter}
          setExportedCompaniesFilter={setExportedCompaniesFilter}
          resetOffset={resetOffset}
        />,
        <LastActivityDateFilter
          lastActivityDateAfterFilter={lastActivityDateAfterFilter}
          blurred={
            !isAccountFilterIncluded(AccountFilterEnum.ActivityDateFilter)
          }
          setLastActivityDateAfterFilter={setLastActivityDateAfterFilter}
          resetOffset={resetOffset}
          lastActivityDateBeforeFilter={lastActivityDateBeforeFilter}
          setLastActivityDateBeforeFilter={setLastActivityDateBeforeFilter}
          userAccountData={lastActivityDateFilterData!}
        />,
        <OpenOpportunityFilter
          blurred={
            !isAccountFilterIncluded(AccountFilterEnum.OpenOpportunityFilter)
          }
          openOpportunityFilter={openOpportunityFilter}
          resetOffset={resetOffset}
          setOpenOpportunityFilter={setOpenOpportunityFilter}
          userAccountData={openOpportunityFilterData!}
        />,
      ].filter(Boolean) as JSX.Element[],
    },
  ];

  return (
    <>
      <CiroSmbFilterToggle accountFilters={accountFilters} />
      <CiroFilterGroup
        filterSections={filterSections.filter(
          (filterSection) => filterSection.filters.length > 0,
        )}
      />
    </>
  );
};

export default CiroFilterSectionAccountFilters;

import React, { useCallback, useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  CiroTableHeaderCheckboxContainer_CreditsToSpendQuery,
  CiroTableHeaderCheckboxContainer_CreditsToSpendQueryVariables,
  CiroUnlockPremiumDataContent_CreditsToSpendResponseFragmentDoc,
  CompaniesFromFiltersInput,
} from "../../../__generated__/graphql";
import CiroCheckbox from "../../shared/CiroCheckbox";
import {
  CiroDropdownButton,
  CiroDropdownButtonItem,
} from "../../shared/CiroDropdownButton";
import CiroModal from "../../shared/CiroModal";
import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import Loading from "../../shared/Loading";
import {
  ACCOUNTS_MAX_NUM_ROWS,
  Accounts_companiesFromFilters,
  BULK_SELECTION_LIMIT,
} from "../../../routes/accounts/Accounts";
import XIcon from "../../../assets/img/icons/XIcon";
import CiroUnlockPremiumDataContent, {
  CiroCreditUseCaseEnum,
  CiroUnlockPremiumDataContent_CreditsToSpendResponse,
} from "../../shared/CiroUnlockPremiumData/CiroUnlockPremiumDataContent";
import pluralize from "pluralize";
import CiroUnlockPremiumDataSubmit from "../../shared/CiroUnlockPremiumData/CiroUnlockPremiumDataSubmit";
import { useFragment } from "../../../__generated__";

const CiroTableHeaderCheckboxContainer_CreditsToSpend = gql`
  query CiroTableHeaderCheckboxContainer_CreditsToSpend(
    $companyQuery: CompaniesFromFiltersAndCountInput
  ) {
    creditsToSpend(companyQuery: $companyQuery) {
      currentBalance
      unlockPms {
        creditsToSpend
        accountsCount
      }
      ...CiroUnlockPremiumDataContent_CreditsToSpendResponse
    }
  }
  ${CiroUnlockPremiumDataContent_CreditsToSpendResponse}
`;

interface ICiroTableHeaderCheckboxContainerProps {
  canSelectAccountsInBulk?: boolean | null;
  canSelectMoreRecords: boolean;
  companyQuery?: CompaniesFromFiltersInput;
  displayedRecords: any[];
  fetchMaxNumberRecords: () => void;
  firstMaxNumberRecordsLoading: boolean;
  handleTopCheckboxSelection: () => void;
  lockedAccounts: number;
  numberVisibleChecked: number;
  offset: number;
  setOffset: (v: number) => void;
  startIdx: number;
  totalCount: number;
  totalNumberChecked: number;
  unselectAll: () => void;
  unselectVisible: () => void;
}

function CiroTableHeaderCheckboxContainer({
  canSelectAccountsInBulk,
  canSelectMoreRecords,
  displayedRecords,
  fetchMaxNumberRecords,
  companyQuery,
  firstMaxNumberRecordsLoading,
  handleTopCheckboxSelection,
  lockedAccounts,
  numberVisibleChecked,
  offset,
  setOffset,
  startIdx,
  totalCount,
  totalNumberChecked,
  unselectAll,
  unselectVisible,
}: ICiroTableHeaderCheckboxContainerProps) {
  const [accountsSelectedModalOpen, setAccountsSelectedModalOpen] =
    useState(false);

  const selectFirstMaxNumberAccounts = useCallback(() => {
    if (!companyQuery) {
      throw new Error("Missing filters");
    }

    setAccountsSelectedModalOpen(true);
    fetchMaxNumberRecords();
  }, [fetchMaxNumberRecords, companyQuery]);

  const [
    fetchCreditsToSpend,
    { data: fetchedCreditData, loading: fetchCreditsLoading },
  ] = useLazyQuery<
    CiroTableHeaderCheckboxContainer_CreditsToSpendQuery,
    CiroTableHeaderCheckboxContainer_CreditsToSpendQueryVariables
  >(CiroTableHeaderCheckboxContainer_CreditsToSpend);

  useEffect(() => {
    if (lockedAccounts) {
      const variables = {
        companyQuery: {
          filters: companyQuery || {},
          offset: startIdx,
          limit: BULK_SELECTION_LIMIT,
        },
      };

      fetchCreditsToSpend({
        variables,
        fetchPolicy: "network-only",
      });
    }
  }, [fetchCreditsToSpend, companyQuery, lockedAccounts, startIdx]);

  const currentBalance = fetchedCreditData?.creditsToSpend?.currentBalance || 0;
  const unlockPmsCredits =
    fetchedCreditData?.creditsToSpend?.unlockPms.creditsToSpend || 0;

  const creditsToSpendContent = useFragment(
    CiroUnlockPremiumDataContent_CreditsToSpendResponseFragmentDoc,
    fetchedCreditData?.creditsToSpend,
  );

  const noDropdownActions = !canSelectAccountsInBulk && !Boolean(numberVisibleChecked) && !Boolean(totalNumberChecked);

  return (
    <>
      <CiroDropdownButton
        disabled={totalNumberChecked === 0 && !canSelectMoreRecords}
        customPadding={noDropdownActions ? classNames("ciro-v1-pl-4", "ciro-v1-pr-4", "ciro-v1-py-2") : undefined}
        hideChevron={noDropdownActions}
        prettyDisable={true}
        onLabelClick={handleTopCheckboxSelection}
        menuPosition="right"
        label={
          <CiroCheckbox
            className="test-table-top-checkbox"
            checked={totalNumberChecked > 0}
            indeterminate={
              totalNumberChecked !== 0 &&
              numberVisibleChecked !== displayedRecords.length
            }
          />
        }
      >
        {canSelectMoreRecords && canSelectAccountsInBulk && (
          <CiroDropdownButtonItem
            onClick={selectFirstMaxNumberAccounts}
            analyticsField={`Select first N accounts`}
          >
            Select {startIdx + 1}-
            {Math.min(totalCount, startIdx + BULK_SELECTION_LIMIT)}
          </CiroDropdownButtonItem>
        )}
        {numberVisibleChecked > 0 && (
          <CiroDropdownButtonItem
            onClick={unselectVisible}
            analyticsField="Unselect all"
          >
            Unselect visible
          </CiroDropdownButtonItem>
        )}
        {totalNumberChecked > 0 && (
          <CiroDropdownButtonItem
            onClick={unselectAll}
            analyticsField="Unselect all"
          >
            Unselect all
          </CiroDropdownButtonItem>
        )}
      </CiroDropdownButton>
      <CiroModal
        isOpen={accountsSelectedModalOpen || firstMaxNumberRecordsLoading}
        onClose={() => setAccountsSelectedModalOpen(false)}
      >
        {firstMaxNumberRecordsLoading && <Loading size="SMALL" />}
        {!firstMaxNumberRecordsLoading && (
          <>
            <div
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-font-medium",
                "ciro-v1-justify-between",
                "ciro-v1-pb-4",
                "ciro-v1-text-lg",
              )}
            >
              <span>
                Accounts {startIdx + 1}-
                {Math.min(totalCount, startIdx + BULK_SELECTION_LIMIT)} selected
              </span>
              <span>
                <CiroButton
                  style={CiroButtonStyleEnum.UNSTYLED}
                  analyticsField="Close N selected modal"
                  onClick={() => setAccountsSelectedModalOpen(false)}
                >
                  <XIcon />
                </CiroButton>
              </span>
            </div>
            {Boolean(lockedAccounts) && (
              <div className={classNames("ciro-v1-pb-4")}>
                <div className={classNames("ciro-v1-text-base")}>
                  Note: Only unlocked records were selected. You can unlock the
                  remaining {lockedAccounts}{" "}
                  {pluralize("account", lockedAccounts)}.
                </div>
                <CiroUnlockPremiumDataContent
                  isLoading={fetchCreditsLoading}
                  creditsToSpend={creditsToSpendContent}
                  creditActions={[CiroCreditUseCaseEnum.UNLOCK_PMS]}
                />
              </div>
            )}
            <div className={classNames("ciro-v1-flex", "ciro-v1-justify-end")}>
              {Boolean(lockedAccounts) && (
                <div
                  className={classNames({
                    "ciro-v1-pr-2": Boolean(
                      totalCount > startIdx + BULK_SELECTION_LIMIT,
                    ),
                  })}
                >
                  <CiroUnlockPremiumDataSubmit
                    companyQuery={{
                      filters: companyQuery || {},
                      limit: BULK_SELECTION_LIMIT,
                      offset: startIdx,
                    }}
                    disabled={
                      fetchCreditsLoading || currentBalance < unlockPmsCredits
                    }
                    onCompleted={selectFirstMaxNumberAccounts}
                    refetchQueries={[
                      {
                        query: Accounts_companiesFromFilters,
                        variables: {
                          filters: companyQuery,
                          limit: ACCOUNTS_MAX_NUM_ROWS,
                          offset,
                        },
                      },
                    ]}
                  />
                </div>
              )}
              {totalCount > startIdx + BULK_SELECTION_LIMIT && (
                <CiroButton
                  analyticsField="Change offset from next N accounts"
                  onClick={() => {
                    setOffset(startIdx + BULK_SELECTION_LIMIT);
                    setAccountsSelectedModalOpen(false);
                  }}
                >
                  Jump to account {startIdx + BULK_SELECTION_LIMIT + 1}
                </CiroButton>
              )}
            </div>
          </>
        )}
      </CiroModal>
    </>
  );
}

export default CiroTableHeaderCheckboxContainer;

import React from "react";
import classNames from "classnames";
import { analytics } from "../../utils/vendors";
import { useNavigate } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import CiroTooltipContainer from "./CiroTooltipContainer";

export enum CiroButtonStyleEnum {
  INVERTED,
  LOUD,
  MAGIC,
  PRIMARY,
  UNSTYLED,
  INVERTED_LOUD,
  BORDERLESS,
  DELETE,
  DANGER,
  SECONDARY,
  SOFT,
}

export enum CiroButtonGroupEnum {
  FIRST,
  LAST,
  MIDDLE,
  SOLO,
}

const getCiroButtonClasses = ({
  buttonGroup,
  style,
  customPaddingY = "",
}: {
  buttonGroup?: CiroButtonGroupEnum;
  style: CiroButtonStyleEnum;
  customPaddingY?: string;
}) => {
  if (style === CiroButtonStyleEnum.UNSTYLED) {
    return "";
  }

  const styleClasses = {
    [CiroButtonStyleEnum.INVERTED_LOUD]: [
      "enabled:ciro-v1-border-amber-500",
      "enabled:ciro-v1-text-amber-500",
      "enabled:ciro-v1-bg-white",
      "enabled:hover:ciro-v1-bg-neutral-100",
    ],
    [CiroButtonStyleEnum.INVERTED]: [
      "enabled:ciro-v1-border-gray-300",
      "enabled:ciro-v1-text-white",
      "enabled:ciro-v1-bg-black",
      "enabled:hover:ciro-v1-bg-white",
      "enabled:hover:ciro-v1-text-black",
    ],
    [CiroButtonStyleEnum.LOUD]: [
      "enabled:ciro-v1-text-white",
      "enabled:ciro-v1-bg-orange-600",
      "enabled:ciro-v1-border-orange-600",
      "enabled:hover:ciro-v1-bg-orange-700",
    ],
    [CiroButtonStyleEnum.MAGIC]: [
      "enabled:ciro-v1-text-purple-500",
      "enabled:hover:ciro-v1-text-purple-800",
    ],
    [CiroButtonStyleEnum.PRIMARY]: [
      "enabled:ciro-v1-border-gray-300",
      "enabled:ciro-v1-text-neutral-600",
      "enabled:ciro-v1-bg-white",
      "enabled:hover:ciro-v1-bg-gray-300",
    ],
    [CiroButtonStyleEnum.SECONDARY]: [
      "enabled:ciro-v1-bg-white",
      "enabled:ciro-v1-border-amber-500",
      "enabled:ciro-v1-text-amber-500",
      "enabled:hover:ciro-v1-bg-amber-500/40",
      "enabled:hover:ciro-v1-border-amber-500/40",
    ],
    [CiroButtonStyleEnum.SOFT]: [
      "enabled:ciro-v1-bg-amber-500/20",
      "enabled:ciro-v1-border-amber-500/20",
      "enabled:ciro-v1-text-amber-500",
      "enabled:hover:ciro-v1-bg-amber-500/40",
      "enabled:hover:ciro-v1-border-amber-500/40",
    ],
    [CiroButtonStyleEnum.DELETE]: [
      "enabled:ciro-v1-text-white",
      "enabled:ciro-v1-bg-red-500",
      "enabled:hover:ciro-v1-bg-red-700",
    ],
    [CiroButtonStyleEnum.DANGER]: [
      "enabled:ciro-v1-text-white",
      "enabled:ciro-v1-bg-red-500",
      "enabled:hover:ciro-v1-bg-red-700",
    ],
    [CiroButtonStyleEnum.BORDERLESS]: [
      "enabled:ciro-v1-bg-transparent",
      "enabled:ciro-v1-text-neutral-600",
      "enabled:hover:ciro-v1-bg-gray-100",
    ],
  }[style];

  return classNames(
    [
      "ciro-v1-cursor-pointer",
      "disabled:ciro-v1-cursor-not-allowed",
      "ciro-v1-no-underline",
      "ciro-v1-duration-200",
      "ciro-v1-flex",
      "ciro-v1-px-4",
      "ciro-v1-text-sm",
      "ciro-v1-transition",
      "disabled:ciro-v1-bg-neutral-300",
      "disabled:ciro-v1-text-zinc-500",
      "disabled:ciro-v1-border-zinc-500",
      "enabled:hover:ciro-v1-no-underline",
      "hover:ciro-v1-ease",
      customPaddingY,
    ],
    {
      "ciro-v1-py-2.5": !customPaddingY,
      "ciro-v1-rounded-lg":
        buttonGroup === CiroButtonGroupEnum.SOLO || !buttonGroup,
      "ciro-v1-rounded-l-lg": buttonGroup === CiroButtonGroupEnum.FIRST,
      "ciro-v1-rounded-r-lg": buttonGroup === CiroButtonGroupEnum.LAST,
      "ciro-v1-border-1": style !== CiroButtonStyleEnum.BORDERLESS,
    },
    styleClasses,
  );
};

const CiroButton = ({
  analyticsField,
  analyticsProps = {},
  children,
  customClassName,
  customFlexClass = classNames(
    "ciro-v1-items-center",
    "ciro-v1-justify-center",
  ),
  disabled = false,
  disabledTooltip = "",
  href,
  onClick,
  onBlur,
  customPaddingY = "",
  style = CiroButtonStyleEnum.PRIMARY,
  buttonGroup,
  passedRef = null,
}: {
  analyticsField: string;
  analyticsProps?: Object;
  children: any;
  customClassName?: string;
  customFlexClass?: string;
  customPaddingY?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  href?: string;
  onClick?: () => void;
  onBlur?: () => void;
  style?: CiroButtonStyleEnum;
  buttonGroup?: CiroButtonGroupEnum;
  passedRef?: any;
}) => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const handleButtonClick = () => {
    posthog?.capture("buttonClicked", {
      value: analyticsField,
      ...analyticsProps,
    });
    analytics.track("buttonClicked", {
      value: analyticsField,
      ...analyticsProps,
    });
    onClick && onClick();
  };
  const buttonClasses = classNames(
    getCiroButtonClasses({ style, customPaddingY, buttonGroup }),
    customClassName,
    customFlexClass,
  );
  const button = (
    <button
      ref={passedRef}
      disabled={disabled}
      className={buttonClasses}
      onClick={handleButtonClick}
      onBlur={onBlur}
    >
      {children}
    </button>
  );
  const innerButton = href ? (
    <div
      onClick={() => {
        handleButtonClick();
        if (/^(http|www|mailto|tel)/.test(href)) {
          // Don't use React Router to navigate to external URLs
          window.open(href, "_blank");
        } else {
          navigate(href);
        }
      }}
    >
      {button}
    </div>
  ) : (
    button
  );
  if (disabled && disabledTooltip) {
    return (
      <CiroTooltipContainer tooltip={disabledTooltip}>
        {innerButton}
      </CiroTooltipContainer>
    );
  } else {
    return innerButton;
  }
};

export default CiroButton;

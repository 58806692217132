import classNames from "classnames";

export enum loadingBarHeight {
  SMALL,
  MEDIUM,
  LARGE,
}

interface LoadingBarProps {
  customFillPercentage?: number;
  height?: number;
  showPercentage?: boolean;
  longLoad?: boolean; // New prop
}

const LoadingBar = ({
  customFillPercentage,
  height = loadingBarHeight.SMALL,
  showPercentage = false,
  longLoad = false, // Default value for longLoad
}: LoadingBarProps) => {
  return (
    <div
      className={classNames(
        "ciro-v1-w-full",
        "ciro-v1-bg-zinc-100",
        "ciro-v1-rounded-full",
        {
          "ciro-v1-h-2": height === loadingBarHeight.SMALL,
          "ciro-v1-h-3": height === loadingBarHeight.MEDIUM,
          "ciro-v1-h-4": height === loadingBarHeight.LARGE,
        },
      )}
    >
      <div
        className={classNames(
          "ciro-v1-bg-orange-400",
          {
            "ciro-v1-h-2": height === loadingBarHeight.SMALL,
            "ciro-v1-h-3": height === loadingBarHeight.MEDIUM,
            "ciro-v1-h-4": height === loadingBarHeight.LARGE,
          },
          "ciro-v1-rounded-full",
          "ciro-v1-text-white",
          "ciro-v1-text-xs",
          "ciro-v1-flex",
          "ciro-v1-justify-center",
          customFillPercentage === undefined && !longLoad && "ciro-v1-animate-load-10s",
          customFillPercentage === undefined && longLoad && "ciro-v1-animate-load-20s",
        )}
        //Tailwind does not support dynamic style strings, so we need to use basic react styling
        style={
          customFillPercentage !== undefined
            ? { width: Math.round(customFillPercentage * 100) + "%" }
            : { width: "0%" }
        }
      >
        {showPercentage &&
          customFillPercentage &&
          Math.round(customFillPercentage * 100) + "%"}
      </div>
    </div>
  );
};

export default LoadingBar;

import React from "react";
import classNames from "classnames";

interface ICiroPopoutDropdownProps {
  children: any;
  setIsOpen: (v: boolean) => void;
  isOpen: boolean;
  parent: any;
  customWidth?: string;
  isGray?: boolean;
}

const CiroPopoutDropdown = ({
  children,
  setIsOpen,
  isOpen,
  parent,
  customWidth,
  isGray = false,
}: ICiroPopoutDropdownProps) => {
  return (
    <div>
      <div
        className={classNames("ciro-v1-relative", {
          "ciro-v1-z-20": isOpen,
        })}
      >
        {parent}
      </div>
      <div
        className={classNames([
          "ciro-v1-flex-col",
          "ciro-v1-flex",
          "ciro-v1-items-end",
        ])}
      >
        {isOpen && (
          <>
            <div
              className={classNames(
                {
                  "ciro-v1-bg-gray-100": isGray,
                  "ciro-v1-bg-white": !isGray,
                },
                "ciro-v1-fixed",
                "ciro-v1-overflow-scroll",
                "ciro-v1-shadow-lg",
                "ciro-v1-z-20",
                "lg:ciro-v1-absolute",
                "lg:ciro-v1-inset-auto",
                "sm:ciro-v1-ml-32",
                customWidth,
                {
                  "ciro-v1-inset-0": !customWidth,
                  "ciro-v1-p-6": customWidth !== "ciro-v1-w-full",
                  "ciro-v1-p-4": customWidth === "ciro-v1-w-full",
                  "lg:ciro-v1-mt-4": customWidth !== "ciro-v1-w-full",
                  "ciro-v1-rounded-md": customWidth !== "ciro-v1-w-full",
                  "lg:ciro-v1-w-3/5": !customWidth,
                },
              )}
            >
              <div>{children}</div>
            </div>
            <div
              className={classNames([
                "ciro-v1-bg-gray-500",
                "ciro-v1-fixed",
                "ciro-v1-inset-0",
                "ciro-v1-opacity-50",
                "ciro-v1-z-10",
              ])}
              onClick={() => setIsOpen(!isOpen)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CiroPopoutDropdown;

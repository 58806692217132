import classNames from "classnames";
import { TextTypeFilterEnum } from "../../../../reactHooks/filters/accounts/useAccountFilters";
import {
  CiroDropdownButton,
  CiroDropdownButtonItem,
} from "../../CiroDropdownButton";
import CiroSearchBarLabel from "./CiroSearchBarLabel";
import { SparklesIcon } from "@heroicons/react/24/outline";
import ContentSearchIcon from "../../../../assets/img/icons/ContentSearchIcon";
import KeywordSearchIcon from "../../../../assets/img/icons/KeywordSearchIcon";
import toast from "react-hot-toast";

interface ICiroSearchBarDropdownOptions {
  canSearchWebPageContent: boolean;
  setTextTypeFilter: (textTypeFilter: TextTypeFilterEnum) => void;
  textTypeFilter: TextTypeFilterEnum | null;
}

const searchTypeToTitle = {
  [TextTypeFilterEnum.KEYWORD]: "Keyword search",
  [TextTypeFilterEnum.WEB]: "Web page search",
  [TextTypeFilterEnum.SEMANTIC]: "AI search",
};
const searchTypeToSupportingText = {
  [TextTypeFilterEnum.KEYWORD]: "Search by name, website, or phone",
  [TextTypeFilterEnum.WEB]: "Search by website page content",
  [TextTypeFilterEnum.SEMANTIC]: "Describe in natural language",
};
const searchTypeToIcon = {
  [TextTypeFilterEnum.KEYWORD]: <KeywordSearchIcon />,
  [TextTypeFilterEnum.WEB]: <ContentSearchIcon />,
  [TextTypeFilterEnum.SEMANTIC]: (
    <div className={classNames("ciro-v1-text-orange-500", "ciro-v1-w-5")}>
      <SparklesIcon />
    </div>
  ),
};

const CiroSearchBarDropdownOption = ({
  canSearchWebPageContent,
  searchType,
  setTextTypeFilter,
}: {
  canSearchWebPageContent: boolean;
  searchType: TextTypeFilterEnum;
  setTextTypeFilter: (textTypeFilter: TextTypeFilterEnum) => void;
}) => {
  const title = searchTypeToTitle[searchType];
  const supportingText = searchTypeToSupportingText[searchType];
  const icon = searchTypeToIcon[searchType];

  return (
    <CiroDropdownButtonItem
      onClick={() => {
        if (!canSearchWebPageContent && searchType === TextTypeFilterEnum.WEB) {
          toast.error("You don't have access to that kind of search");
          return;
        }
        setTextTypeFilter(searchType);
      }}
      analyticsField={`Set search type to ${searchType}`}
    >
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-flex-row",
          "ciro-v1-items-start",
          "ciro-v1-gap-1",
        )}
      >
        {icon}
        <div className={classNames("ciro-v1-flex", "ciro-v1-flex-col")}>
          <div>{title}</div>
          <div>{supportingText}</div>
        </div>
      </div>
    </CiroDropdownButtonItem>
  );
};

const CiroSearchBarDropdownOptions = ({
  canSearchWebPageContent,
  setTextTypeFilter,
  textTypeFilter,
}: ICiroSearchBarDropdownOptions) => {
  const searchOptionEnumValues = [
    TextTypeFilterEnum.SEMANTIC,
    TextTypeFilterEnum.KEYWORD,
    ...(canSearchWebPageContent ? [TextTypeFilterEnum.WEB] : []),
  ];

  return (
    <CiroDropdownButton
      customPadding={classNames("ciro-v1-px-3", "ciro-v1-py-[9px]")}
      customClassName={classNames("ciro-v1-rounded-r-none")}
      customButtonClassName={classNames(
        "ciro-v1-border-r-0",
        "ciro-v1-rounded-r-none",
        "ciro-v1-shadow-none",
        "ciro-v1-flex",
        "ciro-v1-flex-row",
        "ciro-v1-items-center",
        "ciro-v1-min-h-[2.75rem]",
        "ciro-v1-bg-gray-50",
      )}
      menuPosition="right"
      label={<CiroSearchBarLabel textTypeFilter={textTypeFilter} />}
    >
      {searchOptionEnumValues.map((searchType) => {
        return (
          <CiroSearchBarDropdownOption
            key={searchType}
            canSearchWebPageContent={canSearchWebPageContent}
            searchType={searchType}
            setTextTypeFilter={setTextTypeFilter}
          />
        );
      })}
    </CiroDropdownButton>
  );
};

export default CiroSearchBarDropdownOptions;

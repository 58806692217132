import { useContext } from "react";
import EnrichmentFlowContext from "../../contexts/EnrichmentFlowContext";
import { getStepSchema } from "../../services/enrichments/getStepSchema";

const textIsIncluded = ({
  optionLabel,
  search,
}: {
  optionLabel: string;
  search: string;
}) => {
  if (!search) return true;
  return RegExp(search, "i").test(optionLabel);
};

export const useEnrichmentFlowStepInputs = ({ search }: { search: string }) => {
  const { enrichmentFlowWithSteps } = useContext(EnrichmentFlowContext);
  const enrichmentStepsWithTechnique =
    enrichmentFlowWithSteps?.enrichmentSteps.map((step) => {
      return {
        stepId: step?.id,
        stepName: step?.name,
        enrichmentTechnique: step?.enrichment_technique,
      };
    }) || [];
  let idx = 0;
  const options = [];
  for (const step of enrichmentStepsWithTechnique) {
    const { fields } = getStepSchema(step.enrichmentTechnique);
    for (const field of fields) {
      const optionLabel = `${step.stepName} > ${field.label}`;

      if (!textIsIncluded({ optionLabel, search })) {
        continue;
      }
      const option = {
        optionIdx: idx,
        stepId: step.stepId,
        stepName: step.stepName,
        stepInput: field.name,
        optionLabel: optionLabel,
      };
      options.push(option);
      idx += 1;
    }
    if (fields.length === 0) {
      const optionLabel = String(step.stepName);
      if (!textIsIncluded({ optionLabel, search })) {
        continue;
      }
      const option = {
        optionIdx: idx,
        stepId: step.stepId,
        stepName: step.stepName,
        stepInput: null,
        optionLabel: optionLabel,
      };
      options.push(option);
      idx += 1;
    }
  }
  return options;
};

import { gql, useMutation, useQuery } from "@apollo/client";
import classNames from "classnames";
import * as Yup from "yup";
import { useForm, UseFormWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import {
  CrmRecordType,
  FilterLogicInput,
  IntegrationType,
  MobileNumbersWorkflowForm_OrgContactObjectFieldsQuery,
  MobileNumbersWorkflowForm_OrgContactObjectFieldsQueryVariables,
  MobileNumbersWorkflowForm_PhoneNumberRequestWorkflowQuery,
  MobileNumbersWorkflowForm_PhoneNumberRequestWorkflowQueryVariables,
  MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflowMutation,
  MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflowMutationVariables,
  OrgContactObjectType,
  PhoneNumberRequestSegmentTypeEnum,
  PhoneNumberRequestWorkflowScheduleEnum,
} from "../../__generated__/graphql";
import CiroErrorMsg from "../shared/forms/CiroErrorMsg";
import { MobileNumbersWorkflowsTable_Workflows } from "../mobileNumbers/MobileNumbersTable/MobileNumbersWorkflowsTable";
import { useNavigate } from "react-router-dom";
import StartWorkflowFormSection from "./StartWorkflowFormSection";
import useOrgContactObjectFields, {
  OrgContactObjectFields_Organization,
} from "../../reactHooks/filters/mobileNumbers/useOrgContactObjectFields";
import WriteSettingsWorkflowFormSection from "./WriteSettingsWorkflowFormSection";
import FilterOptionsWorkflowFormSection from "./FilterOptionsWorkflowFormSection";
import {
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import ScheduleWorkflowFormSection from "./ScheduleWorkflowFormSection";
import FilterCutoffDateOption from "./FilterCutoffDateOption";

const PhoneNumberRequestWorkflowAttributesSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  org_contact_object_type: Yup.string()
    .required("CRM object type is required")
    .oneOf(["ACCOUNT", "CONTACT", "LEAD"], "CRM object type is required"),
  overwrite: Yup.string()
    .required("Write option is required")
    .oneOf(["YES", "SAME_FIELD_ONLY", "NO"], "Write option is required"),
  timestamp: Yup.boolean().nullable(),
  timestamp_field: Yup.string().when("timestamp", {
    is: (val: boolean) => val,
    then: (schema) => schema.required("Timestamp field is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  default_phone_write_field: Yup.string().when("overwrite", {
    is: (val: string) => val === "YES" || val === "SAME_FIELD_ONLY",
    then: (schema) => schema.required("Default phone write field is required"),
    otherwise: (schema) => schema.nullable(),
  }),
  segmentation: Yup.object().shape({
    segment_type: Yup.mixed()
      .oneOf(
        [
          PhoneNumberRequestSegmentTypeEnum.CallDisposition,
          PhoneNumberRequestSegmentTypeEnum.CrmRecordValues,
        ],
        "Segmentation type is required",
      )
      .required("Segmentation type is required"),
    call_dispositions: Yup.array()
      .of(Yup.string().required())
      .when("segment_type", {
        is: PhoneNumberRequestSegmentTypeEnum.CallDisposition,
        then: (schema) =>
          schema
            .min(1, "At least one call disposition is required")
            .required("Call dispositions are required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    crm_record_values: Yup.array()
      .of(Yup.string().nullable())
      .when("segment_type", {
        is: PhoneNumberRequestSegmentTypeEnum.CrmRecordValues,
        then: (schema) =>
          schema
            .min(1, "At least one CRM object value is required")
            .required("CRM object values are required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    crm_record_field: Yup.string().when("segment_type", {
      is: PhoneNumberRequestSegmentTypeEnum.CrmRecordValues,
      then: (schema) => schema.required("CRM object field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    crm_record_field_type: Yup.string().when("segment_type", {
      is: PhoneNumberRequestSegmentTypeEnum.CrmRecordValues,
      then: (schema) => schema.required("CRM object field type is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    crm_record_type: Yup.string().when("segment_type", {
      is: PhoneNumberRequestSegmentTypeEnum.CrmRecordValues,
      then: (schema) => schema.required("CRM object type is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    schedule_enabled: Yup.boolean().nullable(),
    schedule: Yup.string()
      .oneOf(
        ["HOURLY", "DAILY", "WEEKLY"],
        "Schedule must be hourly, daily, or weekly",
      )
      .when("schedule_enabled", {
        is: (val: boolean) => val,
        then: (schema) => schema.required("Please specify a schedule"),
        otherwise: (schema) => schema.nullable(),
      }),
  }),
});

const MobileNumbersWorkflowForm_PhoneNumberRequestWorkflow = gql`
  query MobileNumbersWorkflowForm_PhoneNumberRequestWorkflow(
    $id: Int!
    $skipWorkflowLoad: Boolean!
  ) {
    phoneNumberRequestWorkflow(id: $id) @skip(if: $skipWorkflowLoad) {
      id
      name
      segmentation
      timestamp_field
      org_contact_object_type
      overwrite
      default_phone_write_field
      schedule
      filter_logic {
        filter_object
        filter_property
        filter_type
        filter_op
        filter_value
      }
      nextRunTimestamp
    }
    organization {
      organizationMergeIntegration {
        integration
      }
    }
  }
`;

const MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflow = gql`
  mutation MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflow(
    $input: UpsertPhoneNumberRequestWorkflowInput!
  ) {
    upsertPhoneNumberRequestWorkflow(input: $input) {
      success
      message
    }
  }
`;

const MobileNumbersWorkflowForm_OrgContactObjectFields = gql`
  query MobileNumbersWorkflowForm_OrgContactObjectFields(
    $customObjectType: OrgContactObjectType
  ) {
    organization {
      ...OrgContactObjectFields_Organization
    }
  }
  ${OrgContactObjectFields_Organization}
`;

export interface IMobileNumbersWorkflowFormValues {
  name: string;
  overwrite: string;
  default_phone_write_field: string | null;
  timestamp_field: string | null;
  org_contact_object_type: OrgContactObjectType;
  timestamp: boolean;
  segmentation: {
    segment_type: PhoneNumberRequestSegmentTypeEnum | null;
    call_dispositions: string[] | null;
    crm_record_values: (string | null)[] | null;
    crm_record_field: string | null;
    crm_record_field_type: string | null;
    crm_record_type: string | null;
  };
  date_filter: Date | null;
  schedule_enabled: boolean | null;
  schedule: string | null;
}

export interface ISharedWorkflowFormProperties {
  errors: FieldErrors<IMobileNumbersWorkflowFormValues>;
  integrationName: string;
  isHubspot: boolean;
  isSalesforce: boolean;
  orgContactObjectFields: ReturnType<typeof useOrgContactObjectFields>;
  register: UseFormRegister<IMobileNumbersWorkflowFormValues>;
  setValue: UseFormSetValue<IMobileNumbersWorkflowFormValues>;
  trigger: UseFormTrigger<IMobileNumbersWorkflowFormValues>;
  watch: UseFormWatch<IMobileNumbersWorkflowFormValues>;
  workflowId: number | null;
}

interface IMobileNumbersWorkflowFormProps {
  workflowId: string | undefined;
}

const MobileNumbersWorkflowForm = ({
  workflowId,
}: IMobileNumbersWorkflowFormProps) => {
  const navigate = useNavigate();
  const [hubspotIntegration, setHubspotIntegration] = useState(false);

  const {
    data: workflowData,
    loading: workflowLoading,
    error: workflowError,
  } = useQuery<
    MobileNumbersWorkflowForm_PhoneNumberRequestWorkflowQuery,
    MobileNumbersWorkflowForm_PhoneNumberRequestWorkflowQueryVariables
  >(MobileNumbersWorkflowForm_PhoneNumberRequestWorkflow, {
    variables: {
      id: Number(workflowId ? workflowId : "0"),
      skipWorkflowLoad: !workflowId,
    },
    onError: (error) => {
      setError("root", { message: error.message });
    },
  });

  const [upsertPhoneNumberRequestWorkflow, { loading: upsertLoading }] =
    useMutation<
      MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflowMutation,
      MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflowMutationVariables
    >(MobileNumbersWorkflowForm_UpsertPhoneNumberRequestWorkflow, {
      refetchQueries: [MobileNumbersWorkflowsTable_Workflows],
    });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
    watch,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(PhoneNumberRequestWorkflowAttributesSchema),
    defaultValues: {
      name: "",
      overwrite: "NO",
      default_phone_write_field: null,
      timestamp: false,
      timestamp_field:
        workflowData?.phoneNumberRequestWorkflow?.timestamp_field || null,
      org_contact_object_type:
        workflowData?.phoneNumberRequestWorkflow?.org_contact_object_type ||
        null,
      segmentation: {
        segment_type: null,
        call_dispositions: null,
        crm_record_values: null,
        crm_record_field: null,
        crm_record_field_type: null,
        crm_record_type: null,
      },
      date_filter: null,
    } as IMobileNumbersWorkflowFormValues,
  });

  const getDateFilter = (
    data: MobileNumbersWorkflowForm_PhoneNumberRequestWorkflowQuery,
  ) => {
    const date = new Date(
      data?.phoneNumberRequestWorkflow?.filter_logic?.find((filter) => {
        return filter.filter_type === "Date" && filter.filter_op === ">=";
      })?.filter_value[0] || "",
    );
    if (date.toString() === "Invalid Date") {
      return null;
    }
    return date;
  };

  const isHubspot = useMemo(
    () =>
      workflowData?.organization?.organizationMergeIntegration?.integration ===
      IntegrationType.HubSpot,
    [workflowData],
  );

  const isSalesforce = useMemo(
    () =>
      workflowData?.organization?.organizationMergeIntegration?.integration ===
      IntegrationType.Salesforce,
    [workflowData],
  );

  const org_contact_object_type = watch("org_contact_object_type");
  watch("segmentation"); // needed for useEffect to trigger

  const {
    data: orgContactObjectFieldsData,
    loading: orgContactObjectFieldsLoading,
  } = useQuery<
    MobileNumbersWorkflowForm_OrgContactObjectFieldsQuery,
    MobileNumbersWorkflowForm_OrgContactObjectFieldsQueryVariables
  >(MobileNumbersWorkflowForm_OrgContactObjectFields, {
    variables: {
      customObjectType:
        (org_contact_object_type as OrgContactObjectType | null) ||
        OrgContactObjectType.Contact,
    },
  });

  const orgContactObjectFields = useOrgContactObjectFields({
    organization: orgContactObjectFieldsData?.organization || null,
    isSalesforce: isSalesforce,
    isHubspot: isHubspot,
  });

  const onSubmit = useCallback(
    (data: IMobileNumbersWorkflowFormValues) => {
      // Separating this since the types from schema not matching with valid types that get through.
      data as IMobileNumbersWorkflowFormValues;

      const getLogicFilters = (data: IMobileNumbersWorkflowFormValues) => {
        return data.date_filter
          ? ({
              filter_object:
                data.segmentation.segment_type ===
                PhoneNumberRequestSegmentTypeEnum.CrmRecordValues
                  ? data.segmentation.crm_record_type
                  : "CALL",
              filter_property: hubspotIntegration
                ? data.segmentation.segment_type ===
                  PhoneNumberRequestSegmentTypeEnum.CallDisposition
                  ? "hs_timestamp"
                  : "createdate"
                : "CreatedDate",
              filter_type: "Date",
              filter_op: ">=",
              filter_value: [data.date_filter.toString()],
            } as FilterLogicInput)
          : [];
      };

      const {
        segmentation: {
          call_dispositions,
          crm_record_values,
          crm_record_field,
          crm_record_field_type,
          crm_record_type,
          segment_type,
        },
      } = data;

      const formattedSegmentation = {
        callDisposition:
          segment_type === PhoneNumberRequestSegmentTypeEnum.CallDisposition
            ? {
                segment_type: segment_type,
                call_dispositions: call_dispositions as string[],
              }
            : null,
        crmRecordValues:
          segment_type === PhoneNumberRequestSegmentTypeEnum.CrmRecordValues
            ? {
                segment_type,
                crm_record_type: crm_record_type as CrmRecordType,
                crm_record_field: crm_record_field as string,
                crm_record_field_type: crm_record_field_type as string,
                crm_record_values: crm_record_values as (string | null)[],
              }
            : null,
      };

      upsertPhoneNumberRequestWorkflow({
        variables: {
          input: {
            id: workflowId ? Number(workflowId) : undefined,
            name: data.name,
            segmentation: formattedSegmentation,
            org_contact_object_type: data.org_contact_object_type,
            overwrite: data.overwrite,
            timestamp_field: data.timestamp_field,
            default_phone_write_field: data.default_phone_write_field,
            filter_logic: getLogicFilters(data) as FilterLogicInput[],
            schedule:
              data.schedule as PhoneNumberRequestWorkflowScheduleEnum | null,
          },
        },
        onCompleted: (_data) => {
          navigate(`/enrich-crm/workflows`);
        },
        onError: (_error) => {
          setError("root", {
            message: "Something went wrong. Please contact the Ciro Team",
          });
        },
        refetchQueries: [MobileNumbersWorkflowsTable_Workflows],
      });
    },
    [
      upsertPhoneNumberRequestWorkflow,
      workflowId,
      navigate,
      setError,
      hubspotIntegration,
    ],
  );

  useEffect(() => {
    setValue(
      "org_contact_object_type",
      workflowData?.phoneNumberRequestWorkflow?.org_contact_object_type ||
        OrgContactObjectType.Contact,
    );
    setValue(
      "overwrite",
      workflowData?.phoneNumberRequestWorkflow?.overwrite || "NO",
    );
    setValue(
      "timestamp_field",
      workflowData?.phoneNumberRequestWorkflow?.timestamp_field || null,
    );
    setValue(
      "timestamp",
      Boolean(workflowData?.phoneNumberRequestWorkflow?.timestamp_field),
    );
    setValue(
      "default_phone_write_field",
      workflowData?.phoneNumberRequestWorkflow?.default_phone_write_field ||
        null,
    );
    setValue("name", workflowData?.phoneNumberRequestWorkflow?.name || "");

    if (workflowData?.phoneNumberRequestWorkflow) {
      const segmentation = JSON.parse(
        workflowData?.phoneNumberRequestWorkflow?.segmentation || "{}",
      );

      setValue("segmentation", segmentation);
    }
    setValue(
      "schedule_enabled",
      workflowData?.phoneNumberRequestWorkflow?.schedule ? true : false,
    );
    setValue(
      "schedule",
      workflowData?.phoneNumberRequestWorkflow?.schedule || null,
    );
    if (workflowData) {
      setValue("date_filter", getDateFilter(workflowData));
    }
  }, [workflowData, setValue]);

  useEffect(() => {
    if (
      workflowData?.organization?.organizationMergeIntegration?.integration ===
      IntegrationType.HubSpot
    ) {
      setHubspotIntegration(true);
    }
  }, [workflowData]);

  const enrichmentNotFound = Boolean(
    workflowId && workflowData && !workflowData?.phoneNumberRequestWorkflow,
  );

  const sharedWorkflowFormProperties = useMemo(() => {
    return {
      errors,
      integrationName:
        workflowData?.organization?.organizationMergeIntegration?.integration ||
        "your system",
      isHubspot,
      isSalesforce,
      orgContactObjectFields,
      register,
      setValue,
      trigger,
      watch,
      workflowId: workflowId ? Number(workflowId) : null,
    };
  }, [
    register,
    workflowData,
    errors,
    setValue,
    trigger,
    isSalesforce,
    isHubspot,
    workflowId,
    orgContactObjectFields,
    watch,
  ]);

  return (
    <>
      <StartWorkflowFormSection
        sharedWorkflowFormProperties={sharedWorkflowFormProperties}
        isLoadingWorkflow={workflowLoading}
        enrichmentNotFound={enrichmentNotFound}
      />
      <FilterOptionsWorkflowFormSection
        sharedWorkflowFormProperties={sharedWorkflowFormProperties}
        isLoadingWorkflow={workflowLoading || orgContactObjectFieldsLoading}
      />
      <FilterCutoffDateOption
        sharedWorkflowFormProperties={sharedWorkflowFormProperties}
        isLoadingWorkflow={workflowLoading || orgContactObjectFieldsLoading}
      />
      <WriteSettingsWorkflowFormSection
        sharedWorkflowFormProperties={sharedWorkflowFormProperties}
        isLoadingWorkflow={workflowLoading || orgContactObjectFieldsLoading}
      />
      <ScheduleWorkflowFormSection
        sharedWorkflowFormProperties={sharedWorkflowFormProperties}
        isLoadingWorkflow={workflowLoading || orgContactObjectFieldsLoading}
        nextRunTimestamp={
          workflowData?.phoneNumberRequestWorkflow?.nextRunTimestamp
        }
        scheduleIsDirty={dirtyFields.schedule || false}
      />
      <div
        className={classNames(
          "ciro-v1-w-full",
          "ciro-v1-flex",
          "ciro-v1-justify-end",
          "ciro-v1-mt-6",
        )}
      >
        <CiroButton
          style={CiroButtonStyleEnum.LOUD}
          analyticsField="upsert-workflow-changes"
          onClick={handleSubmit(onSubmit)}
          disabled={upsertLoading || workflowLoading}
        >
          {workflowId ? "Save" : "Create"}
        </CiroButton>
      </div>
      {errors.root?.message && <CiroErrorMsg error={errors.root.message} />}
      {workflowError && (
        <CiroErrorMsg
          error={"Something went wrong. Please contact the Ciro Team"}
        />
      )}
    </>
  );
};

export default MobileNumbersWorkflowForm;

const FacebookIcon = () => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14.2578" cy="14" r="14" fill="url(#paint0_linear_63_23726)" />
      <path
        d="M19.4715 18.2816L20.0934 14.3301H16.203V11.767C16.203 10.6857 16.7455 9.63107 18.488 9.63107H20.2578V6.26699C20.2578 6.26699 18.6523 6 17.1181 6C13.9127 6 11.8195 7.89294 11.8195 11.3184V14.3301H8.25781V18.2816H11.8195V27.8345C12.5345 27.944 13.266 28 14.0113 28C14.7565 28 15.488 27.944 16.203 27.8345V18.2816H19.4715Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_63_23726"
          x1="14.2578"
          y1="0"
          x2="14.2578"
          y2="27.917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18ACFE" />
          <stop offset="1" stopColor="#0163E0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FacebookIcon;

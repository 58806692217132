import classNames from "classnames";
import CiroTitle from "../shared/CiroTitle";
import { gql, useMutation } from "@apollo/client";
import {
  EnrichmentFlowTitleEdit_EnrichmentFlowFragment,
  Maybe,
} from "../../__generated__/graphql";
import { useEffect, useRef, useState } from "react";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroTextInput from "../shared/CiroTextInput";
import CiroSpinner from "../shared/CiroSpinner";

export const EnrichmentFlowTitleEdit_EnrichmentFlow = gql`
  fragment EnrichmentFlowTitleEdit_EnrichmentFlow on EnrichmentFlow {
    id
    name
  }
`;

const EnrichmentFlowTitleEdit_UpdateEnrichmentFlow = gql`
  mutation EnrichmentFlowTitleEdit_UpdateEnrichmentFlow(
    $id: Int!
    $name: String!
  ) {
    updateEnrichmentFlow(id: $id, enrichmentFlowInput: { name: $name }) {
      id
      name
    }
  }
`;

interface IEnrichmentFlowTitleEditProps {
  enrichmentFlow?: Maybe<EnrichmentFlowTitleEdit_EnrichmentFlowFragment>;
  enrichmentFlowLoading: boolean;
}

const EnrichmentFlowTitleEdit = ({
  enrichmentFlow,
  enrichmentFlowLoading,
}: IEnrichmentFlowTitleEditProps) => {
  const [editingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState(enrichmentFlow?.name || "");
  const inputRef = useRef(
    null,
  ) as React.MutableRefObject<HTMLInputElement | null>;

  const [updateEnrichmentFlow] = useMutation(
    EnrichmentFlowTitleEdit_UpdateEnrichmentFlow,
  );

  const handleSubmit = () => {
    setEditingTitle(false);

    if (title === enrichmentFlow?.name) {
      return;
    }

    if (title === "") {
      setTitle(enrichmentFlow?.name || "");
      return;
    }

    updateEnrichmentFlow({
      variables: {
        id: enrichmentFlow!.id,
        name: title,
      },
    });
  };

  useEffect(() => {
    setTitle(enrichmentFlow?.name || "");
  }, [enrichmentFlow]);

  useEffect(() => {
    // Needs to be done after the page is refreshed since a hidden input can't be focused
    if (editingTitle) {
      inputRef?.current?.focus();
    }
  }, [editingTitle]);

  if (!enrichmentFlow) {
    return null;
  }

  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-flex-row",
        "ciro-v1-items-center",
      )}
    >
      {!editingTitle && (
        <div
          className={classNames(
            "ciro-v1-border-0",
            "hover:ciro-v1-border-gray-300",
            "ciro-v1-rounded-md",
          )}
        >
          <CiroButton
            analyticsField="Edit enrichment flow title"
            style={CiroButtonStyleEnum.BORDERLESS}
            onClick={() => {
              setEditingTitle(true);
            }}
          >
            <CiroTitle title={title} />
          </CiroButton>
        </div>
      )}
      {editingTitle && (
        <CiroTextInput
          ref={inputRef}
          value={title}
          className={classNames("ciro-v1-text-3xl")}
          onBlur={handleSubmit}
          onChange={(e) => setTitle(e.target.value)}
        />
      )}
      <CiroSpinner loading={enrichmentFlowLoading} />
    </div>
  );
};

export default EnrichmentFlowTitleEdit;

import { useEffect, useState } from "react";
import {
  CiroConfirmFromFiltersModal_FiltersFromSemanticSearchQuery,
  RangeFilterInput,
} from "../../../../__generated__/graphql";

const useConfirmFiltersFromSemanticSearch = (
  filtersQueryData?: CiroConfirmFromFiltersModal_FiltersFromSemanticSearchQuery,
) => {

  const [filtersHaveBeenSetByAi, setFiltersHaveBeenSetByAi] = useState(false);
  const filtersForConfirmation = filtersQueryData?.filtersFromSemanticSearch?.filters;

  const [confirmIndustriesFilter, setConfirmIndustriesFilter] = useState<
    string[]
  >([]);
  const [confirmNotIndustriesFilter, setConfirmNotIndustriesFilter] = useState<
    string[]
  >([]);
  const [confirmCitiesFilter, setConfirmCitiesFilter] = useState<string[]>([]);
  const [confirmNotCitiesFilter, setConfirmNotCitiesFilter] = useState<
    string[]
  >([]);
  const [confirmSpecialtiesFilter, setConfirmSpecialtiesFilter] = useState<
    string[]
  >([]);
  const [confirmAllSpecialtiesFilter, setConfirmAllSpecialtiesFilter] =
    useState<string[]>([]);
  const [confirmNotSpecialtiesFilter, setConfirmNotSpecialtiesFilter] =
    useState<string[]>([]);
  const [confirmStatesFilter, setConfirmStatesFilter] = useState<string[]>([]);
  const [confirmNotStatesFilter, setConfirmNotStatesFilter] = useState<
    string[]
  >([]);
  const [confirmNumEmployeesFilter, setConfirmNumEmployeesFilter] =
    useState<RangeFilterInput>(() => {
      return {
        lowerBound: null,
        upperBound: null,
      } as RangeFilterInput;
    });

  useEffect(() => {
    if (filtersQueryData && !filtersHaveBeenSetByAi) {
      setConfirmIndustriesFilter(
        filtersForConfirmation?.industriesFilter?.length ? filtersForConfirmation.industriesFilter : [],
      );
      setConfirmCitiesFilter(
        filtersForConfirmation?.citiesFilter?.length ? filtersForConfirmation.citiesFilter : [],
      );
      setConfirmSpecialtiesFilter(
        filtersForConfirmation?.specialtiesFilter?.length ? filtersForConfirmation.specialtiesFilter : [],
      );
      setConfirmStatesFilter(
        filtersForConfirmation?.statesFilter?.length ? filtersForConfirmation.statesFilter : [],
      );
      const lowerBound = filtersForConfirmation?.numEmployeesFilter?.lowerBound;
      const upperBound = filtersForConfirmation?.numEmployeesFilter?.upperBound;
      setConfirmNumEmployeesFilter({
        lowerBound: lowerBound ? lowerBound : null,
        upperBound: upperBound ? upperBound : null,
      });
      setFiltersHaveBeenSetByAi(true);
    }
  }, [
    filtersForConfirmation?.citiesFilter,
    filtersForConfirmation?.industriesFilter,
    filtersForConfirmation?.numEmployeesFilter?.lowerBound,
    filtersForConfirmation?.numEmployeesFilter?.upperBound,
    filtersForConfirmation?.specialtiesFilter,
    filtersForConfirmation?.statesFilter,
    filtersQueryData,
    filtersHaveBeenSetByAi,
  ]);

  return {
    confirmAllSpecialtiesFilter,
    confirmCitiesFilter,
    confirmIndustriesFilter,
    confirmNotCitiesFilter,
    confirmNotIndustriesFilter,
    confirmNotSpecialtiesFilter,
    confirmNotStatesFilter,
    confirmNumEmployeesFilter,
    confirmSpecialtiesFilter,
    confirmStatesFilter,
    setConfirmAllSpecialtiesFilter,
    setConfirmCitiesFilter,
    setConfirmIndustriesFilter,
    setConfirmNotCitiesFilter,
    setConfirmNotIndustriesFilter,
    setConfirmNotSpecialtiesFilter,
    setConfirmNotStatesFilter,
    setConfirmNumEmployeesFilter,
    setConfirmSpecialtiesFilter,
    setConfirmStatesFilter,
  };
};

export default useConfirmFiltersFromSemanticSearch;

import React from "react";
import classNames from "classnames";

interface IAccountsUpsertCollectionResponseProps {
  message: string;
  isSuccess: boolean;
}

const AccountsUpsertCollectionResponse = ({
  message,
  isSuccess,
}: IAccountsUpsertCollectionResponseProps) => {
  return (
    <div
      className={classNames(["ciro-v1-text-xs", "ciro-v1-pt-2"], {
        "ciro-v1-text-green-600": isSuccess,
        "ciro-v1-text-rose-500": !isSuccess,
      })}
    >
      {message}
    </div>
  );
};

export default AccountsUpsertCollectionResponse;

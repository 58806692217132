const CloudDownloadIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66649 13.1667L9.99982 16.5M9.99982 16.5L13.3332 13.1667M9.99982 16.5V9M17.3998 14.075C18.1243 13.5655 18.6676 12.8385 18.9509 11.9993C19.2342 11.1601 19.2427 10.2525 18.9752 9.40819C18.7076 8.56386 18.178 7.82674 17.4632 7.3038C16.7484 6.78086 15.8855 6.49931 14.9998 6.5H13.9498C13.6992 5.52323 13.2302 4.61604 12.5783 3.84674C11.9263 3.07743 11.1083 2.46605 10.1858 2.05863C9.26338 1.65121 8.26053 1.45836 7.25276 1.4946C6.245 1.53084 5.25858 1.79523 4.36778 2.26786C3.47698 2.74049 2.70501 3.40904 2.10998 4.22319C1.51495 5.03734 1.11237 5.97587 0.93254 6.96813C0.752714 7.96038 0.800331 8.9805 1.07181 9.95169C1.34328 10.9229 1.83154 11.8198 2.49982 12.575"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloudDownloadIcon;

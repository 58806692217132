import Switch from "react-switch";

interface CiroSwitchProps {
  checked: boolean;
  onChange: (
    checked: boolean,
    event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => void;
}

const CiroSwitch = ({ checked, onChange }: CiroSwitchProps) => {  

  return (
    <Switch
      checked={checked}
      activeBoxShadow="'0 0 2px 3px #808080"
      onChange={onChange}
      onColor="#f97316" //orange-500
      offColor="#E5E7EB"
      checkedIcon={false}
      uncheckedIcon={false}
      height={24}
      width={48}
    />
  );
};

export default CiroSwitch;

import classNames from "classnames";
import CiroNumbersFormSection from "../shared/CiroNumbersFormSection";
import CiroDropDown from "../shared/CiroDropdown";
import CiroSwitch from "../shared/CiroSwitch";
import { ISharedWorkflowFormProperties } from "./MobileNumbersWorkflowForm";
import { dateToTimeAgo } from "../../utils/formatters";

const ScheduleWorkflowFormSection = ({
  sharedWorkflowFormProperties: {
    setValue,
    errors,
    trigger,
    watch,
  },
  isLoadingWorkflow,
  nextRunTimestamp,
  scheduleIsDirty,
}: {
  sharedWorkflowFormProperties: ISharedWorkflowFormProperties;
  isLoadingWorkflow: boolean;
  nextRunTimestamp: string | null;
  scheduleIsDirty: boolean;
}) => {
  const schedule = watch("schedule");
  const scheduleEnabled = watch("schedule_enabled");

  return (
    <CiroNumbersFormSection
      title="Schedule options"
      description="Choose how often to run the workflow."
      isLoading={isLoadingWorkflow}
    >
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-flex-row",
          "ciro-v1-gap-2",
        )}
      >
        <div>
          <CiroSwitch
            checked={Boolean(scheduleEnabled)}
            onChange={(checked) => {
              if (!checked) {
                setValue("schedule", null, { shouldDirty: true });
                trigger("schedule");
                setValue("schedule_enabled", false);
              } else {
                setValue("schedule_enabled", true);
              }
              trigger("schedule_enabled");
            }}
          />
        </div>
        <div>Enable scheduling</div>
      </div>
      {scheduleEnabled && (
        <>
          <div className={classNames("ciro-v1-my-4")}>
            <CiroDropDown
              label="Schedule frequency"
              options={[
                { label: "Hourly", value: "HOURLY" },
                { label: "Daily", value: "DAILY" },
                { label: "Weekly", value: "WEEKLY" },
              ]}
              error={errors.schedule?.message}
              value={schedule}
              onChange={(v) => {
                setValue("schedule", v as "HOURLY" | "DAILY" | "WEEKLY" | null, { shouldDirty: true });
                trigger("schedule");
              }}
            />
          </div>
          {nextRunTimestamp && !scheduleIsDirty && (
            <div
              className={classNames("ciro-v1-text-sm", "ciro-v1-text-gray-600")}
            >
              Next run {dateToTimeAgo(nextRunTimestamp)}
            </div>
          )}
        </>
      )}
    </CiroNumbersFormSection>
  );
};

export default ScheduleWorkflowFormSection;

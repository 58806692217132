import { EnrichmentTechniqueEnum } from "../../__generated__/graphql";

interface PrimitiveField {
  name: string;
  label: string;
  type: "string" | "integer" | "boolean";
}

interface ArrayField {
  name: string;
  label: string;
  type: "array";
  children: Field;
}

interface ObjectField {
  name: string;
  label: string;
  type: "object";
  children: Field;
}

export type Field = PrimitiveField | ArrayField | ObjectField;

export interface StepSchema {
  fields: Field[];
}

export const getStepSchema = (
  enrichmentTechnique?: EnrichmentTechniqueEnum | null,
): StepSchema => {
  switch (enrichmentTechnique) {
    case EnrichmentTechniqueEnum.FindCrmAccount:
      return {
        fields: [
          { name: "remote_id", label: "ID", type: "string" },
          { name: "name", label: "Account name", type: "string" },
          { name: "description", label: "Description", type: "string" },
          { name: "industry", label: "Industry", type: "string" },
          { name: "website", label: "Website", type: "string" },
          {
            name: "number_of_employees",
            label: "Number of employees",
            type: "integer",
          },
          {
            name: "number_of_employees_range_min",
            label: "Est. number of employees (min)",
            type: "integer",
          },
          {
            name: "number_of_employees_range_max",
            label: "Est. number of employees (max)",
            type: "integer",
          },
          { name: "address", label: "Address", type: "string" },
          { name: "phone_number", label: "Phone number", type: "string" },
        ],
      };
    case EnrichmentTechniqueEnum.FindCiroCompany:
      return {
        fields: [
          { name: "id", label: "ID", type: "string" },
          { name: "company_name", label: "Company name", type: "string" },
          { name: "industry", label: "Industry", type: "string" },
          { name: "website", label: "Website", type: "string" },
          { name: "linkedin_url", label: "LinkedIn URL", type: "string" },
          {
            name: "number_of_employees",
            label: "Number of employees",
            type: "integer",
          },
          { name: "address", label: "Address", type: "string" },
          { name: "phone_number", label: "Phone number", type: "string" },
          {
            name: "specialties",
            label: "Specialties",
            type: "array",
            children: {
              name: "specialty",
              label: "Specialty",
              type: "string",
            },
          },
        ],
      };
    case EnrichmentTechniqueEnum.InitializeCompany:
      return {
        fields: [
          { name: "company_name", label: "Company name", type: "string" },
          { name: "industry", label: "Industry", type: "string" },
          { name: "website", label: "Website", type: "string" },
          { name: "linkedin_url", label: "LinkedIn URL", type: "string" },
          { name: "address", label: "Address", type: "string" },
          { name: "phone_number", label: "Phone number", type: "string" },
          {
            name: "specialties",
            label: "Specialties",
            type: "array",
            children: {
              name: "specialty",
              label: "Specialty",
              type: "string",
            },
          },
        ],
      };
    case EnrichmentTechniqueEnum.ChatGpt:
    case EnrichmentTechniqueEnum.FiltersIncludes:
    case EnrichmentTechniqueEnum.FiltersIsNotNull:
    case EnrichmentTechniqueEnum.FiltersIsNull:
    case EnrichmentTechniqueEnum.ReturnValue:
    case EnrichmentTechniqueEnum.Coalesce:
    case EnrichmentTechniqueEnum.FiltersEquals:
    case EnrichmentTechniqueEnum.Initialize:
    default:
      return {
        // no structure to backend response — just a string
        fields: [],
      };
  }
};

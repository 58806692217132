import React from "react";
import { gql, useQuery } from "@apollo/client";
import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";
import { TechnologiesFilter_TechnologiesQuery } from "../../__generated__/graphql";

interface ITechnologiesFilterProps {
  allTechnologiesFilter?: string[];
  blurred?: boolean;
  notTechnologiesFilter?: string[];
  resetOffset: () => void;
  setAllTechnologiesFilter?: (v: string[]) => void;
  setNotTechnologiesFilter?: (v: string[]) => void;
  setTechnologiesFilter: (v: string[]) => void;
  technologiesFilter: string[];
}

const TechnologiesFilter_technologies = gql`
  query TechnologiesFilter_technologies {
    technologies {
      id
      vendor_name
    }
  }
`;

function TechnologiesFilter({
  allTechnologiesFilter,
  blurred,
  notTechnologiesFilter,
  resetOffset,
  setAllTechnologiesFilter,
  setNotTechnologiesFilter,
  setTechnologiesFilter,
  technologiesFilter,
}: ITechnologiesFilterProps) {
  const { data: technologiesData } =
    useQuery<TechnologiesFilter_TechnologiesQuery>(
      TechnologiesFilter_technologies,
      {
        fetchPolicy: "cache-first",
      },
    );

  const dropdownOptions = (technologiesData?.technologies || []).map(
    (element) => {
      return {
        value: String(element?.id),
        label: String(element?.vendor_name),
      };
    },
  );

  const techFilterOptions = [
    {
      label: "Include any of:",
      filter: (
        <CiroDropDown
          isMulti
          blurred={blurred}
          options={dropdownOptions}
          value={technologiesFilter}
          onChange={(newValue) => {
            setTechnologiesFilter(newValue);
            resetOffset();
          }}
        />
      ),
    },
  ];

  if (setAllTechnologiesFilter && allTechnologiesFilter) {
    techFilterOptions.push({
      label: "Include all of:",
      filter: (
        <CiroDropDown
          isMulti
          blurred={blurred}
          options={dropdownOptions}
          value={allTechnologiesFilter}
          onChange={(newValue) => {
            setAllTechnologiesFilter(newValue);
            resetOffset();
          }}
        />
      ),
    });
  }

  if (setNotTechnologiesFilter && notTechnologiesFilter) {
    techFilterOptions.push({
      label: "Exclude:",
      filter: (
        <CiroDropDown
          isMulti
          blurred={blurred}
          options={dropdownOptions}
          value={notTechnologiesFilter}
          onChange={(newValue) => {
            setNotTechnologiesFilter(newValue);
            resetOffset();
          }}
        />
      ),
    });
  }

  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={
        (technologiesFilter.length ? 1 : 0) +
        (allTechnologiesFilter?.length ? 1 : 0) +
        (notTechnologiesFilter?.length ? 1 : 0)
      }
      filterOptions={techFilterOptions}
      sectionTitle="Web Crawled Technologies"
      smbFilter
    />
  );
}

export default TechnologiesFilter;

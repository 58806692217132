import { gql, useMutation, useQuery } from "@apollo/client";
import {
  OnboardingHintEnum,
  UseOnboardingHints_UserAccountQuery,
  UseOnboardingHints_UserAccountQueryVariables,
  UseOnboardingHints_ViewOnboardingHintMutation,
  UseOnboardingHints_ViewOnboardingHintMutationVariables,
} from "../../__generated__/graphql";
import toast from "react-hot-toast";
import { useState } from "react";

const AccountsIntroModal_UserAccount = gql`
  query UseOnboardingHints_UserAccount {
    userAccount {
      id
      onboarding_hints_viewed
    }
  }
`;

const AccountsIntroModal_ViewOnboardingHint = gql`
  mutation UseOnboardingHints_ViewOnboardingHint($hint: OnboardingHintEnum!) {
    viewOnboardingHint(hint: $hint) {
      success
      error
    }
  }
`;

const useOnboardingHints = ({
  hintType,
  setShowHint,
}: {
  setShowHint: (v: boolean) => void;
  hintType: OnboardingHintEnum;
}) => {
  const [dismissing, setDismissing] = useState(false);

  const { data, loading, refetch } = useQuery<
    UseOnboardingHints_UserAccountQuery,
    UseOnboardingHints_UserAccountQueryVariables
  >(AccountsIntroModal_UserAccount);

  const [viewOnboardingHint] = useMutation<
    UseOnboardingHints_ViewOnboardingHintMutation,
    UseOnboardingHints_ViewOnboardingHintMutationVariables
  >(AccountsIntroModal_ViewOnboardingHint);

  const hintLoading = dismissing || loading;
  const hintViewed =
    data?.userAccount?.onboarding_hints_viewed?.includes(hintType);

  const onDismiss = () => {
    setDismissing(true);
    setShowHint(false);
    viewOnboardingHint({
      variables: {
        hint: hintType,
      },
      onCompleted: async () => {
        await refetch();
        setDismissing(false);
      },
      onError: async (error) => {
        toast.error(`Failed to dismiss: ${error}`);
        await refetch();
        setDismissing(false);
      },
    });
  };

  return {
    hintLoading,
    hintViewed,
    onDismiss,
  };
};

export default useOnboardingHints;

import * as yup from "yup";
import {
  EnrichmentStep,
  EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
  EnrichmentStepCardFiltersList_EnrichmentStepFragment,
  EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
  EnrichmentTechniqueEnum,
} from "../../../../__generated__/graphql";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { FormProvider, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import EnrichmentStepCardFiltersList, {
  EnrichmentStepCardFiltersListSchema,
  IFilterEnrichmentStep,
  parseEnrichmentStepsForGraphQL,
  parseEnrichmentStepsForList,
} from "../EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import { useFragment } from "../../../../__generated__";
import EnrichmentStepRichTextContainer, {
  IEnrichmentTemplateVariable,
} from "../EnrichmentStepRichTextEditor/EnrichmentStepRichTextContainer";
import EnrichmentStepCardContainer from "../EnrichmentStepCardContainer";
import { IEnrichmentStepTechniqueCardProps } from ".";
import useEnrichmentStepForm from "../../../../reactHooks/enrichmentFlow/useEnrichmentStepForm";

export const TEMPLATE_INPUT_KEY = "template";

export const EnrichmentStepTechniqueSerpApiCardSchema = yup
  .object({
    selected_input: yup.string().nullable(),
    queryTemplate: yup.string().nonNullable(),
    templateVariables: yup.array().of(
      yup.object({
        key: yup.string().required("One of your variables is missing a name"),
        input: yup
          .string()
          // Nullable when sourceEnrichmentStep is just a primitive type
          .nullable(),
        sourceEnrichmentStepId: yup.number().nullable(),
      }),
    ),
    filterEnrichmentSteps: EnrichmentStepCardFiltersListSchema,
  })
  .required()
  .test(
    "enrichmentStepsExist",
    "One of the steps used in your variables has been removed",
    // Note: not using an arrow function here so that the function inherits the `this` context
    function (schema) {
      const templateVariables = schema.templateVariables;
      if (!templateVariables) return;
      for (const { sourceEnrichmentStepId, key } of templateVariables) {
        if (key !== TEMPLATE_INPUT_KEY && !sourceEnrichmentStepId) {
          return this.createError({
            path: `templateVariables`,
            message: "One of the steps used in your variables has been removed",
          });
        }
      }
      return true;
    },
  );

export const getDefaultSerpApiValues = (
  enrichmentStep: EnrichmentStep,
  enrichmentStepWithFilters?: EnrichmentStepCardFiltersList_EnrichmentStepFragment,
) => {
  const filterEnrichmentSteps = parseEnrichmentStepsForList(
    enrichmentStepWithFilters,
  );
  const defaultValueWithoutTemplate = {
    name: enrichmentStep?.name,
    templateVariables:
      enrichmentStep?.parentEnrichmentStepInputs
        ?.filter((input) => input.key !== TEMPLATE_INPUT_KEY)
        .map((parentEnrichmentStepInput) => {
          return {
            key: parentEnrichmentStepInput.key,
            input: parentEnrichmentStepInput?.input
              ? JSON.parse(parentEnrichmentStepInput.input)
              : null,
            sourceEnrichmentStepId:
              parentEnrichmentStepInput.sourceEnrichmentStep?.id,
          };
        }) || [],
    filterEnrichmentSteps: filterEnrichmentSteps,
  };
  const template =
    enrichmentStep?.parentEnrichmentStepInputs
      ?.filter((input) => input.key === TEMPLATE_INPUT_KEY)
      .map((input) => input.input)[0] || "";
  const parsedTemplate = template ? JSON.parse(template) : template;
  const defaultValue = {
    ...defaultValueWithoutTemplate,
    queryTemplate: parsedTemplate,
  };
  return defaultValue;
};

interface SerpApiCardFormData {
  queryTemplate: string;
  templateVariables: {
    key: string;
    input: string | null;
    sourceEnrichmentStepId?: number | null;
  }[];
  filterEnrichmentSteps: IFilterEnrichmentStep[];
}

const EnrichmentStepTechniqueSerpApiCard = ({
  enrichmentStep,
  onClose,
  confirmUpdateEnrichmentStep,
  loading,
  error,
}: IEnrichmentStepTechniqueCardProps) => {
  const enrichmentStepWithFilters = useFragment(
    EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );

  const defaultValues = useMemo(() => {
    return getDefaultSerpApiValues(
      enrichmentStep as EnrichmentStep,
      enrichmentStepWithFilters as EnrichmentStepCardFiltersList_EnrichmentStepFragment,
    );
  }, [enrichmentStep, enrichmentStepWithFilters]);

  const formatAndSubmitRequest = (data: SerpApiCardFormData) => {
    const templateInput = {
      key: TEMPLATE_INPUT_KEY,
      required: true,
      input: JSON.stringify(data.queryTemplate),
      sourceEnrichmentStepId: undefined,
    };

    const variableInputs = data.templateVariables.map((variable: any) => {
      return {
        key: variable.key,
        input: JSON.stringify(variable.input) || null,
        required: true,
        sourceEnrichmentStepId: variable.sourceEnrichmentStepId || undefined,
      };
    });

    confirmUpdateEnrichmentStep({
      data: {
        id: enrichmentStep?.id || null,
        enrichmentStepInput: {
          enrichment_technique: EnrichmentTechniqueEnum.SerpApi,
          selected_input: null,
          parentEnrichmentStepInputs: [templateInput, ...variableInputs],
          filterEnrichmentSteps: parseEnrichmentStepsForGraphQL(
            data.filterEnrichmentSteps,
          ),
        },
      },
    });
  };

  const methods = useEnrichmentStepForm({
    resolver: yupResolver(EnrichmentStepTechniqueSerpApiCardSchema),
    defaultValues,
  });
  const {
    control,
    formState: { errors, isDirty },
    setValue,
    handleSubmit,
    reset,
  } = methods;

  const { remove: removeVariableField } = useFieldArray({
    control,
    name: "templateVariables",
  });

  const [queryTemplate, templateVariables, filterEnrichmentSteps] = useWatch({
    control,
    name: ["queryTemplate", "templateVariables", "filterEnrichmentSteps"],
  });

  // Ensure that the default values are loaded after the form is reset
  const [defaultValsAreLoaded, setDefaultValsAreLoaded] = useState(false);
  useEffect(() => {
    if (loading) {
      setDefaultValsAreLoaded(false);
    }
  }, [loading]);

  useEffect(() => {
    reset(defaultValues);
    if (!loading) {
      setDefaultValsAreLoaded(true);
    }
  }, [defaultValues, reset, loading]);

  const variables = useMemo(() => {
    const variables = new Map();
    templateVariables.forEach((variable) => {
      const { key, input, sourceEnrichmentStepId } = variable;
      variables.set(key, {
        stepId: sourceEnrichmentStepId || null,
        stepInput: input || "",
      });
    });

    return variables;
  }, [templateVariables]);

  const highestVariableIndex = templateVariables.length;

  return (
    <EnrichmentStepCardContainer
      isDirty={isDirty}
      loading={loading}
      error={error}
      onSave={handleSubmit(formatAndSubmitRequest)}
      enrichmentStep={useFragment(
        EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
        enrichmentStep,
      )}
      onClose={onClose}
    >
      <div className={classNames("ciro-v1-pt-2")}>
        <EnrichmentStepRichTextContainer
          enrichmentStep={enrichmentStep}
          label="Google search query"
          loading={loading || !defaultValsAreLoaded}
          variables={variables}
          templateError={errors.queryTemplate?.message as string}
          variableErrors={errors.templateVariables}
          setVariables={(
            newVariables: Map<string, IEnrichmentTemplateVariable>,
          ) => {
            let idx = 0;
            for (const [key, { stepId, stepInput }] of newVariables) {
              if (stepId) {
                setValue(
                  `templateVariables.${idx}.sourceEnrichmentStepId`,
                  stepId,
                );
              } else {
                setValue(
                  `templateVariables.${idx}.sourceEnrichmentStepId`,
                  undefined,
                );
              }
              setValue(`templateVariables.${idx}.input`, stepInput);
              setValue(`templateVariables.${idx}.key`, key);
              idx += 1;
            }

            // Remove extraneous variables (e.g., that were deleted)
            for (let i = idx + 1; i < highestVariableIndex; i++) {
              removeVariableField(i);
            }
          }}
          template={queryTemplate}
          setTemplate={(newTemplate: string) => {
            setValue("queryTemplate", newTemplate);
          }}
        />
      </div>
      <div className={classNames("ciro-v1-mt-12")}>
        <FormProvider {...methods}>
          <EnrichmentStepCardFiltersList
            filterEnrichmentSteps={filterEnrichmentSteps}
          />
        </FormProvider>
      </div>
    </EnrichmentStepCardContainer>
  );
};

export default EnrichmentStepTechniqueSerpApiCard;

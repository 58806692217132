import classNames from "classnames";
import EnrichmentExecutionDetailsContext from "../../../contexts/EnrichmentExecutionDetailsContext";
import EnrichmentExecutionDetailsJsonBlock from "./EnrichmentExecutionDetailsJsonBlock";
import JsonIcon from "../../../assets/img/icons/JsonIcon";
import { EnrichmentTechniqueEnum } from "../../../__generated__/graphql";
import XIcon from "../../../assets/img/icons/XIcon";
import CiroMarkdown from "../../shared/CiroMarkdown";

export interface IExecutionCellData {
  output: any;
  enrichmentStep: {
    id: number;
    enrichment_technique: EnrichmentTechniqueEnum;
    name: string;
  };
}

interface IEnrichmentExecutionDetailsViewerProps {
  hidden: boolean;
  executionCellData?: IExecutionCellData | null;
  onClose: () => void;
}

const EnrichmentExecutionDetailsViewer = ({
  hidden,
  executionCellData,
  onClose,
}: IEnrichmentExecutionDetailsViewerProps) => {
  if (hidden) return null;

  return (
    <div
      className={classNames(
        "ciro-v1-w-[40%]",
        "ciro-v1-ml-4",
        "ciro-v1-h-full",
        "ciro-v1-max-h-screen-minus-16",
        "ciro-v1-rounded-lg",
        "ciro-v1-border-1",
        "ciro-v1-flex",
        "ciro-v1-flex-col",
        "ciro-v1-overflow-auto",
        "ciro-v1-bg-neutral-50",
      )}
    >
      <EnrichmentExecutionDetailsContext.Provider
        value={{
          enrichmentStepId: executionCellData?.enrichmentStep?.id || 0,
          onClose,
        }}
      >
        <div
          className={classNames(
            "ciro-v1-w-full",
            "ciro-v1-h-[48px]",
            "ciro-v1-min-h-[48px]",
            "ciro-v1-p-1",
            "ciro-v1-border-b",
            "ciro-v1-border-neutral-200",
            "ciro-v1-flex-col",
            "ciro-v1-justify-center",
            "ciro-v1-items-start",
            "ciro-v1-gap-1",
            "ciro-v1-inline-flex",
          )}
        >
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-flex-row",
              "ciro-v1-justify-between",
              "ciro-v1-w-full",
              "ciro-v1-items-center",
            )}
          >
            <div
              className={classNames(
                "ciro-v1-flex-row",
                "ciro-v1-items-center",
                "ciro-v1-flex",
                "ciro-v1-gap-1",
                "ciro-v1-ml-2",
              )}
            >
              <div className={classNames("ciro-v1-text-neutral-400")}>
                <JsonIcon />
              </div>
              <div
                className={classNames(
                  "ciro-v1-text-neutral-900",
                  "ciro-v1-text-sm",
                )}
              >
                {executionCellData?.enrichmentStep?.name}
              </div>
            </div>
            <div
              className={classNames(
                "ciro-v1-text-neutral-400",
                "ciro-v1-text-sm",
                "ciro-v1-font-medium",
                "ciro-v1-mr-2",
                "ciro-v1-cursor-pointer",
              )}
              onClick={onClose}
            >
              <XIcon />
            </div>
          </div>
        </div>
        <div className={classNames("ciro-v1-my-4")}>
          {["object", "array"].includes(typeof executionCellData?.output) && (
            <EnrichmentExecutionDetailsJsonBlock
              data={executionCellData?.output}
              currentPath={[]}
            />
          )}
          {!["object", "array"].includes(typeof executionCellData?.output) && (
            <div
              className={classNames("ciro-v1-ml-5", "ciro-v1-text-neutral-600")}
            >
              <CiroMarkdown markdown={executionCellData?.output} />
            </div>
          )}
        </div>
      </EnrichmentExecutionDetailsContext.Provider>
    </div>
  );
};

export default EnrichmentExecutionDetailsViewer;

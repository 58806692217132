import { IEnrichmentExecutionCellProps } from ".";

const ExecutionCell_FlatCompany = ({
  value,
  setCellDetailsValue,
}: IEnrichmentExecutionCellProps) => {
  let parsedValue = value?.output && JSON.parse(value.output);
  const originalParsedValue = parsedValue;

  const returnValue = parsedValue && parsedValue["company_name"];
  return (
    <div>
      <div
        onClick={() => {
          setCellDetailsValue(originalParsedValue);
        }}
      >
        {returnValue}
      </div>
    </div>
  );
};

export default ExecutionCell_FlatCompany;

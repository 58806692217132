const ALLOWED_HEADERS = 100;

// On the server, the max request size is 30MB, but leave some buffer for other data in request
// and differences in data type size between CSV and JSON transmitted in request
const MAX_FILE_SIZE_MB = 20;

const useCsvFileSizeError = (
  headers: string[],
  totalRows: number,
  csvFile: File | null,
  allowedTotalRows: number,
) => {
  const allowedDimensions =
    headers &&
    headers.length > 0 &&
    headers.length <= ALLOWED_HEADERS &&
    totalRows <= allowedTotalRows &&
    totalRows > 0;

  const fileSizeMegabytes = csvFile ? csvFile.size / (1024 * 1024) : 0;

  let fileSizeError = null;
  if (!allowedDimensions) {
    fileSizeError = `Your CSV can have no more than ${ALLOWED_HEADERS} columns or ${allowedTotalRows.toLocaleString("en-US")} rows and will be truncated. To upload more rows, please contact the Ciro team.`;
  }
  if (fileSizeMegabytes > MAX_FILE_SIZE_MB) {
    fileSizeError = `File size exceeds the maximum size of ${MAX_FILE_SIZE_MB}MB`;
  }
  return fileSizeError;
};

export default useCsvFileSizeError;

import classNames from "classnames";
import CiroButton from "../../../shared/CiroButton";
import FilterIcon from "../../../../assets/img/icons/FilterIcon";
import CiroFilterModalContainer from "../../../shared/CiroFilter/CiroFilterModalContainer";
import EnrichmentFlowTableFilterOptions from "./EnrichmentFlowTableFilterOptions";
import { useContext } from "react";
import EnrichmentFlowContext from "../../../../contexts/EnrichmentFlowContext";
import { getFiltersAppliedCount } from "../../../../reactHooks/filters/enrichmentFlow/useEnrichmentFlowFilters";

interface IEnrichmentFlowTableFilterWrapperProps {
  setFilterIsOpen: (isOpen: boolean) => void;
  filterIsOpen: boolean;
}

const EnrichmentFlowTableFilterWrapper = ({
  setFilterIsOpen,
  filterIsOpen,
}: IEnrichmentFlowTableFilterWrapperProps) => {
  const { enrichmentFlowFilters, resetEnrichmentFlowFilters } = useContext(
    EnrichmentFlowContext,
  );
  const filtersAppliedCount = getFiltersAppliedCount(enrichmentFlowFilters);
  return (
    <>
      <CiroButton
        customClassName={classNames("test-filters-button", "ciro-v1-h-[45px]")}
        onClick={() => setFilterIsOpen(true)}
        analyticsField={"Open filter for enrichment table"}
      >
        <span
          className={classNames(
            "ciro-v1-whitespace-nowrap",
            "ciro-v1-flex",
            "ciro-v1-items-center",
            "ciro-v1-font-medium",
          )}
        >
          <span className="ciro-v1-mr-4">
            <FilterIcon />
          </span>
          Filters
          {Boolean(filtersAppliedCount) && ` (${filtersAppliedCount})`}
        </span>
      </CiroButton>
      <CiroFilterModalContainer
        displayCTA={false}
        resetAll={resetEnrichmentFlowFilters}
        numFiltersApplied={filtersAppliedCount}
        filtersSection={[<EnrichmentFlowTableFilterOptions key={1} />]}
        isLoading={false}
        isOpen={filterIsOpen}
        onClose={() => setFilterIsOpen(false)}
        subtitle={"Apply filters to your table"}
      />
    </>
  );
};

export default EnrichmentFlowTableFilterWrapper;

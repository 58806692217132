import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import CiroModal from "../shared/CiroModal";
import pluralize from "pluralize";
import { gql, useMutation } from "@apollo/client";
import {
  CollectionSearchRemoveFromCollection_CollectionFragment,
  CollectionSearchRemoveFromCollection_UpsertCollectionMutation,
  CollectionSearchRemoveFromCollection_UpsertCollectionMutationVariables,
  Maybe,
  UpsertCollectionMethodEnum,
} from "../../__generated__/graphql";
import Loading from "../shared/Loading";

interface ICollectionSearchRemoveFromCollectionProps {
  checked: Set<string>;
  collection?: Maybe<CollectionSearchRemoveFromCollection_CollectionFragment>;
  refetchFilteredCompanies: () => void;
  setChecked: (v: Set<string>) => void;
}

export const CollectionSearchRemoveFromCollection_Collection = gql`
  fragment CollectionSearchRemoveFromCollection_Collection on Collection {
    id
    name
  }
`;

const CollectionSearchRemoveFromCollection_UpsertCollection = gql`
  mutation CollectionSearchRemoveFromCollection_UpsertCollection(
    $removalList: [UpsertCollectionArgs!]!
  ) {
    upsertCollections(changes: $removalList) {
      success
    }
  }
`;

const CollectionSearchRemoveFromCollection = ({
  checked,
  collection,
  refetchFilteredCompanies,
  setChecked,
}: ICollectionSearchRemoveFromCollectionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [
    removeAccountsFromSingleCollection,
    {
      data: removedAccountsMutationData,
      loading: removedAccountsMutationLoading,
      reset: resetRemoveAccountsFromCollectionData,
    },
  ] = useMutation<
    CollectionSearchRemoveFromCollection_UpsertCollectionMutation,
    CollectionSearchRemoveFromCollection_UpsertCollectionMutationVariables
  >(CollectionSearchRemoveFromCollection_UpsertCollection);

  useEffect(() => {
    if (!isOpen) {
      resetRemoveAccountsFromCollectionData();
    }
  }, [isOpen, resetRemoveAccountsFromCollectionData]);

  useEffect(() => {
    if (
      removedAccountsMutationData?.upsertCollections &&
      removedAccountsMutationData?.upsertCollections[0].success
    ) {
      setChecked(new Set());
      setIsOpen(false);
      resetRemoveAccountsFromCollectionData();
      refetchFilteredCompanies();
    }
  }, [
    refetchFilteredCompanies,
    removedAccountsMutationData,
    resetRemoveAccountsFromCollectionData,
    setChecked,
  ]);

  if (!checked.size) {
    return null;
  }

  return (
    <>
      <CiroButton
        customClassName={classNames("ciro-v1-mr-2", "ciro-v1-font-medium")}
        analyticsField={"Open remove collections from collections page"}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <TrashIcon className={classNames("ciro-v1-h-5", "ciro-v1-pr-2")} />
        Remove from collection
      </CiroButton>
      <CiroModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-items-center",
            "ciro-v1-justify-between",
          )}
        >
          <span>
            Are you sure you want to remove {checked.size}{" "}
            {pluralize("account", checked.size)} from {collection?.name}?
          </span>
        </div>
        {removedAccountsMutationLoading && (
          <div className={classNames("ciro-v1-py-4")}>
            <Loading size="SMALL" />
          </div>
        )}
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-justify-end",
            "ciro-v1-pt-4",
          )}
        >
          <CiroButton
            analyticsField="Confirm remove records from collection"
            disabled={removedAccountsMutationLoading}
            onClick={() => {
              removeAccountsFromSingleCollection({
                variables: {
                  removalList: [
                    {
                      method: UpsertCollectionMethodEnum.Remove,
                      collectionId: Number(collection?.id),
                      companyPks: [...checked],
                    },
                  ],
                },
              });
            }}
            style={CiroButtonStyleEnum.LOUD}
          >
            Confirm
          </CiroButton>
          <span className={classNames("ciro-v1-ml-2")}>
            <CiroButton
              analyticsField="Confirm remove records from collection"
              disabled={removedAccountsMutationLoading}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Cancel
            </CiroButton>
          </span>
        </div>
      </CiroModal>
    </>
  );
};

export default CollectionSearchRemoveFromCollection;

import React, { useContext, useEffect, useState } from "react";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroModal from "../shared/CiroModal";
import classNames from "classnames";
import { InternalRefetchQueriesInclude, gql, useQuery } from "@apollo/client";
import {
  AccountsAddToCollectionModal_CollectionsFilterQuery,
  AccountsAddToCollectionModal_CollectionsFilterQueryVariables,
  CompaniesFromFiltersInput,
  UpsertCollectionArgs,
  UpsertCollectionMethodEnum,
} from "../../__generated__/graphql";
import Loading from "../shared/Loading";
import pluralize from "pluralize";
import CiroDropDown from "../shared/CiroDropdown";
import XIcon from "../../assets/img/icons/XIcon";
import CiroByQueryLimitSelectionModal, {
  MAX_DOWNLOAD_LIMIT,
} from "../shared/CiroByQueryLimitSelectionModal";
import CiroByQueryUnlockModal from "../shared/CiroByQueryUnlockModal";
import CiroCollectionUpsertJobCreator from "../shared/CollectionUploadStatus/CiroCollectionUpsertJobCreator";
import { useSearchParams } from "react-router-dom";
import FilterContext from "../../contexts/FilterContext";

export const AccountsAddToCollectionModal_CollectionsFilter = gql`
  query AccountsAddToCollectionModal_CollectionsFilter {
    collections(editableOnly: true) {
      id
      name
    }
  }
`;

interface IAccountsAddToCollectionModalProps {
  filteredCompanyQueryVariables?: CompaniesFromFiltersInput;
  isOpen: boolean;
  numberChecked: number;
  refetchQueries: InternalRefetchQueriesInclude;
  selectedCompanyPks: string[];
  setIsOpen: (v: boolean) => void;
  setCheckedCompanies(v: Set<string>): void;
}

const AccountsAddToCollectionModal = ({
  filteredCompanyQueryVariables,
  isOpen,
  numberChecked,
  refetchQueries,
  selectedCompanyPks,
  setIsOpen,
  setCheckedCompanies
}: IAccountsAddToCollectionModalProps) => {
  const { companiesCount } = useContext(FilterContext);
  const [searchParams] = useSearchParams();
  const offset = Number(searchParams.get("offset") || 0);
  const [unlockedAccounts, setUnlockedAccounts] = useState(false);
  const [queryLimit, setQueryLimit] = useState(0);
  const [selectedCollectionIds, setSelectedCollectionIds] = useState<string[]>(
    [],
  );
  const [doneUpserting, setDoneUpserting] = useState(false);
  const closeAndClearChecks = () => {
    if (doneUpserting) {
      setCheckedCompanies(new Set());
    }
    setIsOpen(false);
  };


  const { data: collectionFiltersData } = useQuery<
    AccountsAddToCollectionModal_CollectionsFilterQuery,
    AccountsAddToCollectionModal_CollectionsFilterQueryVariables
  >(AccountsAddToCollectionModal_CollectionsFilter);

  const usingQueryFilters =
    filteredCompanyQueryVariables && !selectedCompanyPks?.length;

  const savedCollections = collectionFiltersData?.collections || [];
  const savedCollectionIds = savedCollections.map((collection) =>
    String(collection.id),
  );

  const [newCollectionOptions, setNewCollectionOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const selectedNewCollections = selectedCollectionIds.filter(
    (selectedCollectionId) => {
      return newCollectionOptions
        .map((option) => option.value)
        .includes(selectedCollectionId);
    },
  );

  const [selectedChanges, setSelectedChanges] = useState<
    UpsertCollectionArgs[]
  >([]);

  const handleAddAccountsToCollection = () => {
    if (!selectedCollectionIds.length) {
      return;
    }

    const addAccountsToCollectionsArr = selectedCollectionIds
      .filter((collectionId) => savedCollectionIds.includes(collectionId))
      .map((collectionId) => {
        return {
          method: UpsertCollectionMethodEnum.Add,
          collectionId: Number(collectionId),
          companyPks: usingQueryFilters ? undefined : selectedCompanyPks,
          companyQuery: usingQueryFilters
            ? {
                filters: filteredCompanyQueryVariables,
                limit: queryLimit,
                offset,
              }
            : undefined,
        };
      }) as UpsertCollectionArgs[];

    const createNewCollectionsArr = selectedNewCollections.map(
      (newCollectionId) => {
        return {
          method: UpsertCollectionMethodEnum.Create,
          name: newCollectionId.replace("_", ""),
          companyPks: usingQueryFilters ? undefined : selectedCompanyPks,
          companyQuery: usingQueryFilters
            ? {
                filters: filteredCompanyQueryVariables,
                limit: queryLimit,
                offset,
              }
            : undefined,
        };
      },
    ) as UpsertCollectionArgs[];

    setSelectedChanges(
      addAccountsToCollectionsArr.concat(createNewCollectionsArr),
    );
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedCollectionIds([]);
      setNewCollectionOptions([]);
      setSelectedChanges([]);
      setUnlockedAccounts(false);
      setQueryLimit(0);
    }
  }, [isOpen, setSelectedCollectionIds]);

  const collectionOptionsFromServer = savedCollections.map((collection) => {
    return {
      value: String(collection?.id),
      label: String(collection?.name),
    };
  });
  const collectionOptions =
    collectionOptionsFromServer.concat(newCollectionOptions);

  if (usingQueryFilters && !queryLimit) {
    const totalAccounts = Math.min(
      MAX_DOWNLOAD_LIMIT,
      companiesCount || MAX_DOWNLOAD_LIMIT,
    );
    const limitType =
      totalAccounts === MAX_DOWNLOAD_LIMIT ? "maximum" : "total";
    return (
      <CiroByQueryLimitSelectionModal
        closeModal={closeAndClearChecks}
        isOpen={isOpen}
        setLimit={setQueryLimit}
        textLabel={`How many records do you want to add to a collection? (${totalAccounts} ${limitType})`}
        title={"Select number of accounts to add to collection"}
        maxAccounts={totalAccounts}
      />
    );
  }

  if (
    usingQueryFilters &&
    queryLimit &&
    filteredCompanyQueryVariables.pmsProviderFilter?.length &&
    !unlockedAccounts
  ) {
    return (
      <CiroByQueryUnlockModal
        filteredCompanyQueryVariables={filteredCompanyQueryVariables}
        offset={0}
        numRecordsToUnlock={queryLimit}
        onClose={() => {
          setQueryLimit(0);
          setIsOpen(false);
        }}
        onCompleted={() => {
          setUnlockedAccounts(true);
        }}
        subtitle={`In order to add these ${queryLimit} ${pluralize("account", queryLimit)} to a collection, you must unlock them first.`}
        refetchQueries={refetchQueries}
      />
    );
  }

  return (
    <CiroModal onClose={closeAndClearChecks} isOpen={isOpen}>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-font-medium",
          "ciro-v1-items-center",
          "ciro-v1-justify-between",
        )}
      >
        <span>Add accounts to a collection</span>
        <span
          onClick={closeAndClearChecks}
          className={classNames("ciro-v1-cursor-pointer")}
        >
          <XIcon />
        </span>
      </div>
      {!collectionFiltersData && <Loading />}
      {collectionFiltersData && (
        <div className={classNames(["ciro-v1-py-4"])}>
          <CiroDropDown
            isMulti
            creatable
            formatCreateLabel={(inputValue) =>
              `Create new collection "${inputValue}"`
            }
            placeholder={collectionOptions?.length > 0 ? "Select one or more" : "Start typing to create a collection"}
            options={collectionOptions}
            value={selectedCollectionIds}
            onCreateOption={(t: string) => {
              setNewCollectionOptions((prev) => {
                return [...prev, { value: `_${t}`, label: t }];
              });
              setSelectedCollectionIds((prev) => {
                return [...prev, `_${t}`];
              });
            }}
            onChange={(newValue) => {
              setSelectedCollectionIds(newValue);
            }}
          />
          <div
            className={classNames([
              "ciro-v1-flex",
              "ciro-v1-justify-end",
              "ciro-v1-pt-4",
            ])}
          >
            <CiroButton
              style={CiroButtonStyleEnum.INVERTED}
              analyticsField="Add accounts to collections"
              onClick={handleAddAccountsToCollection}
              disabled={
                Boolean(selectedChanges.length) || !selectedCollectionIds.length
              }
            >
              <div>
                <div>
                  Add {pluralize("account", numberChecked)} to{" "}
                  {selectedCollectionIds.length}{" "}
                  {pluralize("collection", selectedCollectionIds.length)}
                </div>
                {Boolean(selectedNewCollections.length) && (
                  <div>
                    ({selectedNewCollections.length} new{" "}
                    {pluralize("collection", selectedNewCollections.length)})
                  </div>
                )}
              </div>
            </CiroButton>
          </div>
        </div>
      )}
      {selectedChanges.map((selectedChange, i) => {
        let collectionName = "";
        if (selectedChange.method === UpsertCollectionMethodEnum.Add) {
          const collection = savedCollections.find((collection) => {
            return (
              String(collection.id) === String(selectedChange.collectionId)
            );
          });
          collectionName = collection?.name || "";
        } else {
          collectionName = selectedChange.name || "";
        }
        return (
          <CiroCollectionUpsertJobCreator
            key={i}
            collectionChange={selectedChange}
            collectionName={collectionName}
            preparingText={`Preparing to add accounts to "${collectionName}"...`}
            completedText={`Update to "${collectionName}" complete`}
            setCheckedCompanies={setCheckedCompanies}
            setDoneUpserting={setDoneUpserting}
          />
        );
      })}
    </CiroModal>
  );
};

export default AccountsAddToCollectionModal;

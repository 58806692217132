import { gql, useQuery } from "@apollo/client";
import EnrichmentsTable, {
  EnrichmentFlowsTable_EnrichmentFlows,
} from "../enrichments/EnrichmentsTable";
import { EnrichmentFlowsTable_EnrichmentFlowsFragmentDoc } from "../../__generated__/graphql";
import { useFragment } from "../../__generated__";
import classNames from "classnames";
import CiroTitle from "../shared/CiroTitle";
import CiroButton from "../shared/CiroButton";
import DashedBorder from "../../assets/img/styling/DashedBorder";
import PuzzleIcon from "../../assets/img/icons/PuzzleIcon";
import SkeletonLoading from "../shared/SkeletonLoading";
import EnrichmentsContext from "../../contexts/EnrichmentsContext";

export const HomeListsTable_enrichmentFlows = gql`
  query HomeListsTable_enrichmentFlows {
    enrichmentFlows {
      count
      ...EnrichmentFlowsTable_EnrichmentFlows
    }
  }
  ${EnrichmentFlowsTable_EnrichmentFlows}
`;

const HomeListsTable = ({ newList }: { newList: () => void }) => {
  const { data, loading, refetch } = useQuery(HomeListsTable_enrichmentFlows);
  const enrichmentsTableData = useFragment(
    EnrichmentFlowsTable_EnrichmentFlowsFragmentDoc,
    data?.enrichmentFlows,
  );

  if (loading) {
    return (
      <div className={classNames("ciro-v1-w-full", "ciro-v1-p-5")}>
        <CiroTitle title="My lists" size="small" />
        <SkeletonLoading numSkeletons={5} skeletonHeight="28px" />
      </div>
    );
  }

  const noLists = data?.enrichmentFlows.count === 0;

  const itemsPerPage =
    data?.enrichmentFlows.count && data?.enrichmentFlows.count > 5
      ? 5
      : undefined;

  return (
    <div
      className={classNames("ciro-v1-w-full", "ciro-v1-p-5", "ciro-v1-text-sm")}
    >
      {noLists ? (
        <>
          <CiroTitle title="My lists" size="small" />
          <div
            className={classNames(
              "ciro-v1-relative",
              "ciro-v1-w-full",
              "ciro-v1-p-4",
              "ciro-v1-rounded-lg",
            )}
          >
            <DashedBorder />
            <div
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-justify-between",
                "ciro-v1-items-center",
              )}
            >
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-items-center",
                  "ciro-v1-text-neutral-500",
                )}
              >
                <PuzzleIcon height="20" width="20" />
                <p className="ciro-v1-ml-2">
                  Lists let you enrich data from a CSV with Ciro's data
                  connectors
                </p>
              </div>
              <CiroButton analyticsField="add-list" onClick={newList}>
                <p>+ Create new list</p>
              </CiroButton>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-justify-between",
              "ciro-v1-items-center",
              "ciro-v1-w-full",
              "ciro-v1-mb-4",
            )}
          >
            <CiroTitle title="My lists" size="small" />
            <CiroButton analyticsField="add-list" onClick={newList}>
              + Add new list
            </CiroButton>
          </div>
          <EnrichmentsContext.Provider
            value={{ Enrichments_refetchEnrichmentFlows: refetch }}
          >
            <EnrichmentsTable
              enrichments={enrichmentsTableData}
              itemsPerPage={itemsPerPage}
            />
          </EnrichmentsContext.Provider>
        </div>
      )}
    </div>
  );
};

export default HomeListsTable;

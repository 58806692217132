import classNames from "classnames";
import { IEnrichmentExecutionCellProps } from ".";
import CiroTooltipContainer from "../../../shared/CiroTooltipContainer";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

const ExecutionCell_Error = ({ value }: IEnrichmentExecutionCellProps) => {
  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-items-center",
        "ciro-v1-justify-between",
        "ciro-v1-text-neutral-600",
      )}
    >
      <div className={classNames("ciro-v1-flex", "ciro-v1-items-center", "ciro-v1-mr-1")}>
        <XMarkIcon
          className={classNames(
            "ciro-v1-text-red-500",
            "ciro-v1-w-4",
            "ciro-v1-h-4",
            "ciro-v1-mr-1",
          )}
        />
        Failed
      </div>
      <CiroTooltipContainer tooltip={value?.error}>
        <QuestionMarkCircleIcon
          className={classNames(
            "ciro-v1-text-neutral-400",
            "ciro-v1-w-5",
            "ciro-v1-h-5",
          )}
        />
      </CiroTooltipContainer>
    </div>
  );
};

export default ExecutionCell_Error;

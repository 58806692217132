const IndeterminateCheckIcon = () => {
  return (
    <svg width="10px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <line
        x1="0"
        y1="50"
        x2="100"
        y2="50"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IndeterminateCheckIcon;

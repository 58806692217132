import { gql } from "@apollo/client";
import { PhoneNumberRequest } from "../../__generated__/graphql";
import classNames from "classnames";
import CiroTable from "../shared/CiroTable/CiroTable";
import CiroTableRow from "../shared/CiroTable/CiroTableRow";
import CiroTableHeader from "../shared/CiroTable/CiroTableHeader";
import CiroTableCell from "../shared/CiroTable/CiroTableCell";
import { useState, useMemo } from "react";
import CiroCheckbox from "../shared/CiroCheckbox";
import EnrichedContactsDownloadButton from "./EnrichedContactsDownloadButton";

export const EnrichedContactsTable_Fragment = gql`
  fragment EnrichedContactsTable_Fragment on Organization {
    enrichedContactRequests {
      id
      numberFound
      phoneNumberRequestTransaction {
        user_account {
          email
        }
      }
      orgContact {
        first_name
        last_name
        linkedin_id
        source
      }
    }
  }
`;

interface IEnrichedContactsTableProps {
  enrichedContacts: PhoneNumberRequest[];
  itemsPerPage?: number;
}

const EnrichedContactsTable = ({
  enrichedContacts,
  itemsPerPage,
}: IEnrichedContactsTableProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRequestIds, setSelectedRequestIds] = useState<number[]>([]);

  const filteredContacts = useMemo(() => {
    if (!searchTerm) return enrichedContacts;

    const lowercasedTerm = searchTerm.toLowerCase();

    return enrichedContacts?.filter((enrichedContact) => {
      const name =
        `${enrichedContact.orgContact.first_name || ""} ${enrichedContact.orgContact.last_name || ""}`
          .trim()
          .toLowerCase();
      const linkedinId =
        name === ""
          ? enrichedContact.orgContact.linkedin_id?.toLowerCase() || ""
          : "";
      const source = enrichedContact.orgContact.source?.toLowerCase() || "";
      const phoneNumber = enrichedContact.numberFound?.toLowerCase() || "";
      const requestedBy =
        enrichedContact.phoneNumberRequestTransaction?.user_account?.email?.toLowerCase() ||
        "";

      return (
        name.includes(lowercasedTerm) ||
        linkedinId.includes(lowercasedTerm) ||
        source.includes(lowercasedTerm) ||
        phoneNumber.includes(lowercasedTerm) ||
        requestedBy.includes(lowercasedTerm)
      );
    });
  }, [enrichedContacts, searchTerm]);

  const totalItems = filteredContacts?.length || 0;
  const totalPages = itemsPerPage ? Math.ceil(totalItems / itemsPerPage) : 1;

  const paginatedContacts = itemsPerPage
    ? filteredContacts?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage,
      )
    : filteredContacts;

  const renderDisplayName = (phoneNumberRequest: PhoneNumberRequest) => {
    if (
      phoneNumberRequest.orgContact.first_name ||
      phoneNumberRequest.orgContact.last_name
    ) {
      return `${phoneNumberRequest.orgContact.first_name || ""} ${phoneNumberRequest.orgContact.last_name || ""}`.trim();
    }
    return phoneNumberRequest.orgContact.linkedin_id || "N/A";
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentPageRequestIds = paginatedContacts
      ?.map((phoneNumberRequest) => phoneNumberRequest.id)
      .filter(Boolean) as number[];

    if (e.target.checked) {
      setSelectedRequestIds((prev) => [
        ...new Set([...prev, ...currentPageRequestIds]),
      ]);
    } else {
      setSelectedRequestIds((prev) =>
        prev.filter((id) => !currentPageRequestIds.includes(id)),
      );
    }
  };

  const handleSelectOne = (
    e: React.ChangeEvent<HTMLInputElement>,
    requestId: number,
  ) => {
    if (e.target.checked) {
      setSelectedRequestIds((prev) => [...prev, requestId]);
    } else {
      setSelectedRequestIds((prev) => prev.filter((id) => id !== requestId));
    }
  };

  const currentPageRequestIds = paginatedContacts
    ?.map((phoneNumberRequest) => phoneNumberRequest.id)
    .filter(Boolean) as number[];

  const isAllSelected =
    currentPageRequestIds?.length > 0 &&
    currentPageRequestIds.every((id) => selectedRequestIds.includes(id));

  const isIndeterminate =
    selectedRequestIds.some((id) => currentPageRequestIds.includes(id)) &&
    !isAllSelected;

  const sourceFormatting = (source: string) => {
    if (source === "chrome_extension") {
      return "Chrome Extension";
    }
    if (source === "apollo") {
      return "Apollo";
    }
    return source;
  };

  return (
    <div
      className={classNames(
        "ciro-v1-pb-8",
        "test-enriched-contacts-table-main",
      )}
    >
      <div
        className={classNames(
          "ciro-v1-mb-4",
          "ciro-v1-flex",
          "ciro-v1-items-center",
          "ciro-v1-space-x-4",
        )}
      >
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by contact, source, phone number, or requested by"
          className={classNames(
            "ciro-v1-input",
            "ciro-v1-w-full",
            "ciro-v1-px-4",
            "ciro-v1-py-3",
            "ciro-v1-border",
            "ciro-v1-rounded",
            "ciro-v1-text-sm",
          )}
        />
        <EnrichedContactsDownloadButton
          selectedIds={selectedRequestIds}
          allIds={enrichedContacts?.map((contact) => contact.id!)}
        />
      </div>

      <CiroTable
        pagination={
          itemsPerPage
            ? {
                currentPage,
                totalPages,
                onPageChange: (page: number) => setCurrentPage(page),
              }
            : undefined
        }
      >
        <thead className={classNames("ciro-v1-table-header-group")}>
          <CiroTableRow clickable={false} sticky={true}>
            <CiroTableHeader width="ciro-v1-w-16">
              <CiroCheckbox
                checked={isAllSelected}
                indeterminate={isIndeterminate}
                onClick={handleSelectAll}
              />
            </CiroTableHeader>
            <CiroTableHeader isFirst>Contact</CiroTableHeader>
            <CiroTableHeader>Phone number</CiroTableHeader>
            <CiroTableHeader>Source</CiroTableHeader>
            <CiroTableHeader>Requested by</CiroTableHeader>
          </CiroTableRow>
        </thead>
        <tbody className={classNames("ciro-v1-table-row-group")}>
          {paginatedContacts?.map((phoneNumberRequest, i) => {
            if (!phoneNumberRequest?.id) {
              return null;
            }
            const requestId = phoneNumberRequest.id;
            if (!requestId) {
              return null;
            }
            const isChecked = selectedRequestIds.includes(requestId);
            return (
              <CiroTableRow key={requestId}>
                <CiroTableCell>
                  <CiroCheckbox
                    checked={isChecked}
                    onClick={(e) => handleSelectOne(e, requestId)}
                  />
                </CiroTableCell>
                <CiroTableCell
                  lastLeft={i === (paginatedContacts?.length || 1) - 1}
                >
                  {renderDisplayName(phoneNumberRequest)}
                </CiroTableCell>
                <CiroTableCell>
                  {phoneNumberRequest.numberFound || "-"}
                </CiroTableCell>
                <CiroTableCell>
                  {sourceFormatting(
                    phoneNumberRequest.orgContact.source || "-",
                  )}
                </CiroTableCell>
                <CiroTableCell>
                  {phoneNumberRequest.phoneNumberRequestTransaction
                    ?.user_account?.email || "-"}
                </CiroTableCell>
              </CiroTableRow>
            );
          })}
        </tbody>
      </CiroTable>
    </div>
  );
};

export default EnrichedContactsTable;

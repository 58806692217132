interface INodeBranchIconProps {
  className?: string;
}

const NodeBranchIcon = ({ className }: INodeBranchIconProps) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.41667 6.6665V13.3332"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="14.5833"
        cy="4.58333"
        rx="2.08333"
        ry="2.08333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="5.41732"
        cy="15.4168"
        r="2.08333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="5.41732"
        cy="4.58333"
        rx="2.08333"
        ry="2.08333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5846 6.6665V6.6665C14.5846 8.50745 13.0923 9.99984 11.2513 9.99984H8.7513C6.91035 9.99984 5.41797 11.4922 5.41797 13.3332V13.3332"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NodeBranchIcon;

import { RefObject, createContext } from "react";
import {
  EnrichmentFlowFilterInputTypeEnum,
  EnrichmentStepsDropdown_EnrichmentStepsFragment,
  EnrichmentTechniqueDetails,
  EnrichmentTechniqueEnum,
} from "../__generated__/graphql";
import { AgGridReact } from "ag-grid-react";
import { IEnrichmentFlowFilters } from "../reactHooks/filters/enrichmentFlow/useEnrichmentFlowFilters";
import { IRunStepParams } from "../components/enrichmentFlow/EnrichmentFlowRunModal";

export interface IRowRange {
  tableIndexStart: number | null;
  tableIndexEnd: number | null;
}

const EnrichmentFlowContext = createContext({
  enrichmentFlowId: 0,
  EnrichmentFlow_refetchEnrichmentFlow: async () => null,
  enrichmentFlowWithSteps: null,
  gridRef: null,
  setGridRef: () => null,
  enrichmentFlowTableContainer_refetchEnrichmentFlowRows: async () => {
    return;
  },
  setEnrichmentFlowTableContainer_refetchEnrichmentFlowRows: (v: any) => {
    return;
  },
  loadingStepIds: new Set<number>(),
  setLoadingStepIds: (v: Set<number>) => {
    return;
  },
  prepareRunStep: (v: IRunStepParams) => null,
  loadingRowIds: new Set<number>(),
  setLoadingRowIds: (v: Set<number>) => {
    return;
  },
  newEnrichmentTitle: "",
  setNewEnrichmentTitle: (v: string | null) => {
    return;
  },
  newEnrichmentTechniqueDetails: null,
  setNewEnrichmentTechniqueDetails: (v: EnrichmentTechniqueEnum | null) => {
    return;
  },
  setEnrichmentFlowLoading: (v: boolean) => {
    return;
  },
  selectedRows: { tableIndexStart: null, tableIndexEnd: null },
  setSelectedRows: (v: IRowRange) => {
    return;
  },
  runningJobId: null,
  setRunningJobId: (v: string | null) => {
    return;
  },
  enrichmentFlowRowIds: [],
  enrichmentFlowRowIdIndexMap: new Map<number, number>(),
  enrichmentFlowFilters: {},
  updateEnrichmentFilter: (v: {
    stepId: number;
    method: EnrichmentFlowFilterInputTypeEnum;
    values: string[];
  }) => {
    return;
  },
  resetEnrichmentFlowFilters: () => {
    return;
  },
} as {
  enrichmentFlowId: number;
  EnrichmentFlow_refetchEnrichmentFlow: () => Promise<any>;
  enrichmentFlowWithSteps?: EnrichmentStepsDropdown_EnrichmentStepsFragment | null;
  gridRef: RefObject<AgGridReact<any>> | null;
  setGridRef: (gridRef: RefObject<AgGridReact<any>>) => void;
  enrichmentFlowTableContainer_refetchEnrichmentFlowRows: () => Promise<any>;
  setEnrichmentFlowTableContainer_refetchEnrichmentFlowRows: (v: any) => void;
  loadingStepIds: Set<number>;
  setLoadingStepIds: (v: Set<number>) => void;
  prepareRunStep: (v: IRunStepParams) => void;
  loadingRowIds: Set<number> | null;
  setLoadingRowIds: (v: Set<number> | null) => void;
  newEnrichmentTitle: string | null;
  setNewEnrichmentTitle: (v: string | null) => void;
  newEnrichmentTechniqueDetails: EnrichmentTechniqueDetails | null;
  setNewEnrichmentTechniqueDetails: (
    v: EnrichmentTechniqueDetails | null,
  ) => void;
  setEnrichmentFlowLoading: (v: boolean) => void;
  selectedRows: IRowRange;
  setSelectedRows: (v: IRowRange) => void;
  runningJobId: string | null;
  setRunningJobId: (v: string | null) => void;
  enrichmentFlowRowIds: number[];
  enrichmentFlowRowIdIndexMap: Map<number, number>;
  enrichmentFlowFilters: IEnrichmentFlowFilters;
  updateEnrichmentFilter: (v: any) => void;
  resetEnrichmentFlowFilters: () => void;
});

export default EnrichmentFlowContext;

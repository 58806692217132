import { IEnrichmentExecutionCellProps } from ".";

const ExecutionCell_GetContact = ({
  value,
  setCellDetailsValue,
}: IEnrichmentExecutionCellProps) => {
  const parsedValue = value?.output && JSON.parse(value.output);
  const returnValue = parsedValue
    ? parsedValue.map((item: any) => item?.full_name).join(", ")
    : null;

  return (
    <div
      onClick={() => {
        setCellDetailsValue(parsedValue);
      }}
    >
      {returnValue}
    </div>
  );
};

export default ExecutionCell_GetContact;

import { gql } from "@apollo/client";
import { BusinessDetailsLocalFunFacts_CompanyFragment } from "../../__generated__/graphql";
import classNames from "classnames";
import CiroCard from "../shared/CiroCard";
import { useState } from "react";
import DownArrowIcon from "../../assets/img/icons/DownArrowIcon";
import { analytics } from "../../utils/vendors";
import CiroTitle from "../shared/CiroTitle";

export const BusinessDetailsLocalFunFacts_Company = gql`
  fragment BusinessDetailsLocalFunFacts_Company on Company {
    local_fun_facts {
      city
      state
      country
      fun_facts {
        emoji
        fact
      }
      failed_response
    }
  }
`;

interface IBusinessDetailsLocalFunFactsProps {
  company: BusinessDetailsLocalFunFacts_CompanyFragment;
  beginExpanded: Boolean;
}

const BusinessDetailsLocalFunFacts = ({
  company,
  beginExpanded,
}: IBusinessDetailsLocalFunFactsProps) => {
  const expandable = company?.local_fun_facts;
  const [expanded, setExpanded] = useState(beginExpanded);
  if (
    company.local_fun_facts === null ||
    company.local_fun_facts?.failed_response
  ) {
    return null;
  }
  return (
    <div className={classNames(["ciro-v1-mt-4"])}>
      <CiroCard size="small">
        <div
          className={classNames([
            "ciro-v1-flex",
            "ciro-v1-justify-between",
            "ciro-v1-whitespace-pre-wrap",
            "ciro-v1-inline-block",
            "ciro-v1-text-slate-900",
            "ciro-v1-font-semibold",
            "ciro-v1-text-base",
            "ciro-v1-leading-loose",
          ])}
        >
          <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
            <div
              className={classNames(
                "ciro-v1-bg-gray-300",
                "ciro-v1-h-10",
                "ciro-v1-w-10",
                "ciro-v1-rounded-full",
                "ciro-v1-text-center",
                "ciro-v1-text-2xl",
                "ciro-v1-mr-3",
                "ciro-v1-mt-2",
                "ciro-v1-pt-1",
              )}
            >
              📍
            </div>
            <CiroTitle
              title={
                "What to know about " +
                company.local_fun_facts?.city +
                ", " +
                company.local_fun_facts?.state
              }
              size="small"
            />
          </div>
          {expandable && (
            <div
              className={classNames(
                [
                  "ciro-v1-cursor-pointer",
                  "ciro-v1-duration-500",
                  "ciro-v1-ease-in-out",
                  "ciro-v1-flex",
                  "ciro-v1-items-center",
                  "ciro-v1-origin-center",
                  "ciro-v1-transform",
                  "ciro-v1-transition-all",
                ],
                {
                  "ciro-v1-rotate-180": expanded,
                },
              )}
              onClick={() => {
                setExpanded(!expanded);
                analytics.track("Viewed Fun Fact");
              }}
            >
              <DownArrowIcon stroke="black" />
            </div>
          )}
        </div>
        <div
          className={classNames(
            [
              "ciro-v1-ease",
              "ciro-v1-transition-all",
              "ciro-v1-duration-500",
              "ciro-v1-whitespace-pre-line",
              "ciro-v1-text-slate-600",
            ],
            {
              "ciro-v1-overflow-y-auto": expandable,
              "ciro-v1-max-h-0": !expanded,
              "ciro-v1-max-h-96": expanded || !expandable,
            },
          )}
        >
          <div>
            <div className={classNames(["ciro-v1-pt-4"])}>
              <ul className="ciro-v1-pl-3">
                {company?.local_fun_facts?.fun_facts?.map((fact, idx) => {
                  return (
                    <li
                      className={classNames([
                        "ciro-v1-flex",
                        "ciro-v1-items-top",
                        "ciro-v1-mb-1",
                      ])}
                      key={idx}
                    >
                      <span role="img" className={classNames(["ciro-v1-mr-3"])}>
                        {fact?.emoji}
                      </span>
                      {fact?.fact}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </CiroCard>
    </div>
  );
};

export default BusinessDetailsLocalFunFacts;

import { gql, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import CiroDropDown from "../../shared/CiroDropdown";
import classNames from "classnames";
import AdminEditUserModal from "./AdminEditUserModal";
import {
  AdminFindUserDropdown_UserAccountsQuery,
  AdminFindUserDropdown_UserAccountsQueryVariables,
} from "../../../__generated__/graphql";
import CiroCheckbox from "../../shared/CiroCheckbox";

// Used in AdminDestroyUserConfirmation.tsx
export const AdminFindUserDropdown_UserAccounts = gql`
  query AdminFindUserDropdown_UserAccounts($showDeleted: Boolean) {
    userAccounts(showDeleted: $showDeleted) {
      email
      id
    }
  }
`;

const AdminFindUserDropdown = () => {
  const [showDeleted, setShowDeleted] = useState(false);
  const { data: userAccountsData } = useQuery<
    AdminFindUserDropdown_UserAccountsQuery,
    AdminFindUserDropdown_UserAccountsQueryVariables
  >(AdminFindUserDropdown_UserAccounts, {
    variables: {
      showDeleted,
    },
  });

  const userAccounts = useMemo(() => {
    return userAccountsData?.userAccounts || [];
  }, [userAccountsData]);

  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const userAccountOptions =
    userAccounts.map((user) => {
      return {
        label: String(user.email),
        value: Number(user.id),
      };
    }) || [];

  return (
    <div>
      <CiroDropDown
        placeholder="Find user by email"
        isClearable
        label="Find user"
        labelClassName={classNames("ciro-v1-font-medium")}
        value={selectedUserId}
        options={userAccountOptions}
        onChange={(v) => {
          const selectedUser = userAccounts.find((user) => user.id === v);
          setSelectedUserId(selectedUser?.id || null);
        }}
      />
      {selectedUserId && (
        <AdminEditUserModal
          userId={selectedUserId}
          setUserId={setSelectedUserId}
        />
      )}
      <div className={classNames("ciro-v1-pt-4")}>
        <CiroCheckbox
          label="Show deleted users"
          checked={showDeleted}
          onClick={() => {
            setShowDeleted(!showDeleted);
          }}
        />
      </div>
    </div>
  );
};

export default AdminFindUserDropdown;

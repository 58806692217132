import classNames from "classnames";
import NumberFormat from "react-number-format";
import CoinIcon from "../../assets/img/icons/CoinIcon";

const CiroCoinPill = ({
  amount,
  additionalText,
  customColor,
  customBackgroundColor,
}: {
  amount: number;
  additionalText?: string;
  customColor?: string;
  customBackgroundColor?: string;
}) => {
  return (
    <span
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-items-center",
        "ciro-v1-rounded",
        "ciro-v1-px-1",
        "ciro-v1-text-sm",
        {
          "ciro-v1-text-amber-500": !customColor,
          "ciro-v1-bg-amber-100": !customBackgroundColor,
        },
        customColor,
        customBackgroundColor,
      )}
    >
      {additionalText && (
        <span className={classNames("ciro-v1-pr-1")}>{additionalText}</span>
      )}
      <NumberFormat displayType="text" thousandSeparator="," value={amount} />
      <span className={classNames("ciro-v1-pl-1")}>
        <CoinIcon />
      </span>
    </span>
  );
};

export default CiroCoinPill;

const ageOptions = [
  { value: "20-30", label: "20-30" },
  { value: "31-40", label: "31-40" },
  { value: "41-50", label: "41-50" },
  { value: "51-60", label: "51-60" },
  { value: "61+", label: "60+" },
];

const organizationStatusOptions = [
  { value: "CURRENT_CUSTOMER", label: "Current customer" },
  { value: "FORMER_CUSTOMER", label: "Former customer" },
  { value: "PROSPECT", label: "Prospect" },
  { value: "NULL", label: "Not in CRM" },
];

const businessTypeOptions = [
  { value: "COMPANY", label: "Company" },
  { value: "LOCATION", label: "Location" },
];

const parentChildTypeOptions = [
  { value: "PARENT", label: "Parent" },
  { value: "CHILD", label: "Child" },
  { value: "INDEPENDENT", label: "Independent" },
];

export { ageOptions, organizationStatusOptions, businessTypeOptions, parentChildTypeOptions };

import { createContext } from "react";

const NavigationContext = createContext({
  refreshNavData: async () => {
    return;
  },
} as {
  refreshNavData: () => Promise<any>;
});

export default NavigationContext;

import { FieldValues, useForm, UseFormProps } from "react-hook-form";

const useEnrichmentStepForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props: UseFormProps<TFieldValues, TContext>,
) => {
  const methods = useForm(props);

  // Always dirty the form whenever one of the values is set
  const setValueAndDirty = (name: any, value: any, options = {}) => {
    return methods.setValue(name, value, { ...options, shouldDirty: true });
  };

  // Return a new object with the overridden setValue
  return {
    ...methods,
    setValue: setValueAndDirty,
  };
};

export default useEnrichmentStepForm;

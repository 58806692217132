import { EnrichmentTechniqueEnum } from "../../../../__generated__/graphql";
import CiroDropDown from "../../../shared/CiroDropdown";

export type EnrichmentFilterTechnique =
  | EnrichmentTechniqueEnum.FiltersEquals
  | EnrichmentTechniqueEnum.FiltersIsNotNull
  | EnrichmentTechniqueEnum.FiltersIncludes
  | EnrichmentTechniqueEnum.FiltersIsNull;

export const enrichmentStepFilterDropdownOptions = [
  {
    value: EnrichmentTechniqueEnum.FiltersEquals,
    label: "=",
  },
  {
    value: EnrichmentTechniqueEnum.FiltersIsNotNull,
    label: "Exists",
  },
  {
    value: EnrichmentTechniqueEnum.FiltersIncludes,
    label: "Includes",
  },
  {
    value: EnrichmentTechniqueEnum.FiltersIsNull,
    label: "Is Null",
  },
] as {
  value: EnrichmentFilterTechnique;
  label: string;
}[];

export const enrichmentFilters = enrichmentStepFilterDropdownOptions.map(
  (option) => option.value,
);

interface IEnrichmentStepFilterDropdownProps {
  value: EnrichmentFilterTechnique;
  onChange: (value: EnrichmentFilterTechnique) => void;
}

const EnrichmentStepFilterDropdown = ({
  value,
  onChange,
}: IEnrichmentStepFilterDropdownProps) => {
  return (
    <CiroDropDown
      label="Filter Type"
      isMulti={false}
      isClearable={false}
      value={value}
      options={enrichmentStepFilterDropdownOptions}
      onChange={(newValue) => {
        onChange(newValue);
      }}
    />
  );
};

export default EnrichmentStepFilterDropdown;

import { formatTimestamp } from "../../utils/formatters";
import classNames from "classnames";
import { gql } from "@apollo/client";
import {
  BusinessDetailsMergeCrmAccountDetails_MergeCrmAccountFragment,
  Maybe,
} from "../../__generated__/graphql";

export const BusinessDetailsMergeCrmAccountDetails_MergeCrmAccount = gql`
  fragment BusinessDetailsMergeCrmAccountDetails_MergeCrmAccount on MergeCrmAccount {
    name
    last_activity_at
    owner {
      email
      name
    }
    opportunities {
      name
      remote_created_at
      close_date
      last_activity_at
      owner {
        email
        name
      }
    }
  }
`;

interface IBusinessDetailsMergeCrmAccountDetailsProps {
  mergeCrmAccount?: Maybe<BusinessDetailsMergeCrmAccountDetails_MergeCrmAccountFragment>;
}

const BusinessDetailsMergeCrmAccountDetails = ({
  mergeCrmAccount,
}: IBusinessDetailsMergeCrmAccountDetailsProps) => {
  if (!mergeCrmAccount) {
    return null;
  }
  return (
    <div>
      <div>{mergeCrmAccount.name}</div>
      {mergeCrmAccount.owner && (
        <div
          className={classNames("ciro-v1-text-sm", "ciro-v1-text-slate-600")}
        >
          Owner: {mergeCrmAccount.owner.name}
        </div>
      )}
      {mergeCrmAccount.last_activity_at && (
        <div
          className={classNames("ciro-v1-text-sm", "ciro-v1-text-slate-600")}
        >
          Last activity:{" "}
          {formatTimestamp(new Date(mergeCrmAccount.last_activity_at))}
        </div>
      )}
      {Boolean(mergeCrmAccount.opportunities.length) && (
        <div>
          <div className={classNames("ciro-v1-text-base", "ciro-v1-pt-4")}>
            Opportunities
          </div>
          {mergeCrmAccount.opportunities.map((opportunity, i) => {
            return (
              <div
                key={i}
                className={classNames("ciro-v1-pt-2", "ciro-v1-text-sm")}
              >
                <div className={classNames("ciro-v1-font-medium")}>
                  {opportunity.name}
                </div>
                {opportunity.owner &&
                  opportunity.owner?.email !== mergeCrmAccount.owner?.email && (
                    <div className={classNames("ciro-v1-text-slate-600")}>
                      Owner: {opportunity.owner.name}
                    </div>
                  )}
                <div className={classNames("ciro-v1-text-slate-600")}>
                  {opportunity.close_date
                    ? `Closed: ${formatTimestamp(new Date(opportunity.close_date))}`
                    : `Created: ${formatTimestamp(new Date(opportunity.remote_created_at))}`}
                </div>
                {opportunity.last_activity_at && (
                  <div className={classNames("ciro-v1-text-slate-600")}>
                    Last activity:{" "}
                    {formatTimestamp(new Date(opportunity.last_activity_at))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BusinessDetailsMergeCrmAccountDetails;

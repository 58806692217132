import React, { useContext } from "react";
import { gql } from "@apollo/client";
import {
  AccountsTableHeaderCheckbox_CompaniesFromFiltersResponseFragmentDoc,
  AccountsTable_CompaniesFromFiltersResponseFragment,
  AccountsTable_PermissionsFragment,
  AccountsTable_UserAccountFragment,
  CompaniesFromFiltersInput,
  Maybe,
} from "../../__generated__/graphql";
import CiroTable from "../shared/CiroTable/CiroTable";
import CiroTableHeader, {
  CiroTableHeaderAlignEnum,
} from "../shared/CiroTable/CiroTableHeader";
import CiroTableCell, {
  CiroTableCellAlignEnum,
} from "../shared/CiroTable/CiroTableCell";
import CiroTableRow from "../shared/CiroTable/CiroTableRow";
import classNames from "classnames";
import CiroCheckbox from "../shared/CiroCheckbox";
import { ACCOUNTS_MAX_NUM_ROWS } from "../../routes/accounts/Accounts";
import CiroTablePagination from "../shared/CiroTable/CiroTablePagination";
import CiroCRMPill from "../shared/CiroCRMPill";
import CiroNoRecordsCard from "../shared/CiroNoRecordsCard";
import url from "url";
import { prettifyUrl } from "../../utils/formatters";
import AccountsTableHeaderCheckbox, {
  AccountsTableHeaderCheckbox_CompaniesFromFiltersResponse,
} from "./AccountTableHeaderCheckbox";
import { useFragment } from "../../__generated__";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import SkeletonLoading from "../shared/SkeletonLoading";
import FilterContext from "../../contexts/FilterContext";

export const AccountsTable_CompaniesFromFiltersResponse = gql`
  fragment AccountsTable_CompaniesFromFiltersResponse on CompaniesFromFiltersResponse {
    companies {
      matchedTexts
      company {
        pk
        industry {
          value
        }
        address_city {
          value
        }
        crmMatchStats {
          bestCrmMatch {
            current_status
          }
          totalCrmMatches
        }
        is_parent {
          value
        }
        address_state {
          value
        }
        company_name {
          value
        }
        phone_number {
          value
        }
        website {
          value
        }
      }
    }
    ...AccountsTableHeaderCheckbox_CompaniesFromFiltersResponse
  }
  ${AccountsTableHeaderCheckbox_CompaniesFromFiltersResponse}
`;

export const AccountsTable_Permissions = gql`
  fragment AccountsTable_Permissions on Permissions {
    accountPaginationLimit
    canSelectAccountsInBulk
  }
`;

export const AccountsTable_UserAccount = gql`
  fragment AccountsTable_UserAccount on UserAccount {
    id
    hasCRMConnection
  }
`;

const ClickableAccountTableData = ({
  id,
  children,
  className,
  align,
}: {
  id: string;
  children: any;
  className?: string;
  align?: CiroTableCellAlignEnum;
}) => {
  return (
    <CiroTableCell
      align={align}
      className={className}
      href={`/businessDetails?businessId=${id}`}
    >
      {children}
    </CiroTableCell>
  );
};

const StyledTextMatch = ({ inputString }: { inputString: string }) => {
  const parts = inputString.split(/<mark>|<\/mark>/);

  return (
    <div>
      ...
      {parts.map((part, index) => {
        const bold = index % 2 !== 0;
        return (
          <span
            key={index}
            className={classNames("ciro-v1-italic", {
              "ciro-v1-font-normal": !bold,
              "ciro-v1-font-bold": bold,
            })}
          >
            {part}
          </span>
        );
      })}
      ...
    </div>
  );
};

interface IAccountsTableProps {
  canSelectRecords: boolean;
  checked: Set<string>;
  companiesFromFilter?: Maybe<AccountsTable_CompaniesFromFiltersResponseFragment>;
  filteredCompanyQueryVariables: CompaniesFromFiltersInput;
  isPageLoading: boolean;
  numberVisibleChecked: number;
  permissions: AccountsTable_PermissionsFragment;
  setChecked: (v: Set<string>) => void;
  totalNumberChecked: number;
  setOffset: (v: number) => void;
  offset: number;
  userAccount?: Maybe<AccountsTable_UserAccountFragment>;
}

function AccountsTable({
  canSelectRecords,
  checked,
  companiesFromFilter,
  filteredCompanyQueryVariables,
  isPageLoading,
  numberVisibleChecked,
  totalNumberChecked,
  permissions,
  setChecked,
  setOffset,
  offset,
  userAccount,
}: IAccountsTableProps) {
  const { companiesCount } = useContext(FilterContext);
  const accountsTableHeaderCheckboxCompanies = useFragment(
    AccountsTableHeaderCheckbox_CompaniesFromFiltersResponseFragmentDoc,
    companiesFromFilter,
  );
  const companies = companiesFromFilter?.companies || [];
  const toggleCheck = (ciroBusinessId: string) => {
    const newChecked = new Set([...checked]);
    newChecked.has(ciroBusinessId)
      ? newChecked.delete(ciroBusinessId)
      : newChecked.add(ciroBusinessId);
    setChecked(newChecked);
  };

  // Using companies.length here because companiesCount could still be loading, different query from companies
  if (companies.length === 0) {
    if (isPageLoading) {
      return <SkeletonLoading numSkeletons={25} skeletonHeight={"3rem"} />;
    }

    return (
      <CiroNoRecordsCard
        title="No accounts found"
        subtitle="Please try adjusting your filters and search again"
      />
    );
  }

  const headers = [
    canSelectRecords && (
      <CiroTableHeader key={1} width="ciro-v1-w-24" isFirst={true}>
        <AccountsTableHeaderCheckbox
          checked={checked}
          companiesFromFilter={accountsTableHeaderCheckboxCompanies}
          filteredCompanyQueryVariables={filteredCompanyQueryVariables}
          numberVisibleChecked={numberVisibleChecked}
          offset={offset}
          setChecked={setChecked}
          setOffset={setOffset}
          permissions={permissions}
          totalNumberChecked={totalNumberChecked}
        />
      </CiroTableHeader>
    ),
    <CiroTableHeader
      key={2}
      width={classNames("ciro-v1-w-full")}
      isFirst={!canSelectRecords}
    >
      Business Name
    </CiroTableHeader>,
    <CiroTableHeader
      key={3}
      width="ciro-v1-w-64"
      className={classNames("ciro-v1-hidden", "lg:ciro-v1-table-cell")}
    >
      Website
    </CiroTableHeader>,
    <CiroTableHeader
      key={4}
      isLast={!canSelectRecords}
      width="ciro-v1-w-64"
      className={classNames("ciro-v1-hidden", "sm:ciro-v1-table-cell")}
    >
      Industry
    </CiroTableHeader>,
    canSelectRecords && (
      <CiroTableHeader
        key={5}
        isLast={true}
        width={classNames(
          "ciro-v1-hidden",
          "ciro-v1-w-40",
          "sm:ciro-v1-table-cell",
        )}
        align={CiroTableHeaderAlignEnum.LEFT}
      >
        Status
      </CiroTableHeader>
    ),
  ].filter(Boolean);

  return (
    <div className={classNames("ciro-v1-w-full", "test-business-table")}>
      <CiroTable>
        <thead className={classNames("ciro-v1-table-header-group")}>
          <CiroTableRow clickable={false} sticky={true}>
            {headers}
          </CiroTableRow>
        </thead>
        <tbody className={classNames("ciro-v1-table-row-group")}>
          {companies.map(function ({ company, matchedTexts }) {
            const websiteUrlObj = company.website?.value
              ? prettifyUrl(url.parse(company.website?.value))
              : null;
            const cityValue = company?.address_city?.value;
            const stateValue = company?.address_state?.value;
            let addressString = stateValue;
            if (cityValue && stateValue) {
              addressString = `${cityValue}, ${stateValue}`;
            }
            return (
              <CiroTableRow key={company.pk}>
                {canSelectRecords && (
                  <CiroTableCell>
                    <CiroCheckbox
                      checked={checked.has(company.pk)}
                      onClick={() => toggleCheck(company.pk)}
                      className={"ciro-v1-ml-4"}
                    />
                  </CiroTableCell>
                )}
                <ClickableAccountTableData id={company.pk}>
                  <div>
                    <div
                      className={classNames(
                        "ciro-v1-font-medium",
                        "ciro-v1-flex",
                        "ciro-v1-items-center",
                      )}
                    >
                      {company.company_name?.value}
                      {company.is_parent?.value && (
                        <span className={classNames("ciro-v1-ml-2")}>
                          <CiroTooltipContainer tooltip="Enterprise">
                            <BuildingOffice2Icon
                              className={classNames(
                                "ciro-v1-text-slate-500",
                                "ciro-v1-w-4",
                                "ciro-v1-h-4",
                              )}
                            />
                          </CiroTooltipContainer>
                        </span>
                      )}
                    </div>
                    <div className={classNames()}>{addressString}</div>
                    {matchedTexts?.map((matchedText) => {
                      return <StyledTextMatch inputString={matchedText} />;
                    })}
                  </div>
                </ClickableAccountTableData>
                <ClickableAccountTableData
                  id={company.pk}
                  className={classNames(
                    "lg:ciro-v1-table-cell",
                    "ciro-v1-hidden",
                  )}
                >
                  {websiteUrlObj}
                </ClickableAccountTableData>
                <ClickableAccountTableData
                  id={company.pk}
                  className={classNames(
                    "ciro-v1-hidden",
                    "sm:ciro-v1-table-cell",
                  )}
                >
                  {company.industry?.value}
                </ClickableAccountTableData>
                {canSelectRecords && (
                  <ClickableAccountTableData
                    id={company.pk}
                    align={CiroTableCellAlignEnum.LEFT}
                  >
                    <span
                      className={classNames(
                        "ciro-v1-flex",
                        "ciro-v1-hidden",
                        "ciro-v1-justify-start",
                        "sm:ciro-v1-table-cell",
                      )}
                    >
                      <CiroCRMPill
                        crmStatus={
                          company.crmMatchStats?.bestCrmMatch?.current_status ||
                          ""
                        }
                        crmConnected={Boolean(userAccount?.hasCRMConnection)}
                        foundInCrm={Boolean(
                          company.crmMatchStats?.bestCrmMatch,
                        )}
                      />
                    </span>
                  </ClickableAccountTableData>
                )}
              </CiroTableRow>
            );
          })}
          <CiroTablePagination
            paginationLimit={permissions.accountPaginationLimit || 1}
            setOffset={setOffset}
            offset={offset}
            perPage={ACCOUNTS_MAX_NUM_ROWS}
            tableRows={headers.length}
            totalCount={companiesCount}
          />
        </tbody>
      </CiroTable>
    </div>
  );
}

export default AccountsTable;

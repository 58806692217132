import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import {
  CiroByQueryUnlockModal_CreditsToSpendQuery,
  CiroByQueryUnlockModal_CreditsToSpendQueryVariables,
  CiroUnlockPremiumDataContent_CreditsToSpendResponseFragmentDoc,
  CompaniesFromFiltersInput,
} from "../../__generated__/graphql";
import CiroModal from "../shared/CiroModal";
import XIcon from "../../assets/img/icons/XIcon";
import CiroUnlockPremiumDataContent, {
  CiroCreditUseCaseEnum,
  CiroUnlockPremiumDataContent_CreditsToSpendResponse,
} from "../shared/CiroUnlockPremiumData/CiroUnlockPremiumDataContent";
import CiroUnlockPremiumDataSubmit from "../shared/CiroUnlockPremiumData/CiroUnlockPremiumDataSubmit";
import { InternalRefetchQueriesInclude, gql, useQuery } from "@apollo/client";
import { useFragment } from "../../__generated__";

interface CiroByQueryUnlockModalProps {
  filteredCompanyQueryVariables: CompaniesFromFiltersInput;
  numRecordsToUnlock: number;
  offset: number;
  onClose: () => void;
  onCompleted: () => void;
  premiumDataContentAction?: string;
  refetchQueries: InternalRefetchQueriesInclude;
  subtitle?: string;
}

const CiroByQueryUnlockModal_CreditsToSpend = gql`
  query CiroByQueryUnlockModal_CreditsToSpend(
    $companyQuery: CompaniesFromFiltersAndCountInput
  ) {
    creditsToSpend(companyQuery: $companyQuery) {
      currentBalance
      parentType
      totalParentCount
      unlockPms {
        creditsToSpend
        accountsCount
      }
      ...CiroUnlockPremiumDataContent_CreditsToSpendResponse
    }
  }
  ${CiroUnlockPremiumDataContent_CreditsToSpendResponse}
`;

const CiroByQueryUnlockModal = ({
  filteredCompanyQueryVariables,
  numRecordsToUnlock,
  offset,
  onClose,
  onCompleted,
  premiumDataContentAction,
  refetchQueries,
  subtitle,
}: CiroByQueryUnlockModalProps) => {
  const fetchCreditDataVariables = {
    companyQuery: {
      filters: filteredCompanyQueryVariables,
      offset,
      limit: numRecordsToUnlock,
    },
  };
  const { data: fetchedCreditData, loading: fetchCreditsLoading } = useQuery<
    CiroByQueryUnlockModal_CreditsToSpendQuery,
    CiroByQueryUnlockModal_CreditsToSpendQueryVariables
  >(CiroByQueryUnlockModal_CreditsToSpend, {
    variables: fetchCreditDataVariables,
    fetchPolicy: "network-only",
  });

  const currentBalance = fetchedCreditData?.creditsToSpend?.currentBalance || 0;
  const unlockPmsCredits =
    fetchedCreditData?.creditsToSpend?.unlockPms.creditsToSpend || 0;

  const creditsToSpendContent = useFragment(
    CiroUnlockPremiumDataContent_CreditsToSpendResponseFragmentDoc,
    fetchedCreditData?.creditsToSpend,
  );

  return (
    <CiroModal onClose={onClose} isOpen={Boolean(numRecordsToUnlock)}>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-font-medium",
          "ciro-v1-items-center",
          "ciro-v1-justify-between",
        )}
      >
        <span>Unlock Premium Data</span>
        <CiroButton
          style={CiroButtonStyleEnum.UNSTYLED}
          analyticsField={`Close Unlock Premium Data Modal'}`}
          onClick={onClose}
        >
          <XIcon />
        </CiroButton>
      </div>
      {subtitle && <div className={classNames("ciro-v1-pt-4")}>{subtitle}</div>}
      <CiroUnlockPremiumDataContent
        action={premiumDataContentAction}
        creditActions={[CiroCreditUseCaseEnum.UNLOCK_PMS]}
        creditsToSpend={creditsToSpendContent}
        isLoading={fetchCreditsLoading}
      />
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-end",
          "ciro-v1-pt-4",
        )}
      >
        <CiroUnlockPremiumDataSubmit
          companyQuery={{
            filters: filteredCompanyQueryVariables,
            limit: numRecordsToUnlock,
            offset,
          }}
          disabled={fetchCreditsLoading || currentBalance < unlockPmsCredits}
          onCompleted={onCompleted}
          refetchQueries={refetchQueries}
        />
      </div>
    </CiroModal>
  );
};

export default CiroByQueryUnlockModal;

import { createContext } from "react";
import { IEnrichmentFlowFilters } from "../reactHooks/filters/enrichmentFlow/useEnrichmentFlowFilters";

export interface IEnrichmentStepCardSavingStatus {
  saving: boolean;
  error: string;
  success: boolean;
}

const EnrichmentStepCardContext = createContext({
  rowIds: [] as number[],
  offset: 0,
  totalCount: 0,
  savingStatus: null,
  enrichmentFlowFilters: {},
} as {
  rowIds: number[];
  offset: number;
  totalCount: number;
  savingStatus: IEnrichmentStepCardSavingStatus | null;
  enrichmentFlowFilters: IEnrichmentFlowFilters;
});

export default EnrichmentStepCardContext;

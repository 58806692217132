const JsonIcon = ({ width="20", height="20", className }: { width?: string; height?: string; className?: string}) => {
  return (<svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.83161 16.6695C4.68054 16.6695 3.74741 15.7363 3.74741 14.5853V12.0842C3.74741 11.5314 3.52782 11.0013 3.13696 10.6105C2.7461 10.2196 2.21597 10 1.66321 10C2.21597 10 2.7461 9.78043 3.13696 9.38956C3.52782 8.9987 3.74741 8.46857 3.74741 7.91581V5.41477C3.74741 4.2637 4.68054 3.33057 5.83161 3.33057"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1685 3.33057C15.3195 3.33057 16.2527 4.2637 16.2527 5.41477V7.91581C16.2527 9.06688 17.1858 10 18.3369 10C17.1858 10 16.2527 10.9331 16.2527 12.0842V14.5853C16.2527 15.7363 15.3195 16.6695 14.1685 16.6695"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.22806 10.0683C7.22806 10.0453 7.20939 10.0266 7.18637 10.0266C7.16335 10.0266 7.14469 10.0453 7.14469 10.0683C7.14469 10.0914 7.16335 10.11 7.18637 10.11C7.20939 10.11 7.22806 10.0914 7.22806 10.0683"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0417 10.0609C10.0417 10.0379 10.023 10.0192 9.99997 10.0192C9.97695 10.0192 9.95829 10.0379 9.95829 10.0609C9.95829 10.0839 9.97695 10.1026 9.99997 10.1026C10.023 10.1026 10.0417 10.0839 10.0417 10.0609"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5428 10.0609C12.5428 10.0379 12.5241 10.0192 12.5011 10.0192C12.478 10.0192 12.4594 10.0379 12.4594 10.0609C12.4594 10.0839 12.478 10.1026 12.5011 10.1026C12.5241 10.1026 12.5428 10.0839 12.5428 10.0609"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>)
}

export default JsonIcon;
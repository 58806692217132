import { CrmIntegrationEnum } from "../../__generated__/graphql";

import {
  ArrowPathIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { Menu } from "@headlessui/react";
import { dateToTimeAgo } from "../../utils/formatters";
import { useState } from "react";
import RemoveCrmIntegrationModal from "./RemoveCrmIntegrationModal";
import classNames from "classnames";

interface ICrmSyncStatus {
  lastSyncedAt: string | null | undefined;
  integration: CrmIntegrationEnum;
  refetchUserAccount: () => void;
}

export function CrmSyncStatus({
  lastSyncedAt,
  integration,
  refetchUserAccount,
}: ICrmSyncStatus) {
  const [deletingCrmIntegration, setDeletingCrmIntegration] = useState(false);
  const handleDeleteConnection = () => {
    setDeletingCrmIntegration(true);
  };

  if (deletingCrmIntegration) {
    return (
      <RemoveCrmIntegrationModal
        crmIntegration={integration}
        onClose={() => setDeletingCrmIntegration(false)}
        refetchUserAccount={refetchUserAccount}
      />
    );
  }

  return (
    <div
      className={classNames(
        "ciro-v1-text-sm",
        "ciro-v1-text-neutral-600",
        "ciro-v1-flex",
        "ciro-v1-items-center",
        "ciro-v1-justify-between",
      )}
    >
      <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
        <ArrowPathIcon
          height={20}
          className={classNames("ciro-v1-pr-2", "ciro-v1-text-blue-500")}
        />
        {lastSyncedAt
          ? `${integration} synced ${dateToTimeAgo(lastSyncedAt)}`
          : `${integration} synced`}
      </div>
      <Menu
        as="div"
        className={classNames(
          "ciro-v1-relative",
          "ciro-v1-inline-block",
          "ciro-v1-text-left",
          "ciro-v1-mx-2",
        )}
      >
        <Menu.Button
          className={classNames(
            "ciro-v1-p-2",
            "ciro-v1-rounded-full",
            "hover:ciro-v1-bg-gray-100",
          )}
        >
          <EllipsisHorizontalIcon
            className={classNames("ciro-v1-h-5", "ciro-v1-w-5")}
          />
        </Menu.Button>
        <Menu.Items
          className={classNames(
            "ciro-v1-absolute",
            "ciro-v1-right-0",
            "ciro-v1-mt-2",
            "ciro-v1-w-56",
            "ciro-v1-origin-top-right",
            "ciro-v1-rounded-md",
            "ciro-v1-bg-white",
            "ciro-v1-shadow-lg",
            "ciro-v1-ring-1",
            "ciro-v1-ring-black",
            "ciro-v1-ring-opacity-5",
            "focus:ciro-v1-outline-none",
          )}
        >
          <Menu.Item>
            {({ active }) => (
              <button
                className={classNames(
                  active
                    ? "ciro-v1-bg-gray-100 ciro-v1-text-gray-900"
                    : "ciro-v1-text-gray-700",
                  "ciro-v1-block",
                  "ciro-v1-w-full",
                  "ciro-v1-text-left",
                  "ciro-v1-px-4",
                  "ciro-v1-py-2",
                  "ciro-v1-text-sm",
                )}
                onClick={handleDeleteConnection}
              >
                Delete connection
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>
  );
}

export default CrmSyncStatus;

import React from "react";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";
import CiroDropDown from "../shared/CiroDropdown";
import { RequiredFieldFilterOptionsEnum } from "../../__generated__/graphql";

const REQUIRED_FIELD_FILTER_ENUM_COPY = {
  [RequiredFieldFilterOptionsEnum.HasAddress]: "Must have address",
  [RequiredFieldFilterOptionsEnum.HasCompanyEmail]: "Must have a company email",
  [RequiredFieldFilterOptionsEnum.HasManuallyVerifiedTech]:
    "Must have a manually verified tech",
  [RequiredFieldFilterOptionsEnum.HasPhoneNumber]:
    "Must have a company phone number",
  [RequiredFieldFilterOptionsEnum.HasWebsite]: "Must have a website",
};

interface IRequiredFieldFilterProps {
  blurred: boolean;
  requiredFieldFilter: string[];
  setRequiredFieldFilter: (v: string[]) => void;
  resetOffset: () => void;
}

function RequiredFieldFilter({
  blurred,
  requiredFieldFilter,
  setRequiredFieldFilter,
  resetOffset,
}: IRequiredFieldFilterProps) {
  const filterOptions = Object.values(RequiredFieldFilterOptionsEnum).map(
    (element) => {
      return {
        value: element,
        label: REQUIRED_FIELD_FILTER_ENUM_COPY[element],
      };
    },
  );

  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={requiredFieldFilter.length}
      sectionTitle="Required Fields"
      filterOptions={[
        {
          label: "Include all of:",
          filter: (
            <CiroDropDown
              blurred={blurred}
              isMulti
              options={filterOptions}
              value={requiredFieldFilter}
              onChange={(newSelection) => {
                setRequiredFieldFilter(newSelection);
                resetOffset();
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default RequiredFieldFilter;

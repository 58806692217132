const DatabaseIcon = ({
  height = 24,
  width = 24,
}: {
  height?: number | string;
  width?: number | string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={"0 0 24 24"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.966 21C15.851 21 19 19.815 19 18.353V5.647C19 4.185 15.866 3 12 3C8.134 3 5 4.185 5 5.647V18.353C5 19.815 8.149 21 12.034 21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16.5L14 14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.5L12 16.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12V16.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8.29397C8.134 8.29397 5 7.10897 5 5.64697"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 5.64697C19 7.10897 15.866 8.29397 12 8.29397"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DatabaseIcon;

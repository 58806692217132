import classNames from "classnames";
import { IFilterEnrichmentStep } from "./EnrichmentStepCardFiltersList";
import { useFormContext } from "react-hook-form";
import EnrichmentStepSelector from "../EnrichmentStepSelector/EnrichmentStepSelector";

interface IEnrichmentStepFilterIsEqualProps {
  filterEnrichmentStep: IFilterEnrichmentStep;
  stepIndex: number;
}

const EnrichmentStepFilterIsEqual = ({
  filterEnrichmentStep,
  stepIndex,
}: IEnrichmentStepFilterIsEqualProps) => {
  const {
    formState: { errors },
    setValue,
  } = useFormContext<{
    filterEnrichmentSteps: IFilterEnrichmentStep[];
  }>();

  const value2Idx = filterEnrichmentStep.parentEnrichmentStepInputs.findIndex(
    (input) => {
      return input.key === "value2";
    },
  );

  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-flex-col",
        "ciro-v1-items-start",
      )}
    >
      <EnrichmentStepSelector
        stepVariable={{
          stepId:
            filterEnrichmentStep.parentEnrichmentStepInputs[value2Idx]
              .sourceEnrichmentStepId,
          stepInput:
            filterEnrichmentStep.parentEnrichmentStepInputs[value2Idx].input,
        }}
        setStepVariable={(newVariable) => {
          setValue(
            `filterEnrichmentSteps.${stepIndex}.parentEnrichmentStepInputs.${value2Idx}.sourceEnrichmentStepId`,
            newVariable.stepId,
          );
          setValue(
            `filterEnrichmentSteps.${stepIndex}.parentEnrichmentStepInputs.${value2Idx}.input`,
            newVariable.stepInput,
          );
        }}
        freeText={true}
        inputError={
          errors.filterEnrichmentSteps?.[stepIndex]
            ?.parentEnrichmentStepInputs?.[value2Idx]?.input?.message
        }
      />
    </div>
  );
};

export default EnrichmentStepFilterIsEqual;

const StarsIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.53732 10.779L7.31447 11.4543C8.65014 11.9618 9.70486 13.0166 10.2124 14.3522L10.8877 16.1294C11.0106 16.4529 11.3206 16.6667 11.6667 16.6667C12.0127 16.6667 12.3227 16.4529 12.4456 16.1294L13.1209 14.3522C13.6285 13.0166 14.6832 11.9618 16.0189 11.4543L17.796 10.779C18.1195 10.6561 18.3333 10.3461 18.3333 10C18.3333 9.654 18.1195 9.34396 17.796 9.22105L16.0189 8.54576C14.6832 8.03823 13.6285 6.98351 13.1209 5.64784L12.4456 3.8707C12.3227 3.54722 12.0127 3.33337 11.6667 3.33337C11.3206 3.33337 11.0106 3.54722 10.8877 3.8707L10.2124 5.64784C9.70486 6.98351 8.65014 8.03823 7.31447 8.54576L5.53732 9.22105C5.21385 9.34396 5 9.654 5 10C5 10.3461 5.21385 10.6561 5.53732 10.779Z"
        fill="#FFDBCD"
        stroke="#FD5B1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99992 17.5V14.1666"
        stroke="#FD5B1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33325 15.8333H6.66659"
        stroke="#FD5B1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16667 5.83333V2.5"
        stroke="#FD5B1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 4.16667H5.83333"
        stroke="#FD5B1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarsIcon;

const ArrowheadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.99998 8.74474L7.99998 12.4068"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.41775 12.5066L7.28255 2.77449C7.41837 2.50268 7.69613 2.33097 7.99998 2.33097C8.30383 2.33097 8.58159 2.50268 8.71741 2.77449L13.5822 12.5066C13.7233 12.7886 13.686 13.1271 13.487 13.3717C13.2881 13.6163 12.9643 13.7217 12.6595 13.641L7.99998 12.4068L3.34051 13.641C3.03568 13.7217 2.71188 13.6163 2.51291 13.3717C2.31394 13.1271 2.27671 12.7886 2.41775 12.5066Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowheadIcon;

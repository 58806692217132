import { XMarkIcon } from "@heroicons/react/24/outline";
import CiroButton from "../../shared/CiroButton";
import classNames from "classnames";
import { useContext } from "react";
import EnrichmentFlowContext from "../../../contexts/EnrichmentFlowContext";

const EnrichmentFlowClearSelectionButton = () => {
  const { selectedRows, setSelectedRows } = useContext(EnrichmentFlowContext);

  return (
    <>
      <CiroButton
        analyticsField="Clear enrichment table selection pressed"
        onClick={() => {
          setSelectedRows({
            tableIndexStart: null,
            tableIndexEnd: null,
          });
        }}
      >
        <XMarkIcon className={classNames("ciro-v1-h-5", "ciro-v1-mr-2")} />
        <div>{`Unselect ${selectedRows.tableIndexStart! + 1}-${selectedRows.tableIndexEnd! + 1}`}</div>
      </CiroButton>
    </>
  );
};

export default EnrichmentFlowClearSelectionButton;

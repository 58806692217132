import { gql, useQuery } from "@apollo/client";
import {
  EnrichmentStepCardFiltersList_EnrichmentStepFragment,
  EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
  EnrichmentStepInput,
  EnrichmentTechniqueDetails,
  EnrichmentTechniqueEnum,
  UseEnrichmentInputErrors_EnrichmentFlowQuery,
  UseEnrichmentInputErrors_EnrichmentFlowQueryVariables,
} from "../../__generated__/graphql";
import {
  EnrichmentStepTechniqueChatGptCardSchema,
  getDefaultChatGptValues,
} from "../../components/enrichmentFlow/EnrichmentStepCard/EnrichmentStepTechniqueCards/EnrichmentStepTechniqueChatGptCard";
import {
  EnrichmentStepTechniqueSerpApiCardSchema,
  getDefaultSerpApiValues,
} from "../../components/enrichmentFlow/EnrichmentStepCard/EnrichmentStepTechniqueCards/EnrichmentStepTechniqueSerpApiCard";
import {
  EnrichmentStepTechniqueReturnValueCardSchema,
  getReturnValueDefaultValues,
} from "../../components/enrichmentFlow/EnrichmentStepCard/EnrichmentStepTechniqueCards/EnrichmentStepTechniqueReturnValueCard";
import { EnrichmentStepTechniqueDefaultCardSchema } from "../../components/enrichmentFlow/EnrichmentStepCard/EnrichmentStepTechniqueCards/EnrichmentStepTechniqueDefaultCard";
import { EnrichmentStepCardFiltersList_EnrichmentStep } from "../../components/enrichmentFlow/EnrichmentStepCard/EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import { useFragment } from "../../__generated__";
import {
  EnrichmentStepTechniqueFindEmailCardSchema,
  getFindEmailDefaultValues,
} from "../../components/enrichmentFlow/EnrichmentStepCard/EnrichmentStepTechniqueCards/EnrichmentStepTechniqueFindEmailCard";
import {
  EnrichmentStepTechniqueGetContactCardSchema,
  getDefaultGetContactEnrichmentValues,
} from "../../components/enrichmentFlow/EnrichmentStepCard/EnrichmentStepTechniqueCards/EnrichmentStepTechniqueGetContactCard";
import {
  EnrichmentStepTechniqueFindPhoneCardSchema,
  getFindPhoneDefaultValues,
} from "../../components/enrichmentFlow/EnrichmentStepCard/EnrichmentStepTechniqueCards/EnrichmentStepTechniqueFindPhoneCard";

type EnrichmentStep = {
  id: number;
  enrichmentExecutionCount: number;
  enrichment_technique: EnrichmentTechniqueEnum;
  name: string;
  parentEnrichmentStepInputs: Array<EnrichmentStepInput>;
  enrichmentTechniqueDetails: EnrichmentTechniqueDetails;
};

export type EnrichmentStepError = {
  stepId: number;
  error: string;
};

export const UseEnrichmentInputErrors_EnrichmentFlow = gql`
  query UseEnrichmentInputErrors_EnrichmentFlow($id: Int!) {
    enrichmentFlow(id: $id) {
      enrichmentSteps {
        id
        name
        enrichment_technique
        selected_input
        parentEnrichmentStepInputs {
          id
          key
          input
          required
          sourceEnrichmentStep {
            id
            name
          }
        }
        ...EnrichmentStepCardFiltersList_EnrichmentStep
      }
    }
  }
  ${EnrichmentStepCardFiltersList_EnrichmentStep}
`;

const getSchemaFromTechnique = (
  enrichmentTechnique: EnrichmentTechniqueEnum,
) => {
  switch (enrichmentTechnique) {
    case EnrichmentTechniqueEnum.ChatGpt:
      return EnrichmentStepTechniqueChatGptCardSchema;
    case EnrichmentTechniqueEnum.SerpApi:
      return EnrichmentStepTechniqueSerpApiCardSchema;
    case EnrichmentTechniqueEnum.ReturnValue:
      return EnrichmentStepTechniqueReturnValueCardSchema;
    case EnrichmentTechniqueEnum.FindEmail:
      return EnrichmentStepTechniqueFindEmailCardSchema;
    case EnrichmentTechniqueEnum.FindContact:
      return EnrichmentStepTechniqueGetContactCardSchema;
    case EnrichmentTechniqueEnum.FindPhone:
      return EnrichmentStepTechniqueFindPhoneCardSchema;
    case EnrichmentTechniqueEnum.FiltersEquals:
    default:
      return EnrichmentStepTechniqueDefaultCardSchema;
  }
};

const getDefaultValuesForTechnique = (
  enrichmentTechnique: EnrichmentTechniqueEnum,
  step: EnrichmentStep,
  filters?: EnrichmentStepCardFiltersList_EnrichmentStepFragment,
) => {
  switch (enrichmentTechnique) {
    case EnrichmentTechniqueEnum.ChatGpt:
      return getDefaultChatGptValues(step, filters);
    case EnrichmentTechniqueEnum.SerpApi:
      return getDefaultSerpApiValues(step, filters);
    case EnrichmentTechniqueEnum.ReturnValue:
      return getReturnValueDefaultValues(step, filters);
    case EnrichmentTechniqueEnum.FindEmail:
      return getFindEmailDefaultValues(step, filters);
    case EnrichmentTechniqueEnum.FindContact:
      return getDefaultGetContactEnrichmentValues(step, filters);
    case EnrichmentTechniqueEnum.FindPhone:
      return getFindPhoneDefaultValues(step, filters);
    default:
      return null;
  }
};

const useEnrichmentInputErrors = (
  flowId: number | null | undefined,
  stepId?: number,
) => {
  const { data } = useQuery<
    UseEnrichmentInputErrors_EnrichmentFlowQuery,
    UseEnrichmentInputErrors_EnrichmentFlowQueryVariables
  >(UseEnrichmentInputErrors_EnrichmentFlow, {
    variables: { id: flowId! },
    skip: !flowId,
  });

  const filters = useFragment(
    EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
    data?.enrichmentFlow?.enrichmentSteps,
  );

  let idx = 0;

  const errors = [] as EnrichmentStepError[];

  for (const step of data?.enrichmentFlow?.enrichmentSteps || []) {
    if (stepId && step?.id !== stepId) continue;
    const filterEnrichmentStepInputs = filters
      ? filters[idx].filterEnrichmentStepInputs
      : null;

    const enrichmentTechnique = step?.enrichment_technique;
    const schema = getSchemaFromTechnique(enrichmentTechnique);
    const defaultValues = getDefaultValuesForTechnique(
      enrichmentTechnique,
      step as EnrichmentStep,
      filterEnrichmentStepInputs as any,
    );
    if (!defaultValues) continue;
    try {
      schema.validateSync(defaultValues);
    } catch (err) {
      errors.push({
        stepId: step.id,
        error: err as string,
      });
    }
  }
  return errors;
};

export default useEnrichmentInputErrors;

import React, { useState } from "react";
import classNames from "classnames";
import CiroButton from "../CiroButton";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

interface ICiroTableProps {
  borderless?: boolean;
  children: React.ReactNode;
  containerClassName?: string;
  pagination?: {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
  };
}

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}) => {
  const [inputPage, setInputPage] = useState<string>(currentPage.toString());

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setInputPage(value);
    }
  };

  const handleInputBlur = () => {
    let pageNumber = parseInt(inputPage, 10);
    if (isNaN(pageNumber) || pageNumber < 1) {
      pageNumber = 1;
    } else if (pageNumber > totalPages) {
      pageNumber = totalPages;
    }
    setInputPage(pageNumber.toString());
    if (pageNumber !== currentPage) {
      onPageChange(pageNumber);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleInputBlur();
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
      setInputPage((currentPage - 1).toString());
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
      setInputPage((currentPage + 1).toString());
    }
  };

  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-justify-between",
        "ciro-v1-items-center",
        "ciro-v1-py-4",
        "ciro-v1-space-x-4",
        "ciro-v1-px-4",
        "ciro-v1-text-sm",
        "ciro-v1-text-neutral-500"
      )}
    >
      <div className={classNames("ciro-v1-w-40")}>
        {currentPage !== 1 && (
          <CiroButton
            onClick={handlePrevious}
            disabled={currentPage === 1}
            analyticsField="previous-page"
          >
            <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
              <ArrowLeftIcon
                className={classNames(
                  "ciro-v1-w-4",
                  "ciro-v1-h-4",
                  "ciro-v1-mr-1",
                )}
              />
              Previous
            </div>
          </CiroButton>
        )}
      </div>
      <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
        <span className={classNames("ciro-v1-mr-2")}>Page</span>
        <input
          type="text"
          value={inputPage}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
          className={classNames(
            "ciro-v1-w-12",
            "ciro-v1-px-2",
            "ciro-v1-border",
            "ciro-v1-border-gray-300",
            "ciro-v1-rounded",
            "ciro-v1-text-center",
          )}
        />
        <span className={classNames("ciro-v1-ml-2")}>of {totalPages}</span>
      </div>
      <div
        className={classNames(
          "ciro-v1-w-40",
          "ciro-v1-justify-end",
          "ciro-v1-flex",
        )}
      >
        {currentPage !== totalPages && (
          <CiroButton onClick={handleNext} analyticsField="next-page">
            <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
              Next
              <ArrowRightIcon
                className={classNames(
                  "ciro-v1-w-4",
                  "ciro-v1-h-4",
                  "ciro-v1-ml-1",
                )}
              />
            </div>
          </CiroButton>
        )}
      </div>
    </div>
  );
};

const CiroTable = ({
  borderless = false,
  children,
  containerClassName = "",
  pagination,
}: ICiroTableProps) => {
  return (
    <div
      className={classNames([
        "ciro-v1-bg-white",
        {
          "ciro-v1-border-1": !borderless,
          "ciro-v1-border-gray-200": !borderless,
          "ciro-v1-border-separate": !borderless,
        },
        "ciro-v1-rounded-lg",
        "ciro-v1-text-left",
        "ciro-v1-relative",
        "ciro-v1-border-b-0",
        containerClassName,
      ])}
    >
      <table
        // Unsure why w-full is not working, need to use this instead
        style={{
          width: "100%",
        }}
        className={classNames([
          "ciro-v1-table",
          "ciro-v1-table-fixed",
          "ciro-w-full",
          "ciro-v1-rounded-lg",
        ])}
      >
        {children}
      </table>
      {pagination && (
        <Pagination
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          onPageChange={pagination.onPageChange}
        />
      )}
    </div>
  );
};

export default CiroTable;

import { useContext, useMemo, useRef, useState } from "react";
import {
  Field,
  getStepSchema,
} from "../../../../services/enrichments/getStepSchema";
import { EnrichmentTechniqueEnum } from "../../../../__generated__/graphql";
import Dropdown from "react-multilevel-dropdown";
import classNames from "classnames";
import EnrichmentFlowContext from "../../../../contexts/EnrichmentFlowContext";
import CiroTextInput from "../../../shared/CiroTextInput";
import DownArrowIcon from "../../../../assets/img/icons/DownArrowIcon";
import CiroTooltipContainer from "../../../shared/CiroTooltipContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { PencilIcon } from "@heroicons/react/24/outline";
import { IEnrichmentTemplateVariable } from "../EnrichmentStepRichTextEditor/EnrichmentStepRichTextContainer";

interface IEnrichmentStepSelector {
  inputError?: string;
  label?: string;
  onBlur?: () => void;
  stepVariable: IEnrichmentTemplateVariable;
  setStepVariable: (stepVariable: IEnrichmentTemplateVariable) => void;
  freeText?: boolean;
}

interface IEnrichmentStepFieldSelector {
  enrichmentTechnique?: EnrichmentTechniqueEnum | null;
  onBlur?: () => void;
  onFieldSelected: (event: any, field: Field) => void;
}

const EnrichmentStepFieldSelector = ({
  enrichmentTechnique,
  onBlur,
  onFieldSelected,
}: IEnrichmentStepFieldSelector) => {
  const stepSchema = getStepSchema(enrichmentTechnique);
  return stepSchema.fields.length ? (
    <Dropdown.Submenu position={"right"}>
      {stepSchema.fields.map((field) => {
        return (
          <Dropdown.Item
            key={field.name}
            onClick={(e: any) => {
              onFieldSelected(e, field);
              onBlur?.();
            }}
          >
            <div>{field.label}</div>
          </Dropdown.Item>
        );
      })}
    </Dropdown.Submenu>
  ) : null;
};

const EnrichmentStepSelector = ({
  inputError,
  label,
  onBlur,
  stepVariable,
  setStepVariable,
  freeText = false,
}: IEnrichmentStepSelector) => {
  const ref = useRef<any>(null);
  const [showFreeTextInput, setShowFreeTextInput] = useState(false);
  const [freeTextInput, setFreeTextInput] = useState("");
  const { enrichmentFlowWithSteps } = useContext(EnrichmentFlowContext);

  let title = "Select";
  const stepName = enrichmentFlowWithSteps?.enrichmentSteps.find(
    (step) => step.id === stepVariable.stepId,
  )?.name;

  if (stepVariable.stepInput && stepName) {
    title = `${stepName} > ${stepVariable.stepInput}`;
  } else if (stepName) {
    title = stepName;
  } else if (stepVariable.stepInput) {
    title = stepVariable.stepInput;
  }

  const onClick = (e: any) => {
    ref?.current?.toggle(e);
  };

  const enrichmentFlowOptions = useMemo(() => {
    const options =
      (enrichmentFlowWithSteps?.enrichmentSteps as {
        id: number;
        name: string;
        enrichment_technique?: EnrichmentTechniqueEnum | null;
      }[]) || [];
    const optionsCopy = [...options];

    if (freeText) {
      optionsCopy?.unshift({
        id: -1,
        name: "Free text",
        enrichment_technique: null,
      });
    }

    return optionsCopy;
  }, [enrichmentFlowWithSteps, freeText]);

  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-flex-col",
        "ciro-v1-items-start",
      )}
    >
      {label && (
        <div
          className={classNames(
            "ciro-v1-text-gray-500",
            "ciro-v1-text-xs",
            "ciro-v1-mb-1",
          )}
        >
          {label}
        </div>
      )}
      <div className={classNames("ciro-v1-relative")}>
        {showFreeTextInput && (
          <CiroTextInput
            onChange={(e) => {
              e.target.value && setFreeTextInput(e.target.value);
            }}
            onBlur={onBlur}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setStepVariable({
                  stepId: null,
                  stepInput: freeTextInput,
                });
                setShowFreeTextInput(false);
              }
            }}
            className={classNames("ciro-v1-w-20")}
          />
        )}
        {!showFreeTextInput && (
          <Dropdown
            position="right"
            title={
              <div
                className={classNames(
                  {
                    "ciro-v1-text-gray-500": !inputError,
                    "ciro-v1-text-rose-500": inputError,
                  },
                  "hover:ciro-v1-text-gray-800",
                  "ciro-v1-font-normal",
                  "ciro-v1-leading-5",
                  "ciro-v1-tracking-normal",
                  "ciro-v1-flex",
                  "ciro-v1-flex-row",
                  "ciro-v1-items-center",
                  "ciro-v1-text-sm",
                )}
              >
                <div className={classNames("ciro-v1-mr-1")}>{title}</div>
                {inputError && (
                  <CiroTooltipContainer tooltip={<div>{inputError}</div>}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </CiroTooltipContainer>
                )}
                <div
                  className={classNames("ciro-v1-text-gray-500", {
                    "ciro-v1-ml-2": inputError,
                  })}
                >
                  <DownArrowIcon strokeWidth="1" />
                </div>
              </div>
            }
            buttonVariant="tertiary"
            ref={ref}
            buttonClassName={classNames(
              "!ciro-v1-p-0",
              "!ciro-v1-shadow-none",
              "!ciro-v1-h-fit",
            )}
          >
            {enrichmentFlowOptions?.map((step) => {
              return (
                <Dropdown.Item
                  key={step.id}
                  onClick={(e: any) => {
                    onClick(e);
                    if (step.id === -1) {
                      setShowFreeTextInput(true);
                      return;
                    }
                    if (
                      !getStepSchema(step?.enrichment_technique).fields.length
                    ) {
                      setStepVariable({
                        stepId: step.id,
                        stepInput: "",
                      });
                    }
                  }}
                >
                  <div
                    className={classNames(
                      // Note: The ! modifier (tailwind important modifier) is needed
                      // because the react-multilevel-dropdown library adds its own
                      // flex styles on all child divs
                      "!ciro-v1-flex",
                      "!ciro-v1-flex-row",
                      "!ciro-v1-items-center",
                    )}
                  >
                    <div>{step.name}</div>
                    {step.id === -1 && (
                      <div
                        className={classNames(
                          "ciro-v1-w-3",
                          "ciro-v1-ml-2",
                          "ciro-v1-text-gray-500",
                        )}
                      >
                        <PencilIcon></PencilIcon>
                      </div>
                    )}
                  </div>
                  <EnrichmentStepFieldSelector
                    enrichmentTechnique={step?.enrichment_technique}
                    onFieldSelected={(event: any, field: Field) => {
                      setStepVariable({
                        stepId: step.id,
                        stepInput: field.name,
                      });
                      onClick(event);
                    }}
                    onBlur={onBlur}
                  />
                </Dropdown.Item>
              );
            })}
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default EnrichmentStepSelector;

import { createContext } from "react";

const EnrichmentExecutionDetailsContext = createContext(
  {} as {
    enrichmentStepId: number;
    onClose: () => void;
  },
);

export default EnrichmentExecutionDetailsContext;

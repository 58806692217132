import { gql } from "@apollo/client";
import classNames from "classnames";
import {
  CrmIntegrationEnum,
  CrmProfileDetails_UserAccountFragment,
  LinkCrmButton_UserAccountFragment,
} from "../../__generated__/graphql";
import { LinkCRMButton, LinkCRMButton_UserAccount } from "./LinkCRMButton";
import CrmSyncStatus from "./CrmSyncStatus";
export const CrmProfileDetails_UserAccount = gql`
  fragment CrmProfileDetails_UserAccount on UserAccount {
    id
    hasCrmIntegrationEnabled
    hasCRMConnection
    org {
      crmIntegration
      organizationMergeIntegration {
        last_matched_at
        integration
      }
    }
    ...LinkCRMButton_UserAccount
  }
  ${LinkCRMButton_UserAccount}
`;

interface ICrmProfileDetailsProps {
  userAccount: CrmProfileDetails_UserAccountFragment | null | undefined;
  refetchUserAccount: () => void;
}

export function CRMProfileDetails({
  userAccount,
  refetchUserAccount,
}: ICrmProfileDetailsProps) {
  const lastSyncedAt =
    userAccount?.org?.organizationMergeIntegration?.last_matched_at;
  const crmIntegration = userAccount?.org?.crmIntegration;
  const crmIntegrationEnabled = userAccount?.hasCrmIntegrationEnabled;

  if (!crmIntegrationEnabled) {
    return null;
  }

  if (!crmIntegration && userAccount) {
    return (
      <div className={classNames("ciro-v1-mt-2")}>
        <LinkCRMButton
          userAccount={userAccount as LinkCrmButton_UserAccountFragment}
          refreshUserAccount={refetchUserAccount}
        />
      </div>
    );
  }

  if (
    crmIntegration &&
    [CrmIntegrationEnum.HubSpot, CrmIntegrationEnum.Salesforce].includes(crmIntegration)
  ) {
    return (
      <div className={classNames("ciro-v1-pt-2")}>
          <CrmSyncStatus
            lastSyncedAt={lastSyncedAt}
            integration={crmIntegration}
            refetchUserAccount={refetchUserAccount}
          />
      </div>
    );
  }

  if (crmIntegration === CrmIntegrationEnum.Apollo) {
    return (
      <div className={classNames("ciro-v1-pt-2")}>
        <CrmSyncStatus lastSyncedAt={null} integration={CrmIntegrationEnum.Apollo} refetchUserAccount={refetchUserAccount} />
      </div>
    );
  }

  return null;
}

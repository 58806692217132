import classNames from "classnames";
import MobileNumbersTransactionsTable from "./MobileNumbersTable/MobileNumbersTransactionsTable";
import MobileNumbersGetStartedContainer from "./MobileNumbersGetStartedContainer";
import { useState } from "react";
import MobileNumbersWorkflowsTable from "./MobileNumbersTable/MobileNumbersWorkflowsTable";
import { useNavigate } from "react-router-dom";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";

interface IMobileNumbersBodyContainerProps {
  hasIntegration: boolean;
  hasTransactions: boolean;
  startOnWorkflows: boolean;
}

const Tabs = Object.freeze({
  RUN_HISTORY: "History",
  WORKFLOWS: "Workflows",
});

const MobileNumbersBodyContainer = ({
  hasIntegration,
  hasTransactions,
  startOnWorkflows,
}: IMobileNumbersBodyContainerProps) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>(
    startOnWorkflows && hasIntegration ? Tabs.WORKFLOWS : Tabs.RUN_HISTORY,
  );

  if (!hasIntegration && !hasTransactions) {
    return <MobileNumbersGetStartedContainer />;
  }

  return (
    <div className={classNames("ciro-v1-w-full", "ciro-v1-pt-4")}>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-border-b",
          "ciro-v1-border-gray-200",
        )}
      >
        {Object.values(Tabs).map((tab: string) =>
          hasIntegration || tab === Tabs.RUN_HISTORY ? (
            <CiroButton
              analyticsField={`Clicked enrich CRM tab ${tab}`}
              key={tab}
              style={CiroButtonStyleEnum.UNSTYLED}
              onClick={() => {
                setActiveTab(tab);
                const baseUrl = "/enrich-crm";
                const newUrl =
                  tab === Tabs.WORKFLOWS ? `${baseUrl}/workflows` : baseUrl;
                navigate(newUrl);
              }}
              customClassName={classNames(
                "ciro-v1-px-4",
                "ciro-v1-py-2",
                "ciro-v1-text-sm",
                {
                  "ciro-v1-text-gray-500": activeTab !== tab,
                  "ciro-v1-font-semibold": activeTab === tab,
                  "ciro-v1-text-black": activeTab === tab,
                  "ciro-v1-border-b-2": activeTab === tab,
                  "ciro-v1-border-orange-500": activeTab === tab,
                },
              )}
            >
              {tab}
            </CiroButton>
          ) : null,
        )}
      </div>
      <div className={classNames("ciro-v1-w-full", "ciro-v1-mt-8")}>
        {activeTab === Tabs.RUN_HISTORY && <MobileNumbersTransactionsTable />}
        {activeTab === Tabs.WORKFLOWS && hasIntegration && (
          <>
            <MobileNumbersWorkflowsTable />
            <div
              className={classNames(
                "ciro-v1-w-full",
                "ciro-v1-justify-center",
                "ciro-v1-mt-4",
                "ciro-v1-flex",
              )}
            >
              <CiroButton
                analyticsField="add-workflow"
                style={CiroButtonStyleEnum.LOUD}
                onClick={() => {
                  navigate(`/enrich-crm/workflow`);
                }}
              >
                + Add workflow
              </CiroButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MobileNumbersBodyContainer;

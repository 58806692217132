const ContentSearchIcon = ({
  width = "20",
  height = "20",
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 7.33333V5.21867C12.6667 4.86533 12.526 4.526 12.276 4.276L10.3907 2.39067C10.1407 2.14067 9.80133 2 9.448 2H3.33333C2.59667 2 2 2.59667 2 3.33333V12.6667C2 13.4033 2.59667 14 3.33333 14H8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 5.33333H10C9.63204 5.33333 9.33337 5.03467 9.33337 4.66667V2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7473 9.91937C13.6127 10.7847 13.5193 12.244 12.4687 12.982C11.8 13.4514 10.866 13.4514 10.1973 12.982C9.14601 12.2447 9.05335 10.7847 9.91868 9.91937C10.7 9.13804 11.9667 9.13804 12.7473 9.91937"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14L12.7467 12.7467"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ContentSearchIcon;

import classNames from "classnames";
interface ICiroPillProps {
  children: any;
  background?: string;
  fontColor?: string;
}

const CiroPill = ({
  children,
  background = "ciro-v1-bg-gray-100",
  fontColor = "ciro-v1-text-slate-700",
}: ICiroPillProps) => {
  return (
    <div
      className={classNames([
        "ciro-v1-flex",
        "ciro-v1-font-medium",
        "ciro-v1-items-center",
        "ciro-v1-px-2",
        "ciro-v1-py-1",
        "ciro-v1-rounded-full",
        "ciro-v1-text-xs",
        "ciro-v1-w-min",
        "ciro-v1-whitespace-nowrap",
        background,
        fontColor,
      ])}
    >
      {children}
    </div>
  );
};

export default CiroPill;

import CiroTableRow from "./CiroTableRow";
import CiroTableCell from "./CiroTableCell";
import CiroPagination from "../CiroPagination";

interface ICiroTablePaginationProps {
  setOffset: (v: number) => void;
  offset: number;
  perPage: number;
  tableRows: number;
  totalCount: number;
  paginationLimit: number;
}

function CiroTablePagination({
  offset,
  perPage,
  setOffset,
  tableRows,
  totalCount,
  paginationLimit,
}: ICiroTablePaginationProps) {
  return (
    <CiroTableRow clickable={false}>
      <CiroTableCell colSpan={tableRows}>
        <CiroPagination
          offset={offset}
          perPage={perPage}
          setOffset={setOffset}
          totalCount={totalCount}
          paginationLimit={paginationLimit}
        />
      </CiroTableCell>
    </CiroTableRow>
  );
}

export default CiroTablePagination;

import React, { useEffect, useState } from "react";
import CiroModal from "../shared/CiroModal";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import { gql, useMutation } from "@apollo/client";

const BusinessDetailsReportModalAndButton_CreateErrorReport = gql`
  mutation BusinessDetailsReportModalAndButton_CreateErrorReport(
    $companyPk: String!
    $errorMessage: String
  ) {
    createErrorReport(companyPk: $companyPk, errorMessage: $errorMessage) {
      message
      success
    }
  }
`;

interface IBusinessDetailsReportModalAndButtonProps {
  companyPk: string;
}

enum ReportModalStateEnum {
  UNSENT,
  SENDING,
  SUCCESS,
  ERROR,
}

const BusinessDetailsReportModalAndButton = ({
  companyPk,
}: IBusinessDetailsReportModalAndButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [reportText, setReportText] = useState("");
  const [reportState, setReportState] = useState<ReportModalStateEnum>(
    ReportModalStateEnum.UNSENT,
  );

  const [
    sendErrorReport,
    { data: errorReportResponseData, error: errorReportResponseError },
  ] = useMutation(BusinessDetailsReportModalAndButton_CreateErrorReport, {
    variables: {
      companyPk: companyPk,
      errorMessage: reportText,
    },
  });
  const handleSubmit = () => {
    if (!reportText) {
      setReportState(ReportModalStateEnum.ERROR);
      return;
    }
    sendErrorReport();
    setReportState(ReportModalStateEnum.SENDING);
  };
  useEffect(() => {
    if (errorReportResponseError) {
      setReportState(ReportModalStateEnum.ERROR);
    }
    if (errorReportResponseData?.createErrorReport) {
      setReportState(ReportModalStateEnum.SUCCESS);
      setTimeout(() => {
        setIsOpen(false);
        setReportState(ReportModalStateEnum.UNSENT);
        setReportText("");
      }, 2000);
    }
  }, [errorReportResponseData, errorReportResponseError]);

  return (
    <>
      <CiroButton
        onClick={() => setIsOpen(true)}
        customClassName={classNames(["ciro-v1-w-full"])}
        style={CiroButtonStyleEnum.INVERTED}
        analyticsField="Report an error"
      >
        Report an Error
      </CiroButton>
      <CiroModal onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <div className={classNames(["ciro-v1-font-medium"])}>
          Report an Error
        </div>
        <textarea
          value={reportText}
          onChange={(event) => {
            setReportText(event.currentTarget.value);
            setReportState(ReportModalStateEnum.UNSENT);
          }}
          placeholder="Enter a description..."
          className={classNames([
            "ciro-v1-border-1",
            "ciro-v1-border-gray-300",
            "ciro-v1-h-48",
            "ciro-v1-my-1.5",
            "ciro-v1-p-3.5",
            "ciro-v1-rounded-lg",
            "ciro-v1-text-sm",
            "ciro-v1-w-full",
          ])}
        />
        {[ReportModalStateEnum.SENDING, ReportModalStateEnum.UNSENT].includes(
          reportState,
        ) && (
          <div
            className={classNames(["ciro-v1-text-zinc-500", "ciro-v1-text-xs"])}
          >
            Please be as descriptive as possible
          </div>
        )}
        {reportState === ReportModalStateEnum.ERROR && (
          <div
            className={classNames(["ciro-v1-text-xs", "ciro-v1-text-rose-500"])}
          >
            {!reportText.length
              ? "Please enter a description of the error"
              : "Error report could not be sent"}
          </div>
        )}
        {reportState === ReportModalStateEnum.SUCCESS && (
          <div
            className={classNames([
              "ciro-v1-text-xs",
              "ciro-v1-text-green-600",
            ])}
          >
            Report sent successfully
          </div>
        )}
        <div className={classNames(["ciro-v1-flex", "ciro-v1-justify-end"])}>
          <CiroButton
            onClick={handleSubmit}
            style={CiroButtonStyleEnum.INVERTED}
            disabled={[
              ReportModalStateEnum.SENDING,
              ReportModalStateEnum.ERROR,
            ].includes(reportState)}
            analyticsField="Submit Error Report"
          >
            Submit
          </CiroButton>
        </div>
      </CiroModal>
    </>
  );
};

export default BusinessDetailsReportModalAndButton;

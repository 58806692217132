import React from "react";
import CiroDropDown from "../shared/CiroDropdown";
import { gql } from "@apollo/client";
import {
  CONNECT_TO_CRM_TOOLTIP_TEXT,
  DEFAULT_FILTER_TOOLTIP_TEXT,
} from "../../utils/consts";
import { OpenOpportunityFilter_UserAccountFragment } from "../../__generated__/graphql";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

export const OpenOpportunityFilter_userAccount = gql`
  fragment OpenOpportunityFilter_userAccount on Query {
    userAccount {
      id
      hasCRMConnection
    }
  }
`;

interface IOpenOpportunityFilterProps {
  blurred?: boolean;
  openOpportunityFilter: boolean | null;
  setOpenOpportunityFilter: (v: boolean | null) => void;
  resetOffset: () => void;
  userAccountData: OpenOpportunityFilter_UserAccountFragment;
}

function OpenOpportunityFilter({
  blurred,
  openOpportunityFilter,
  setOpenOpportunityFilter,
  resetOffset,
  userAccountData,
}: IOpenOpportunityFilterProps) {
  const filterOptions = [
    {
      value: true,
      label: "Has open opportunity",
    },
    {
      value: false,
      label: "Has no open opportunities",
    },
  ];
  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={openOpportunityFilter == null ? 0 : 1}
      sectionTitle="Open Opportunities"
      filterOptions={[
        {
          filter: (
            <CiroDropDown
              blurred={
                blurred || !userAccountData.userAccount?.hasCRMConnection
              }
              blurredTooltip={
                blurred
                  ? DEFAULT_FILTER_TOOLTIP_TEXT
                  : CONNECT_TO_CRM_TOOLTIP_TEXT
              }
              options={filterOptions}
              value={openOpportunityFilter}
              onChange={(newValue) => {
                setOpenOpportunityFilter(newValue);
                resetOffset();
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default OpenOpportunityFilter;

import { usePostHog } from "posthog-js/react";
import { TextTypeFilterEnum } from "../reactHooks/filters/accounts/useAccountFilters";
import { EnrichmentTechniqueEnum } from "../__generated__/graphql";

interface ILogEnrichmentFlowRun {
  runId: string;
  enrichmentFlowId: number;
  enrichmentStepId: number;
  enrichmentStepTechnique: EnrichmentTechniqueEnum;
  rowCount: number;
}

export const useLogging = () => {
  const posthog = usePostHog();

  const logEnrichmentFlowRun = async ({
    runId,
    enrichmentFlowId,
    enrichmentStepId,
    enrichmentStepTechnique,
    rowCount,
  }: ILogEnrichmentFlowRun) => {
    posthog?.capture("enrichmentFlowRun", {
      runId,
      enrichmentFlowId,
      enrichmentStepId,
      enrichmentStepTechnique,
      rowCount,
    });
  };

  const logAccountSearch = async (
    searchTerm: string,
    searchType: TextTypeFilterEnum | null,
  ) => {
    posthog?.capture("accountSearch", {
      searchTerm,
      searchType,
    });
  };

  return {
    logEnrichmentFlowRun,
    logAccountSearch,
  };
};

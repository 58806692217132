import { gql, useQuery } from "@apollo/client";
import {
  BusinessDetailsContact_CompanyQuery,
  BusinessDetailsContact_CompanyQueryVariables,
  BusinessDetailsContactCard_CompanyContactExperienceFragment,
} from "../../__generated__/graphql";
import BusinessDetailsContactCard, {
  BusinessDetailsContactCard_CompanyContactExperience,
} from "./BusinessDetailsContactCard";
import classNames from "classnames";
import CiroCTACard from "../shared/CiroCTACard";
import CiroButton from "../shared/CiroButton";
import SkeletonLoading from "../shared/SkeletonLoading";
import CiroAlert from "../shared/CiroAlert";

export const BUSINESS_DETAILS_COMPANY_QUERY = gql`
  query BusinessDetailsContact_Company($companyPk: ID!) {
    company(pk: $companyPk) {
      company_display_name {
        value
      }
      contacts {
        count
        populated
        value {
          id
          ...BusinessDetailsContactCard_CompanyContactExperience
        }
      }
    }
  }
  ${BusinessDetailsContactCard_CompanyContactExperience}
`;

interface IBuisnessDetailContactsProps {
  companyPk: string;
}

const BusinessDetailsContacts = ({
  companyPk,
}: IBuisnessDetailContactsProps) => {
  const { data, loading, error } = useQuery<
    BusinessDetailsContact_CompanyQuery,
    BusinessDetailsContact_CompanyQueryVariables
  >(BUSINESS_DETAILS_COMPANY_QUERY, {
    variables: { companyPk },
  });

  if (loading) {
    return (
      <>
        <SkeletonLoading numSkeletons={3} skeletonHeight="7rem" />
      </>
    );
  }
  if (error) {
    return <CiroAlert message={"Error loading contacts"} />;
  }

  const company = data?.company!;

  if (company.contacts?.populated && !company.contacts?.value?.length) {
    return (
      <div className={classNames(["ciro-v1-mt-4"])}>
        <CiroCTACard
          title={`Upgrade to see contacts`}
          subtitle="Access contact details like names, emails, ages, and more by upgrading your plan."
        />
      </div>
    );
  }

  return (
    <>
      <div className={classNames(["ciro-v1-text-left"])}>
        {company.contacts?.value?.map((contactExperience, index) => {
          if (!contactExperience) {
            return null;
          }

          return (
            <BusinessDetailsContactCard
              key={index}
              contactExperience={
                contactExperience as BusinessDetailsContactCard_CompanyContactExperienceFragment
              }
              beginExpanded={false}
            />
          );
        })}
      </div>
      {company.contacts?.count === 10 && (
        <div
          className={classNames([
            "ciro-v1-flex",
            "ciro-v1-justify-end",
            "ciro-v1-w-full",
            "ciro-v1-pt-3",
          ])}
        >
          <CiroButton
            disabled={true}
            analyticsField="Next Contact Page"
            disabledTooltip="More contacts on this page coming soon!"
          >
            <span>Next</span>
          </CiroButton>
        </div>
      )}
    </>
  );
};

export default BusinessDetailsContacts;

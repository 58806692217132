import React, { useState } from "react";
import classNames from "classnames";
import BusinessProfileIcon from "../../assets/img/icons/BusinessProfileIcon";
import { gql } from "@apollo/client";
import { BusinessDetailsContactCard_CompanyContactExperienceFragment } from "../../__generated__/graphql";
import DownArrowIcon from "../../assets/img/icons/DownArrowIcon";
import CiroCard from "../shared/CiroCard";
import CiroLink from "../shared/CiroLink";
import BusinessDetailsSocialLink from "./BusinessDetailsSocialLink";
import LinkedInIcon from "../../assets/img/icons/LinkedInIcon";
import pluralize from "pluralize";

export const BusinessDetailsContactCard_CompanyContactExperience = gql`
  fragment BusinessDetailsContactCard_CompanyContactExperience on CompanyContactExperience {
    id
    description
    title
    contact {
      age_range
      full_name
      linkedin_id
      emails {
        email
      }
      phones {
        phone_number
      }
    }
  }
`;

interface IBusinessDetailsContactCardProps {
  contactExperience: BusinessDetailsContactCard_CompanyContactExperienceFragment;
  beginExpanded: Boolean;
}

const BusinessDetailsContactCard = ({
  contactExperience,
  beginExpanded,
}: IBusinessDetailsContactCardProps) => {
  const { contact } = contactExperience;
  const expandable = Boolean(contactExperience.description);
  const [expanded, setExpanded] = useState(beginExpanded);

  return (
    <div className={classNames(["ciro-v1-mt-4"])}>
      <CiroCard>
        <div
          className={classNames(["ciro-v1-flex", "ciro-v1-justify-between"])}
        >
          <div className={classNames(["ciro-v1-flex"])}>
            <BusinessProfileIcon />
            <div className={classNames(["ciro-v1-pl-2"])}>
              <div
                className={classNames([
                  "ciro-v1-font-semibold",
                  "ciro-v1-slate-700",
                  "ciro-v1-text-base",
                  "ciro-v1-flex",
                ])}
              >
                <div className="ciro-v1-ml-2">
                  <span>{contact.full_name}</span>
                  <div className="ciro-v1-flex ciro-v1-items-center">
                    <div
                      className={classNames([
                        "ciro-v1-font-normal",
                        "ciro-v1-slate-600",
                        "ciro-v1-text-sm",
                      ])}
                    >
                      {contactExperience.title || "Contact"}
                      {contact.age_range && ` - ${contact.age_range} years old`}
                    </div>
                    {contact?.linkedin_id && (
                      <div className={classNames(["ciro-v1-pl-2"])}>
                        <BusinessDetailsSocialLink
                          field={{
                            populated: true,
                            value: `https://www.linkedin.com/in/${contact?.linkedin_id}`,
                          }}
                          inline={true}
                          icon={<LinkedInIcon mini={true} />}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {expandable && (
            <div
              className={classNames(
                [
                  "ciro-v1-cursor-pointer",
                  "ciro-v1-duration-500",
                  "ciro-v1-ease-in-out",
                  "ciro-v1-flex",
                  "ciro-v1-items-center",
                  "ciro-v1-origin-center",
                  "ciro-v1-transform",
                  "ciro-v1-transition-all",
                ],
                {
                  "ciro-v1-rotate-180": expanded,
                },
              )}
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <DownArrowIcon stroke="black" />
            </div>
          )}
        </div>
        <div
          className={classNames(
            ["ciro-v1-ease", "ciro-v1-transition-all", "ciro-v1-duration-500"],
            {
              "ciro-v1-overflow-auto": expandable,
              "ciro-v1-max-h-0": !expanded,
              "ciro-v1-max-h-96": expanded || !expandable,
            },
          )}
        >
          <div>
            {Boolean(contact?.emails?.length) && (
              <div
                className={classNames([
                  "ciro-v1-flex",
                  "ciro-v1-font-medium",
                  "ciro-v1-slate-900",
                  "ciro-v1-text-base",
                  "ciro-v1-flex-row",
                  "ciro-v1-items-center",
                  "ciro-v1-pt-2",
                ])}
              >
                <span
                  className={classNames([
                    "ciro-v1-text-base",
                    "ciro-v1-text-gray-500",
                  ])}
                >{`${pluralize("Email", contact?.emails?.length)}:`}</span>
                <ul
                  className={classNames([
                    "ciro-v1-flex",
                    "ciro-v1-flex-wrap",
                    "ciro-v1-pl-2",
                    "ciro-v1-my-0",
                  ])}
                >
                  {contact?.emails?.map((email, idx) => (
                    <div>
                      <CiroLink href={`mailto:${email?.email}`}>
                        {email?.email}
                      </CiroLink>
                      {contact?.emails && idx < contact?.emails?.length - 1 && (
                        <span
                          className={classNames([
                            "ciro-v1-pr-2",
                            "ciro-v1-pl-2",
                            "ciro-v1-text-stone-400",
                          ])}
                        >
                          •
                        </span>
                      )}
                    </div>
                  ))}
                </ul>
              </div>
            )}
            {Boolean(contact?.phones?.length) && (
              <div
                className={classNames([
                  "ciro-v1-flex",
                  "ciro-v1-font-medium",
                  "ciro-v1-slate-900",
                  "ciro-v1-text-base",
                  "ciro-v1-flex-row",
                  "ciro-v1-items-center",
                  "ciro-v1-pt-2",
                ])}
              >
                <span
                  className={classNames([
                    "ciro-v1-text-base",
                    "ciro-v1-text-gray-500",
                  ])}
                >{`${pluralize("Phone", contact?.phones?.length)}:`}</span>
                <ul
                  className={classNames([
                    "ciro-v1-flex",
                    "ciro-v1-flex-wrap",
                    "ciro-v1-pl-2",
                    "ciro-v1-my-0",
                  ])}
                >
                  {contact?.phones?.map((phone, idx) => (
                    <div>
                      <CiroLink href={`tel:${phone?.phone_number}`}>
                        {phone?.phone_number}
                      </CiroLink>
                      {contact?.phones && idx < contact?.phones?.length - 1 && (
                        <span
                          className={classNames([
                            "ciro-v1-pr-2",
                            "ciro-v1-pl-2",
                            "ciro-v1-text-stone-400",
                          ])}
                        >
                          •
                        </span>
                      )}
                    </div>
                  ))}
                </ul>
              </div>
            )}
            <div
              className={classNames([
                "ciro-v1-font-medium",
                "ciro-v1-slate-900",
                "ciro-v1-text-base",
                "ciro-v1-pt-2",
              ])}
            >
              {contactExperience.description && `Description`}
            </div>
            <div
              className={classNames([
                "ciro-v1-text-slate-600",
                "ciro-v1-font-400",
                "ciro-v1-mt-2",
              ])}
            >
              {contactExperience.description}
            </div>
          </div>
        </div>
      </CiroCard>
    </div>
  );
};
export default BusinessDetailsContactCard;

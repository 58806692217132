import classNames from "classnames";
import DashedBorder from "../../assets/img/styling/DashedBorder";

const BlankOverviewCard = ({ description }: { description: string }) => {
  return (
    <div
      className={classNames(
        "ciro-v1-relative",
        "ciro-v1-p-5",
        "ciro-v1-w-full",
      )}
    >
      <DashedBorder />
      <div
        className={classNames(
          "ciro-v1-text-2xl",
          "ciro-v1-pb-4",
          "ciro-v1-text-neutral-400",
        )}
      >
        --
      </div>
      <div
        className={classNames("ciro-v1-text-sm", "ciro-v1-text-neutral-400")}
      >
        {description}
      </div>
    </div>
  );
};

export default BlankOverviewCard;

import EnrichmentStepCardFiltersList, {
  EnrichmentStepCardFiltersListSchema,
  parseEnrichmentStepsForGraphQL,
  parseEnrichmentStepsForList,
} from "../EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
  EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
  EnrichmentStep,
  EnrichmentStepCardFiltersList_EnrichmentStepFragment,
  EnrichmentTechniqueEnum,
} from "../../../../__generated__/graphql";
import { useFragment } from "../../../../__generated__";
import { useEffect, useMemo } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import classNames from "classnames";
import EnrichmentStepSelector from "../EnrichmentStepSelector/EnrichmentStepSelector";
import EnrichmentStepCardContainer from "../EnrichmentStepCardContainer";
import { IEnrichmentStepTechniqueCardProps } from ".";
import useEnrichmentStepForm from "../../../../reactHooks/enrichmentFlow/useEnrichmentStepForm";
import CiroDropDown from "../../../shared/CiroDropdown";

const FIND_EMAILS_FROM_WEBSITE_METHOD = "find_emails_from_website";
const FIND_EMAIL_FROM_NAME_AND_WEBSITE_METHOD =
  "find_email_from_name_and_website";

export const EnrichmentStepTechniqueFindEmailCardSchema = yup
  .object({
    runMethod: yup.string().required("Please select the enrichment method"),
    firstName: yup.object({
      input: yup.string().nullable(),
      sourceEnrichmentStepId: yup.number().nullable(),
    }),
    lastName: yup.object({
      input: yup.string().nullable(),
      sourceEnrichmentStepId: yup.number().nullable(),
    }),
    companyWebsite: yup
      .object({
        input: yup.string().nullable(),
        sourceEnrichmentStepId: yup.number().nullable(),
      })
      .required("Company website is required"),
    filterEnrichmentSteps: EnrichmentStepCardFiltersListSchema,
  })
  .required()
  .test(
    "filledOutInputFieldsForEmail",
    "All three input fields must be filled out",
    function (values) {
      const { runMethod, firstName, lastName, companyWebsite } = values;
      if (
        runMethod === FIND_EMAIL_FROM_NAME_AND_WEBSITE_METHOD &&
        !(
          firstName?.sourceEnrichmentStepId &&
          lastName?.sourceEnrichmentStepId &&
          companyWebsite?.sourceEnrichmentStepId
        )
      ) {
        return this.createError({
          path: "filledOutInputFieldsForEmail",
          message: "All three input fields must be filled out",
        });
      }
      if (
        runMethod === FIND_EMAILS_FROM_WEBSITE_METHOD &&
        !companyWebsite?.sourceEnrichmentStepId
      ) {
        return this.createError({
          path: "filledOutInputFieldsForEmail",
          message: "Company website is required",
        });
      }
      return true;
    },
  );

export const getFindEmailDefaultValues = (
  enrichmentStep: EnrichmentStep,
  enrichmentStepWithFilters?: EnrichmentStepCardFiltersList_EnrichmentStepFragment,
) => {
  const firstNameInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
    (stepInput) => stepInput.key === "first_name",
  );
  const lastNameInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
    (stepInput) => stepInput.key === "last_name",
  );
  const companyWebsiteInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
    (stepInput) => stepInput.key === "website",
  );
  const runMethod = enrichmentStep?.parentEnrichmentStepInputs?.find(
    (stepInput) => stepInput.key === "runMethod",
  );
  const filterEnrichmentSteps = parseEnrichmentStepsForList(
    enrichmentStepWithFilters,
  );

  return (
    {
      name: enrichmentStep?.name,
      enrichment_technique: EnrichmentTechniqueEnum.FindEmail,
      runMethod: JSON.parse(runMethod?.input || "null"),
      selected_input: null,
      firstName: {
        input: JSON.parse(firstNameInput?.input || "null") || "",
        sourceEnrichmentStepId:
          firstNameInput?.sourceEnrichmentStep?.id || null,
      },
      lastName: {
        input: JSON.parse(lastNameInput?.input || "null") || "",
        sourceEnrichmentStepId: lastNameInput?.sourceEnrichmentStep?.id || null,
      },
      companyWebsite: {
        input: JSON.parse(companyWebsiteInput?.input || "null") || "",
        sourceEnrichmentStepId:
          companyWebsiteInput?.sourceEnrichmentStep?.id || null,
      },
      filterEnrichmentSteps: filterEnrichmentSteps,
    } || []
  );
};

const EnrichmentStepTechniqueFindEmailCard = ({
  enrichmentStep,
  confirmUpdateEnrichmentStep,
  onClose,
  loading,
  error,
}: IEnrichmentStepTechniqueCardProps) => {
  const enrichmentStepWithFilters = useFragment(
    EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );

  const defaultValues = useMemo(() => {
    return getFindEmailDefaultValues(
      enrichmentStep as EnrichmentStep,
      enrichmentStepWithFilters as EnrichmentStepCardFiltersList_EnrichmentStepFragment,
    );
  }, [enrichmentStep, enrichmentStepWithFilters]);

  const formatAndSubmitRequest = (data: any) => {
    let parentEnrichmentStepInputs = [
      {
        key: "runMethod",
        input: JSON.stringify(data.runMethod),
        required: true,
        sourceEnrichmentStepId: null,
      },
    ] as {
      key: string;
      input?: string | null | undefined;
      required: boolean;
      sourceEnrichmentStepId?: number | null | undefined;
    }[];
    if (data.runMethod === FIND_EMAIL_FROM_NAME_AND_WEBSITE_METHOD) {
      parentEnrichmentStepInputs.push({
        key: "first_name",
        input: JSON.stringify(data.firstName.input),
        required: true,
        sourceEnrichmentStepId: data.firstName.sourceEnrichmentStepId,
      });
      parentEnrichmentStepInputs.push({
        key: "last_name",
        input: JSON.stringify(data.lastName.input),
        required: true,
        sourceEnrichmentStepId: data.lastName.sourceEnrichmentStepId,
      });
    }
    parentEnrichmentStepInputs.push({
      key: "website",
      input: JSON.stringify(data.companyWebsite.input),
      required: true,
      sourceEnrichmentStepId: data.companyWebsite.sourceEnrichmentStepId,
    });

    confirmUpdateEnrichmentStep({
      data: {
        id: enrichmentStep?.id || null,
        enrichmentStepInput: {
          enrichment_technique: data.enrichment_technique,
          selected_input: null,
          parentEnrichmentStepInputs,
          filterEnrichmentSteps: parseEnrichmentStepsForGraphQL(
            data.filterEnrichmentSteps,
          ),
        },
      },
    });
  };

  const methods = useEnrichmentStepForm({
    resolver: yupResolver(EnrichmentStepTechniqueFindEmailCardSchema),
    defaultValues: defaultValues,
  });
  const {
    control,
    formState: { errors, isDirty },
    setValue,
    handleSubmit,
    reset,
    clearErrors,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    return () => {
      reset();
    };
  }, [defaultValues, reset]);

  const [
    firstName,
    lastName,
    companyWebsite,
    filterEnrichmentSteps,
    runMethod,
  ] = useWatch({
    control,
    name: [
      "firstName",
      "lastName",
      "companyWebsite",
      "filterEnrichmentSteps",
      "runMethod",
    ],
  });

  return (
    <EnrichmentStepCardContainer
      isDirty={isDirty}
      loading={loading}
      error={error}
      onSave={handleSubmit(formatAndSubmitRequest)}
      enrichmentStep={useFragment(
        EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
        enrichmentStep,
      )}
      onClose={onClose}
    >
      <CiroDropDown
        label="Enrichment Method"
        error={errors?.runMethod?.message as string}
        labelClassName={classNames("ciro-v1-text-gray-500")}
        creatable
        isMulti={false}
        options={[
          {
            label: "Get Email from Name and Website",
            value: FIND_EMAIL_FROM_NAME_AND_WEBSITE_METHOD,
          },
          {
            label: "Get Emails From Website",
            value: FIND_EMAILS_FROM_WEBSITE_METHOD,
          },
        ]}
        value={runMethod || ""}
        isClearable
        onChange={(v) => {
          clearErrors();
          setValue("runMethod", v as any);
        }}
      />
      {runMethod != null && (
        <>
          {runMethod === FIND_EMAIL_FROM_NAME_AND_WEBSITE_METHOD && (
            <>
              <div className={classNames("ciro-v1-pt-4")}>First Name</div>
              <EnrichmentStepSelector
                inputError={errors.firstName?.message}
                stepVariable={{
                  stepId: firstName.sourceEnrichmentStepId || null,
                  stepInput: firstName.input,
                }}
                setStepVariable={(newVariable) => {
                  setValue(
                    "firstName.sourceEnrichmentStepId",
                    newVariable.stepId || null,
                    { shouldDirty: true },
                  );
                  setValue("firstName.input", newVariable?.stepInput || "", {
                    shouldDirty: true,
                  });
                }}
              />
              <div className={classNames("ciro-v1-pt-4")}>Last Name</div>
              <EnrichmentStepSelector
                inputError={errors.lastName?.message}
                stepVariable={{
                  stepId: lastName.sourceEnrichmentStepId || null,
                  stepInput: lastName.input,
                }}
                setStepVariable={(newVariable) => {
                  setValue(
                    "lastName.sourceEnrichmentStepId",
                    newVariable.stepId || null,
                    { shouldDirty: true },
                  );
                  setValue("lastName.input", newVariable?.stepInput || "", {
                    shouldDirty: true,
                  });
                }}
              />
            </>
          )}
          <div className={classNames("ciro-v1-pt-4")}>Company Website</div>
          <EnrichmentStepSelector
            inputError={errors.companyWebsite?.message}
            stepVariable={{
              stepId: companyWebsite.sourceEnrichmentStepId || null,
              stepInput: companyWebsite.input,
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "companyWebsite.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue("companyWebsite.input", newVariable?.stepInput || "", {
                shouldDirty: true,
              });
            }}
          />
        </>
      )}
      {Boolean((errors as any)?.filledOutInputFieldsForEmail?.message) && (
        <div
          className={classNames(
            "ciro-v1-text-xs",
            "ciro-v1-text-rose-500",
            "ciro-v1-mt-4",
          )}
        >
          {(errors as any)?.filledOutInputFieldsForEmail?.message}
        </div>
      )}
      <div className={classNames("ciro-v1-mt-12")}>
        <FormProvider {...methods}>
          <EnrichmentStepCardFiltersList
            filterEnrichmentSteps={filterEnrichmentSteps}
          />
        </FormProvider>
      </div>
    </EnrichmentStepCardContainer>
  );
};

export default EnrichmentStepTechniqueFindEmailCard;

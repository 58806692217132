import classNames from "classnames";
import CiroButton from "../../shared/CiroButton";
import EnrichmentStepCardContext from "../../../contexts/EnrichmentStepCardContext";
import { useContext } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import RunEnrichmentFlowButton, {
  REMOVE_FILTERS_TOOLTIP,
  STEP_IS_DIRTY_TOOLTIP,
} from "../RunEnrichmentFlowButton";
import { getFiltersAppliedCount } from "../../../reactHooks/filters/enrichmentFlow/useEnrichmentFlowFilters";
import { gql } from "@apollo/client";
import { EnrichmentStepCardFooter_EnrichmentStepFragment } from "../../../__generated__/graphql";
import CiroCoinPill from "../../shared/CiroCoinPill";
import EnrichmentFlowContext from "../../../contexts/EnrichmentFlowContext";

interface IEnrichmentStepCardFooterProps {
  cardIsDirty?: boolean;
  onSave: () => void;
  enrichmentStep?: EnrichmentStepCardFooter_EnrichmentStepFragment | null;
}

export const EnrichmentStepCardFooter_EnrichmentStep = gql`
  fragment EnrichmentStepCardFooter_EnrichmentStep on EnrichmentStep {
    id
    enrichmentTechniqueDetails {
      costPerExecution
    }
    name
  }
`;

const EnrichmentStepCardFooter = ({
  cardIsDirty,
  onSave,
  enrichmentStep,
}: IEnrichmentStepCardFooterProps) => {
  const { savingStatus, offset, totalCount, enrichmentFlowFilters } =
    useContext(EnrichmentStepCardContext);
  const { newEnrichmentTechniqueDetails } = useContext(EnrichmentFlowContext);

  const enrichmentTechniqueDetails =
    enrichmentStep?.enrichmentTechniqueDetails || newEnrichmentTechniqueDetails;

  let runEnrichmentFlowTooltip = null;
  if (getFiltersAppliedCount(enrichmentFlowFilters) > 0) {
    runEnrichmentFlowTooltip = REMOVE_FILTERS_TOOLTIP;
  }
  if (!enrichmentStep?.id || Boolean(cardIsDirty)) {
    runEnrichmentFlowTooltip = STEP_IS_DIRTY_TOOLTIP;
  }

  const runEnrichmentFlowDisabled = !enrichmentStep?.id || Boolean(cardIsDirty);

  return (
    <div>
      <div
        className={classNames(
          "ciro-v1-p-4",
          "ciro-v1-border-t",
          "ciro-v1-flex",
          "ciro-v1-flex-row",
          "ciro-v1-items-center",
          "ciro-v1-justify-between",
        )}
      >
        <div>
          {savingStatus?.saving && (
            <div className={classNames("ciro-v1-text-gray-500")}>Saving...</div>
          )}
          {savingStatus?.success && (
            <div
              className={classNames(
                "ciro-v1-text-green-700",
                "ciro-v1-flex",
                "ciro-v1-justify-start",
                "ciro-v1-items-center",
              )}
            >
              <div
                className={classNames("ciro-v1-text-gray-500", "ciro-v1-mr-1")}
              >
                Saved
              </div>
              <CheckIcon className={classNames("ciro-v1-w-4", "ciro-v1-h-4")} />
            </div>
          )}
          {savingStatus?.error && (
            <div
              className={classNames(
                "ciro-v1-text-rose-700",
                "ciro-v1-flex",
                "ciro-v1-justify-start",
                "ciro-v1-items-center",
              )}
            >
              <div
                className={classNames("ciro-v1-text-gray-500", "ciro-v1-mr-1")}
              >
                Error while saving
              </div>
              <FontAwesomeIcon icon={faExclamationCircle} />
            </div>
          )}
          {!savingStatus?.error &&
            !savingStatus?.saving &&
            !savingStatus?.success &&
            (enrichmentTechniqueDetails?.costPerExecution || 0) > 0 && (
              <span
                className={classNames("ciro-v1-flex", "ciro-v1-items-center")}
              >
                <span
                  className={classNames(
                    "ciro-v1-pr-2",
                    "ciro-v1-text-neutral-600",
                  )}
                >
                  Credits
                </span>
                <CiroCoinPill
                  amount={enrichmentTechniqueDetails?.costPerExecution || 0}
                />
              </span>
            )}
        </div>
        <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
          <div className={classNames("ciro-v1-pr-2")}>
            <CiroButton
              analyticsField="Save Enrichment Step"
              onClick={() => {
                onSave();
              }}
            >
              Save
            </CiroButton>
          </div>
          <RunEnrichmentFlowButton
            cascade={false}
            offset={offset}
            enrichmentStepId={enrichmentStep?.id}
            totalCount={totalCount}
            tooltip={runEnrichmentFlowTooltip}
            disabled={runEnrichmentFlowDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default EnrichmentStepCardFooter;

import { useCallback, useContext, useState } from "react";
import fileDownload from "js-file-download";
import AppContext from "../../contexts/AppContext";
import CiroButton from "../shared/CiroButton";
import { gql } from "@apollo/client";
import {
  DownloadEnrichmentFlowButton_EnrichmentFlowFragment,
  Maybe,
} from "../../__generated__/graphql";
import CloudDownloadIcon from "../../assets/img/icons/CloudDownloadIcon";
import classNames from "classnames";
import CiroModal from "../shared/CiroModal";
import Loading from "../shared/Loading";
import EnrichmentFlowContext from "../../contexts/EnrichmentFlowContext";
import { setFiltersToArray } from "../../reactHooks/filters/enrichmentFlow/useEnrichmentFlowFilters";

export const DownloadEnrichmentFlowButton_EnrichmentFlow = gql`
  fragment DownloadEnrichmentFlowButton_EnrichmentFlow on EnrichmentFlow {
    id
    name
  }
`;

interface IDownloadEnrichmentFlowButton {
  enrichmentFlow?: Maybe<DownloadEnrichmentFlowButton_EnrichmentFlowFragment>;
  includeFilterColumns: boolean;
}

function DownloadEnrichmentFlowButton({
  enrichmentFlow,
  includeFilterColumns,
}: IDownloadEnrichmentFlowButton) {
  const { accessToken } = useContext(AppContext);
  const [isExporting, setIsExporting] = useState(false);
  const { enrichmentFlowFilters } = useContext(EnrichmentFlowContext);

  const downloadEnrichmentFlowRunCSV = useCallback(async () => {
    if (!enrichmentFlow) {
      return;
    }

    setIsExporting(true);
    try {
      const exportResponse = await fetch(`/export-enrichment-flow`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          enrichmentFlowId: enrichmentFlow.id,
          includeFilterColumns,
          enrichmentFlowFilters: setFiltersToArray(enrichmentFlowFilters),
        }),
      });
      const blob = await exportResponse.blob();
      fileDownload(blob, `${enrichmentFlow.name}.csv`);
    } catch (error) {
      console.error("Error during CSV download:", error);
    } finally {
      setIsExporting(false);
    }
  }, [
    accessToken,
    enrichmentFlow,
    includeFilterColumns,
    enrichmentFlowFilters,
  ]);

  if (!enrichmentFlow) {
    return null;
  }

  return (
    <>
      <CiroButton
        analyticsField="Download enrichments"
        disabled={isExporting}
        onClick={() => {
          setIsExporting(true);
          downloadEnrichmentFlowRunCSV();
        }}
      >
        <div>
          <CloudDownloadIcon />
        </div>
      </CiroButton>
      <CiroModal size="SMALL" onClose={() => {}} isOpen={isExporting}>
        <Loading size="SMALL" />
        <div
          className={classNames(
            "ciro-v1-pt-4",
            "ciro-v1-text-center",
            "ciro-v1-text-neutral-500",
            "ciro-v1-italic",
            "ciro-v1-text-sm",
          )}
        >
          Downloading...
        </div>
      </CiroModal>
    </>
  );
}

export default DownloadEnrichmentFlowButton;

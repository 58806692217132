import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import CheckIcon from "../../assets/img/icons/CheckIcon";
import IndeterminateCheckIcon from "../../assets/img/icons/IndeterminateCheckIcon";

interface ICiroCheckboxProps {
  checked: boolean;
  indeterminate?: boolean;
  className?: string;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  label?: any;
  labelClassName?: string;
}

const CiroCheckbox = ({
  checked,
  indeterminate,
  className = "",
  onClick,
  label,
  labelClassName,
  disabled = false,
}: ICiroCheckboxProps) => {
  const inputRef =
    useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = Boolean(indeterminate);
    }
  });

  return (
    <label
      className={classNames(
        "ciro-v1-cursor-pointer",
        "ciro-v1-flex",
        "ciro-v1-items-start",
      )}
    >
      <input
        ref={inputRef}
        checked={checked}
        readOnly
        className={classNames(
          "ciro-v1-absolute",
          "ciro-v1-w-0",
          "ciro-v1-h-0",
          "ciro-v1-opacity-0",
        )}
        disabled={disabled}
        onClick={onClick}
        type="checkbox"
      />
      <span
        className={classNames(
          [
            "ciro-v1-border-1",
            "ciro-v1-checkmark",
            "ciro-v1-cursor-pointer",
            "ciro-v1-flex",
            "ciro-v1-h-5",
            "ciro-v1-items-center",
            "ciro-v1-justify-center",
            "ciro-v1-p-0.5",
            "ciro-v1-rounded",
            "ciro-v1-w-5",
            "test-checkbox",
            className,
            "ciro-v1-flex-shrink-0",
            "ciro-v1-mt-0.5",
          ],
          {
            "ciro-v1-bg-amber-500/30": checked,
            "ciro-v1-text-orange-400": checked,
            "ciro-v1-border-orange-400": checked,

            "ciro-v1-bg-white": !checked,
            "ciro-v1-border-gray-300": !checked,
            "ciro-v1-text-white": !checked,
          },
        )}
      >
        {checked && indeterminate && <IndeterminateCheckIcon />}
        {checked && !indeterminate && <CheckIcon />}
      </span>
      {label && (
        <span className={classNames("ciro-v1-pl-2", "ciro-v1-flex-grow", labelClassName)}>
          {label}
        </span>
      )}
    </label>
  );
};

export default CiroCheckbox;

import React from "react";
import classNames from "classnames";
import CiroTextInput from "../shared/CiroTextInput";
import { gql, useMutation } from "@apollo/client";
import {
  AddUserAccountForm_CreateUserAccountMutation,
  AddUserAccountForm_CreateUserAccountMutationVariables,
  AddUserAccountForm_OrganizationsFragment,
} from "../../__generated__/graphql";
import CiroDropDown from "../shared/CiroDropdown";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroCheckbox from "../shared/CiroCheckbox";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const AddUserAccountFormSchema = yup
  .object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email().required("Email is required"),
    newOrgName: yup.string().when("isNewOrg", {
      is: true,
      then: (schema) => {
        return schema
          .required("Organization name is required")
          .matches(
            /^[a-z0-9\-_]+$/,
            "Organization name must be lower case with no spaces",
          );
      },
    }),
    selectedOrg: yup.string().when("isNewOrg", {
      is: false,
      then: (schema) => {
        return schema.required("Organization is required");
      },
    }),
    isNewOrg: yup.boolean(),
  })
  .required();

export const AddUserAccountForm_Organizations = gql`
  fragment AddUserAccountForm_Organizations on Query {
    organizations {
      name
      subscription_tier
    }
  }
`;

export const AddUserAccountForm_CreateUserAccount = gql`
  mutation AddUserAccountForm_CreateUserAccount(
    $email: String!
    $organizationName: String!
    $isNewOrg: Boolean!
    $firstName: String
    $lastName: String
  ) {
    createUserAccount(
      email: $email
      organizationName: $organizationName
      isNewOrg: $isNewOrg
      firstName: $firstName
      lastName: $lastName
    ) {
      newUser {
        email
      }
      temporaryPassword
    }
  }
`;

interface IAddUserAccountFormProps {
  organizationData?: AddUserAccountForm_OrganizationsFragment;
}

const AddUserAccountForm = ({ organizationData }: IAddUserAccountFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(AddUserAccountFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      newOrgName: "",
      isNewOrg: false,
      selectedOrg: "",
    },
  });
  const [isNewOrg, selectedOrg] = useWatch({
    control,
    name: ["isNewOrg", "selectedOrg"],
  });

  const organizationDropdownOptions =
    organizationData?.organizations?.map((org) => {
      return {
        label: String(org?.name),
        value: String(org?.name),
      };
    }) || [];

  const [
    createUserAccount,
    {
      data: createUserAccountData,
      loading: createUserAccountLoading,
      error: createUserAccountError,
    },
  ] = useMutation<
    AddUserAccountForm_CreateUserAccountMutation,
    AddUserAccountForm_CreateUserAccountMutationVariables
  >(AddUserAccountForm_CreateUserAccount);

  const onSubmit = async (data: any) => {
    try {
      await createUserAccount({
        variables: {
          email: data.email,
          isNewOrg: data.isNewOrg,
          firstName: data.firstName,
          lastName: data.lastName,
          organizationName: data.isNewOrg ? data.newOrgName : data.selectedOrg,
        },
      });
    } catch {
      // Needed to render errors and keep page functionality
    }
  };

  return (
    <div>
      <div className={classNames("ciro-v1-pb-2", "ciro-v1-font-medium")}>
        Create new account
      </div>
      <div
        className={classNames(
          "ciro-v1-grid",
          "ciro-v1-grid-cols-2",
          "ciro-v1-gap-4",
        )}
      >
        <CiroTextInput
          placeholder="First name"
          register={register("firstName")}
          error={errors.firstName?.message}
        />
        <CiroTextInput
          placeholder="Last name"
          register={register("lastName")}
          error={errors.lastName?.message}
        />
        <CiroTextInput
          placeholder="Email"
          register={register("email")}
          error={errors.email?.message}
        />
        {isNewOrg && (
          <CiroTextInput
            placeholder="Organization"
            register={register("newOrgName")}
            error={errors.newOrgName?.message}
          />
        )}
        {!isNewOrg && (
          <CiroDropDown
            placeholder="Select Organization"
            isClearable
            value={selectedOrg}
            options={organizationDropdownOptions}
            error={errors.selectedOrg?.message}
            onChange={(v) => {
              setValue("selectedOrg", v);
            }}
          />
        )}
        <CiroCheckbox
          checked={!isNewOrg}
          {...register("isNewOrg")}
          onClick={() => {
            setValue("isNewOrg", !isNewOrg);
          }}
          label="Use Existing Organization"
          disabled={createUserAccountLoading}
        />
        <CiroButton
          style={CiroButtonStyleEnum.LOUD}
          onClick={handleSubmit(onSubmit)}
          analyticsField="Create new user"
        >
          Create new user
        </CiroButton>
        {createUserAccountData?.createUserAccount && (
          <div>
            <div>
              User created for{" "}
              {createUserAccountData.createUserAccount.newUser.email}
            </div>
            <div>
              Temporary password:{" "}
              {createUserAccountData.createUserAccount.temporaryPassword}
            </div>
          </div>
        )}
        {createUserAccountError && <div>{createUserAccountError.message}</div>}
      </div>
    </div>
  );
};

export default AddUserAccountForm;

import Markdown from "react-markdown";
import CiroLink from "./CiroLink";
import classNames from "classnames";
import remarkGfm from "remark-gfm";

interface ICiroMarkdownOptions {
  addPSpacing?: boolean;
}

const CiroMarkdown = ({
  markdown,
  options,
}: {
  markdown: string;
  options?: ICiroMarkdownOptions;
}) => {
  return (
    <Markdown
      components={{
        a: ({ href, children }) => {
          return children ? <CiroLink href={href}>{children}</CiroLink> : null;
        },
        p: ({ children }) => {
          return children ? (
            <p
              className={classNames({
                "ciro-v1-pt-2": options?.addPSpacing,
              })}
            >
              {children}
            </p>
          ) : null;
        },
      }}
      remarkPlugins={[remarkGfm]}
    >
      {markdown}
    </Markdown>
  );
};

export default CiroMarkdown;

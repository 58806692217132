import { IEnrichmentExecutionCellProps } from ".";

enum ManualWebResearchStatusEnum {
  PENDING = "PENDING",
  ENRICHED = "ENRICHED",
  NOT_FOUND = "NOT_FOUND",
}

interface IManualWebResearchEnrichmentOutput {
  status: ManualWebResearchStatusEnum;
  answer: string;
  research_notes: string;
}

const ExecutionCell_ManualWebResearch = ({
  value,
  setCellDetailsValue,
}: IEnrichmentExecutionCellProps) => {
  let parsedValue =
    value?.output &&
    (JSON.parse(value.output) as IManualWebResearchEnrichmentOutput);
  const originalParsedValue = parsedValue;

  let returnValue = "";
  if (
    value?.output &&
    parsedValue?.status === ManualWebResearchStatusEnum.PENDING
  ) {
    returnValue = "Pending";
  } else if (parsedValue?.status === ManualWebResearchStatusEnum.ENRICHED) {
    returnValue = parsedValue.answer;
  } else if (parsedValue?.status === ManualWebResearchStatusEnum.NOT_FOUND) {
    returnValue = "Not found";
  }

  return (
    <div>
      <div
        onClick={() => {
          setCellDetailsValue(originalParsedValue);
        }}
      >
        {returnValue}
      </div>
    </div>
  );
};

export default ExecutionCell_ManualWebResearch;

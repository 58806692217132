import classNames from "classnames";
import { useState } from "react";
import CiroButton, { CiroButtonStyleEnum } from "./CiroButton";
import XIcon from "../../assets/img/icons/XIcon";
import React from "react";

interface ICiroAlertProps {
  message: string;
  level?: "error" | "info";
}

const CiroAlert = ({ message, level = "error" }: ICiroAlertProps) => {
  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) return null;

  return (
    <div
      className={classNames(
        "ciro-v1-border",
        "ciro-v1-mb-8",
        "ciro-v1-p-4",
        "ciro-v1-rounded-xl",
        "ciro-v1-text-sm",
        "ciro-v1-flex",
        "ciro-v1-items-start",
        "ciro-v1-justify-between",
        {
          "ciro-v1-bg-red-100": level === "error",
          "ciro-v1-border-red-400": level === "error",
          "ciro-v1-text-red-700": level === "error",
          "ciro-v1-bg-gray-100": level === "info",
          "ciro-v1-border-gray-400": level === "info",
        },
      )}
    >
      {message}
      <CiroButton
        style={CiroButtonStyleEnum.UNSTYLED}
        analyticsField="Close alert for modal"
        onClick={() => setIsOpen(false)}
      >
        <XIcon />
      </CiroButton>
    </div>
  );
};

export default CiroAlert;

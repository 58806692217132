import { NetworkStatus, gql, useMutation, useQuery } from "@apollo/client";
import {
  EnrichmentStepCard_UpdateEnrichmentStepMutation,
  EnrichmentStepCard_UpdateEnrichmentStepMutationVariables,
  EnrichmentStepCard_EnrichmentStepQuery,
  EnrichmentTechniqueEnum,
  Maybe,
  EnrichmentStepCard_EnrichmentStepQueryVariables,
  EnrichmentStepCard_CreateNewEnrichmentStepMutation,
  EnrichmentStepCard_CreateNewEnrichmentStepMutationVariables,
} from "../../../__generated__/graphql";
import { useCallback, useContext, useMemo, useState } from "react";
import EnrichmentFlowContext from "../../../contexts/EnrichmentFlowContext";
import { EnrichmentStepCardContainer_EnrichmentStep } from "./EnrichmentStepCardContainer";
import chooseEnrichmentStepTechniqueCard from "./EnrichmentStepTechniqueCards";
import { EnrichmentStepCardFiltersList_EnrichmentStep } from "./EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import EnrichmentStepTechniqueDefaultCard from "./EnrichmentStepTechniqueCards/EnrichmentStepTechniqueDefaultCard";
import EnrichmentStepCardContext, {
  IEnrichmentStepCardSavingStatus,
} from "../../../contexts/EnrichmentStepCardContext";
import { UseEnrichmentInputErrors_EnrichmentFlow } from "../../../reactHooks/enrichmentFlow/useEnrichmentInputErrors";

interface IEnrichmentStepCardProps {
  enrichmentStepId: Maybe<number>;
  onClose: () => void;
  offset: number;
  totalCount: number;
  setSelectedEnrichmentStepId: (v: number | null) => void;
  rowIds: number[];
  selectedEnrichmentTechnique: EnrichmentTechniqueEnum | null;
}

export interface IUpsertEnrichmentStepVariables {
  id: number | null;
  enrichmentStepInput: {
    enrichment_technique: EnrichmentTechniqueEnum;
    selected_input: string | null;
    parentEnrichmentStepInputs: {
      key: string;
      input?: string | null;
      required: boolean;
      sourceEnrichmentStepId?: number | null;
    }[];
    filterEnrichmentSteps?: {
      enrichment_technique: EnrichmentTechniqueEnum;
      name: string;
      parentEnrichmentStepInputs: {
        key: string;
        required: boolean;
        input?: string | null;
        sourceEnrichmentStepId?: number | null;
      }[];
    }[];
  };
}

const EnrichmentStepCard_EnrichmentStep = gql`
  query EnrichmentStepCard_EnrichmentStep(
    $enrichmentStepId: Int!
    $skip: Boolean!
  ) {
    enrichmentStep(id: $enrichmentStepId) @skip(if: $skip) {
      id
      name
      selected_input
      enrichment_technique
      parentEnrichmentStepInputs(sourceType: nonfilter) {
        id
        key
        input
        required
        sourceEnrichmentStep {
          id
          name
        }
      }
      ...EnrichmentStepCardContainer_EnrichmentStep
      ...EnrichmentStepCardFiltersList_EnrichmentStep
    }
  }
  ${EnrichmentStepCardContainer_EnrichmentStep}
  ${EnrichmentStepCardFiltersList_EnrichmentStep}
`;

const EnrichmentStepCard_UpdateEnrichmentStep = gql`
  mutation EnrichmentStepCard_UpdateEnrichmentStep(
    $id: Int
    $enrichmentStepInput: UpsertEnrichmentStepInput!
  ) {
    updateEnrichmentStep(id: $id, enrichmentStepInput: $enrichmentStepInput) {
      id
    }
  }
`;

const EnrichmentStepCard_CreateNewEnrichmentStep = gql`
  mutation EnrichmentStepCard_CreateNewEnrichmentStep(
    $enrichment_flow_id: Int!
    $input: UpsertEnrichmentStepInput!
  ) {
    createEnrichmentStep(
      enrichment_flow_id: $enrichment_flow_id
      input: $input
    ) {
      id
    }
  }
`;

const EnrichmentStepCard = ({
  enrichmentStepId,
  rowIds,
  offset,
  totalCount,
  setSelectedEnrichmentStepId,
  onClose,
  selectedEnrichmentTechnique,
}: IEnrichmentStepCardProps) => {
  const { enrichmentFlowId } = useContext(EnrichmentFlowContext);
  const [updateEnrichmentStep] = useMutation<
    EnrichmentStepCard_UpdateEnrichmentStepMutation,
    EnrichmentStepCard_UpdateEnrichmentStepMutationVariables
  >(EnrichmentStepCard_UpdateEnrichmentStep, {
    refetchQueries: [UseEnrichmentInputErrors_EnrichmentFlow],
  });

  const [createNewEnrichmentStep] = useMutation<
    EnrichmentStepCard_CreateNewEnrichmentStepMutation,
    EnrichmentStepCard_CreateNewEnrichmentStepMutationVariables
  >(EnrichmentStepCard_CreateNewEnrichmentStep, {
    refetchQueries: [UseEnrichmentInputErrors_EnrichmentFlow],
  });

  const [savingStatus, setSavingStatus] =
    useState<IEnrichmentStepCardSavingStatus>({
      saving: false,
      success: false,
      error: "",
    });

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);
  const {
    EnrichmentFlow_refetchEnrichmentFlow,
    newEnrichmentTitle,
    enrichmentFlowFilters,
  } = useContext(EnrichmentFlowContext);

  const {
    data,
    loading,
    error,
    networkStatus,
    refetch: EnrichmentStepCard_refetchEnrichmentStep,
  } = useQuery<
    EnrichmentStepCard_EnrichmentStepQuery,
    EnrichmentStepCard_EnrichmentStepQueryVariables
  >(EnrichmentStepCard_EnrichmentStep, {
    variables: {
      enrichmentStepId: enrichmentStepId || 0,
      skip: !enrichmentStepId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const showLoadingBar = enrichmentStepId
    ? loading &&
      // Don't flash loading bar after pressing save button (if enrichment step already exists)
      ![NetworkStatus.refetch].includes(networkStatus)
    : loading &&
      // If enrichment step already doesn't exist, don't flash loading bar after first save
      ![NetworkStatus.refetch, NetworkStatus.setVariables].includes(
        networkStatus,
      );

  const isCreateNewEnrichmentStepMutation = (
    data: any,
  ): data is EnrichmentStepCard_CreateNewEnrichmentStepMutation => {
    return (
      (data as EnrichmentStepCard_CreateNewEnrichmentStepMutation)
        .createEnrichmentStep !== undefined
    );
  };

  const confirmUpdateEnrichmentStep = useCallback(
    ({ data }: { data: IUpsertEnrichmentStepVariables }) => {
      setSavingStatus({ saving: true, success: false, error: "" });
      const onCompleted = async (
        data:
          | EnrichmentStepCard_CreateNewEnrichmentStepMutation
          | EnrichmentStepCard_UpdateEnrichmentStepMutation,
      ) => {
        let selectedEnrichmentStepId;
        if (isCreateNewEnrichmentStepMutation(data)) {
          selectedEnrichmentStepId = data.createEnrichmentStep.id;
          setSelectedEnrichmentStepId(data.createEnrichmentStep.id);
        } else {
          selectedEnrichmentStepId = data.updateEnrichmentStep.id;
        }
        setSavingStatus({ saving: false, success: true, error: "" });
        setTimeout(() => {
          setSavingStatus({ saving: false, success: false, error: "" });
        }, 3000);
        await Promise.all([
          EnrichmentFlow_refetchEnrichmentFlow(),
          EnrichmentStepCard_refetchEnrichmentStep({
            enrichmentStepId: selectedEnrichmentStepId,
          }),
        ]);
      };
      const onError = (error: any) => {
        setSavingStatus({
          saving: false,
          success: false,
          error: error.message,
        });
      };
      if (!data?.id) {
        createNewEnrichmentStep({
          variables: {
            enrichment_flow_id: enrichmentFlowId,
            input: {
              enrichment_technique:
                data.enrichmentStepInput.enrichment_technique,
              filterEnrichmentSteps:
                data.enrichmentStepInput.filterEnrichmentSteps,
              name: newEnrichmentTitle,
              parentEnrichmentStepInputs:
                data.enrichmentStepInput.parentEnrichmentStepInputs,
              selected_input: data.enrichmentStepInput.selected_input,
            },
          },
          onCompleted,
          onError,
        });
      } else {
        updateEnrichmentStep({
          variables: data,
          onCompleted,
          onError,
        });
      }
    },
    [
      EnrichmentFlow_refetchEnrichmentFlow,
      EnrichmentStepCard_refetchEnrichmentStep,
      setSelectedEnrichmentStepId,
      createNewEnrichmentStep,
      enrichmentFlowId,
      newEnrichmentTitle,
      updateEnrichmentStep,
    ],
  );

  const enrichmentStep = data?.enrichmentStep;

  const EnrichmentTechniqueCard = useMemo(() => {
    return chooseEnrichmentStepTechniqueCard(selectedEnrichmentTechnique);
  }, [selectedEnrichmentTechnique]);

  if (!selectedEnrichmentTechnique) {
    return null;
  }

  if (EnrichmentTechniqueCard) {
    return (
      <EnrichmentStepCardContext.Provider
        value={{
          rowIds,
          offset,
          savingStatus,
          totalCount,
          enrichmentFlowFilters,
        }}
      >
        <EnrichmentTechniqueCard
          confirmUpdateEnrichmentStep={confirmUpdateEnrichmentStep}
          enrichmentStep={enrichmentStep}
          onClose={handleClose}
          loading={showLoadingBar}
          error={error}
        />
      </EnrichmentStepCardContext.Provider>
    );
  }

  return (
    <EnrichmentStepCardContext.Provider
      value={{
        rowIds,
        offset,
        totalCount,
        savingStatus,
        enrichmentFlowFilters,
      }}
    >
      <EnrichmentStepTechniqueDefaultCard
        enrichmentStep={enrichmentStep}
        selectedEnrichmentTechnique={selectedEnrichmentTechnique}
        confirmUpdateEnrichmentStep={confirmUpdateEnrichmentStep}
        onClose={handleClose}
        loading={false}
        error={error}
      />
    </EnrichmentStepCardContext.Provider>
  );
};

export default EnrichmentStepCard;

const ApolloIcon = ({ height = "16", width = "20" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="apolloio_symbol.svg" clipPath="url(#clip0_3018_37554)">
        <g id="Group">
          <path
            id="Vector"
            d="M16.327 3.10339H13.741L15.0857 5.34477L16.327 3.10339Z"
            fill="#FFC127"
          />
          <path
            id="Vector_2"
            d="M17.7752 12.0345L16.0511 9.10345L10.5343 0L0.879883 16H6.05191C6.74151 16 7.43111 15.8276 8.01727 15.4828C8.60344 15.1379 9.12064 14.6897 9.46544 14.1034L12.1204 9.68965L10.6378 7.24138L7.43111 12.5862C7.25871 12.8966 6.98287 13.1379 6.67255 13.3103C6.36223 13.4828 6.01742 13.5862 5.67262 13.5862H5.29334L10.5343 4.86207L17.2235 15.9655H19.1199V14.2069L17.7752 12.0345Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3018_37554">
          <rect
            width="18.24"
            height="16"
            fill="white"
            transform="translate(0.879883)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ApolloIcon;

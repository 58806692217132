import React from "react";
import classNames from "classnames";

interface IContainerProps {
  children: any;
  className?: any;
}

// Large screens have no navigation header
export const FULL_SCREEN_CONTAINER_CLASSES = [
  "lg:ciro-v1-h-full",
  "lg:ciro-v1-min-h-screen",
];

const CiroContainer = ({ children, className = "" }: IContainerProps) => {
  return (
    <div
      className={classNames(
        className,
        FULL_SCREEN_CONTAINER_CLASSES,
        "ciro-v1-flex-col",
        "ciro-v1-flex",
        "ciro-v1-items-start",
        "ciro-v1-pt-8",
        "ciro-v1-pb-8",
        "ciro-v1-text-left",
        "ciro-v1-text-start",
        "ciro-v1-w-full",
        "ciro-v1-px-4",
        "sm:ciro-v1-px-8",
        "lg:ciro-v1-px-16",
      )}
    >
      {children}
    </div>
  );
};

export default CiroContainer;

import { gql, useMutation, useQuery } from "@apollo/client";
import CiroContainer from "../../components/shared/CiroContainer";
import { useParams, useSearchParams } from "react-router-dom";
import CiroAlert from "../../components/shared/CiroAlert";
import {
  EnrichmentFLow_EnrichmentFlowQuery,
  EnrichmentFLow_EnrichmentFlowQueryVariables,
  EnrichmentFlowr_RunEnrichmentFlowMutation,
  EnrichmentFlowr_RunEnrichmentFlowMutationVariables,
  EnrichmentFlowTableContainer_EnrichmentFlowFragmentDoc,
  EnrichmentStepsDropdown_EnrichmentStepsFragmentDoc,
  EnrichmentTechniqueDetails,
  EnrichmentTechniqueEnum,
  EnrichmentFlow_CloneEnrichmentFlowMutation,
  EnrichmentFlow_CloneEnrichmentFlowMutationVariables,
} from "../../__generated__/graphql";
import EnrichmentFlowTableContainer, {
  EnrichmentFlowTableContainer_EnrichmentFlow,
} from "../../components/enrichmentFlow/EnrichmentFlowTable/EnrichmentFlowTableContainer";
import { useFragment } from "../../__generated__";
import EnrichmentFlowContext, {
  IRowRange,
} from "../../contexts/EnrichmentFlowContext";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import { EnrichmentStepsDropdown_EnrichmentSteps } from "../../components/enrichmentFlow/EnrichmentStepsDropdown";
import { AgGridReact } from "ag-grid-react";
import { RefObject, useContext, useState } from "react";
import EnrichmentFlowTitleEdit, {
  EnrichmentFlowTitleEdit_EnrichmentFlow,
} from "../../components/enrichmentFlow/EnrichmentFlowTitleEdit";
import toast from "react-hot-toast";
import classNames from "classnames";
import SkeletonLoading from "../../components/shared/SkeletonLoading";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import useOnboardingHints from "../../reactHooks/onboarding/useOnboardingHints";
import CiroLink from "../../components/shared/CiroLink";
import useEnrichmentFlowFilters from "../../reactHooks/filters/enrichmentFlow/useEnrichmentFlowFilters";
import { useLogging } from "../../utils/loggingUtil";
import EnrichmentFlowRunModal, {
  IRunStepParams,
} from "../../components/enrichmentFlow/EnrichmentFlowRunModal";
import NavigationContext from "../../contexts/NavigationContext";
import CiroButton from "../../components/shared/CiroButton";
import CiroSpinner from "../../components/shared/CiroSpinner";

const EnrichmentFlow_EnrichmentFlow = gql`
  query EnrichmentFLow_EnrichmentFlow($id: Int!) {
    enrichmentFlow(id: $id) {
      id
      name
      ...EnrichmentFlowTableContainer_EnrichmentFlow
      ...EnrichmentStepsDropdown_EnrichmentSteps
      ...EnrichmentFlowTitleEdit_EnrichmentFlow
      enrichmentFlowRowIds
    }
    permissions {
      hasAdminPermissions
    }
    originalPermissions {
      hasAdminPermissions
    }
  }
  ${EnrichmentStepsDropdown_EnrichmentSteps}
  ${EnrichmentFlowTableContainer_EnrichmentFlow}
  ${EnrichmentFlowTitleEdit_EnrichmentFlow}
`;

const EnrichmentFlow_RunEnrichmentFlow = gql`
  mutation EnrichmentFlowr_RunEnrichmentFlow($input: RunEnrichmentFlowInput!) {
    runEnrichmentFlow(input: $input) {
      success
      jobId
    }
  }
`;

const EnrichmentFlow_CloneEnrichmentFlow = gql`
  mutation EnrichmentFlow_CloneEnrichmentFlow($enrichmentFlowId: Int!) {
    cloneEnrichmentFlow(enrichmentFlowId: $enrichmentFlowId) {
      enrichmentFlow {
        id
        name
      }
      success
      error
    }
  }
`;

const EnrichmentFlow = () => {
  const { enrichmentFlowId } = useParams();
  const { logEnrichmentFlowRun } = useLogging();

  const [gridRef, setGridRef] = useState<RefObject<AgGridReact<any>> | null>(
    null,
  );
  const [
    enrichmentFlowTableContainer_refetchEnrichmentFlowRows,
    setEnrichmentFlowTableContainer_refetchEnrichmentFlowRows,
    // Apollo refetch returns a promise
  ] = useState<() => Promise<any>>(async () => {
    return;
  });
  const [loadingStepIds, setLoadingStepIds] = useState<Set<number>>(new Set());
  const [loadingRowIds, setLoadingRowIds] = useState<Set<number> | null>(null);
  const [newEnrichmentTitle, setNewEnrichmentTitle] = useState<string | null>(
    "",
  );
  const [newEnrichmentTechniqueDetails, setNewEnrichmentTechniqueDetails] =
    useState<EnrichmentTechniqueDetails | null>(null);
  const [enrichmentFlowLoading, setEnrichmentFlowLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<IRowRange>({
    tableIndexStart: null,
    tableIndexEnd: null,
  });
  const [runningJobId, setRunningJobId] = useState<string | null>(null);

  const {
    data,
    loading,
    error,
    refetch: EnrichmentFlow_refetchEnrichmentFlow,
  } = useQuery<
    EnrichmentFLow_EnrichmentFlowQuery,
    EnrichmentFLow_EnrichmentFlowQueryVariables
  >(EnrichmentFlow_EnrichmentFlow, {
    variables: { id: Number(enrichmentFlowId) },
  });

    const showCloneButton =
      data?.originalPermissions?.hasAdminPermissions ||
      data?.permissions?.hasAdminPermissions


  const [runEnrichmentFlow] = useMutation<
    EnrichmentFlowr_RunEnrichmentFlowMutation,
    EnrichmentFlowr_RunEnrichmentFlowMutationVariables
  >(EnrichmentFlow_RunEnrichmentFlow);

  const [cloneEnrichmentFlow] = useMutation<
    EnrichmentFlow_CloneEnrichmentFlowMutation,
    EnrichmentFlow_CloneEnrichmentFlowMutationVariables
  >(EnrichmentFlow_CloneEnrichmentFlow);

  const [runStepParams, setRunStepParams] = useState<IRunStepParams | null>(
    null,
  );

  const { refreshNavData } = useContext(NavigationContext);
  const [isCloning, setIsCloning] = useState(false);

  const runStep = ({
    enrichmentStepId,
    enrichmentFlowRowIds,
    numRowsToRun,
  }: IRunStepParams) => {
    if (numRowsToRun && enrichmentFlowRowIds) {
      enrichmentFlowRowIds = enrichmentFlowRowIds.slice(0, numRowsToRun);
    }

    if (enrichmentStepId) {
      loadingStepIds.add(enrichmentStepId);
      setLoadingStepIds(new Set(loadingStepIds));
    } else {
      enrichmentFlowWithSteps?.enrichmentSteps.forEach((step) => {
        if (
          step.enrichment_technique !== EnrichmentTechniqueEnum.Initialize &&
          step.enrichment_technique !==
            EnrichmentTechniqueEnum.InitializeCompany
        ) {
          loadingStepIds.add(step.id);
        }
      });
      setLoadingStepIds(new Set(loadingStepIds));
    }

    if (enrichmentFlowRowIds) {
      setLoadingRowIds(new Set(enrichmentFlowRowIds));
    }

    runEnrichmentFlow({
      variables: {
        input: {
          cascade: enrichmentStepId ? false : true,
          enrichmentStepId,
          enrichmentFlowRowIds: enrichmentFlowRowIds,
          enrichmentFlowId: Number(enrichmentFlowId),
        },
      },
    })
      .then(({ data: result }) => {
        setRunningJobId(result?.runEnrichmentFlow?.jobId!);

        // Refresh credit balance in navigation
        refreshNavData();
        if (enrichmentStepId) {
          logEnrichmentFlowRun({
            runId: result?.runEnrichmentFlow?.jobId!,
            enrichmentFlowId: Number(enrichmentFlowId),
            enrichmentStepId: enrichmentStepId,
            enrichmentStepTechnique:
              enrichmentFlowWithSteps?.enrichmentSteps.find(
                (step) => step.id === enrichmentStepId,
              )?.enrichment_technique!,
            rowCount:
              numRowsToRun ||
              enrichmentFlowRowIds?.length ||
              data?.enrichmentFlow?.enrichmentFlowRowIds?.length!,
          });
        } else {
          enrichmentFlowWithSteps?.enrichmentSteps.forEach((step) => {
            if (
              step.enrichment_technique !==
                EnrichmentTechniqueEnum.Initialize &&
              step.enrichment_technique !==
                EnrichmentTechniqueEnum.InitializeCompany
            ) {
              logEnrichmentFlowRun({
                runId: result?.runEnrichmentFlow?.jobId!,
                enrichmentFlowId: Number(enrichmentFlowId),
                enrichmentStepId: step.id,
                enrichmentStepTechnique: step.enrichment_technique,
                rowCount:
                  numRowsToRun ||
                  enrichmentFlowRowIds?.length ||
                  data?.enrichmentFlow?.enrichmentFlowRowIds?.length!,
              });
            }
          });
        }
      })
      .catch((error) => {
        toast.error(`Run failed with error: ${error.message}`);
      });
  };

  const prepareRunStep = ({
    enrichmentFlowRowIds,
    numRowsToRun,
    enrichmentStepId,
  }: IRunStepParams) => {
    const stepsToRun = enrichmentStepId
      ? [
          enrichmentFlowWithSteps?.enrichmentSteps.find(
            (step) => step.id === enrichmentStepId,
          )!,
        ]
      : enrichmentFlowWithSteps?.enrichmentSteps;

    if (
      stepsToRun?.some((step) => {
        return (step?.enrichmentTechniqueDetails?.costPerExecution || 0) > 0;
      })
    ) {
      setRunStepParams({
        enrichmentStepId,
        enrichmentFlowRowIds,
        numRowsToRun,
      });
    } else {
      runStep({
        enrichmentStepId,
        enrichmentFlowRowIds,
        numRowsToRun,
      });
    }
  };

  const handleEnrichmentFlow_CloneEnrichmentFlow = () => {
    setIsCloning(true);
    cloneEnrichmentFlow({
      variables: { enrichmentFlowId: Number(enrichmentFlowId) },
      onCompleted: (data) => {
        if (data?.cloneEnrichmentFlow.success) {
          toast.success("Enrichment table cloned successfully");
        } else {
          toast.error(
            `Failed to clone enrichment table: ${data?.cloneEnrichmentFlow.error}`
          );
        }
        setIsCloning(false);
      },
      onError: (error) => {
        toast.error(`Error cloning enrichment table: ${error.message}`);
        setIsCloning(false);
      },
    });
  };

  const enrichmentFlowTableEnrichmentFlow = useFragment(
    EnrichmentFlowTableContainer_EnrichmentFlowFragmentDoc,
    data?.enrichmentFlow,
  );

  const enrichmentFlowWithSteps = useFragment(
    EnrichmentStepsDropdown_EnrichmentStepsFragmentDoc,
    data?.enrichmentFlow,
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    enrichmentFlowFilters,
    updateEnrichmentFilter,
    resetEnrichmentFlowFilters,
  } = useEnrichmentFlowFilters({
    searchParams,
    setSearchParams,
  });

  if (loading) {
    return (
      <CiroContainer className={classNames("ciro-v1-bg-zinc-100")}>
        <div className={classNames("ciro-v1-pb-20", "ciro-v1-w-full")}>
          <SkeletonLoading
            numSkeletons={1}
            skeletonHeight={"2rem"}
            containerCustomerClassName="ciro-v1-w-1/4"
          ></SkeletonLoading>
        </div>
        <SkeletonLoading
          numSkeletons={7}
          skeletonHeight={"3rem"}
          containerCustomerClassName="ciro-v1-w-full"
        ></SkeletonLoading>
      </CiroContainer>
    );
  }

  if (error) {
    return <CiroAlert message={`Error loading page: ${error.message}`} />;
  }

  return (
    <EnrichmentFlowContext.Provider
      value={{
        enrichmentFlowId: Number(enrichmentFlowId),
        EnrichmentFlow_refetchEnrichmentFlow,
        enrichmentFlowWithSteps,
        gridRef,
        prepareRunStep,
        setGridRef,
        enrichmentFlowTableContainer_refetchEnrichmentFlowRows,
        setEnrichmentFlowTableContainer_refetchEnrichmentFlowRows,
        loadingStepIds,
        setLoadingStepIds,
        loadingRowIds,
        setLoadingRowIds,
        newEnrichmentTitle,
        setNewEnrichmentTitle,
        newEnrichmentTechniqueDetails,
        setNewEnrichmentTechniqueDetails,
        setEnrichmentFlowLoading,
        selectedRows,
        setSelectedRows,
        runningJobId,
        setRunningJobId,
        enrichmentFlowRowIds: data?.enrichmentFlow?.enrichmentFlowRowIds || [],
        enrichmentFlowRowIdIndexMap: new Map(
          (data?.enrichmentFlow?.enrichmentFlowRowIds || []).map(
            (rowId, index) => [rowId, index],
          ),
        ),
        enrichmentFlowFilters,
        updateEnrichmentFilter,
        resetEnrichmentFlowFilters,
      }}
    >
      <CiroContainer className={classNames("ciro-v1-bg-zinc-100")}>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-row",
            "ciro-v1-justify-between",
            "ciro-v1-w-full",
            "ciro-v1-items-center",
          )}
        >
          <CiroBreadCrumbs
            values={["Lists", data?.enrichmentFlow?.name || ""]}
            href="/lists"
          />
          <div
            className={classNames(
              "ciro-v1-text-neutral-500",
              "ciro-v1-text-xs",
              "ciro-v1-flex",
              "ciro-v1-flex-col",
              "ciro-v1-items-end",
            )}
          >
            <CiroLink
              href={"https://cal.com/team/ciro/ciro-support-15min"}
              shouldOpenInNewTab
            >
              Book a 15 min training session
            </CiroLink>
            {showCloneButton && (
              <CiroButton
                onClick={handleEnrichmentFlow_CloneEnrichmentFlow}
                analyticsField={"Clone Enrichment Flow"}
                customClassName="ciro-v1-my-2"
              >
                {isCloning ? <CiroSpinner loading={isCloning} /> : "Clone"}
              </CiroButton>
            )}
          </div>
        </div>
        <EnrichmentFlowTitleEdit
          enrichmentFlow={data?.enrichmentFlow}
          enrichmentFlowLoading={enrichmentFlowLoading}
        />

        <EnrichmentFlowTableContainer
          enrichmentFlow={enrichmentFlowTableEnrichmentFlow}
          // TODO: Convert this back to showIntroCard when
          // we have an updated video / documentation (CIR-2190)
          hintCardVisible={false}
        />
      </CiroContainer>
      <EnrichmentFlowRunModal
        runStepParams={runStepParams}
        setRunStepParams={setRunStepParams}
        runStep={runStep}
      />
    </EnrichmentFlowContext.Provider>
  );
};
export default EnrichmentFlow;

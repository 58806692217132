import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface ISkeletonLoadingProps {
  containerCustomerClassName?: string;
  numSkeletons: number;
  skeletonHeight: string;
  ignoreDefaultPadding?: boolean;
  skeletonZIndex?: number;
  inline?: boolean;
}

const SkeletonLoading = ({
  containerCustomerClassName,
  numSkeletons,
  skeletonHeight,
  ignoreDefaultPadding = false,
  skeletonZIndex,
  inline = false,
}: ISkeletonLoadingProps) => {
  const skeletonStyle: { zIndex?: number } = {};
  if (skeletonZIndex != null) {
    skeletonStyle.zIndex = skeletonZIndex;
  }

  const elements = Array.from({ length: numSkeletons }, (_, index) => (
    <div
      key={index}
      className={classNames({ "ciro-v1-py-2": !ignoreDefaultPadding })}
    >
      <Skeleton inline={inline} style={skeletonStyle} height={skeletonHeight} />
    </div>
  ));

  return <div className={containerCustomerClassName}>{elements}</div>;
};

export default SkeletonLoading;

import { useEffect, useState } from "react";
import { analytics } from "../../../utils/vendors";
import { RangeFilterInput } from "../../../__generated__/graphql";

interface IUseFilterBaseProps {
  searchParams: URLSearchParams;
  filterName: string;
}

const useFilterBaseRange = <T extends RangeFilterInput | null>({
  searchParams,
  filterName,
}: IUseFilterBaseProps): [T, React.Dispatch<T>] => {
  const [filter, setFilter] = useState<T>(() => {
    try {
      const lowerBoundFilter = searchParams.get(
        `${filterName}LowerBoundFilter`,
      );
      const upperBoundFilter = searchParams.get(
        `${filterName}UpperBoundFilter`,
      );
      return {
        lowerBound: lowerBoundFilter ? Number(lowerBoundFilter) : null,
        upperBound: upperBoundFilter ? Number(upperBoundFilter) : null,
      } as T;
    } catch {
      return null as T;
    }
  });

  useEffect(() => {
    if (filter?.lowerBound || filter?.upperBound) {
      analytics.track("singleFilterUsed", {
        filter: filterName,
        value: filter,
      });
    }
  }, [filter, filterName]);

  return [filter, setFilter];
};

export default useFilterBaseRange;

export const API_ORIGIN =
  process.env.NODE_ENV === "production" || process.env.PLASMO_TAG === "prod"
    ? "https://app.ciro.io"
    : "http://localhost:3001";
export const AUTH0_CLIENT_ID = process.env.REACT_APP_USE_DEV_AUTH0_TENANT
  ? "DtGdHDidaFUtLJuDrXAeZ0kNMZJ6hWFW"
  : "KAkKHT94yNyr92d4sw0MfCJl4AxznIsY";
export const AUTH0_DOMAIN = process.env.REACT_APP_USE_DEV_AUTH0_TENANT
  ? "https://dev-o6lrh1qytvh81rsm.us.auth0.com"
  : "https://auth.ciro.io";
export const POSTHOG_HOST = "https://us.i.posthog.com";
export const POSTHOG_KEY = "phc_tWhBaffEOzwK8dhMfWZ9TJt9L4Ea6k22sfhUaKiBroj";
export const POSTHOG_PROJECT_ID = 77326;
export const PRODUCTION_ORIGIN = "app.ciro.io";
export const STRIPE_LINKS =
  process.env.NODE_ENV !== "production"
    ? {
        starter: "https://buy.stripe.com/test_eVa4hweiV39C1pu004",
        plus: "https://buy.stripe.com/test_4gw6pE4Il6lO2ty28e",
        between: "https://buy.stripe.com/test_5kA4hw3Eh7pS3xC005",
      }
    : {
        starter: "https://buy.stripe.com/28ocPW9Y8fzw6ze3ck",
        plus: "https://buy.stripe.com/8wM7vC0ny5YWcXCeV4",
        between: "https://buy.stripe.com/6oE03afisdro3n28wF",
      };

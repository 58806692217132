import classNames from "classnames";
import CiroLink from "../../../shared/CiroLink";
import CiroTextInput from "../../../shared/CiroTextInput";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  ApolloIntegrationApiKeyForm_AddApolloIntegrationMutation,
  ApolloIntegrationApiKeyForm_AddApolloIntegrationMutationVariables,
  ApolloIntegrationApiKeyForm_ApolloIntegrationFragment,
} from "../../../../__generated__/graphql";
import { ApolloMobileConnection_ApolloIntegration } from "../../../../routes/enrichments/ApolloMobileConnection";
import toast from "react-hot-toast";
import CiroModal from "../../../shared/CiroModal";

const ApolloIntegrationApiKeyForm_AddApolloIntegration = gql`
  mutation ApolloIntegrationApiKeyForm_AddApolloIntegration(
    $input: AddApolloIntegrationInput!
  ) {
    addApolloIntegration(input: $input) {
      disposition_map
    }
  }
`;

export const ApolloIntegrationApiKeyForm_ApolloIntegration = gql`
  fragment ApolloIntegrationApiKeyForm_ApolloIntegration on ApolloIntegration {
    id
    api_key
  }
`;

const ApolloIntegrationApiKeyForm = ({
  apolloIntegration,
  onSuccess,
  onClose,
  refetchUserAccount,
}: {
  apolloIntegration: ApolloIntegrationApiKeyForm_ApolloIntegrationFragment | null;
  onSuccess: () => void;
  onClose: () => void;
  refetchUserAccount: () => void;
}) => {
  const [apiKey, setApiKey] = useState("");

  const [addApolloIntegration, { loading, error }] = useMutation<
    ApolloIntegrationApiKeyForm_AddApolloIntegrationMutation,
    ApolloIntegrationApiKeyForm_AddApolloIntegrationMutationVariables
  >(ApolloIntegrationApiKeyForm_AddApolloIntegration, {
    refetchQueries: [ApolloMobileConnection_ApolloIntegration],
    awaitRefetchQueries: true,
  });

  return (
    <CiroModal isOpen={true} onClose={onClose}>
      <h1
        className={classNames(
          "ciro-v1-text-neutral-900",
          "ciro-v1-text-lg",
          "ciro-v1-font-semibold",
        )}
      >
        Apollo API Key
      </h1>
      <div className={classNames("ciro-v1-pt-4")}>
        {apolloIntegration && (
          <div>
            <div
              className={classNames(
                "ciro-v1-text-sm",
                "ciro-v1-text-neutral-500",
              )}
            >
              API Key: {apolloIntegration.api_key}
            </div>
          </div>
        )}
        {!apolloIntegration && (
          <div>
            <div
              className={classNames(
                "ciro-v1-text-sm",
                "ciro-v1-text-neutral-500",
                "ciro-v1-mb-2",
              )}
            >
              Enter your Apollo API key below to connect to your Apollo account.
            </div>
            <CiroTextInput
              isPassword={true}
              label="API Key"
              placeholder="Paste API Key here..."
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              error={error?.message}
            />
            <div className={classNames("ciro-v1-pt-4")}>
              <CiroLink href="https://knowledge.apollo.io/hc/en-us/articles/4415734629773-Configure-Access-to-the-Apollo-REST-API">
                Where do I find my API key?
              </CiroLink>
            </div>
            <div className={classNames("ciro-v1-flex", "ciro-v1-justify-end")}>
              <CiroButton
                style={CiroButtonStyleEnum.LOUD}
                analyticsField="Add ApolloIntegration"
                disabled={!apiKey || loading}
                onClick={async () => {
                  await addApolloIntegration({
                    variables: {
                      input: {
                        api_key: apiKey,
                      },
                    },
                    onCompleted: async () => {
                      await refetchUserAccount();
                      onSuccess();
                    },
                    onError: async () => {
                      await refetchUserAccount();
                      toast.error("Error adding Apollo integration");
                    },
                  });
                }}
              >
                Connect
              </CiroButton>
            </div>
          </div>
        )}
      </div>
    </CiroModal>
  );
};

export default ApolloIntegrationApiKeyForm;

import CiroDropDown from "../shared/CiroDropdown";
import { gql } from "@apollo/client";
import { ExistsInCrmFilter_UserAccountFragment } from "../../__generated__/graphql";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";
import {
  CONNECT_TO_CRM_TOOLTIP_TEXT,
  DEFAULT_FILTER_TOOLTIP_TEXT,
} from "../../utils/consts";

export const ExistsInCrmFilter_userAccount = gql`
  fragment ExistsInCrmFilter_userAccount on Query {
    userAccount {
      id
      hasCRMConnection
    }
  }
`;

interface IExistsInCrmFilterProps {
  blurred?: boolean;
  existsInCrmFilter: boolean | null;
  setExistsInCrmFilter: (v: boolean | null) => void;
  resetOffset: () => void;
  userAccountData: ExistsInCrmFilter_UserAccountFragment;
}

function ExistsInCrmFilter({
  blurred,
  existsInCrmFilter,
  setExistsInCrmFilter,
  resetOffset,
  userAccountData,
}: IExistsInCrmFilterProps) {
  const filterOptions = [
    {
      value: true,
      label: "Found in CRM",
    },
    {
      value: false,
      label: "Not found in CRM",
    },
  ];
  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={existsInCrmFilter == null ? 0 : 1}
      sectionTitle="Found in CRM"
      filterOptions={[
        {
          filter: (
            <CiroDropDown
              blurred={
                blurred || !userAccountData.userAccount?.hasCRMConnection
              }
              blurredTooltip={
                blurred
                  ? DEFAULT_FILTER_TOOLTIP_TEXT
                  : CONNECT_TO_CRM_TOOLTIP_TEXT
              }
              options={filterOptions}
              value={existsInCrmFilter}
              onChange={(newValue) => {
                setExistsInCrmFilter(newValue);
                resetOffset();
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default ExistsInCrmFilter;

import { Fragment } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { analytics } from "../../utils/vendors";

interface ICiroDropdownButtonProps {
  borderless?: boolean;
  children: any;
  dropdownButtonClassName?: string;
  prettyDisable?: boolean;
  disabled?: boolean;
  hideChevron?: boolean;
  label: any;
  onLabelClick?: () => void;
  customClassName?: string;
  customBgColor?: string;
  customPadding?: string;
  customButtonClassName?: string;
  customItemWidth?: string;
  menuPosition?: "left" | "right";
  isPopover?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement> | null;
}

export function CiroDropdownButton({
  buttonRef = null,
  borderless = false,
  children,
  disabled = false,
  hideChevron = false,
  prettyDisable = false,
  label,
  onLabelClick,
  customBgColor = "ciro-v1-bg-white",
  customClassName = "",
  customPadding = classNames("ciro-v1-pl-4", "ciro-v1-pr-2", "ciro-v1-py-2"),
  customButtonClassName = "",
  menuPosition = "left",
  dropdownButtonClassName = "",
  isPopover = false,
}: ICiroDropdownButtonProps) {
  const MenuClass = isPopover ? Popover : Menu;
  const MenuButton = isPopover ? Popover.Button : Menu.Button;
  const MenuPanel = isPopover ? Popover.Panel : Menu.Items;

  return (
    <MenuClass
      as="div"
      className={classNames(
        "ciro-v1-inline-block",
        "ciro-v1-relative",
        "ciro-v1-text-left",
        "ciro-v1-rounded-md",
        customClassName,
        customBgColor,
      )}
    >
      <MenuButton
        ref={buttonRef}
        disabled={disabled}
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-w-full",
          "ciro-v1-justify-center",
          "ciro-v1-gap-x-1.5",
          "ciro-v1-rounded-md",
          "ciro-v1-text-sm",
          "ciro-v1-rounded-lg",
          "ciro-v1-text-sm",
          "ciro-v1-border-gray-300",
          {
            "ciro-v1-border-1": !borderless,
            "ciro-v1-bg-stone-300": disabled && !prettyDisable,
            "ciro-v1-shadow-sm": !borderless,
          },
          customPadding,
          customButtonClassName,
        )}
      >
        <div
          className={classNames([
            { "ciro-v1-pr-2": disabled && prettyDisable },
          ])}
          onClick={(event: any) => {
            if (onLabelClick) {
              event.preventDefault();
              onLabelClick();
            }
          }}
        >
          {label}
        </div>
        {!disabled && !hideChevron && (
          <ChevronDownIcon
            className={classNames(
              "ciro-v1-mr-1",
              "ciro-v1-h-5 w-5",
              "ciro-v1-text-gray-400",
              dropdownButtonClassName,
            )}
            aria-hidden="true"
          />
        )}
      </MenuButton>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuPanel
          className={classNames(
            "ciro-v1-absolute",
            "ciro-v1-z-20",
            "ciro-v1-mt-2",
            "ciro-v1-rounded-md",
            "ciro-v1-bg-white",
            "ciro-v1-shadow-lg",
            "ciro-v1-ring-1",
            "ciro-v1-ring-black",
            "ciro-v1-ring-opacity-5",
            "focus:ciro-v1-outline-none",
            {
              "ciro-v1-right-0": menuPosition === "left",
              "ciro-v1-origin-top-right": menuPosition === "left",
              "ciro-v1-left-0": menuPosition === "right",
              "ciro-v1-origin-top-left": menuPosition === "right",
            },
          )}
        >
          <div className="ciro-v1-py-1">{children}</div>
        </MenuPanel>
      </Transition>
    </MenuClass>
  );
}

interface ICiroDropdownButtonItemProps {
  children: any;
  onClick: () => void;
  analyticsField: string;
  disabled?: boolean;
}

export function CiroDropdownButtonItem({
  analyticsField,
  disabled,
  children,
  onClick,
}: ICiroDropdownButtonItemProps) {
  const handleClick = () => {
    analytics.track("buttonClicked", {
      value: analyticsField,
    });
    onClick();
  };
  return (
    <Menu.Item>
      {({ active }) => (
        <div
          onClick={handleClick}
          className={classNames(
            "ciro-v1-block",
            "ciro-v1-px-4",
            "ciro-v1-py-2",
            "ciro-v1-text-sm",
            "ciro-v1-whitespace-nowrap",
            {
              "ciro-v1-bg-gray-100 ciro-v1-text-gray-900 ciro-v1-cursor-pointer":
                active,
              "ciro-v1-text-gray-700": !active,
              "ciro-v1-bg-gray-200 ciro-v1-opacity-50 ciro-v1-cursor-not-allowed":
                disabled,
            },
          )}
        >
          {children}
        </div>
      )}
    </Menu.Item>
  );
}

import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import {
  CiroDropdownButton,
  CiroDropdownButtonItem,
} from "./CiroDropdownButton";
import classNames from "classnames";

interface IShowExplainerDropdown {
  setShowHint: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowExplainerDropdown = ({ setShowHint }: IShowExplainerDropdown) => {
  return (
    <CiroDropdownButton
      borderless
      customBgColor="ciro-v1-bg-inherit"
      customPadding={classNames("ciro-v1-px-0", "ciro-v1-py-2")}
      label={
        <QuestionMarkCircleIcon
          className={classNames("ciro-v1-h-5", "ciro-v1-text-neutral-500")}
        />
      }
      hideChevron
    >
      <CiroDropdownButtonItem
        onClick={() => setShowHint(true)}
        analyticsField={"showEnrichmentsExplainerClicked"}
      >
        Show explainer
      </CiroDropdownButtonItem>
    </CiroDropdownButton>
  );
};

export default ShowExplainerDropdown;

import { gql, useMutation } from "@apollo/client";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroModal from "../shared/CiroModal";
import {
  DeleteEnrichmentFlowModal_EnrichmentFlowFragment,
  DeleteEnrichmentFlowModal_DeleteEnrichmentFlowMutation,
  DeleteEnrichmentFlowModal_DeleteEnrichmentFlowMutationVariables,
  Maybe,
} from "../../__generated__/graphql";
import { useContext, useState } from "react";
import EnrichmentsContext from "../../contexts/EnrichmentsContext";
import classNames from "classnames";
import toast from "react-hot-toast";
import Loading from "../shared/Loading";

export const DeleteEnrichmentFlowModal_EnrichmentFlow = gql`
  fragment DeleteEnrichmentFlowModal_EnrichmentFlow on EnrichmentFlow {
    id
    name
  }
`;

const DeleteEnrichmentFlowModal_DeleteEnrichmentFlow = gql`
  mutation DeleteEnrichmentFlowModal_DeleteEnrichmentFlow($id: Int!) {
    deleteEnrichmentFlow(id: $id)
  }
`;

interface IDeleteEnrichmentFlowModalProps {
  enrichmentFlow?: Maybe<DeleteEnrichmentFlowModal_EnrichmentFlowFragment>;
  onClose: () => void;
}

const DeleteEnrichmentFlowModal = ({
  enrichmentFlow,
  onClose,
}: IDeleteEnrichmentFlowModalProps) => {
  const { Enrichments_refetchEnrichmentFlows } = useContext(EnrichmentsContext);
  const [loading, setLoading] = useState(false);

  const [deleteEnrichmentFlow] = useMutation<
    DeleteEnrichmentFlowModal_DeleteEnrichmentFlowMutation,
    DeleteEnrichmentFlowModal_DeleteEnrichmentFlowMutationVariables
  >(DeleteEnrichmentFlowModal_DeleteEnrichmentFlow);

  if (!enrichmentFlow) {
    return null;
  }

  const handleSubmit = () => {
    setLoading(true);
    deleteEnrichmentFlow({
      variables: {
        id: enrichmentFlow.id,
      },
      onCompleted: async () => {
        await Enrichments_refetchEnrichmentFlows();
        setLoading(false);
        onClose();
        toast.success("Enrichment table deleted");
      },
      onError: () => {
        setLoading(false);
        onClose();
        toast.error("Enrichment table couldn't be deleted");
      }
    });
  };

  return (
    <CiroModal isOpen={true} onClose={onClose}>
      {loading && <Loading size="SMALL"/>}
      {!loading && <div className={classNames("ciro-v1-text-gray-700")}>
        <div>
          Are you sure you want to delete{" "}
          <span className={"ciro-v1-font-semibold"}>{enrichmentFlow.name}</span>
          ?
        </div>
        <div className={classNames("ciro-v1-text-sm", "ciro-v1-pt-4")}>
          This action cannot be undone.
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-justify-end",
            "ciro-v1-pt-4",
          )}
        >
          <span
            className={classNames({
              "ciro-v1-pr-2": true,
            })}
          >
            <CiroButton
              analyticsField="Cancel Delete Enrichment Flow"
              onClick={onClose}
            >
              Cancel
            </CiroButton>
          </span>
          <CiroButton
            analyticsField="Deleted Enrichment Flow"
            onClick={handleSubmit}
            style={CiroButtonStyleEnum.LOUD}
          >
            Delete
          </CiroButton>
        </div>
      </div>}
    </CiroModal>
  );
};

export default DeleteEnrichmentFlowModal;

import React, { useState } from "react";
import { gql } from "@apollo/client";
import {
  BusinessDetailsMergeCrmAccountDetails_MergeCrmAccountFragmentDoc,
  BusinessDetailsMergeCrmLeadDetails_MergeCrmLeadFragmentDoc,
  BusinessDetailsOrgCrmDetails_OrganizationCompanyStatusFragment,
  Maybe,
  OrganizationCompanyStatusMatchKeyEnum,
} from "../../__generated__/graphql";
import { useFragment } from "../../__generated__";
import CiroCRMPill from "../shared/CiroCRMPill";
import CiroCard from "../shared/CiroCard";
import classNames from "classnames";
import CiroLink from "../shared/CiroLink";
import BoxLinkIcon from "../../assets/img/icons/BoxLinkIcon";
import BusinessDetailsMergeCrmAccountDetails, {
  BusinessDetailsMergeCrmAccountDetails_MergeCrmAccount,
} from "./BusinessDetailsMergeCrmAccountDetails";
import BusinessDetailsMergeCrmLeadDetails, {
  BusinessDetailsMergeCrmLeadDetails_MergeCrmLead,
} from "./BusinessDetailsMergeCrmLeadDetails";
import CiroPill from "../shared/CiroPill";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import DownArrowIcon from "../../assets/img/icons/DownArrowIcon";
import BadgeCheckIcon from "../../assets/img/icons/BadgeCheckIcon";

export const BusinessDetailsOrgCrmDetails_OrganizationCompanyStatus = gql`
  fragment BusinessDetailsOrgCrmDetails_OrganizationCompanyStatus on OrganizationCompanyStatus {
    crm_url
    current_status
    match_keys
    merge_crm_account {
      ...BusinessDetailsMergeCrmAccountDetails_MergeCrmAccount
    }
    merge_crm_lead {
      ...BusinessDetailsMergeCrmLeadDetails_MergeCrmLead
    }
  }

  ${BusinessDetailsMergeCrmAccountDetails_MergeCrmAccount}
  ${BusinessDetailsMergeCrmLeadDetails_MergeCrmLead}
`;

const MATCH_KEY_TO_DISPLAY_NAME = {
  [OrganizationCompanyStatusMatchKeyEnum.Address]: "Address",
  [OrganizationCompanyStatusMatchKeyEnum.Phone]: "Company Phone",
  [OrganizationCompanyStatusMatchKeyEnum.Website]: "Website",
  [OrganizationCompanyStatusMatchKeyEnum.CompanyPhone]: "Company Phone",
  [OrganizationCompanyStatusMatchKeyEnum.ContactPhone]: "Contact Phone",
  [OrganizationCompanyStatusMatchKeyEnum.ContactEmail]: "Contact Email",
};

const formatMatchKeys = (
  matchKeys: OrganizationCompanyStatusMatchKeyEnum[],
) => {
  const matchKeysSet = new Set<string>();

  matchKeys.forEach((matchKey) => {
    if (matchKey === OrganizationCompanyStatusMatchKeyEnum.Composite) {
      matchKeysSet.add("Company Phone");
      matchKeysSet.add("Address");
    } else {
      matchKeysSet.add(MATCH_KEY_TO_DISPLAY_NAME[matchKey]);
    }
  });

  const formattedMatchKeys = Array.from(matchKeysSet);
  const lastMatchKey = formattedMatchKeys.pop();

  if (!lastMatchKey) {
    return "";
  }

  if (formattedMatchKeys.length === 0) {
    return lastMatchKey;
  }

  return [formattedMatchKeys.join(", "), lastMatchKey].join(" & ");
};

interface IBusinessDetailsOrgCrmDetailsProps {
  organizationCompanyStatus?: Maybe<BusinessDetailsOrgCrmDetails_OrganizationCompanyStatusFragment>;
  totalMatches?: number | null;
}

const BusinessDetailsOrgCrmDetails = ({
  organizationCompanyStatus,
  totalMatches,
}: IBusinessDetailsOrgCrmDetailsProps) => {
  const [expanded, setExpanded] = useState(false);

  const mergeCrmAccount = useFragment(
    BusinessDetailsMergeCrmAccountDetails_MergeCrmAccountFragmentDoc,
    organizationCompanyStatus?.merge_crm_account,
  );

  const mergeCrmLead = useFragment(
    BusinessDetailsMergeCrmLeadDetails_MergeCrmLeadFragmentDoc,
    organizationCompanyStatus?.merge_crm_lead,
  );

  if (!organizationCompanyStatus) {
    return null;
  }

  return (
    <div className={classNames("ciro-v1-mb-4")}>
      <CiroCard size="small">
        <div
          className={classNames([
            "ciro-v1-flex",
            "ciro-v1-justify-between",
            "ciro-v1-whitespace-pre-wrap",
            "ciro-v1-inline-block",
            "ciro-v1-text-slate-900",
            "ciro-v1-font-semibold",
            "ciro-v1-text-base",
            "ciro-v1-leading-loose",
          ])}
        >
          <div className={classNames("ciro-v1-font-medium")}>
            <div>
              {!organizationCompanyStatus.crm_url && <div>CRM Details</div>}
              {organizationCompanyStatus.crm_url && (
                <CiroLink href={organizationCompanyStatus.crm_url}>
                  <div
                    className={classNames(
                      "ciro-v1-flex",
                      "ciro-v1-items-center",
                    )}
                  >
                    CRM Details
                    <span className="ciro-v1-pl-2">
                      <BoxLinkIcon />
                    </span>
                  </div>
                </CiroLink>
              )}
            </div>
          </div>
          <div
            className={classNames(
              [
                "ciro-v1-cursor-pointer",
                "ciro-v1-duration-500",
                "ciro-v1-ease-in-out",
                "ciro-v1-flex",
                "ciro-v1-items-center",
                "ciro-v1-origin-center",
                "ciro-v1-transform",
                "ciro-v1-transition-all",
              ],
              {
                "ciro-v1-rotate-180": expanded,
              },
            )}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <DownArrowIcon stroke="black" />
          </div>
        </div>
        <div
          className={classNames(
            [
              "ciro-v1-ease",
              "ciro-v1-transition-all",
              "ciro-v1-duration-500",
              "ciro-v1-whitespace-pre-line",
              "ciro-v1-text-slate-600",
              "ciro-v1-overflow-y-auto",
            ],
            {
              "ciro-v1-max-h-0": !expanded,
              "ciro-v1-max-h-96": expanded,
            },
          )}
        >
          <div>
            <div className={classNames("ciro-v1-flex")}>
              <BusinessDetailsMergeCrmAccountDetails
                mergeCrmAccount={mergeCrmAccount}
              />
              <div className={classNames("ciro-v1-ml-2", "ciro-v1-flex")}>
                <BusinessDetailsMergeCrmLeadDetails
                  mergeCrmLead={mergeCrmLead}
                />
                {totalMatches && totalMatches > 1 && (
                  <CiroTooltipContainer
                    tooltip={`${totalMatches} CRM records match to this business`}
                  >
                    <CiroPill>{`${totalMatches}`}</CiroPill>
                  </CiroTooltipContainer>
                )}
                <div className={classNames("ciro-v1-ml-2")}>
                  <CiroCRMPill
                    crmStatus={String(organizationCompanyStatus.current_status)}
                    crmConnected={true}
                    foundInCrm={Boolean(organizationCompanyStatus)}
                  />
                </div>
              </div>
            </div>
            <div
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-items-center",
                "ciro-v1-text-stone-400",
                "ciro-v1-text-sm",
                "ciro-v1-pt-1",
              )}
            >
              <BadgeCheckIcon />
              <span className={classNames("ciro-v1-pl-1")}>
                {formatMatchKeys(organizationCompanyStatus?.match_keys || [])}{" "}
                matched
              </span>
            </div>
          </div>
        </div>
      </CiroCard>
    </div>
  );
};

export default BusinessDetailsOrgCrmDetails;

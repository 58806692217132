import CiroButton, { CiroButtonStyleEnum } from "./CiroButton";
import classNames from "classnames";
import CiroCard from "./CiroCard";
import CiroHR from "./CiroHR";
import { PRICING_PAGE_LINK } from "../../utils/consts";

interface ICiroCTACardProps {
  subtitle: string;
  title: string;
}

const CiroCTACard = ({ subtitle, title }: ICiroCTACardProps) => {
  return (
    <CiroCard>
      <div
        className={classNames([
          "ciro-v1-flex",
          "ciro-v1-flex-col",
          "ciro-v1-align-start",
        ])}
      >
        <div
          className={classNames(["ciro-v1-font-semibold", "ciro-v1-text-2xl"])}
        >
          {title}
        </div>
        <div className={classNames(["ciro-v1-pt-2", "ciro-v1-text-slate-600"])}>
          {subtitle}
        </div>
      </div>
      <div className={classNames(["-ciro-v1-mx-8"])}>
        <CiroHR />
      </div>
      <div>
        <CiroButton
          href={PRICING_PAGE_LINK}
          style={CiroButtonStyleEnum.INVERTED}
          customClassName={classNames(["ciro-v1-w-full"])}
          analyticsField="Upgrade CTA"
        >
          Upgrade Now
        </CiroButton>
      </div>
    </CiroCard>
  );
};

export default CiroCTACard;

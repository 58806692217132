import React from "react";
import CiroDropDown from "../shared/CiroDropdown";
import classNames from "classnames";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { gql } from "@apollo/client";
import {
  Maybe,
  PmsProviderFilter_PmsProvidersFragment,
} from "../../__generated__/graphql";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

export const PmsProviderFilter_pmsProviders = gql`
  fragment PmsProviderFilter_pmsProviders on Query {
    pmsProviders
  }
`;

interface IPmsProviderFilterFilterProps {
  blurred?: boolean;
  pmsProviderFilter: string[];
  pmsProviderOptions?: Maybe<PmsProviderFilter_PmsProvidersFragment>;
  resetOffset: () => void;
  setPmsProviderFilter: (v: string[]) => void;
}

function PmsProviderFilter({
  blurred = false,
  pmsProviderFilter,
  pmsProviderOptions,
  resetOffset,
  setPmsProviderFilter,
}: IPmsProviderFilterFilterProps) {
  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={pmsProviderFilter.length ? 1 : 0}
      sectionTitle={
        <div className={classNames("ciro-v1-flex")}>
          <span>Manually Verified Technologies</span>
          <span className={classNames("ciro-v1-pl-2")}>
            <CiroTooltipContainer
              tooltip={
                <div>
                  <div>Verified by Ciro phone teams</div>
                  <div>(Note: Search will use credits)</div>
                </div>
              }
            >
              <FontAwesomeIcon
                icon={faQuestionCircle}
                color="rgba(0,0,0,.55)"
              />
            </CiroTooltipContainer>
          </span>
        </div>
      }
      filterOptions={[
        {
          label: "Include any of:",
          filter: (
            <CiroDropDown
              isMulti
              blurred={blurred}
              options={(pmsProviderOptions?.pmsProviders || []).map(
                (element) => {
                  return {
                    value: String(element),
                    label: String(element),
                  };
                },
              )}
              value={pmsProviderFilter}
              onChange={(newValue) => {
                setPmsProviderFilter(newValue);
                resetOffset();
              }}
            />
          ),
        },
      ]}
      smbFilter
    />
  );
}

export default PmsProviderFilter;

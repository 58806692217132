import { ApolloError, gql } from "@apollo/client";
import Loading from "../../shared/Loading";
import classNames from "classnames";
import EnrichmentStepCardHeader, {
  EnrichmentStepCardHeader_EnrichmentStep,
} from "./EnrichmentStepCardHeader";
import {
  EnrichmentStepCardContainer_EnrichmentStepFragment,
  EnrichmentStepCardFooter_EnrichmentStepFragmentDoc,
  EnrichmentStepCardHeader_EnrichmentStepFragmentDoc,
  Maybe,
} from "../../../__generated__/graphql";
import { useFragment } from "../../../__generated__";
import EnrichmentStepCardFooter, {
  EnrichmentStepCardFooter_EnrichmentStep,
} from "./EnrichmentStepCardFooter";

export const EnrichmentStepCardContainer_EnrichmentStep = gql`
  fragment EnrichmentStepCardContainer_EnrichmentStep on EnrichmentStep {
    id
    ...EnrichmentStepCardHeader_EnrichmentStep
    ...EnrichmentStepCardFooter_EnrichmentStep
  }
  ${EnrichmentStepCardHeader_EnrichmentStep}
  ${EnrichmentStepCardFooter_EnrichmentStep}
`;

interface IEnrichmentStepCardContainerProps {
  isDirty?: boolean;
  loading: boolean;
  error: ApolloError | undefined;
  children: any;
  onSave?: () => void;
  enrichmentStep?: Maybe<EnrichmentStepCardContainer_EnrichmentStepFragment>;
  onClose: () => void;
}

const EnrichmentStepCardContainer = ({
  children,
  error,
  loading,
  isDirty,
  onClose,
  onSave,
  enrichmentStep,
}: IEnrichmentStepCardContainerProps) => {
  const headerEnrichmentStep = useFragment(
    EnrichmentStepCardHeader_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );
  const footerEnrichmentStep = useFragment(
    EnrichmentStepCardFooter_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );

  return (
    <div
      className={classNames(
        "ciro-v1-w-1/2",
        "ciro-v1-ml-4",
        "ciro-v1-h-full",
        "ciro-v1-max-h-screen-minus-16",
        "ciro-v1-rounded-lg",
        "ciro-v1-border-1",
        "ciro-v1-flex",
        "ciro-v1-flex-col",
        "ciro-v1-bg-white",
      )}
    >
      {loading && (
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-col",
            "ciro-v1-justify-center",
            "ciro-v1-h-full",
          )}
        >
          <Loading size="SMALL" />
        </div>
      )}
      {error && (
        <div className={classNames("ciro-v1-whitespace-pre-wrap")}>
          Something went wrong: {error.message}
        </div>
      )}
      {!loading && !error && (
        <>
          <EnrichmentStepCardHeader
            enrichmentStep={headerEnrichmentStep}
            onClose={onClose}
          />
          <div
            className={classNames(
              "ciro-v1-grow",
              "ciro-v1-p-4",
              "ciro-v1-overflow-auto",
            )}
          >
            {children}
          </div>
          {onSave && (
            <EnrichmentStepCardFooter
              cardIsDirty={isDirty}
              onSave={onSave}
              enrichmentStep={footerEnrichmentStep}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EnrichmentStepCardContainer;

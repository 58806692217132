import classNames from "classnames";
import CiroCard from "../shared/CiroCard";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import {
  ACCOUNTS_MAX_NUM_ROWS,
  BULK_SELECTION_LIMIT,
} from "../../routes/accounts/Accounts";
import { useState } from "react";
import { CompaniesFromFiltersInput } from "../../__generated__/graphql";
import { InternalRefetchQueriesInclude } from "@apollo/client";
import CiroByQueryUnlockModal from "./CiroByQueryUnlockModal";

interface CiroUnlockFromFiltersCardProps {
  filteredCompanyQueryVariables: CompaniesFromFiltersInput;
  offset: number;
  totalNumberRecords: number;
  refetchQueries: InternalRefetchQueriesInclude;
}

const CiroUnlockFromFiltersCard = ({
  totalNumberRecords,
  offset,
  filteredCompanyQueryVariables,
  refetchQueries,
}: CiroUnlockFromFiltersCardProps) => {
  const [numRecordsToUnlock, setNumRecordsToUnlock] = useState<null | number>(
    null,
  );
  const passSelectPage = Math.floor(offset / BULK_SELECTION_LIMIT);
  const startIdx = passSelectPage * BULK_SELECTION_LIMIT;

  return (
    <>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-w-full",
          "ciro-v1-justify-center",
          "ciro-v1-mt-4",
        )}
      >
        <div className={classNames("ciro-v1-max-w-sm")}>
          <CiroCard>
            <div
              className={classNames("ciro-v1-font-semibold", "ciro-v1-text-lg")}
            >
              Unlock accounts
            </div>
            <div className={classNames("ciro-v1-py-4")}>
              Some search results have been hidden. Continue below to review the
              number of credits you need to view your search results.
            </div>
            <div className={classNames("ciro-v1-flex", "ciro-v1-justify-end")}>
              {totalNumberRecords > ACCOUNTS_MAX_NUM_ROWS && (
                <CiroButton
                  analyticsField={`review premium data from accounts (25)'}`}
                  onClick={() => setNumRecordsToUnlock(ACCOUNTS_MAX_NUM_ROWS)}
                >
                  Review search results {offset + 1}-
                  {offset + ACCOUNTS_MAX_NUM_ROWS}
                </CiroButton>
              )}
              <span className={classNames("ciro-v1-ml-2")}>
                <CiroButton
                  style={CiroButtonStyleEnum.LOUD}
                  analyticsField={`review premium data from accounts (250)'}`}
                  onClick={() =>
                    setNumRecordsToUnlock(
                      Math.min(totalNumberRecords, BULK_SELECTION_LIMIT),
                    )
                  }
                >
                  Review search results
                  {totalNumberRecords >= BULK_SELECTION_LIMIT &&
                    ` ${startIdx + 1}-${startIdx + BULK_SELECTION_LIMIT}`}
                </CiroButton>
              </span>
            </div>
          </CiroCard>
        </div>
      </div>
      {numRecordsToUnlock && (
        <CiroByQueryUnlockModal
          filteredCompanyQueryVariables={filteredCompanyQueryVariables}
          offset={offset}
          numRecordsToUnlock={numRecordsToUnlock}
          onClose={() => setNumRecordsToUnlock(0)}
          onCompleted={() => setNumRecordsToUnlock(0)}
          premiumDataContentAction="View"
          refetchQueries={refetchQueries}
        />
      )}
    </>
  );
};

export default CiroUnlockFromFiltersCard;

import classNames from "classnames";
import { FormProvider, useFieldArray, useWatch } from "react-hook-form";
import {
  EnrichmentStep,
  EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
  EnrichmentStepCardFiltersList_EnrichmentStepFragment,
  EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
  EnrichmentTechniqueEnum,
} from "../../../../__generated__/graphql";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import EnrichmentStepRichTextContainer, {
  IEnrichmentTemplateVariable,
} from "../EnrichmentStepRichTextEditor/EnrichmentStepRichTextContainer";
import CiroCheckbox from "../../../shared/CiroCheckbox";
import CiroDropDown from "../../../shared/CiroDropdown";
import CiroTooltipContainer from "../../../shared/CiroTooltipContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import EnrichmentStepCardFiltersList, {
  EnrichmentStepCardFiltersListSchema,
  parseEnrichmentStepsForGraphQL,
  parseEnrichmentStepsForList,
} from "../EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import { useFragment } from "../../../../__generated__";
import EnrichmentStepCardContainer from "../EnrichmentStepCardContainer";
import { IEnrichmentStepTechniqueCardProps } from ".";
import useEnrichmentStepForm from "../../../../reactHooks/enrichmentFlow/useEnrichmentStepForm";

export const TEMPLATE_INPUT_KEY = "template";
export const PICKLIST_INPUT_KEY = "picklist";

export const EnrichmentStepTechniqueChatGptCardSchema = yup
  .object({
    selected_input: yup.string().nullable(),
    usePicklist: yup.boolean(),
    picklist: yup
      .array()
      .of(yup.string())
      .when("usePicklist", {
        is: true, // Only when usePicklist is true
        then: (schema: any) =>
          schema.min(1, "Picklist must have at least one item"),
        otherwise: (schema: any) => schema.nullable(),
      }),
    promptTemplate: yup.string().required("Prompt is required"),
    templateVariables: yup.array().of(
      yup.object({
        key: yup.string().required("One of your variables is missing a name"),
        input: yup
          .string()
          // Nullable when sourceEnrichmentStep is just a primitive type
          .nullable(),
        sourceEnrichmentStepId: yup.number().nullable(),
      }),
    ),
    filterEnrichmentSteps: EnrichmentStepCardFiltersListSchema,
  })
  .required()
  .test(
    "enrichmentStepsExist",
    "One of the steps used in your variables has been removed",
    // Note: not using an arrow function here so that the function inherits the `this` context
    function (schema) {
      const templateVariables = schema.templateVariables;
      if (!templateVariables) return;
      for (const { sourceEnrichmentStepId, key } of templateVariables) {
        if (
          key !== TEMPLATE_INPUT_KEY &&
          key !== PICKLIST_INPUT_KEY &&
          !sourceEnrichmentStepId
        ) {
          return this.createError({
            path: `templateVariables`,
            message: "One of the steps used in your variables has been removed",
          });
        }
      }
      return true;
    },
  );

export const getDefaultChatGptValues = (
  enrichmentStep: EnrichmentStep,
  enrichmentStepWithFilters?: EnrichmentStepCardFiltersList_EnrichmentStepFragment,
) => {
  const filterEnrichmentSteps = parseEnrichmentStepsForList(
    enrichmentStepWithFilters,
  );
  const defaultValueWithoutTemplateOrPicklist = {
    name: enrichmentStep?.name,
    usePicklist: enrichmentStep?.parentEnrichmentStepInputs?.filter(
      (input) => input.key === PICKLIST_INPUT_KEY,
    ).length
      ? true
      : false,
    templateVariables:
      enrichmentStep?.parentEnrichmentStepInputs
        ?.filter(
          (input) =>
            input.key !== TEMPLATE_INPUT_KEY &&
            input.key !== PICKLIST_INPUT_KEY,
        )
        .map((parentEnrichmentStepInput) => {
          return {
            key: parentEnrichmentStepInput.key,
            input: parentEnrichmentStepInput?.input
              ? JSON.parse(parentEnrichmentStepInput.input)
              : null,
            sourceEnrichmentStepId:
              parentEnrichmentStepInput.sourceEnrichmentStep?.id,
          };
        }) || [],
    filterEnrichmentSteps: filterEnrichmentSteps,
  };
  const template =
    enrichmentStep?.parentEnrichmentStepInputs
      ?.filter((input) => input.key === TEMPLATE_INPUT_KEY)
      .map((input) => input.input)[0] || "";
  const parsedTemplate = template ? JSON.parse(template) : template;
  const picklist = enrichmentStep?.parentEnrichmentStepInputs
    ?.filter((input) => input.key === PICKLIST_INPUT_KEY && input.input)
    .map((input) => input.input)[0];
  const jsonPicklist = picklist ? (JSON.parse(picklist) as string[]) : [];
  const defaultValue = {
    ...defaultValueWithoutTemplateOrPicklist,
    promptTemplate: parsedTemplate,
    picklist: jsonPicklist,
  };
  return defaultValue;
};

const EnrichmentStepTechniqueChatGptCard = ({
  loading,
  error,
  enrichmentStep,
  confirmUpdateEnrichmentStep,
  onClose,
}: IEnrichmentStepTechniqueCardProps) => {
  const enrichmentStepWithFilters = useFragment(
    EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );

  const getDefaultValues = useCallback(() => {
    return getDefaultChatGptValues(
      enrichmentStep as EnrichmentStep,
      enrichmentStepWithFilters as EnrichmentStepCardFiltersList_EnrichmentStepFragment,
    );
  }, [enrichmentStep, enrichmentStepWithFilters]);

  const formMethods = useEnrichmentStepForm({
    resolver: yupResolver(EnrichmentStepTechniqueChatGptCardSchema),
    defaultValues: getDefaultValues(),
    mode: "onChange",
  });

  const {
    control,
    formState: { isDirty, errors },
    setValue,
    handleSubmit,
    reset,
  } = formMethods;

  const { remove: removeVariableField } = useFieldArray({
    control,
    name: "templateVariables",
  });

  // Ensure that the default values are loaded after the form is reset
  const [defaultValsAreLoaded, setDefaultValsAreLoaded] = useState(false);

  useEffect(() => {
    reset(getDefaultValues());
    if (!loading) {
      setDefaultValsAreLoaded(true);
    } else {
      setDefaultValsAreLoaded(false);
    }
    return () => {
      reset();
    }
  }, [getDefaultValues, reset, loading]);

  const [
    usePicklist,
    picklist,
    promptTemplate,
    templateVariables,
    filterEnrichmentSteps,
  ] = useWatch({
    control,
    name: [
      "usePicklist",
      "picklist",
      "promptTemplate",
      "templateVariables",
      "filterEnrichmentSteps",
    ],
  });

  const variables = useMemo(() => {
    const variables = new Map();
    templateVariables.forEach((variable) => {
      const { key, input, sourceEnrichmentStepId } = variable;
      variables.set(key, {
        stepId: sourceEnrichmentStepId || null,
        stepInput: input || "",
      });
    });

    return variables;
  }, [templateVariables]);

  const highestVariableIndex = templateVariables.length;

  const formatAndSubmitRequest = (data: any) => {
    const picklistInput =
      usePicklist && picklist.length
        ? {
            key: PICKLIST_INPUT_KEY,
            required: true,
            input: JSON.stringify(data.picklist),
            sourceEnrichmentStepId: undefined,
          }
        : null;

    const templateInput = {
      key: TEMPLATE_INPUT_KEY,
      required: true,
      input: JSON.stringify(data.promptTemplate),
      sourceEnrichmentStepId: undefined,
    };

    const variableInputs = data.templateVariables.map((variable: any) => {
      return {
        key: variable.key,
        input: JSON.stringify(variable.input) || null,
        required: true,
        sourceEnrichmentStepId: variable.sourceEnrichmentStepId || undefined,
      };
    });

    confirmUpdateEnrichmentStep({
      data: {
        id: enrichmentStep?.id || null,
        enrichmentStepInput: {
          enrichment_technique: EnrichmentTechniqueEnum.ChatGpt,

          // selected_input refers to the display value (which JSON key is displayed in the column)
          selected_input: data.selected_input || null,
          parentEnrichmentStepInputs: [
            templateInput,
            ...variableInputs,
            picklistInput,
          ].filter(Boolean),
          filterEnrichmentSteps: parseEnrichmentStepsForGraphQL(
            data.filterEnrichmentSteps,
          ),
        },
      },
    });
  };

  return (
    <EnrichmentStepCardContainer
      isDirty={isDirty}
      loading={loading}
      error={error}
      onSave={handleSubmit(formatAndSubmitRequest)}
      enrichmentStep={useFragment(
        EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
        enrichmentStep,
      )}
      onClose={onClose}
    >
      <div>
        <div className={classNames("ciro-v1-font-semibold")}>
          <div
            className={classNames("ciro-v1-flex", "ciro-v1-items-center")}
          ></div>
        </div>
        <EnrichmentStepRichTextContainer
          enrichmentStep={enrichmentStep}
          label="Prompt"
          loading={loading || !defaultValsAreLoaded}
          variables={variables}
          templateError={errors.promptTemplate?.message as string}
          variableErrors={errors.templateVariables}
          setVariables={(
            newVariables: Map<string, IEnrichmentTemplateVariable>,
          ) => {
            let idx = 0;
            for (const [key, { stepId, stepInput }] of newVariables) {
              if (stepId) {
                setValue(
                  `templateVariables.${idx}.sourceEnrichmentStepId`,
                  stepId,
                );
              } else {
                setValue(
                  `templateVariables.${idx}.sourceEnrichmentStepId`,
                  undefined,
                );
              }
              setValue(`templateVariables.${idx}.input`, stepInput);
              setValue(`templateVariables.${idx}.key`, key);
              idx += 1;
            }

            // Remove extraneous variables (e.g., that were deleted)
            for (let i = idx + 1; i < highestVariableIndex; i++) {
              removeVariableField(i);
            }
          }}
          template={promptTemplate}
          setTemplate={(newTemplate: string) => {
            setValue("promptTemplate", newTemplate);
          }}
        />
        <div
          className={classNames(
            "ciro-v1-mt-4",
            "ciro-v1-mb-2",
            "ciro-v1-flex",
            "ciro-v1-flex-row",
            "ciro-v1-items-center",
          )}
        >
          <CiroCheckbox
            checked={usePicklist}
            label={"Limit answers to picklist"}
            labelClassName={classNames("ciro-v1-text-gray-500")}
            onClick={() => {
              if (!usePicklist) {
                setValue("usePicklist", true);
              } else {
                setValue("usePicklist", false);
              }
            }}
          />
          <div className={classNames("ciro-v1-ml-1", "ciro-v1-text-gray-400")}>
            <CiroTooltipContainer
              tooltip={
                <div>
                  Provide a list of categories or industries you'd like Ciro AI
                  to choose from
                </div>
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </CiroTooltipContainer>
          </div>
        </div>
        {usePicklist && (
          <div className={classNames("ciro-v1-py-2")}>
            <CiroDropDown
              formatCreateLabel={(inputValue) =>
                `Create "${inputValue}" option`
              }
              label="Predefined answers"
              error={errors?.picklist?.message as string}
              labelClassName={classNames("ciro-v1-text-gray-500")}
              placeholder="Start typing to create a picklist option..."
              creatable
              isMulti
              options={picklist.map((option) => {
                return { label: option, value: option };
              })}
              value={picklist as any[]}
              isClearable
              onChange={(v) => {
                setValue("picklist", v as any);
              }}
            />
          </div>
        )}
      </div>
      <div className={classNames("ciro-v1-mt-12")}>
        <FormProvider {...formMethods}>
          <EnrichmentStepCardFiltersList
            filterEnrichmentSteps={filterEnrichmentSteps}
          />
        </FormProvider>
      </div>
    </EnrichmentStepCardContainer>
  );
};

export default EnrichmentStepTechniqueChatGptCard;

import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useRef, useState } from "react";
import CiroTextInput from "../../shared/CiroTextInput";
import { gql, useMutation } from "@apollo/client";
import EnrichmentFlowContext from "../../../contexts/EnrichmentFlowContext";
import { EnrichmentStepCardHeader_EnrichmentStepFragment } from "../../../__generated__/graphql";
import CiroTooltipContainer from "../../shared/CiroTooltipContainer";
import enrichmentTechniqueEnumToIcon from "../enrichmentTechniqueEnumToIcon";
import EnrichmentStepCardHeaderExplainerDropdown from "./EnrichmentStepCardHeaderExplainerDropdown";

const EnrichmentStepCardHeader_UpdateEnrichmentStep = gql`
  mutation EnrichmentStepCardHeader_UpdateEnrichmentStep(
    $id: Int!
    $name: String!
    $nameOnly: Boolean
  ) {
    updateEnrichmentStep(
      id: $id
      enrichmentStepInput: { name: $name }
      nameOnly: $nameOnly
    ) {
      id
      name
    }
  }
`;

export const EnrichmentStepCardHeader_EnrichmentStep = gql`
  fragment EnrichmentStepCardHeader_EnrichmentStep on EnrichmentStep {
    id
    enrichmentTechniqueDetails {
      description
      name
    }
    name
  }
`;

interface IEnrichmentStepCardHeaderProps {
  onClose: () => void;
  enrichmentStep?: EnrichmentStepCardHeader_EnrichmentStepFragment | null;
}

const EnrichmentStepCardHeader = ({
  onClose,
  enrichmentStep,
}: IEnrichmentStepCardHeaderProps) => {
  const [editingTitle, setEditingTitle] = useState(false);
  const inputRef = useRef(
    null,
  ) as React.MutableRefObject<HTMLInputElement | null>;
  const [updateEnrichmentStep] = useMutation(
    EnrichmentStepCardHeader_UpdateEnrichmentStep,
  );
  const {
    EnrichmentFlow_refetchEnrichmentFlow,
    newEnrichmentTitle,
    setNewEnrichmentTitle,
    newEnrichmentTechniqueDetails,
  } = useContext(EnrichmentFlowContext);

  const enrichmentTechniqueDetails =
    enrichmentStep?.enrichmentTechniqueDetails || newEnrichmentTechniqueDetails;

  const Icon =
    enrichmentTechniqueEnumToIcon[enrichmentTechniqueDetails?.name || ""];

  const [title, setTitle] = useState(
    enrichmentStep?.name || newEnrichmentTitle || "",
  );

  const handleSubmit = () => {
    setEditingTitle(false);

    if (title === enrichmentStep?.name) {
      return;
    }

    if (title === "") {
      setTitle(enrichmentStep?.name || "");
      return;
    }

    if (enrichmentStep) {
      // Update existing step
      updateEnrichmentStep({
        variables: {
          id: enrichmentStep.id,
          name: title,
          nameOnly: true,
        },
        onCompleted: () => {
          EnrichmentFlow_refetchEnrichmentFlow();
        },
      });
    } else {
      // step doesn't exist yet
      setNewEnrichmentTitle(title);
    }
  };

  useEffect(() => {
    setTitle(enrichmentStep?.name || newEnrichmentTitle || "");
  }, [enrichmentStep?.name, newEnrichmentTitle]);

  useEffect(() => {
    // Needs to be done after the page is refreshed since a hidden input can't be focused
    if (editingTitle) {
      inputRef?.current?.focus();
    }
  }, [editingTitle]);

  const techniqueIconComponent = Icon && (
    <Icon className={classNames("ciro-v1-w-5", "ciro-v1-h-5")} />
  );

  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-justify-between",
        "ciro-v1-p-4",
        "ciro-v1-border-b-1",
      )}
    >
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-flex-row",
          "ciro-v1-items-center",
          "ciro-v1-gap-1",
        )}
      >
        {
          <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
            {!editingTitle && (
              <CiroButton
                analyticsField="Edit enrichment step title"
                style={CiroButtonStyleEnum.UNSTYLED}
                onClick={() => {
                  setEditingTitle(true);
                }}
              >
                <div
                  className={classNames(
                    "ciro-v1-py-1.5",
                    "ciro-v1-px-2",
                    "ciro-v1-rounded-md",
                    "ciro-v1-border-1",
                    "ciro-v1-border-white",
                    "hover:ciro-v1-border-gray-300",
                    "ciro-v1-flex",
                    "ciro-v1-items-center",
                    "ciro-v1-w-full",
                  )}
                >
                  <CiroTooltipContainer
                    tooltip={enrichmentTechniqueDetails?.description}
                  >
                    {techniqueIconComponent}
                  </CiroTooltipContainer>
                  <span className={classNames("ciro-v1-pl-2")}>
                    {title || enrichmentTechniqueDetails?.description || ""}
                  </span>
                </div>
              </CiroButton>
            )}
            {editingTitle && (
              <CiroTextInput
                icon={techniqueIconComponent}
                ref={inputRef}
                value={title}
                onBlur={handleSubmit}
                onChange={(e) => setTitle(e.target.value)}
              />
            )}
          </div>
        }
        <div className={classNames("ciro-v1-mt-1")}>
          <EnrichmentStepCardHeaderExplainerDropdown
            enrichmentTechnique={enrichmentTechniqueDetails?.name}
          />
        </div>
      </div>
      {
        <CiroButton
          analyticsField="Close Enrichment Step Card"
          onClick={onClose}
          style={CiroButtonStyleEnum.UNSTYLED}
        >
          <XMarkIcon
            className={classNames("ciro-v1-w-5", "ciro-v1-text-gray-400")}
          />
        </CiroButton>
      }
    </div>
  );
};

export default EnrichmentStepCardHeader;

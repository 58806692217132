import { CustomCellRendererProps } from "@ag-grid-community/react";
import { IEnrichmentExecutionValue } from ".";

interface IExecutionCell_SerpApiProps extends CustomCellRendererProps {
  value: IEnrichmentExecutionValue | null;
  setCellDetailsValue: (value: any) => void;
}

const ExecutionCell_SerpApi = ({
  value,
  setCellDetailsValue,
}: IExecutionCell_SerpApiProps) => {
  let parsedValue = value?.output && JSON.parse(value.output);
  const originalParsedValue = parsedValue;

  let returnValue = null;

  if (
    parsedValue &&
    parsedValue["organic_results"] &&
    parsedValue["organic_results"].length > 0
  ) {
    returnValue = parsedValue["organic_results"][0]["title"];
  }

  return (
    <div>
      <div
        onClick={() => {
          setCellDetailsValue(originalParsedValue);
        }}
      >
        {returnValue}
      </div>
    </div>
  );
};

export default ExecutionCell_SerpApi;

import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import { useCallback, useContext, useState } from "react";
import fileDownload from "js-file-download";
import toast from "react-hot-toast";
import AppContext from "../../contexts/AppContext";
import CiroSpinner from "../shared/CiroSpinner";

const ChromeExtensionNumbersDownloadButton = ({ ids }: { ids: number[] }) => {
  const [isExporting, setIsExporting] = useState(false);
  const { accessToken } = useContext(AppContext);

  const downloadPhoneNumbersRequestTransaction = useCallback(async () => {
    try {
      setIsExporting(true);
      const response = await fetch(
        "/export-phone-numbers-request-transaction",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ids,
          }),
        },
      );
      const blob = await response.blob();
      fileDownload(blob, `Ciro Phone Numbers.csv`);
      toast.success("CSV downloaded successfully");
    } catch (error) {
      console.error("Error during CSV download:", error);
      toast.error("Error during CSV download");
    } finally {
      setIsExporting(false);
    }
  }, [ids, accessToken]);

  return (
    <CiroButton
      analyticsField="Download Phone Numbers Request Transaction"
      style={CiroButtonStyleEnum.UNSTYLED}
      onClick={downloadPhoneNumbersRequestTransaction}
      disabled={isExporting}
    >
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-items-center",
          "ciro-v1-border-1",
          "ciro-v1-rounded-lg",
          "ciro-v1-px-4",
          "ciro-v1-py-2",
          "ciro-v1-text-sm",
          "ciro-v1-text-neutral-700",
          isExporting && "ciro-v1-bg-gray-300",
          "hover:ciro-v1-bg-gray-300",
        )}
      >
        {isExporting ? (
          <div
            className={classNames(
              "ciro-v1-mr-1",
              "ciro-v1-flex",
              "ciro-v1-items-center",
            )}
          >
            <CiroSpinner loading={isExporting} />
          </div>
        ) : (
          <CloudArrowDownIcon
            className={classNames(
              "ciro-v1-w-6",
              "ciro-v1-h-6",
              "ciro-v1-mr-2",
              "ciro-v1-text-neutral-600",
            )}
          />
        )}
        <div className={classNames("ciro-v1-text-neutral-600")}>
          {isExporting ? "Downloading numbers" : "Download numbers"}
        </div>
      </div>
    </CiroButton>
  );
};

export default ChromeExtensionNumbersDownloadButton;

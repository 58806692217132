import classNames from "classnames";
import HomeProfileCard from "../../components/home/HomeProfileCard";
import HomeStatsOverview, {
  HomeStatsOverview_PhoneTransactions,
} from "../../components/home/HomeStatsOverview";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroTitle from "../../components/shared/CiroTitle";
import CiroButton from "../../components/shared/CiroButton";
import { useRef, useState } from "react";
import EnrichmentFlowUploadCSVPreviewModal from "../../components/enrichmentFlow/EnrichmentFlowUploadCSVPreviewModal";
import {
  ChevronRightIcon,
  DocumentArrowUpIcon,
} from "@heroicons/react/24/outline";
import {
  LinkCRMButton,
  LinkCRMButton_UserAccount,
} from "../../components/link-crm/LinkCRMButton";
import { gql, useQuery } from "@apollo/client";
import ChromeExtensionCard, {
  ChromeExtensionCard_Fragment,
} from "../../components/home/ChromeExtensionCard";
import HomeWorkflowsTable from "../../components/home/HomeWorkflowsTable";
import HomeListsTable from "../../components/home/HomeListsTable";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import EnrichedContactsTable, {
  EnrichedContactsTable_Fragment,
} from "../../components/home/EnrichedContactsTable";

export const Home_PhoneNumbersOverview = gql`
  query Home_PhoneNumbersOverview {
    ...HomeStatsOverview_PhoneTransactions
    ...ChromeExtensionCard_Fragment
    userAccount {
      ...LinkCRMButton_UserAccount
    }
    organization {
      ...EnrichedContactsTable_Fragment
    }
  }
  ${HomeStatsOverview_PhoneTransactions}
  ${ChromeExtensionCard_Fragment}
  ${LinkCRMButton_UserAccount}
  ${EnrichedContactsTable_Fragment}
`;

const Home = () => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { data, loading } = useQuery(Home_PhoneNumbersOverview);

  return (
    <CiroContainer className="ciro-v1-bg-neutral-100">
      {!loading ? (
        <>
          <HomeProfileCard />
          <HomeStatsOverview stats={data} />
          {!loading &&
            !(
              data?.enrichmentFlows.count > 0 ||
              data?.phoneNumbersRecoveredCount > 0
            ) && (
              <div className={classNames("ciro-v1-p-5", "ciro-v1-w-full")}>
                <CiroTitle title="Get started with Ciro" />
                <div
                  className={classNames(
                    "ciro-v1-flex",
                    "ciro-v1-gap-5",
                    "ciro-v1-mt-5",
                    "ciro-v1-w-full",
                  )}
                >
                  <CiroButton
                    analyticsField="Homepage upload csv"
                    onClick={() => hiddenFileInput.current!.click()}
                    customFlexClass={classNames(
                      "ciro-v1-justify-start",
                      "ciro-v1-items-start",
                      "ciro-v1-w-full",
                    )}
                  >
                    <div className={classNames("ciro-v1-w-full")}>
                      <div
                        className={classNames(
                          "ciro-v1-flex",
                          "ciro-v1-pb-5",
                          "ciro-v1-justify-between",
                        )}
                      >
                        <DocumentArrowUpIcon
                          className={classNames(
                            "ciro-v1-w-10",
                            "ciro-v1-border-2",
                            "ciro-v1-p-1",
                            "ciro-v1-m-1",
                            "ciro-v1-rounded-lg",
                            "ciro-v1-text-green-600",
                          )}
                        />
                        <ChevronRightIcon
                          className={classNames(
                            "ciro-v1-text-orange-500",
                            "ciro-v1-w-5",
                          )}
                        />
                      </div>
                      <div className={classNames("ciro-v1-mt-auto")}>
                        <CiroTitle
                          title="Upload CSV"
                          size="small"
                          subtitle="Enrich a CSV using Ciro’s data connectors."
                          subtitleClassName="ciro-v1-text-left"
                        />
                      </div>
                    </div>
                  </CiroButton>
                  <LinkCRMButton userAccount={data?.userAccount} homeButton />
                </div>
              </div>
            )}
          <ChromeExtensionCard
            chromeExtensionTransactions={data?.chromeExtensionTransactions}
          />
          {data?.organization.enrichedContactRequests.length > 0 && (
            <div
              className={classNames(
                "ciro-v1-w-full",
                "ciro-v1-mt-5",
                "ciro-v1-px-5",
              )}
            >
              <CiroTitle title="My enriched contacts" size="small" />
              <EnrichedContactsTable
                enrichedContacts={data?.organization.enrichedContactRequests}
                itemsPerPage={5}
              />
            </div>
          )}
        </>
      ) : (
        <div className={classNames("ciro-v1-w-full")}>
          <SkeletonLoading numSkeletons={1} skeletonHeight={"7rem"} />
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-flex-row",
              "ciro-v1-justify-between",
              "ciro-v1-gap-4",
              "ciro-v1-mt-4",
              "ciro-v1-w-full",
            )}
          >
            <div className={classNames("ciro-v1-w-full")}>
              <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
            </div>
            <div className={classNames("ciro-v1-w-full")}>
              <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
            </div>
            <div className={classNames("ciro-v1-w-full")}>
              <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
            </div>
          </div>
          <SkeletonLoading numSkeletons={1} skeletonHeight={"7rem"} />
          <div className={classNames("ciro-v1-w-full", "ciro-v1-mt-5")}>
            <SkeletonLoading numSkeletons={5} skeletonHeight={"2rem"} />
          </div>
        </div>
      )}
      <HomeWorkflowsTable />
      <HomeListsTable newList={() => hiddenFileInput.current!.click()} />
      <EnrichmentFlowUploadCSVPreviewModal
        csvFile={csvFile}
        onClose={() => {
          setCsvFile(null);
        }}
      />
      <input
        type="file"
        onChange={(event) => {
          const fileUploaded = event.target.files![0];
          setCsvFile(fileUploaded);
          event.target.value = "";
        }}
        ref={hiddenFileInput}
        style={{ display: "none" }}
      />
    </CiroContainer>
  );
};

export default Home;

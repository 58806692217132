import { useCallback, useContext, useRef, useState } from "react";
import classNames from "classnames";
import CiroTextInput from "../../CiroTextInput";
import SearchIcon from "../../../../assets/img/icons/SearchIcon";
import FilterContext from "../../../../contexts/FilterContext";
import { TextTypeFilterEnum } from "../../../../reactHooks/filters/accounts/useAccountFilters";
import CiroSearchBarDropdownOptions from "./CiroSearchBarDropdownOptions";
import { useLogging } from "../../../../utils/loggingUtil";

interface IFilterSearchBarProps {
  canSearchWebPageContent: boolean;
  setShowFiltersFromSemanticSearchModal: (v: boolean) => void;
  setTextFilter: (v: string) => void;
  setTextTypeFilter: (v: TextTypeFilterEnum | null) => void;
  textFilter: string;
  textTypeFilterWithDefault: TextTypeFilterEnum | null;
  resetOffset: () => void;
}

const KEYWORD_SEARCH_PLACEHOLDER = "Search by name, website or phone";
const WEB_SEARCH_PLACEHOLDER = "Search by website page content";
const SEMANTIC_SEARCH_PLACEHOLDER = "Describe in natural language";
const SEMANTIC_SEARCH_SUGGESTIONS = [
  "Consumer tech companies",
  "Companies that would buy wet lab equipment",
  "San Diego car washes",
  "Global law firms",
];

const CiroSearchBar = ({
  canSearchWebPageContent,
  setShowFiltersFromSemanticSearchModal,
  setTextFilter,
  setTextTypeFilter,
  textFilter,
  textTypeFilterWithDefault,
  resetOffset,
}: IFilterSearchBarProps) => {
  let initialTypeaheadResults: string[] = textFilter ? [textFilter] : [];
  const { setSemanticSearchQuery } = useContext(FilterContext);
  const { logAccountSearch } = useLogging();

  if (
    !textFilter &&
    textTypeFilterWithDefault === TextTypeFilterEnum.SEMANTIC
  ) {
    initialTypeaheadResults = SEMANTIC_SEARCH_SUGGESTIONS;
  }
  const [typeaheadResults, setTypeaheadResults] = useState<string[]>(
    initialTypeaheadResults,
  );
  const [isSelected, setIsSelected] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [searchBarText, setSearchBarText] = useState(textFilter);
  const [dropdownIndex, setDropdownIndex] = useState(-1);
  const isClicking = useRef(false);

  const showTypeaheadSuggestions =
    typeaheadResults.length !== 0 &&
    (!isSelected ||
      (isFocused && textTypeFilterWithDefault === TextTypeFilterEnum.SEMANTIC));

  const handleTypeahead = useCallback(
    async (query: string) => {
      setIsSelected(false);
      setSearchBarText(query);
      setDropdownIndex(-1);

      if (!query) {
        if (textTypeFilterWithDefault !== TextTypeFilterEnum.SEMANTIC) {
          setTypeaheadResults([]);
        } else {
          setTypeaheadResults(SEMANTIC_SEARCH_SUGGESTIONS);
        }
        return;
      }

      if (textTypeFilterWithDefault === TextTypeFilterEnum.SEMANTIC) {
        setTypeaheadResults([]);
      }
    },
    [textTypeFilterWithDefault],
  );

  const triggerSearch = useCallback(
    (selectedIndex: number) => {
      setIsSelected(true);
      if (
        !textTypeFilterWithDefault ||
        textTypeFilterWithDefault === TextTypeFilterEnum.SEMANTIC
      ) {
        setTextTypeFilter(TextTypeFilterEnum.SEMANTIC);
      }

      if (textTypeFilterWithDefault !== TextTypeFilterEnum.SEMANTIC) {
        if (selectedIndex === -1) {
          setTextFilter(searchBarText);
        } else {
          const selectedOption = typeaheadResults[selectedIndex];
          setSearchBarText(selectedOption);
          setTextFilter(typeaheadResults[selectedIndex]);
        }
      } else {
        let semanticSearchQuery: string;
        if (selectedIndex === -1) {
          semanticSearchQuery = searchBarText;
        } else {
          const selectedOption = typeaheadResults[selectedIndex];
          setSearchBarText(selectedOption);
          semanticSearchQuery = selectedOption;
        }
        if (semanticSearchQuery) {
          setSemanticSearchQuery(semanticSearchQuery);
          setShowFiltersFromSemanticSearchModal(true);
        }
      }
      resetOffset();
      setIsFocused(false);
      logAccountSearch(searchBarText, textTypeFilterWithDefault);
    },
    [
      resetOffset,
      searchBarText,
      setSemanticSearchQuery,
      setShowFiltersFromSemanticSearchModal,
      setTextFilter,
      setTextTypeFilter,
      textTypeFilterWithDefault,
      typeaheadResults,
      logAccountSearch,
    ],
  );

  const onKeyDown = useCallback(
    (e: any) => {
      if (e.key === "ArrowUp") {
        setDropdownIndex(Math.max(dropdownIndex - 1, -1));
      }

      if (e.key === "ArrowDown") {
        setDropdownIndex(
          Math.min(dropdownIndex + 1, typeaheadResults.length - 1),
        );
      }

      // Clicking enter without choosing a dropdown typeahead option should search free text.
      if (e.key === "Enter") {
        triggerSearch(dropdownIndex);
      }
    },
    [dropdownIndex, triggerSearch, typeaheadResults.length],
  );

  let placeholderText = KEYWORD_SEARCH_PLACEHOLDER;
  if (textTypeFilterWithDefault === TextTypeFilterEnum.WEB) {
    placeholderText = WEB_SEARCH_PLACEHOLDER;
  } else if (textTypeFilterWithDefault === TextTypeFilterEnum.SEMANTIC) {
    placeholderText = SEMANTIC_SEARCH_PLACEHOLDER;
  }

  return (
    <div
      onBlur={() => {
        if (!isClicking.current) {
          setTypeaheadResults([]);
        }
      }}
    >
      <div className={classNames("ciro-v1-flex", "ciro-v1-flex-row")}>
        <CiroSearchBarDropdownOptions
          canSearchWebPageContent={canSearchWebPageContent}
          setTextTypeFilter={setTextTypeFilter}
          textTypeFilter={textTypeFilterWithDefault}
        />
        <div
          className={classNames(
            "ciro-v1-border-r-0",
            "ciro-v1-bg-white",
            "ciro-v1-h-[42px]",
            "ciro-v1-py-1",
            "ciro-v1-w-0",
          )}
        >
          <div
            className={classNames(
              "ciro-v1-right-0",
              "ciro-v1-top-1/2",
              "ciro-v1-translate-y-2",
              "ciro-v1-h-3/5",
              "ciro-v1-border-r",
              "ciro-v1-border-gray-300",
            )}
          ></div>
        </div>
        <div
          className={classNames(
            "ciro-v1-border-l-0",
            "ciro-v1-border-r-0",
            "ciro-v1-flex",
            "ciro-v1-items-center",
            "ciro-v1-bg-white",
            "ciro-v1-border-t-gray-300",
            "ciro-v1-border-b-gray-300",
            "ciro-v1-border-y-1",
            "ciro-v1-pl-4",
          )}
        >
          <span className="ciro-v1-flex">
            <SearchIcon />
          </span>
        </div>
        <div className={classNames("ciro-v1-w-full")}>
          <CiroTextInput
            containerClassName={classNames(
              "ciro-v1-h-11",
              "ciro-v1-border-l-0",
              "ciro-v1-rounded-l-none",
            )}
            largeBorderRadius
            placeholder={`${placeholderText}...`}
            value={searchBarText}
            onKeyDown={onKeyDown}
            className={classNames("ciro-v1-text-ellipsis")}
            onFocus={() => {
              setIsFocused(true);
              if (textTypeFilterWithDefault === TextTypeFilterEnum.SEMANTIC) {
                setTypeaheadResults(SEMANTIC_SEARCH_SUGGESTIONS);
              }
            }}
            onBlur={() => {
              if (!isClicking.current) {
                setIsFocused(false);
              }
            }}
            onMouseDown={() => (isClicking.current = true)}
            onMouseUp={() => (isClicking.current = false)}
            onChange={(event) => {
              handleTypeahead(event.target.value);
            }}
          />
        </div>
      </div>
      <ul
        className={classNames([
          "ciro-v1-absolute",
          "ciro-v1-bg-white",
          "ciro-v1-p-0",
          "ciro-v1-rounded-md",
          "ciro-v1-shadow-lg",
          "ciro-v1-z-20",
          "ciro-v1-w-1/2",
          "sm:ciro-v1-w-1/4",
        ])}
      >
        {showTypeaheadSuggestions &&
          typeaheadResults.map((option, idx) => (
            <li
              key={option}
              className={classNames(
                [
                  "ciro-v1-cursor-pointer",
                  "hover:ciro-v1-bg-gray-100",
                  "ciro-v1-px-3",
                  "ciro-v1-py-2",
                  "ciro-v1-text-left",
                ],
                {
                  "ciro-v1-bg-gray-200": dropdownIndex === idx,
                  "hover:ciro-v1-text-black": dropdownIndex === idx,
                },
              )}
              onMouseDown={() => (isClicking.current = true)}
              onMouseUp={() => (isClicking.current = false)}
              onClick={(e) => {
                e.stopPropagation();
                triggerSearch(idx);
              }}
            >
              {option}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CiroSearchBar;

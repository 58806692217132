import React from "react";
import classNames from "classnames";

function Title({ size, children }: { size: string; children: any }) {
  return (
    <h1
      className={classNames([
        "ciro-v1-text-gray-900",
        "ciro-v1-font-medium",
        "ciro-v1-leading-9",
        {
          "ciro-v1-text-xl": size === "small",
          "ciro-v1-text-3xl": size === "medium",
          "ciro-v1-text-4xl": size === "large",
          "ciro-v1-leading-6": size === "small",
          "ciro-v1-leading-9": size === "medium",
          "ciro-v1-leading-12": size === "large",
        },
      ])}
    >
      {children}
    </h1>
  );
}

function Subtitle({
  size,
  children,
  className,
}: {
  size: string;
  children: any;
  className: string;
}) {
  return (
    <h2
      className={classNames([
        "ciro-v1-text-gray-500",
        "ciro-v1-font-normal",
        "ciro-v1-leading-6",
        "ciro-v1-text-sm",
        className,
        {
          "ciro-v1-text-sm": size === "small",
          "ciro-v1-text-base": size === "medium",
          "ciro-v1-text-lg": size === "large",
          "ciro-v1-leading-3": size === "small",
          "ciro-v1-leading-6": size === "medium",
          "ciro-v1-leading-9": size === "large",
        },
      ])}
    >
      {children}
    </h2>
  );
}

export default function CiroTitle({
  title,
  icon,
  subtitle,
  size = "medium",
  subtitleClassName = "",
}: {
  title: any;
  icon?: any;
  subtitle?: any;
  size?: "tiny" | "small" | "medium" | "large";
  subtitleClassName?: string;
}) {
  return (
    <div>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-flex-row",
          "ciro-v1-items-center",
          "ciro-v1-mb-1",
        )}
      >
        {icon && (
          <div
            className={classNames("ciro-v1-mr-2", "ciro-v1-text-orange-600")}
          >
            {icon}
          </div>
        )}
        <Title size={size}>{title}</Title>
      </div>
      {subtitle && (
        <Subtitle size={size} className={subtitleClassName}>
          {subtitle}
        </Subtitle>
      )}
    </div>
  );
}

import classNames from "classnames";
import StarsIcon from "../../assets/img/icons/StarsIcon";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { useState } from "react";
import CiroModal from "../shared/CiroModal";
import XIcon from "../../assets/img/icons/XIcon";
import CiroTextInput from "../shared/CiroTextInput";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import pluralize from "pluralize";
import {
  AccountsEnrichButton_CreateFlowMutation,
  AccountsEnrichButton_CreateFlowMutationVariables,
  AccountsEnrichButton_EnrichmentFlowsQuery,
  AccountsEnrichButton_PermissionsFragment,
} from "../../__generated__/graphql";
import toast from "react-hot-toast";
import Loading from "../shared/Loading";
import CiroTemplateModal from "../shared/CiroTemplateModal";

export const AccountsEnrichButton_CreateFlow = gql`
  mutation AccountsEnrichButton_CreateFlow(
    $initializationFromPksInput: InitializationFromPksInput
    $name: String!
    $template: String
  ) {
    createEnrichmentFlow(
      initializationFromPksInput: $initializationFromPksInput
      name: $name
      template: $template
    ) {
      enrichmentFlow {
        id
        enrichmentSteps {
          id
        }
      }
      truncated
      success
      error
    }
  }
`;

export const AccountsEnrichButton_Permissions = gql`
  fragment AccountsEnrichButton_Permissions on Permissions {
    enrichmentAllowedRowLimit
    maxEnrichmentTables
  }
`;

export const AccountsEnrichButton_enrichmentFlows = gql`
  query AccountsEnrichButton_enrichmentFlows {
    enrichmentFlows {
      count
    }
  }
`;

interface IAccountsEnrichButton {
  numberChecked: number;
  selectedCompanyPks: string[];
  semanticSearchQuery: string | null;
  permissions: AccountsEnrichButton_PermissionsFragment;
  setCheckedCompanies: (v: Set<string>) => void;
}
const EnrichmentFlowCreateFlowFormSchema = yup.object({
  flowName: yup.string().required("Flow name is required"),
});

const AccountsEnrichButton = ({
  numberChecked,
  selectedCompanyPks,
  semanticSearchQuery,
  setCheckedCompanies,
  permissions,
}: IAccountsEnrichButton) => {
  const [enrichAccountsModalOpen, setEnrichAccountsModalOpen] = useState(false);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [enrichAccountsFlowLoading, setEnrichAccountsFlowLoading] =
    useState(false);
  const [templateSelected, setTemplateSelected] = useState<string | null>(null);

  const [createEnrichmentFlow] = useMutation<
    AccountsEnrichButton_CreateFlowMutation,
    AccountsEnrichButton_CreateFlowMutationVariables
  >(AccountsEnrichButton_CreateFlow);

  const {
    data: enrichmentFlowCountData,
    refetch: AccountsEnrichButton_refetchEnrichmentFlows,
  } = useQuery<AccountsEnrichButton_EnrichmentFlowsQuery>(
    AccountsEnrichButton_enrichmentFlows,
  );

  const templateNav = () => {
    return `${templateSelected ? `?template=${templateSelected}` : ""}`;
  };

  const isEnabled =
    enrichmentFlowCountData?.enrichmentFlows?.count != null &&
    permissions?.maxEnrichmentTables != null &&
    enrichmentFlowCountData?.enrichmentFlows?.count <
      permissions?.maxEnrichmentTables;

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EnrichmentFlowCreateFlowFormSchema),
    defaultValues: {
      flowName: "",
    },
  });

  return (
    <>
      <CiroTemplateModal
        isOpen={templateModalOpen}
        onClose={() => {
          setTemplateModalOpen(false);
        }}
        setTemplate={setTemplateSelected}
        onTemplateSelected={() => {
          setTemplateModalOpen(false);
          setEnrichAccountsModalOpen(true);
        }}
        accountsSelected={numberChecked}
      />
      {enrichAccountsModalOpen && (
        <CiroModal
          isOpen={enrichAccountsModalOpen}
          onClose={() => setEnrichAccountsModalOpen(false)}
        >
          {enrichAccountsFlowLoading && (
            <div>
              <Loading size="SMALL" />
            </div>
          )}
          {!enrichAccountsFlowLoading && (
            <div>
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-text-2xl",
                  "ciro-v1-items-center",
                  "ciro-v1-justify-between",
                  "ciro-v1-font-bold",
                  "ciro-v1-text-black",
                )}
              >
                <div>Enrich Accounts</div>
                <span
                  onClick={() => setEnrichAccountsModalOpen(false)}
                  className={classNames("ciro-v1-cursor-pointer")}
                >
                  <XIcon />
                </span>
              </div>
              <div
                className={classNames(
                  "ciro-v1-mb-4",
                  "ciro-v1-text-neutral-600",
                  "ciro-v1-text-sm",
                  "ciro-v1-font-light",
                )}
              >
                <div>
                  {numberChecked} {pluralize("Account")} selected. Append
                  contacts, emails, and web scraped data.
                </div>
              </div>
              <CiroTextInput
                error={errors.flowName?.message}
                label="Give your list a name"
                showErrorAsBorder={true}
                placeholder="Hot 'n Spicy Leads"
                register={register("flowName")}
              />
              <div className={classNames("ciro-v1-mt-8", "ciro-v1-mb-2")}>
                <CiroButton
                  analyticsField={"clickedEnrichmentsImportButton"}
                  customClassName="ciro-v1-w-full"
                  disabled={Boolean(errors.flowName)}
                  onClick={handleSubmit((data) => {
                    setEnrichAccountsFlowLoading(true);
                    createEnrichmentFlow({
                      variables: {
                        name: data.flowName,
                        initializationFromPksInput: {
                          pks: selectedCompanyPks,
                          semanticSearchToSortBy: semanticSearchQuery,
                        },
                        template: templateSelected,
                      },
                      onCompleted: async (data) => {
                        if (data?.createEnrichmentFlow?.success) {
                          navigate(
                            `/lists/${data?.createEnrichmentFlow?.enrichmentFlow?.id}${
                              data?.createEnrichmentFlow?.truncated
                                ? `?truncated=${permissions?.enrichmentAllowedRowLimit}&`
                                : ""
                            }${templateNav()}`,
                          );
                          setCheckedCompanies(new Set());
                          await AccountsEnrichButton_refetchEnrichmentFlows();
                        } else {
                          toast.error("Error creating enrichment table");
                        }
                        setEnrichAccountsFlowLoading(false);
                      },
                      onError(error) {
                        toast.error("Error creating enrichment table");
                        setEnrichAccountsFlowLoading(false);
                      },
                    });
                  })}
                  style={CiroButtonStyleEnum.LOUD}
                >
                  Create
                </CiroButton>
              </div>
            </div>
          )}
        </CiroModal>
      )}
      <div className={classNames("ciro-v1-pr-2")}>
        <CiroButton
          customPaddingY="ciro-v1-py-2"
          analyticsField={"enrichFromAccountsButton"}
          customClassName={classNames(
            "ciro-v1-shadow-sm",
            "ciro-v1-rounded-md",
          )}
          disabled={!isEnabled || numberChecked === 0}
          disabledTooltip={
            !isEnabled
              ? "You have reached the maximum number of enrichment tables. Delete an existing table or upgrade your account to create more."
              : "Select at least one account to enrich"
          }
          onClick={() => {
            setTemplateModalOpen(true);
          }}
        >
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-flex-row",
              "ciro-v1-gap-2",
            )}
          >
            <StarsIcon />
            Enrich
          </div>
        </CiroButton>
      </div>
    </>
  );
};

export default AccountsEnrichButton;

import classNames from "classnames";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import CiroButton from "../../shared/CiroButton";

const CrmIntegrationOption = ({
  icon,
  title,
  onClick,
}: {
  icon: JSX.Element;
  title: string;
  onClick: () => void;
}) => {
  return (
    <CiroButton
      onClick={onClick}
      analyticsField={"Choose Integration Option"}
      analyticsProps={{
        integrationOption: title,
      }}
      customClassName={classNames(
        "ciro-v1-rounded-md",
        "ciro-v1-border-1",
        "ciro-v1-border-neutral-200",
        "ciro-v1-p-2",
        "ciro-v1-text-md",
        "ciro-v1-text-neutral-600",
        "ciro-v1-mt-4",
        "ciro-v1-flex",
        "ciro-v1-justify-between",
        "ciro-v1-items-center",
        "ciro-v1-w-full",
      )}
    >
      <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
        <span className={classNames("ciro-v1-mr-2")}>{icon}</span>
        {title}
      </div>
      <div className="ciro-v1-text-orange-500">
        <ChevronRightIcon className={classNames("ciro-v1-h-4")} />
      </div>
    </CiroButton>
  );
};

export default CrmIntegrationOption;

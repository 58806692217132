import React from "react";
import { gql } from "@apollo/client";
import CiroDropDown from "../shared/CiroDropdown";
import { ExportedCompaniesFilterOptionsEnum } from "../../__generated__/graphql";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

const EXPORTED_COMPANIES_ENUM_COPY = {
  [ExportedCompaniesFilterOptionsEnum.ExportedCompanies]: "Already exported",
  [ExportedCompaniesFilterOptionsEnum.NotExportedCompanies]: "Not yet exported",
};

interface IExportedCompaniesFilterProps {
  blurred?: boolean;
  exportedCompaniesFilter: ExportedCompaniesFilterOptionsEnum | null;
  setExportedCompaniesFilter: (
    v: ExportedCompaniesFilterOptionsEnum | null,
  ) => void;
  resetOffset: () => void;
}

export const ExportedCompaniesFilter_collections = gql`
  fragment ExportedCompaniesFilter_collections on Query {
    collections {
      id
      name
    }
  }
`;

function ExportedCompaniesFilter({
  blurred,
  exportedCompaniesFilter,
  setExportedCompaniesFilter,
  resetOffset,
}: IExportedCompaniesFilterProps) {
  const filterOptions = Object.values(ExportedCompaniesFilterOptionsEnum).map(
    (element) => {
      return {
        value: element,
        label: EXPORTED_COMPANIES_ENUM_COPY[element],
      };
    },
  );

  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={exportedCompaniesFilter ? 1 : 0}
      sectionTitle="Exported Accounts"
      filterOptions={[
        {
          filter: (
            <CiroDropDown
              blurred={blurred}
              options={filterOptions}
              value={exportedCompaniesFilter}
              onChange={(newValue) => {
                setExportedCompaniesFilter(newValue);
                resetOffset();
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default ExportedCompaniesFilter;

function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): (...funcArgs: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | undefined;

  return function (...args: Parameters<T>): void {
    const later = () => {
      timeout = undefined;
      func(...args);
    };

    if (timeout !== undefined) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);
  };
}

export default debounce;

import React, { useCallback, useEffect, useState } from "react";
import { businessTypeOptions, parentChildTypeOptions } from "../../utils/table-settings";
import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";
import { gql, useLazyQuery } from "@apollo/client";
import {
  MultilocationFilter_MultilocationGroupsQuery,
  MultilocationFilter_MultilocationGroupsQueryVariables,
  ParentChildFilterOptionsEnum,
} from "../../__generated__/graphql";

export const MultilocationFilter_MultilocationGroups = gql`
  query MultilocationFilter_MultilocationGroups(
    $group_ids: [Int!]
    $group_parent_name: String
  ) {
    multilocationGroups(
      group_ids: $group_ids
      group_parent_name: $group_parent_name
    ) {
      id
      parent {
        company_name {
          value
        }
      }
    }
  }
`;

interface IMultilocationFilterProps {
  blurred?: boolean;
  businessTypeFilter: string[];
  multilocationFilter: string[];
  parentChildTypeFilter: ParentChildFilterOptionsEnum[];
  setParentChildTypeFilter: (v: ParentChildFilterOptionsEnum[]) => void;
  resetOffset: () => void;
  setMultilocationFilter: (v: string[]) => void;
  setBusinessTypeFilter: (v: string[] | null) => void;
}

function MultilocationFilter({
  blurred,
  businessTypeFilter,
  multilocationFilter,
  parentChildTypeFilter,
  setParentChildTypeFilter,
  resetOffset,
  setMultilocationFilter,
  setBusinessTypeFilter,
}: IMultilocationFilterProps) {
  const [fetchMultilocationGroups] = useLazyQuery<
    MultilocationFilter_MultilocationGroupsQuery,
    MultilocationFilter_MultilocationGroupsQueryVariables
  >(MultilocationFilter_MultilocationGroups);

  const [selectedGroups, setSelectedGroups] = useState(
    [] as { value: string; label: string }[],
  );
  const [hasInitialGroupSet, setHasInitialGroupSet] = useState(false);

  const transformMultilocationGroups = useCallback(
    (multilocationGroupsData: MultilocationFilter_MultilocationGroupsQuery) => {
      return (multilocationGroupsData?.multilocationGroups || []).map(
        (multilocationGroup) => {
          return {
            value: String(multilocationGroup.id),
            label: String(multilocationGroup.parent?.company_name?.value),
          };
        },
      );
    },
    [],
  );

  const fetchInitialMultilocationGroups = useCallback(async () => {
    const { data: multilocationGroupsData } = await fetchMultilocationGroups({
      variables: {
        group_ids: multilocationFilter.map(Number),
      },
    });

    setSelectedGroups(
      transformMultilocationGroups(multilocationGroupsData || {}),
    );
  }, [
    fetchMultilocationGroups,
    multilocationFilter,
    transformMultilocationGroups,
  ]);

  useEffect(() => {
    if (hasInitialGroupSet) {
      return;
    }

    fetchInitialMultilocationGroups();
    setHasInitialGroupSet(true);
  }, [fetchInitialMultilocationGroups, hasInitialGroupSet]);

  const fetchMultilocationGroupOptions = async (
    inputValue: string,
    callback: (options: any[]) => void,
  ) => {
    const { data: multilocationGroupsData } = await fetchMultilocationGroups({
      variables: {
        group_parent_name: inputValue,
      },
    });

    callback(transformMultilocationGroups(multilocationGroupsData || {}));
  };

  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={
        (businessTypeFilter?.length ? 1 : 0) +
        (multilocationFilter?.length ? 1 : 0) +
        (parentChildTypeFilter?.length ? 1 : 0)
      }
      sectionTitle="Parent / Child Hierarchy"
      filterOptions={[
        {
          label: "Parent company is any of:",
          filter: (
            <CiroDropDown
              async
              isMulti
              loadOptions={fetchMultilocationGroupOptions}
              blurred={blurred}
              options={selectedGroups}
              value={multilocationFilter}
              onChangeFullValues={(newValue) => {
                setSelectedGroups(newValue);
                const newValues = newValue.map((item) => item?.value) as any;
                setMultilocationFilter(newValues);
                resetOffset();
              }}
            />
          ),
        },
        {
          label: "Parent / child status:",
          filter: (
            <CiroDropDown
              blurred={blurred}
              isMulti={true}
              options={parentChildTypeOptions}
              value={parentChildTypeFilter}
              onChange={(newValue) => {
                setParentChildTypeFilter(
                  newValue as ParentChildFilterOptionsEnum[],
                );
                resetOffset();
              }}
            />
          ),
        },
        {
          label: "Account type:",
          filter: (
            <CiroDropDown
              blurred={blurred}
              isMulti={true}
              options={businessTypeOptions}
              value={businessTypeFilter}
              onChange={(newValue) => {
                setBusinessTypeFilter(newValue);
                resetOffset();
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default MultilocationFilter;

import { gql } from "@apollo/client";
import classNames from "classnames";
import { BusinessDetailsInternalCompanyId_CompanyFragment } from "../../__generated__/graphql";

export const BusinessDetailsInternalCompanyId_Company = gql`
  fragment BusinessDetailsInternalCompanyId_Company on Company {
    internal_company_id {
      value
      populated
    }
  }
`;

interface IBusinessDetailsInternalCompanyIdProps {
  company: BusinessDetailsInternalCompanyId_CompanyFragment;
}

const BusinessDetailsInternalCompanyId = ({
  company,
}: IBusinessDetailsInternalCompanyIdProps) => {
  const companyId = company?.internal_company_id?.value;
  if (!companyId) {
    return null;
  } else {
    return (
      <div
        className={classNames([
          "ciro-v1-absolute",
          "ciro-v1-bottom-0",
          "ciro-v1-text-sm",
          "ciro-v1-right-0",
          "ciro-v1-text-slate-100",
        ])}
      >
        Company ID {companyId}
      </div>
    );
  }
};

export default BusinessDetailsInternalCompanyId;

import classNames from "classnames";

const CiroHR = () => {
  return (
    <hr
      className={classNames([
        "ciro-v1-bg-gray-200",
        "ciro-v1-border-1",
        "ciro-v1-h-px",
        "ciro-v1-my-8",
        "ciro-v1-w-full",
      ])}
    />
  );
};

export default CiroHR;

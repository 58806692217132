interface IPuzzleIcon {
  width?: number | string;
  height?: number | string;
}

const PuzzleIcon = ({ width = 24, height = 24 }: IPuzzleIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6V4C7 3.448 6.552 3 6 3H4C3.448 3 3 3.448 3 4V6C3 6.552 3.448 7 4 7H6C6.552 7 7 6.552 7 6Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.846 14.0001V12.1541C7.846 11.6021 7.398 11.1541 6.846 11.1541H5C4.448 11.1541 4 11.6021 4 12.1541V14.0001C4 14.5521 4.448 15.0001 5 15.0001H6.846C7.398 15.0001 7.846 14.5521 7.846 14.0001Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 9V7C14 6.448 13.552 6 13 6H11C10.448 6 10 6.448 10 7V9C10 9.552 10.448 10 11 10H13C13.552 10 14 9.552 14 9Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 17V14C13 13.448 13.448 13 14 13H17V10C17 9.448 17.448 9 18 9H20C20.552 9 21 9.448 21 10V20C21 20.552 20.552 21 20 21H10C9.448 21 9 20.552 9 20V18C9 17.448 9.448 17 10 17H13Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PuzzleIcon;

import classNames from "classnames";
import CiroPill from "./CiroPill";

enum CRMStatusEnum {
  CURRENT_CUSTOMER = "CURRENT_CUSTOMER",
  PROSPECT = "PROSPECT",
  FORMER_CUSTOMER = "FORMER_CUSTOMER",
  NULL = "NULL",
  UNLINKED = "UNLINKED",
  FOUND_IN_CRM = "FOUND_IN_CRM",
}

const CRM_STATUS_STYLES = {
  [CRMStatusEnum.CURRENT_CUSTOMER]: {
    label: "Current customer",
    fontColor: "ciro-v1-text-emerald-700",
    background: "ciro-v1-bg-emerald-50",
    dotColor: "ciro-v1-bg-emerald-500",
  },
  [CRMStatusEnum.FORMER_CUSTOMER]: {
    label: "Churned",
    fontColor: "ciro-v1-red-700",
    background: "ciro-v1-bg-red-50",
    dotColor: "ciro-v1-bg-red-500",
  },
  [CRMStatusEnum.PROSPECT]: {
    label: "Prospect",
    fontColor: "ciro-v1-text-slate-700",
    background: "ciro-v1-bg-gray-100",
    dotColor: "ciro-v1-bg-gray-500",
  },
  [CRMStatusEnum.FOUND_IN_CRM]: {
    label: "Found in CRM",
    fontColor: "ciro-v1-text-slate-700",
    background: "ciro-v1-bg-gray-100",
    dotColor: "ciro-v1-bg-gray-500",
  },
  [CRMStatusEnum.NULL]: {
    label: "Not in CRM",
    fontColor: "ciro-v1-text-slate-700",
    background: "ciro-v1-bg-gray-100",
    dotColor: "ciro-v1-bg-gray-500",
  },
  [CRMStatusEnum.UNLINKED]: {
    label: "CRM not linked",
    fontColor: "ciro-v1-text-slate-700",
    background: "ciro-v1-bg-gray-100",
    dotColor: "ciro-v1-bg-gray-500",
  },
};

interface ICiroCRMPillProps {
  crmStatus: string;
  crmConnected: boolean;
  foundInCrm: boolean;
}

const CiroCRMPill = ({
  crmStatus,
  crmConnected,
  foundInCrm,
}: ICiroCRMPillProps) => {
  const chosenStyles = !crmConnected
    ? CRM_STATUS_STYLES[CRMStatusEnum.UNLINKED]
    : !foundInCrm
      ? CRM_STATUS_STYLES[CRMStatusEnum.NULL]
      : CRM_STATUS_STYLES[crmStatus as unknown as CRMStatusEnum] ||
        CRM_STATUS_STYLES[CRMStatusEnum.FOUND_IN_CRM];

  return (
    <span>
      <CiroPill
        background={chosenStyles.background}
        fontColor={chosenStyles.fontColor}
      >
        <div
          className={classNames([
            "ciro-v1-rounded-full",
            "ciro-v1-h-2",
            "ciro-v1-w-2",
            "ciro-v1-mr-2",
            chosenStyles.dotColor,
          ])}
        />
        <span className="test-customer-status">{chosenStyles.label}</span>
      </CiroPill>
    </span>
  );
};

export default CiroCRMPill;

import { useEffect, useState } from "react";
import { analytics } from "../../../utils/vendors";

interface IUseFilterBaseProps {
  searchParams: URLSearchParams;
  filterName: string;
}

const useFilterBaseDate = <T extends Date | null>({
  searchParams,
  filterName,
}: IUseFilterBaseProps): [T, React.Dispatch<T>] => {
  const [filter, setFilter] = useState<T>(() => {
    try {
      const foundVal = searchParams.get(filterName);

      return (foundVal ? new Date(Number(foundVal)) : null) as T;
    } catch {
      return null as T;
    }
  });

  useEffect(() => {
    if (filter) {
      analytics.track("singleFilterUsed", {
        filter: filterName,
        value: filter,
      });
    }
  }, [filter, filterName]);

  return [filter, setFilter];
};

export default useFilterBaseDate;

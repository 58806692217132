import classNames from "classnames";
import CiroContainer from "../../components/shared/CiroContainer";
import { gql, useQuery } from "@apollo/client";
import ApolloIntegrationUpdateForm, {
  ApolloIntegrationUpdateForm_ApolloIntegration,
} from "../../components/mobileNumbers/mobileNumbersIntegrationModal/apollo/ApolloIntegrationUpdateForm";
import { useEffect, useState } from "react";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import { useFragment } from "../../__generated__";
import {
  ApolloIntegrationUpdateForm_ApolloIntegrationFragment,
  ApolloMobileConnection_ApolloIntegrationQuery,
  ApolloMobileConnection_ApolloIntegrationQueryVariables,
} from "../../__generated__/graphql";
import SkeletonLoading from "../../components/shared/SkeletonLoading";

export const ApolloMobileConnection_ApolloIntegration = gql`
  query ApolloMobileConnection_ApolloIntegration {
    userAccount {
      org {
        apolloIntegration {
          api_key
          bad_number_dispositions
          ...ApolloIntegrationUpdateForm_ApolloIntegration
        }
      }
    }
  }
  ${ApolloIntegrationUpdateForm_ApolloIntegration}
`;

const ApolloMobileConnection = () => {
  const [isInitialSetup, setIsInitialSetup] = useState(true);
  const { data, loading } = useQuery<
    ApolloMobileConnection_ApolloIntegrationQuery,
    ApolloMobileConnection_ApolloIntegrationQueryVariables
  >(ApolloMobileConnection_ApolloIntegration);

  const apolloIntegrationUpdateFormFragment = useFragment(
    ApolloIntegrationUpdateForm_ApolloIntegration,
    data?.userAccount?.org?.apolloIntegration,
  ) as ApolloIntegrationUpdateForm_ApolloIntegrationFragment;

  useEffect(() => {
    if (data?.userAccount?.org?.apolloIntegration?.bad_number_dispositions) {
      setIsInitialSetup(false);
    }
  }, [data, isInitialSetup]);

  if (loading)
    return (
      <div
        className={classNames(
          "ciro-v1-w-full",
          "ciro-v1-px-4",
          "ciro-v1-pt-16",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-row",
            "ciro-v1-justify-between",
            "ciro-v1-gap-4",
            "ciro-v1-w-full",
          )}
        >
          <div className={classNames("ciro-v1-w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"6rem"} />
          </div>
          <div className={classNames("ciro-v1-w-full")}>
            <SkeletonLoading numSkeletons={1} skeletonHeight={"20rem"} />
          </div>
        </div>
      </div>
    );

  return (
    <CiroContainer
      className={classNames("ciro-v1-bg-zinc-100", "ciro-v1-w-full")}
    >
      <CiroBreadCrumbs
        values={["Enrich CRM", "Apollo connection"]}
        href="/enrich-crm"
      />
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-between",
          "ciro-v1-w-full",
        )}
      >
        <h1
          className={classNames(
            "ciro-v1-text-neutral-900",
            "ciro-v1-text-xl",
            "ciro-v1-font-semibold",
          )}
        >
          {isInitialSetup ? "Connect with Apollo" : "Manage Apollo connection"}
        </h1>
      </div>

      <div className={classNames("ciro-v1-flex", "ciro-v1-gap-6")}>
        <div>
          <h1
            className={classNames(
              "ciro-v1-text-neutral-900",
              "ciro-v1-text-base",
              "ciro-v1-font-bold",
              "ciro-v1-mb-2",
            )}
          >
            Call metrics
          </h1>
          <div
            className={classNames(
              "ciro-v1-text-neutral-500",
              "ciro-v1-text-sm",
              "ciro-v1-font-normal",
            )}
          >
            Track the success of calls made to numbers enriched by Ciro.
          </div>
        </div>
        <div
          className={classNames(
            "ciro-v1-bg-white",
            "ciro-v1-p-4",
            "ciro-v1-rounded-lg",
            "ciro-v1-border",
            "ciro-v1-border-neutral-200",
            "ciro-v1-w-full",
          )}
        >
          <ApolloIntegrationUpdateForm
            apolloIntegration={apolloIntegrationUpdateFormFragment}
            initialSetup={isInitialSetup}
          />
        </div>
      </div>
    </CiroContainer>
  );
};

export default ApolloMobileConnection;

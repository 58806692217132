import { gql } from "@apollo/client";
import CiroDropDown, {
  formatOptionsForCiroDropdown,
} from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

interface IStatesFilterProps {
  blurred?: boolean;
  defaultOpen?: boolean;
  notStatesFilter: string[];
  resetOffset?: () => void;
  setCitiesFilter: (v: string[]) => void;
  setNotStatesFilter: (v: string[]) => void;
  setStatesFilter: (v: string[]) => void;
  statesData?: string[];
  statesFilter: string[];
}

export const StatesFilter_states = gql`
  fragment StatesFilter_states on Query {
    states
  }
`;

function StatesFilter({
  blurred = false,
  defaultOpen = false,
  notStatesFilter,
  resetOffset,
  setCitiesFilter,
  setNotStatesFilter,
  setStatesFilter,
  statesData,
  statesFilter,
}: IStatesFilterProps) {
  return (
    <CiroFilterModalDropdownSection
      defaultOpen={defaultOpen}
      numFiltersApplied={
        (statesFilter.length ? 1 : 0) + (notStatesFilter.length ? 1 : 0)
      }
      sectionTitle="States"
      filterOptions={[
        {
          label: "Include any of:",
          filter: (
            <CiroDropDown
              isMulti
              blurred={blurred}
              options={formatOptionsForCiroDropdown(statesData || [])}
              value={statesFilter}
              onChange={(newValue) => {
                setStatesFilter(newValue);
                setCitiesFilter([]);
                if (resetOffset) resetOffset();
              }}
            />
          ),
        },
        {
          label: "Exclude:",
          filter: (
            <CiroDropDown
              isMulti
              blurred={blurred}
              options={formatOptionsForCiroDropdown(statesData || [])}
              value={notStatesFilter}
              onChange={(newValue) => {
                setNotStatesFilter(newValue);
                if (resetOffset) resetOffset();
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default StatesFilter;

import React from "react";
import { gql, useQuery } from "@apollo/client";
import {
  CitiesFilter_CitiesForStatesQuery,
  CitiesFilter_CitiesForStatesQueryVariables,
} from "../../__generated__/graphql";
import CiroDropDown, {
  formatOptionsForCiroDropdown,
} from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

interface ICitiesFilterProps {
  blurred?: boolean;
  citiesFilter: string[];
  defaultOpen?: boolean;
  notCitiesFilter: string[];
  resetOffset?: () => void;
  setCitiesFilter: (v: string[]) => void;
  setNotCitiesFilter: (v: string[]) => void;
  statesFilter: string[];
}

const CitiesFilter_citiesForStates = gql`
  query CitiesFilter_citiesForStates($states: [String]) {
    citiesForStates(states: $states)
  }
`;

function CitiesFilter({
  blurred,
  citiesFilter,
  defaultOpen = false,
  notCitiesFilter,
  resetOffset,
  setCitiesFilter,
  setNotCitiesFilter,
  statesFilter,
}: ICitiesFilterProps) {
  const { data: citiesData } = useQuery<
    CitiesFilter_CitiesForStatesQuery,
    CitiesFilter_CitiesForStatesQueryVariables
  >(CitiesFilter_citiesForStates, {
    variables: { states: statesFilter },
  });

  const cityOptionsFromServer = formatOptionsForCiroDropdown(
    citiesData?.citiesForStates || [],
  );
  const missingOptions = citiesFilter
    .concat(notCitiesFilter)
    .filter((city) => !citiesData?.citiesForStates?.includes(city))
    .map((city) => {
      return {
        value: city,
        label: city,
      };
    });
  const cityOptions = missingOptions
    ? cityOptionsFromServer.concat(missingOptions)
    : cityOptionsFromServer;

  return (
    <CiroFilterModalDropdownSection
      defaultOpen={defaultOpen}
      numFiltersApplied={
        (citiesFilter.length ? 1 : 0) + (notCitiesFilter.length ? 1 : 0)
      }
      sectionTitle="Cities"
      filterOptions={[
        {
          label: "Include any of:",
          filter: (
            <CiroDropDown
              isMulti
              creatable={true}
              blurred={blurred}
              options={cityOptions}
              value={citiesFilter}
              onChange={(newValue) => {
                setCitiesFilter(newValue);
                if (resetOffset) resetOffset();
              }}
            />
          ),
        },
        {
          label: "Exclude:",
          filter: (
            <CiroDropDown
              isMulti
              creatable={true}
              blurred={blurred}
              options={cityOptions}
              value={notCitiesFilter}
              onChange={(newValue) => {
                setNotCitiesFilter(newValue);
                if (resetOffset) resetOffset();
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default CitiesFilter;

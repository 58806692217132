import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import {
  AdminOrganizationDetails_SelectedOrganization,
  OrganizationDetailsModalStateEnum,
} from "./AdminOrganizationDetails";
import {
  AdminViewCustomPermissions_DeleteCustomPermissionMutation,
  AdminViewCustomPermissions_DeleteCustomPermissionMutationVariables,
  AdminViewCustomPermissions_OrganizationFragment,
  AdminViewCustomPermissions_UpsertCustomPermissionMutation,
  AdminViewCustomPermissions_UpsertCustomPermissionMutationVariables,
  Maybe,
  PermissionsEnum,
} from "../../../__generated__/graphql";
import AdminCustomPermissionField from "./AdminCustomPermissionField";
import { useState } from "react";
import CiroDropDown from "../../shared/CiroDropdown";
import { gql, useMutation } from "@apollo/client";

export const AdminViewCustomPermissions_Organization = gql`
  fragment AdminViewCustomPermissions_Organization on Organization {
    id
    name
    customPermissions {
      id
      permission_type
      value
    }
  }
`;

const AdminViewCustomPermissions_deleteCustomPermission = gql`
  mutation AdminViewCustomPermissions_deleteCustomPermission(
    $permissionId: Int!
  ) {
    deleteCustomPermission(permissionId: $permissionId) {
      success
    }
  }
`;

const AdminViewCustomPermissions_UpsertCustomPermission = gql`
  mutation AdminViewCustomPermissions_UpsertCustomPermission(
    $orgId: Int!
    $permissionType: PermissionsEnum!
    $value: String!
  ) {
    upsertCustomPermission(
      orgId: $orgId
      permissionType: $permissionType
      value: $value
    ) {
      success
    }
  }
`;

interface IAdminViewCustomPermissionsProps {
  organization?: Maybe<AdminViewCustomPermissions_OrganizationFragment>;
  setModalState: (v: OrganizationDetailsModalStateEnum) => void;
}

const AdminViewCustomPermissions = ({
  organization,
  setModalState,
}: IAdminViewCustomPermissionsProps) => {
  const [newPermissionType, setNewPermissionType] =
    useState<PermissionsEnum | null>(null);

  const [deleteCustomPermission, { loading: deleteCustomPermissionLoading }] =
    useMutation<
      AdminViewCustomPermissions_DeleteCustomPermissionMutation,
      AdminViewCustomPermissions_DeleteCustomPermissionMutationVariables
    >(AdminViewCustomPermissions_deleteCustomPermission);

  const [upsertCustomPermission, { loading: upsertCustomPermissionLoading }] =
    useMutation<
      AdminViewCustomPermissions_UpsertCustomPermissionMutation,
      AdminViewCustomPermissions_UpsertCustomPermissionMutationVariables
    >(AdminViewCustomPermissions_UpsertCustomPermission);

  if (!organization) {
    return null;
  }

  const refetchQueries = [
    {
      query: AdminOrganizationDetails_SelectedOrganization,
      variables: {
        organizationId: organization.id,
      },
    },
  ];

  return (
    <div>
      <div className={classNames("ciro-v1-flex", "ciro-v1-justify-between")}>
        <div className={classNames("ciro-v1-font-medium", "ciro-v1-text-lg")}>
          {organization.name} custom permissions
        </div>
        <CiroButton
          style={CiroButtonStyleEnum.UNSTYLED}
          analyticsField={"Return to org view from custom permissions"}
          onClick={() => {
            setModalState(OrganizationDetailsModalStateEnum.VIEW);
          }}
        >
          <ArrowUturnLeftIcon
            className={classNames(["ciro-v1-h-5 w-5"])}
            aria-hidden="true"
          />
        </CiroButton>
      </div>
      <div className={classNames("ciro-v1-text-sm")}>
        Overwrite permissions given by the organization's subscription tier.
      </div>
      <div
        className={classNames(
          "ciro-v1-pt-4",
          "ciro-v1-grid",
          "ciro-v1-grid-cols-1",
          "ciro-v1-gap-4",
          "md:ciro-v1-grid-cols-2",
        )}
      >
        {Boolean(!newPermissionType) && (
          <CiroButton
            analyticsField="Begin adding custom permission"
            onClick={() => setNewPermissionType(PermissionsEnum.AccountFilters)}
          >
            Add custom permission
          </CiroButton>
        )}
        {newPermissionType && (
          <>
            <div
              className={classNames(
                "ciro-v1-col-span-full",
                "ciro-v1-font-medium",
              )}
            >
              New Permission
            </div>
            <div>
              <CiroDropDown
                isMulti={false}
                isClearable={false}
                options={Object.values(PermissionsEnum).map((filter) => {
                  return {
                    label: filter,
                    value: filter,
                  };
                })}
                value={newPermissionType}
                onChange={(v) => {
                  setNewPermissionType(v);
                }}
              />
            </div>
            <AdminCustomPermissionField
              handleUpsert={({ variables }) => {
                return upsertCustomPermission({
                  refetchQueries,
                  variables,
                  onCompleted: () => {
                    setNewPermissionType(null);
                  },
                });
              }}
              isLoading={
                deleteCustomPermissionLoading || upsertCustomPermissionLoading
              }
              handleDelete={() => {
                setNewPermissionType(null);
              }}
              permission={newPermissionType}
              orgId={organization.id}
              value={null}
            />
          </>
        )}
      </div>
      <div
        className={classNames(
          "ciro-v1-col-span-full",
          "ciro-v1-font-medium",
          "ciro-v1-pt-4",
        )}
      >
        Custom Permissions
      </div>
      {!(organization.customPermissions || []).length && (
        <div>No custom permissions</div>
      )}
      {(organization.customPermissions || []).map((permission, i) => {
        return (
          <div
            className={classNames(
              "ciro-v1-pt-4",
              "ciro-v1-grid",
              "ciro-v1-grid-cols-1",
              "ciro-v1-gap-4",
              "md:ciro-v1-grid-cols-2",
            )}
            key={i}
          >
            <div>{permission?.permission_type}</div>
            <AdminCustomPermissionField
              handleUpsert={({ variables }) => {
                return upsertCustomPermission({
                  refetchQueries,
                  variables,
                });
              }}
              handleDelete={() => {
                deleteCustomPermission({
                  refetchQueries,
                  variables: {
                    permissionId: permission.id,
                  },
                });
              }}
              isLoading={
                deleteCustomPermissionLoading || upsertCustomPermissionLoading
              }
              permission={permission.permission_type}
              orgId={organization.id}
              value={permission.value}
            />
          </div>
        );
      })}
    </div>
  );
};

export default AdminViewCustomPermissions;

import { useEffect, useState } from "react";
import { analytics } from "../../../utils/vendors";

interface IUseFilterBaseProps {
  searchParams: URLSearchParams;
  filterName: string;
  filterApplicable?: boolean;
}

const useFilterBaseStringArray = <T extends string[]>({
  searchParams,
  filterName,
  filterApplicable = true,
}: IUseFilterBaseProps): [T, React.Dispatch<T>] => {
  const [filter, setFilter] = useState<T>(searchParams.getAll(filterName) as T || []);

  useEffect(() => {
    if (filter?.length && filterApplicable) {
      analytics.track("singleFilterUsed", {
        filter: filterName,
        value: filter,
      });
    }
  }, [filter, filterApplicable, filterName]);

  return [filter, setFilter];
};

export default useFilterBaseStringArray;

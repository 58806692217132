import classNames from "classnames";
import CiroDropDown from "../shared/CiroDropdown";
import { ISharedWorkflowFormProperties } from "./MobileNumbersWorkflowForm";
import CiroCreateableTextInput from "../shared/CiroCreateableTextInput";
import CiroCheckbox from "../shared/CiroCheckbox";
import { useMemo } from "react";

const CrmRecordValuesWorkflowFormSection = ({
  sharedWorkflowFormProperties: {
    setValue,
    trigger,
    errors,
    orgContactObjectFields,
    watch,
  },
}: {
  sharedWorkflowFormProperties: ISharedWorkflowFormProperties;
}) => {
  const { fieldTypes, filterFields, picklistOptions } = orgContactObjectFields;

  const crmRecordField = watch("segmentation.crm_record_field");
  const crmRecordFieldValues = watch("segmentation.crm_record_values");
  const usingBlankValues = useMemo(() => {
    if (!crmRecordFieldValues) {
      return false;
    }
    return crmRecordFieldValues?.findIndex((value) => value === null) !== -1;
  }, [crmRecordFieldValues]);

  return (
    <>
      <div className={classNames("ciro-v1-mb-4")}>
        <CiroDropDown
          isMulti={false}
          label="Field name"
          placeholder="Field indicating the need for a new number"
          value={crmRecordField}
          error={errors.segmentation?.crm_record_field?.message}
          onChange={(value) => {
            setValue("segmentation.crm_record_field", value);
            setValue("segmentation.crm_record_values", null);
            setValue("segmentation.crm_record_field_type", fieldTypes[value]);
            trigger("segmentation.crm_record_field");
          }}
          options={filterFields}
          infoTooltip={
            "Choose the field that indicates the need for a new number"
          }
        />
      </div>
      {crmRecordField && (
        <div className={classNames("ciro-v1-mb-4")}>
          {(picklistOptions[crmRecordField]?.length || 0) > 0 ? (
            <CiroDropDown
              isMulti
              label="Field values"
              placeholder="Enter values indicating need for a new number"
              infoTooltip={
                "Choose the values that indicate the need for a new number"
              }
              error={errors.segmentation?.crm_record_values?.message}
              options={picklistOptions[crmRecordField]}
              value={crmRecordFieldValues || []}
              onChange={(value) => {
                const newValues = [...value] as (string | null)[];
                if (usingBlankValues) {
                  newValues.push(null);
                }
                setValue("segmentation.crm_record_values", newValues);
                trigger("segmentation.crm_record_values");
              }}
            />
          ) : (
            <CiroCreateableTextInput
              containerWidthClass="ciro-v1-w-full"
              label="Field values"
              placeholder="Enter values indicating a need for a new number"
              value={(crmRecordFieldValues || []).filter(Boolean) as string[]}
              setValue={(value) => {
                const newValues = [...value] as (string | null)[];
                if (usingBlankValues) {
                  newValues.push(null);
                }
                setValue("segmentation.crm_record_values", newValues);
                trigger("segmentation.crm_record_values");
              }}
              error={errors.segmentation?.crm_record_values?.message}
            />
          )}
          <div className={classNames("ciro-v1-mt-2")}>
            <CiroCheckbox
              label="Blank / null value should trigger a new number request"
              checked={usingBlankValues}
              onClick={() => {
                if (!usingBlankValues) {
                  setValue("segmentation.crm_record_values", [
                    ...(crmRecordFieldValues || []),
                    null,
                  ]);
                } else {
                  setValue(
                    "segmentation.crm_record_values",
                    (crmRecordFieldValues || []).filter(
                      (value) => value !== null,
                    ),
                  );
                }
                trigger("segmentation.crm_record_values");
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CrmRecordValuesWorkflowFormSection;

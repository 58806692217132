import React from "react";

const DashedBorder: React.FC = () => (
  <svg
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1, // Places the SVG behind the content
      pointerEvents: "none", // Allows clicks to pass through
    }}
  >
    <rect
      x="0.5"
      y="0.5"
      width="calc(100% - 1px)"
      height="calc(100% - 1px)"
      fill="none"
      stroke="#CBD5E0" // Tailwind's neutral-200 equivalent
      strokeWidth="1"
      strokeDasharray="10,5" // Adjust dash and gap sizes as needed
      rx="0.5rem" // Rounded corners matching Tailwind's rounded-lg
      ry="0.5rem"
    />
  </svg>
);

export default DashedBorder;

import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import FilterContext from "../../../contexts/FilterContext";
import { useContext } from "react";

interface ICiroFilterModalFilterOptionProps {
  label?: any;
  filter: any;
  switchFilter? : boolean;
}

const CiroFilterModalFilterOption = ({
  label,
  filter,
  switchFilter
}: ICiroFilterModalFilterOptionProps) => {
  
  if (!switchFilter) return (
    <div className={classNames("ciro-v1-pb-2")}>
      <div className={classNames("ciro-v1-text-slate-700", "ciro-v1-text-sm")}>
        {label}
      </div>
      {filter}
    </div>
  );

  return (
    <div className={classNames("ciro-v1-pb-2", "ciro-v1-mt-1", "ciro-v1-flex", "ciro-v1-flex-row", "ciro-v1-gap-2", "ciro-v1-items-center")}>
      {filter}
      <div className={classNames("ciro-v1-text-slate-700", "ciro-v1-text-sm")}>
        {label}
      </div>
    </div>
  );

};

const CiroFilterNumber = ({ filtersApplied }: { filtersApplied: number }) => {
  if (!filtersApplied) {
    return null;
  }

  return (
    <div
      className={classNames(
        "ciro-v1-bg-orange-400",
        "ciro-v1-rounded-full",
        "ciro-v1-text-white",
        "ciro-v1-w-5",
        "ciro-v1-h-5",
        "ciro-v1-ml-3",
      )}
    >
      {filtersApplied}
    </div>
  );
};

interface ICiroFilterModalDropdownSectionProps {
  btnClassName?: string;
  defaultOpen?: boolean;
  filterOptions: ICiroFilterModalFilterOptionProps[];
  numFiltersApplied: number;
  sectionTitle: any;
  smbFilter?: boolean;
}

const CiroFilterModalDropdownSection = ({
  btnClassName,
  defaultOpen = false,
  filterOptions,
  numFiltersApplied,
  sectionTitle,
  smbFilter
}: ICiroFilterModalDropdownSectionProps) => {

  const { showSmbFilters } = useContext(FilterContext);

  if (smbFilter && !showSmbFilters) return null;
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames(
              "ciro-v1-px-4",
              "ciro-v1-py-2",
              "ciro-v1-w-full",
              btnClassName,
            )}
          >
            <div
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-justify-between",
                "ciro-v1-w-full",
              )}
            >
              <div
                className={classNames(
                  "ciro-v1-text-slate-700",
                  "ciro-v1-text-sm",
                  "ciro-v1-flex",
                )}
              >
                <div className={classNames("ciro-v1-flex", "ciro-v1-flex-row", "ciro-v1-items-center")}>
                  {smbFilter && <div
                    className={classNames(
                      "ciro-v1-w-1",
                      "ciro-v1-h-1",
                      "ciro-v1-bg-orange-500",
                      "ciro-v1-rounded-[1px]",
                      "ciro-v1-mr-2",
                    )}
                  />}
                  <div>{sectionTitle}</div>
                </div>
                <CiroFilterNumber filtersApplied={numFiltersApplied} />
              </div>
              <ChevronUpIcon
                className={classNames(
                  "ciro-v1-h-5",
                  "ciro-v1-text-slate-700",
                  "ciro-v1-transform",
                  {
                    "ciro-v1-rotate-180": !open,
                  },
                )}
              />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel
            className={classNames("ciro-v1-bg-zinc-100", "ciro-v1-p-4")}
          >
            {filterOptions.map((filterOption, i) => {
              return (
                <CiroFilterModalFilterOption
                  key={i}
                  label={filterOption.label}
                  filter={filterOption.filter}
                  switchFilter={filterOption.switchFilter}
                />
              );
            })}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default CiroFilterModalDropdownSection;

import { useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  CiroCollectionUpsertJobCreator_CreateUpsertCollectionJobMutation,
  CiroCollectionUpsertJobCreator_CreateUpsertCollectionJobMutationVariables,
  UpsertCollectionArgs,
} from "../../../__generated__/graphql";
import CiroCollectionUpsertStatusFetcher from "./CiroCollectionUpsertStatusFetcher";

const CiroCollectionUpsertJobCreator_CreateUpsertCollectionJob = gql`
  mutation CiroCollectionUpsertJobCreator_CreateUpsertCollectionJob(
    $change: UpsertCollectionArgs!
  ) {
    createUpsertCollectionJob(change: $change) {
      success
      jobId
      message
    }
  }
`;

interface ICiroCollectionUpsertStatusProps {
  collectionChange: UpsertCollectionArgs;
  collectionName: string;
  completedText: string;
  preparingText: string;
  setCheckedCompanies?: (v: Set<string>) => void;
  setDoneUpserting?: (v: boolean) => void;
}

const CiroCollectionUpsertJobCreator = ({
  collectionChange,
  collectionName,
  completedText,
  preparingText,
  setCheckedCompanies,
  setDoneUpserting
}: ICiroCollectionUpsertStatusProps) => {
  const [createUpsertCollectionJob, { data: createUpsertCollectionJobData }] =
    useMutation<
      CiroCollectionUpsertJobCreator_CreateUpsertCollectionJobMutation,
      CiroCollectionUpsertJobCreator_CreateUpsertCollectionJobMutationVariables
    >(CiroCollectionUpsertJobCreator_CreateUpsertCollectionJob);

  useEffect(() => {
    createUpsertCollectionJob({
      variables: {
        change: collectionChange,
      },
    });
  }, [collectionChange, createUpsertCollectionJob, setDoneUpserting]);

  return (
    <>
      {createUpsertCollectionJobData?.createUpsertCollectionJob && (
        <CiroCollectionUpsertStatusFetcher
          upsertJobCollectionResponse={
            createUpsertCollectionJobData?.createUpsertCollectionJob
          }
          collectionName={collectionName}
          completedText={completedText}
          preparingText={preparingText}
          setCheckedCompanies={setCheckedCompanies}
          setDoneUpserting={setDoneUpserting}
        />
      )}
    </>
  );
};

export default CiroCollectionUpsertJobCreator;

const KeywordSearchIcon = ({
  width = "20",
  height = "20",
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66617 13.3355H3.33145C2.59476 13.3355 1.99756 12.7383 1.99756 12.0017V3.99832C1.99756 3.26163 2.59476 2.66443 3.33145 2.66443H12.6687C13.4054 2.66443 14.0026 3.26163 14.0026 3.99832V5.33221"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="11.0012"
        cy="9.66739"
        r="3.00125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6695 13.3356L13.1235 11.7897"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default KeywordSearchIcon;

import React from "react";
import { gql } from "@apollo/client";
import { CollectionsFilter_CollectionsFragment } from "../../__generated__/graphql";
import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

interface ICollectionsFilterProps {
  collectionsFilter: number[];
  notCollectionsFilter: number[];
  setCollectionsFilter: (v: number[]) => void;
  setNotCollectionsFilter: (v: number[]) => void;
  collectionsData: CollectionsFilter_CollectionsFragment;
  resetOffset: () => void;
  blurred?: boolean;
}

export const CollectionsFilter_collections = gql`
  fragment CollectionsFilter_collections on Query {
    collections {
      id
      name
    }
  }
`;

function CollectionsFilter({
  collectionsData,
  collectionsFilter,
  notCollectionsFilter,
  resetOffset,
  setCollectionsFilter,
  setNotCollectionsFilter,
  blurred = false,
}: ICollectionsFilterProps) {
  const collectionsOptions = (collectionsData.collections || []).map(
    (element) => {
      return {
        value: String(element?.id),
        label: String(element?.name),
      };
    },
  );

  return (
    <CiroFilterModalDropdownSection
      btnClassName="test-collections-filter-dropdown"
      numFiltersApplied={
        (collectionsFilter.length ? 1 : 0) +
        (notCollectionsFilter.length ? 1 : 0)
      }
      sectionTitle="Collections"
      filterOptions={[
        {
          label: "Include any of:",
          filter: (
            <CiroDropDown
              isMulti
              blurred={blurred}
              className="test-collections-filter"
              options={collectionsOptions}
              value={collectionsFilter.map(String)}
              onChange={(newValue) => {
                setCollectionsFilter(newValue.map(Number));
                resetOffset();
              }}
            />
          ),
        },
        {
          label: "Exclude:",
          filter: (
            <CiroDropDown
              isMulti
              blurred={blurred}
              options={collectionsOptions}
              value={notCollectionsFilter.map(String)}
              onChange={(newValue) => {
                setNotCollectionsFilter(newValue.map(Number));
                resetOffset();
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default CollectionsFilter;

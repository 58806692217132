const InstagramIcon = () => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.257812"
        width="28"
        height="28"
        rx="6"
        fill="url(#paint0_radial_63_23726)"
      />
      1
      <rect
        x="0.257812"
        width="28"
        height="28"
        rx="6"
        fill="url(#paint1_radial_63_23726)"
      />
      <rect
        x="0.257812"
        width="28"
        height="28"
        rx="6"
        fill="url(#paint2_radial_63_23726)"
      />
      <path
        d="M21.2578 8.5C21.2578 9.32843 20.5862 10 19.7578 10C18.9294 10 18.2578 9.32843 18.2578 8.5C18.2578 7.67157 18.9294 7 19.7578 7C20.5862 7 21.2578 7.67157 21.2578 8.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2578 19C17.0192 19 19.2578 16.7614 19.2578 14C19.2578 11.2386 17.0192 9 14.2578 9C11.4964 9 9.25781 11.2386 9.25781 14C9.25781 16.7614 11.4964 19 14.2578 19ZM14.2578 17C15.9147 17 17.2578 15.6569 17.2578 14C17.2578 12.3431 15.9147 11 14.2578 11C12.601 11 11.2578 12.3431 11.2578 14C11.2578 15.6569 12.601 17 14.2578 17Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25781 13.6C4.25781 10.2397 4.25781 8.55953 4.91177 7.27606C5.48701 6.14708 6.4049 5.2292 7.53387 4.65396C8.81734 4 10.4975 4 13.8578 4H14.6578C18.0181 4 19.6983 4 20.9818 4.65396C22.1107 5.2292 23.0286 6.14708 23.6039 7.27606C24.2578 8.55953 24.2578 10.2397 24.2578 13.6V14.4C24.2578 17.7603 24.2578 19.4405 23.6039 20.7239C23.0286 21.8529 22.1107 22.7708 20.9818 23.346C19.6983 24 18.0181 24 14.6578 24H13.8578C10.4975 24 8.81734 24 7.53387 23.346C6.4049 22.7708 5.48701 21.8529 4.91177 20.7239C4.25781 19.4405 4.25781 17.7603 4.25781 14.4V13.6ZM13.8578 6H14.6578C16.371 6 17.5356 6.00156 18.4357 6.0751C19.3126 6.14674 19.761 6.27659 20.0738 6.43597C20.8264 6.81947 21.4383 7.43139 21.8218 8.18404C21.9812 8.49684 22.1111 8.94524 22.1827 9.82208C22.2563 10.7223 22.2578 11.8868 22.2578 13.6V14.4C22.2578 16.1132 22.2563 17.2777 22.1827 18.1779C22.1111 19.0548 21.9812 19.5032 21.8218 19.816C21.4383 20.5686 20.8264 21.1805 20.0738 21.564C19.761 21.7234 19.3126 21.8533 18.4357 21.9249C17.5356 21.9984 16.371 22 14.6578 22H13.8578C12.1447 22 10.9801 21.9984 10.0799 21.9249C9.20306 21.8533 8.75465 21.7234 8.44185 21.564C7.6892 21.1805 7.07728 20.5686 6.69379 19.816C6.53441 19.5032 6.40456 19.0548 6.33292 18.1779C6.25937 17.2777 6.25781 16.1132 6.25781 14.4V13.6C6.25781 11.8868 6.25937 10.7223 6.33292 9.82208C6.40456 8.94524 6.53441 8.49684 6.69379 8.18404C7.07728 7.43139 7.6892 6.81947 8.44185 6.43597C8.75465 6.27659 9.20306 6.14674 10.0799 6.0751C10.9801 6.00156 12.1447 6 13.8578 6Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_63_23726"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.2578 21) rotate(-55.3758) scale(25.5196)"
        >
          <stop stopColor="#B13589" />
          <stop offset="0.79309" stopColor="#C62F94" />
          <stop offset="1" stopColor="#8A3AC8" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_63_23726"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.25781 29) rotate(-65.1363) scale(22.5942)"
        >
          <stop stopColor="#E0E8B7" />
          <stop offset="0.444662" stopColor="#FB8A2E" />
          <stop offset="0.71474" stopColor="#E2425C" />
          <stop offset="1" stopColor="#E2425C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_63_23726"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-1.24219 1) rotate(-8.1301) scale(38.8909 8.31836)"
        >
          <stop offset="0.156701" stopColor="#406ADC" />
          <stop offset="0.467799" stopColor="#6A45BE" />
          <stop offset="1" stopColor="#6A45BE" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default InstagramIcon;

const YelpIcon = () => {
  return (
    <svg
      width="25"
      height="30"
      viewBox="0 0 25 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0594 1.28748C12.1792 0.686597 11.7545 0.112377 11.1447 0.053139C9.88489 -0.0693807 8.58312 0.0172796 7.28479 0.341069C5.98647 0.664859 4.79649 1.19964 3.74174 1.89937C3.23115 2.2381 3.12586 2.94445 3.51375 3.41871L10.5151 14.4432C11.0796 15.3321 12.4563 14.9101 12.4258 13.8574L12.0594 1.28748Z"
        fill="#E35336"
      />
      <path
        d="M11.2049 13.9251L4.20352 2.90057C3.89409 2.52217 3.89942 1.99658 4.17317 1.62738C4.02703 1.7151 3.88307 1.80568 3.74174 1.89943C3.23115 2.23816 3.12586 2.94451 3.51375 3.41877L10.5151 14.4433C10.9733 15.1648 11.9667 15.0225 12.3081 14.3804C11.9145 14.4709 11.47 14.3427 11.2049 13.9251Z"
        fill="#D93C1C"
      />
      <path
        d="M3.14787 13.0483C2.61338 12.7487 1.93603 12.9752 1.69123 13.5368C1.18539 14.6972 0.865581 15.962 0.7723 17.2968C0.679018 18.6316 0.819878 19.9287 1.15943 21.148C1.32378 21.7382 1.96304 22.0567 2.53392 21.8344L10.0989 18.8878C10.9147 18.57 10.9932 17.4464 10.2295 17.0182L3.14787 13.0483Z"
        fill="#E35336"
      />
      <path
        d="M2.13185 21.148C1.7923 19.9286 1.65144 18.6316 1.74472 17.2968C1.838 15.9619 2.15781 14.6971 2.66365 13.5368C2.76133 13.3129 2.92832 13.1433 3.12701 13.038C2.59592 12.7555 1.93293 12.9824 1.69123 13.5368C1.18539 14.6971 0.865581 15.9619 0.7723 17.2968C0.679018 18.6316 0.819878 19.9286 1.15943 21.148C1.32378 21.7382 1.96304 22.0566 2.53392 21.8343L2.65275 21.788C2.40765 21.6609 2.21248 21.4375 2.13185 21.148Z"
        fill="#D93C1C"
      />
      <path
        d="M4.89208 25.605C4.44202 26.0206 4.44812 26.7348 4.90661 27.1413C5.85384 27.9809 6.95786 28.676 8.19859 29.1771C9.43931 29.6783 10.7163 29.9452 11.9809 29.999C12.5931 30.0251 13.0935 29.5155 13.0584 28.9039L12.5938 20.7986C12.5436 19.9245 11.4992 19.5026 10.8561 20.0967L4.89208 25.605Z"
        fill="#E35336"
      />
      <path
        d="M12.5562 29.0151C11.2916 28.9612 10.0145 28.6943 8.77386 28.1932C7.53314 27.692 6.42911 26.997 5.48189 26.1574C5.2495 25.9513 5.13384 25.6663 5.13443 25.381L4.89208 25.6049C4.44202 26.0206 4.44812 26.7348 4.90661 27.1413C5.85384 27.9808 6.95787 28.6759 8.19859 29.1771C9.43931 29.6783 10.7163 29.9451 11.9809 29.9989C12.5917 30.025 13.0909 29.5178 13.0585 28.908C12.9078 28.9828 12.7372 29.0228 12.5562 29.0151Z"
        fill="#D93C1C"
      />
      <path
        d="M17.3741 27.8279C17.6304 28.3844 18.3115 28.5993 18.8397 28.2888C19.9309 27.6474 20.9331 26.8122 21.7932 25.7871C22.6532 24.762 23.3016 23.6299 23.7436 22.4438C23.9576 21.8697 23.6276 21.2363 23.035 21.0807L15.1829 19.0179C14.3361 18.7954 13.6121 19.6583 13.9783 20.4536L17.3741 27.8279Z"
        fill="#E35336"
      />
      <path
        d="M18.2809 27.5041L14.8851 20.1298C14.6941 19.7151 14.8 19.2824 15.0646 18.9944C14.2706 18.8771 13.6293 19.6958 13.9783 20.4537L17.3741 27.8279C17.6303 28.3845 18.3114 28.5994 18.8396 28.2889C18.9415 28.229 19.0423 28.1668 19.1426 28.1037C18.7824 28.0752 18.4447 27.86 18.2809 27.5041Z"
        fill="#D93C1C"
      />
      <path
        d="M23.3419 16.6449C23.9504 16.5731 24.3652 15.9917 24.2331 15.3935C23.9603 14.1574 23.4757 12.9462 22.7665 11.8115C22.0574 10.6767 21.181 9.71023 20.1897 8.92331C19.7097 8.5424 19.0054 8.66046 18.6742 9.17597L14.286 16.0064C13.8127 16.743 14.4097 17.6982 15.2792 17.5957L23.3419 16.6449Z"
        fill="#E35336"
      />
      <path
        d="M24.1501 15.0483C24.0007 15.3519 23.7065 15.5791 23.3413 15.6221L15.2786 16.5729C14.8269 16.6262 14.4493 16.3938 14.2565 16.0552C13.8391 16.7845 14.4282 17.696 15.2786 17.5956L23.3413 16.6448C23.9498 16.5731 24.3647 15.9917 24.2326 15.3935C24.2072 15.2781 24.1792 15.1631 24.1501 15.0483Z"
        fill="#D93C1C"
      />
    </svg>
  );
};

export default YelpIcon;

import classNames from "classnames";
import CiroNumbersFormSection from "../shared/CiroNumbersFormSection";
import CiroTextInput from "../shared/CiroTextInput";
import CiroDropDown from "../shared/CiroDropdown";
import { OrgContactObjectType } from "../../__generated__/graphql";
import { ISharedWorkflowFormProperties } from "./MobileNumbersWorkflowForm";

const StartWorkflowFormSection = ({
  sharedWorkflowFormProperties: {
    register,
    errors,
    isSalesforce,
    setValue,
    trigger,
    watch,
    workflowId,
  },
  isLoadingWorkflow,
  enrichmentNotFound,
}: {
  sharedWorkflowFormProperties: ISharedWorkflowFormProperties;
  isLoadingWorkflow: boolean;
  enrichmentNotFound: boolean;
}) => {
  const orgContactObjectType = watch("org_contact_object_type");

  return (
    <CiroNumbersFormSection
      title={`${workflowId ? "Name" : "Create"} workflow`}
      description="Identify your workflow."
      isLoading={isLoadingWorkflow}
    >
      <>
        <div className={classNames("ciro-v1-mb-4")}>
          <CiroTextInput
            label="Workflow name"
            placeholder="Contacts with missing numbers"
            {...register("name")}
            error={
              enrichmentNotFound
                ? "Workflow not found"
                : (errors.name?.message as string)
            }
          />
        </div>
        {isSalesforce && (
          <div className={classNames("ciro-v1-mb-4")}>
            <CiroDropDown
              isMulti={false}
              label="Which object needs to be enriched with new numbers"
              placeholder="CRM object type"
              onChange={(value) => {
                setValue(
                  "org_contact_object_type",
                  value as OrgContactObjectType,
                );
                trigger("org_contact_object_type");
              }}
              error={errors.org_contact_object_type?.message}
              value={orgContactObjectType}
              options={[
                {
                  label: "Contact",
                  value: OrgContactObjectType.Contact as string,
                },
                {
                  label: "Lead",
                  value: OrgContactObjectType.Lead as string,
                },
              ]}
              infoTooltip={
                "Choose which CRM object will be used to identify records needing new numbers"
              }
            />
          </div>
        )}
      </>
    </CiroNumbersFormSection>
  );
};

export default StartWorkflowFormSection;

import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import React from "react";
import CiroTooltipContainer from "../../../shared/CiroTooltipContainer";

interface IEnrichmentFlowTableDropdownOptionProps {
  option: string | React.ReactNode;
  onClick: () => void;
  Icon: any;
  tooltip?: string | null;
  disabled?: boolean;
}

const EnrichmentFlowTableDropdownOption = ({
  option,
  onClick,
  Icon,
  tooltip,
  disabled,
}: IEnrichmentFlowTableDropdownOptionProps) => {
  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-m-1",
        "ciro-v1-rounded-md",
        "ciro-v1-w-full",
        "hover:ciro-v1-bg-gray-200",
      )}
    >
      <CiroTooltipContainer
        className={"ciro-v1-w-full"}
        disabled={!tooltip}
        tooltip={tooltip}
      >
        <CiroButton
          analyticsField={`enrichmentFlowTableDropdownOption ${option}`}
          style={CiroButtonStyleEnum.UNSTYLED}
          onClick={onClick}
          customClassName={classNames("ciro-v1-w-full", "ciro-v1-p-2")}
          customFlexClass={classNames("ciro-v1-items-center")}
          disabled={disabled}
        >
          <span
            className={classNames(
              "ciro-v1-w-full",
              "ciro-v1-flex",
              "ciro-v1-items-start",
            )}
          >
            <Icon
              className={classNames(
                "ciro-v1-mr-2",
                "ciro-v1-h-5",
                "ciro-v1-text-neutral-400",
              )}
              aria-hidden="true"
            />
            <div className={classNames("ciro-v1-text-neutral-600")}>
              {option}
            </div>
          </span>
        </CiroButton>
      </CiroTooltipContainer>
    </div>
  );
};

export default EnrichmentFlowTableDropdownOption;

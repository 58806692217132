import classNames from "classnames";
import { gql } from "@apollo/client";
import {
  CiroUnlockPremiumDataContent_CreditsToSpendResponseFragment,
  Maybe,
} from "../../../__generated__/graphql";
import NumberFormat from "react-number-format";
import CiroTooltipContainer from "../CiroTooltipContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from "../Loading";

export enum CiroCreditUseCaseEnum {
  UNLOCK_PMS = "UNLOCK_PMS",
  EXPORTS = "EXPORTS",
}

interface ICiroUnlockPremiumDataContentProps {
  creditActions: CiroCreditUseCaseEnum[];
  creditsToSpend?: Maybe<CiroUnlockPremiumDataContent_CreditsToSpendResponseFragment>;
  isLoading: boolean;
  action?: string;
}

export const CiroUnlockPremiumDataContent_CreditsToSpendResponse = gql`
  fragment CiroUnlockPremiumDataContent_CreditsToSpendResponse on CreditsToSpendResponse {
    exports {
      creditsToSpend
      accountsCount
    }
    parentType
    unlockPms {
      creditsToSpend
      accountsCount
    }
    totalCompanyCount
    totalParentCount
    currentBalance
    premiumDataMultiplier
  }
`;

const CiroUnlockPremiumDataContent = ({
  action,
  creditActions,
  creditsToSpend,
  isLoading,
}: ICiroUnlockPremiumDataContentProps) => {
  if (isLoading) {
    return (
      <div className={classNames("ciro-v1-py-4")}>
        <Loading size="SMALL" />
      </div>
    );
  }

  if (!creditsToSpend) {
    return null;
  }

  const unlockPmsCredits = creditsToSpend.unlockPms.creditsToSpend || 0;
  const exportCredits = creditsToSpend.exports.creditsToSpend || 0;
  const currentBalance = creditsToSpend.currentBalance || 0;
  const totalCompanyCount = creditsToSpend.totalCompanyCount || 0;
  const premiumDataMultiplier = creditsToSpend.premiumDataMultiplier || 0;
  const totalParentCount = creditsToSpend.totalParentCount || 0;

  let totalCreditsNeeded = 0;
  let remainingBalance = currentBalance;
  if (creditActions.includes(CiroCreditUseCaseEnum.UNLOCK_PMS)) {
    totalCreditsNeeded += unlockPmsCredits;
    remainingBalance -= unlockPmsCredits;
  }
  if (creditActions.includes(CiroCreditUseCaseEnum.EXPORTS)) {
    totalCreditsNeeded += exportCredits;
    remainingBalance -= exportCredits;
  }

  return (
    <div
      className={classNames(
        "ciro-v1-text-black",
        "ciro-v1-text-base",
        "ciro-v1-font-normal",
      )}
    >
      {Boolean(action) && (
        <div className={classNames("ciro-v1-pt-4")}>
          {action}{" "}
          <NumberFormat
            value={totalParentCount}
            thousandSeparator=","
            displayType="text"
          />
          {" accounts"}
        </div>
      )}
      {creditActions.includes(CiroCreditUseCaseEnum.UNLOCK_PMS) && (
        <div className={classNames("ciro-v1-pt-4")}>
          <div>
            Practice Management System (PMS) Software
            {unlockPmsCredits > 0 && (
              <CiroTooltipContainer
                tooltip={`Ciro verified data requires ${premiumDataMultiplier} credits per record. You only need credits for accounts that you have not already unlocked.`}
              >
                <span className={classNames("ciro-v1-ml-2")}>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    color="rgba(0,0,0,.55)"
                  />
                </span>
              </CiroTooltipContainer>
            )}
          </div>
          <div
            className={classNames("ciro-v1-text-sm", "ciro-v1-text-gray-500")}
          >
            Accounts to unlock:{" "}
            <NumberFormat
              value={creditsToSpend.unlockPms.accountsCount}
              thousandSeparator=","
              displayType="text"
            />
          </div>
          <div
            className={classNames("ciro-v1-text-sm", "ciro-v1-text-gray-500")}
          >
            Credits needed:{" "}
            <NumberFormat
              value={unlockPmsCredits}
              thousandSeparator=","
              displayType="text"
            />
          </div>
        </div>
      )}
      {creditActions.includes(CiroCreditUseCaseEnum.EXPORTS) && (
        <div className={classNames("ciro-v1-pt-4")}>
          <div>
            Exports
            {totalCompanyCount > exportCredits && (
              <CiroTooltipContainer tooltip="You only need credits for accounts that you have not already exported.">
                <span className={classNames("ciro-v1-ml-2")}>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    color="rgba(0,0,0,.55)"
                  />
                </span>
              </CiroTooltipContainer>
            )}
          </div>
          <div
            className={classNames("ciro-v1-text-sm", "ciro-v1-text-gray-500")}
          >
            First time exports:{" "}
            <NumberFormat
              value={creditsToSpend.exports.accountsCount}
              thousandSeparator=","
              displayType="text"
            />
          </div>
          <div
            className={classNames("ciro-v1-text-sm", "ciro-v1-text-gray-500")}
          >
            Credits needed:{" "}
            <NumberFormat
              value={exportCredits}
              thousandSeparator=","
              displayType="text"
            />
          </div>
        </div>
      )}
      <div className={classNames("ciro-v1-pt-4")}>
        <div className={classNames("ciro-v1-font-medium")}>
          Total credits needed:{" "}
          <NumberFormat
            value={totalCreditsNeeded}
            thousandSeparator=","
            displayType="text"
          />
        </div>
        <div className={classNames("ciro-v1-text-sm", "ciro-v1-text-gray-500")}>
          Remaining credits after transaction:{" "}
          <span
            className={classNames({
              "ciro-v1-text-rose-500": remainingBalance < 0,
            })}
          >
            <NumberFormat
              value={remainingBalance}
              thousandSeparator=","
              displayType="text"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CiroUnlockPremiumDataContent;

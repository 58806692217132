import CiroButton, { CiroButtonStyleEnum } from "../CiroButton";
import {
  InternalRefetchQueriesInclude,
  gql,
  useMutation,
} from "@apollo/client";
import {
  CiroUnlockPremiumDataSubmit_UnlockPremiumDataMutation,
  CiroUnlockPremiumDataSubmit_UnlockPremiumDataMutationVariables,
  CompaniesFromFiltersAndCountInput,
  PremiumDataFieldEnum,
} from "../../../__generated__/graphql";
import { useCallback, useContext } from "react";
import NavigationContext from "../../../contexts/NavigationContext";

interface ICiroUnlockPremiumDataSubmit {
  companyPks?: string[];
  companyQuery?: CompaniesFromFiltersAndCountInput;
  disabled?: boolean;
  onCompleted: () => void;
  refetchQueries?: InternalRefetchQueriesInclude;
}

const CiroUnlockPremiumDataSubmit_UnlockPremiumData = gql`
  mutation CiroUnlockPremiumDataSubmit_UnlockPremiumData(
    $companyQuery: CompaniesFromFiltersAndCountInput
    $companyPks: [ID!]
    $premiumFields: [PremiumDataFieldEnum!]!
  ) {
    unlockPremiumData(
      companyQuery: $companyQuery
      companyPks: $companyPks
      premiumFields: $premiumFields
    ) {
      success
    }
  }
`;

const CiroUnlockPremiumDataSubmit = ({
  companyPks,
  companyQuery,
  disabled = false,
  onCompleted,
  refetchQueries = [],
}: ICiroUnlockPremiumDataSubmit) => {
  const [unlockPremiumData, { loading: unlockPremiumDataLoading }] =
    useMutation<
      CiroUnlockPremiumDataSubmit_UnlockPremiumDataMutation,
      CiroUnlockPremiumDataSubmit_UnlockPremiumDataMutationVariables
    >(CiroUnlockPremiumDataSubmit_UnlockPremiumData);

  const { refreshNavData } = useContext(NavigationContext);

  const handleSubmit = useCallback(() => {
    unlockPremiumData({
      variables: {
        companyQuery,
        companyPks,
        premiumFields: [PremiumDataFieldEnum.PracticeManagementSystem],
      },
      refetchQueries,
      onCompleted: () => {
        onCompleted();
        
        // Refresh credit balance in navigation
        refreshNavData();
      },
    });
  }, [
    companyPks,
    companyQuery,
    onCompleted,
    refetchQueries,
    refreshNavData,
    unlockPremiumData,
  ]);

  return (
    <CiroButton
      disabled={unlockPremiumDataLoading || disabled}
      style={CiroButtonStyleEnum.LOUD}
      analyticsField="Unlock premium data"
      onClick={handleSubmit}
    >
      Unlock
    </CiroButton>
  );
};

export default CiroUnlockPremiumDataSubmit;

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import url from "url";
import { format, parseJSON } from "date-fns";

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
export const formatTimestamp = (
  isoMillisStr: Date | string | null | undefined,
) => {
  if (!isoMillisStr) {
    return null;
  }

  const valueToFormat =
    isoMillisStr instanceof Date
      ? isoMillisStr
      : new Date(parseInt(isoMillisStr));

  const timeAgo = new TimeAgo("en-US");
  const formattedDate = timeAgo.format(valueToFormat);
  return formattedDate;
};

export const formatDate = (date: string) => {
  return format(parseJSON(date), "yyyy-MM-dd h:mm:ssa");
};

export const dateToTimeAgo = (date: string) => {
  const timeAgo = new TimeAgo("en-US");
  const dateInput = new Date(date);
  return timeAgo.format(dateInput);
};

export const externalUrlWithProtocol = (url: url.UrlWithStringQuery | null) => {
  if (!url) {
    return;
  }

  return url.protocol ? url.href : `//${url.href}`;
};

export const prettifyUrl = (
  url: url.UrlWithStringQuery | null,
  includePath = false,
) => {
  if (!url?.host) {
    return url?.href?.replace("www.", "");
  }

  let returnVal = url.host;
  if (url.pathname && includePath) {
    returnVal = returnVal.concat(String(url.pathname));
  }
  return returnVal.replace("www.", "");
};

import React, { useEffect, useMemo, useState } from "react";
import FolderPlusIcon from "@heroicons/react/20/solid/FolderPlusIcon";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import CiroModal from "../shared/CiroModal";
import CiroTextInput from "../shared/CiroTextInput";
import { gql, useMutation } from "@apollo/client";
import {
  NewCollectionBtnAndModal_PermissionFragment,
  NewCollectionBtnAndModal_UpsertCollectionMutation,
  NewCollectionBtnAndModal_UpsertCollectionMutationVariables,
} from "../../__generated__/graphql";
import { Maybe } from "graphql/jsutils/Maybe";
import { Collections_collections } from "../../routes/collections/Collections";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const NewColectionBtnAndModalSchema = yup.object({
  collectionName: yup.string().required("Collection name is required"),
});

const NewCollectionBtnAndModal_UpsertCollection = gql`
  mutation NewCollectionBtnAndModal_UpsertCollection($name: String) {
    upsertCollections(
      changes: [{ method: CREATE, name: $name, companyPks: [] }]
    ) {
      success
    }
  }
`;

export const NewCollectionBtnAndModal_Permission = gql`
  fragment NewCollectionBtnAndModal_Permission on Permissions {
    canEditCollectionsByPk
    canEditCollectionsByQuery
  }
`;

interface INewCollectionBtnAndModalProps {
  permissions?: Maybe<NewCollectionBtnAndModal_PermissionFragment>;
}

const NewCollectionBtnAndModal = ({
  permissions,
}: INewCollectionBtnAndModalProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(NewColectionBtnAndModalSchema),
    defaultValues: {
      collectionName: "",
    },
  });

  const [collectionName] = useWatch({
    control,
    name: ["collectionName"],
  });

  const [isOpen, setIsOpen] = useState(false);
  const [
    createCollection,
    {
      data: createCollectionData,
      loading: createCollectionLoading,
      error: createCollectionError,
      reset: resetCreateCollectionData,
    },
  ] = useMutation<
    NewCollectionBtnAndModal_UpsertCollectionMutation,
    NewCollectionBtnAndModal_UpsertCollectionMutationVariables
  >(NewCollectionBtnAndModal_UpsertCollection);

  const isSuccess = useMemo(() => {
    return Boolean(
      createCollectionData?.upsertCollections &&
        createCollectionData?.upsertCollections[0]?.success,
    );
  }, [createCollectionData?.upsertCollections]);

  useEffect(() => {
    if (!isOpen) {
      resetCreateCollectionData();
      setValue("collectionName", "");
    }
  }, [isOpen, resetCreateCollectionData, setValue]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    }
  }, [isSuccess, resetCreateCollectionData]);

  if (
    !permissions?.canEditCollectionsByPk &&
    !permissions?.canEditCollectionsByPk
  ) {
    return null;
  }

  return (
    <div>
      <CiroButton
        analyticsField="Create collection clicked"
        onClick={() => setIsOpen(true)}
      >
        <FolderPlusIcon
          className={classNames(
            "ciro-v1-mr-1",
            "ciro-v1-h-5 w-5",
            "ciro-v1-text-gray-400",
          )}
          aria-hidden="true"
        />
      </CiroButton>
      <CiroModal onClose={() => setIsOpen(false)} isOpen={isOpen}>
        <div className={classNames("ciro-v1-font-medium")}>
          Create a new collection
        </div>
        <div className={classNames("ciro-v1-text-zinc-500", "ciro-v1-text-xs")}>
          In order to add records to a collection, please visit the accounts
          page and select records to add.
        </div>
        <div className={classNames("ciro-v1-py-4")}>
          <CiroTextInput
            placeholder="Collection Name"
            register={register("collectionName")}
            error={errors.collectionName?.message}
          />
        </div>
        {(createCollectionError || (createCollectionData && !isSuccess)) && (
          <div
            className={classNames("ciro-v1-text-xs", "ciro-v1-text-rose-500")}
          >
            Error creating your collection
          </div>
        )}
        <div className={classNames("ciro-v1-flex", "ciro-v1-justify-end")}>
          <CiroButton
            style={CiroButtonStyleEnum.INVERTED}
            analyticsField="Create new collection"
            disabled={createCollectionLoading || isSuccess}
            onClick={handleSubmit(() => {
              createCollection({
                variables: {
                  name: collectionName,
                },
                refetchQueries: [{ query: Collections_collections }],
              });
            })}
          >
            Submit
          </CiroButton>
        </div>
        {isSuccess && (
          <div
            className={classNames(
              "ciro-v1-text-xs",
              "ciro-v1-text-green-600",
              "ciro-v1-pt-2",
            )}
          >
            Collection Created Successfully
          </div>
        )}
      </CiroModal>
    </div>
  );
};

export default NewCollectionBtnAndModal;

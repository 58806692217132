import { useState } from "react";
import EnrichmentExecutionDetailsNode from "./EnrichmentExecutionDetailsNode";
import EnrichmentExecutionDetailsJsonBlock from "./EnrichmentExecutionDetailsJsonBlock";

const EnrichmentExecutionDetailsKeyValuePair = ({
  data,
  jsonObjectkey,
  currentPath,
  isLastKeyInObject,
}: {
  data: any;
  jsonObjectkey: string;
  currentPath: string[];
  isLastKeyInObject: boolean;
}) => {
  const valueDisplay = Array.isArray(data[jsonObjectkey])
    ? "array"
    : typeof data[jsonObjectkey] === "object"
      ? "object"
      : null;

  const [isCollapsed, setIsCollapsed] = useState(valueDisplay != null);

  return (
    <div
      key={jsonObjectkey}
      style={{
        marginLeft: `${20}px`,
      }}
      className="ciro-v1-my-1"
    >
      <EnrichmentExecutionDetailsNode
        isCollapsed={isCollapsed}
        isCollapsible={valueDisplay != null}
        data={data}
        jsonObjectkey={jsonObjectkey}
        currentPath={currentPath}
        toggleCollapsed={() =>
          setIsCollapsed((toggleCollapsed) => !toggleCollapsed)
        }
      />
      : {valueDisplay === "array" && "["}
      {valueDisplay === "object" && "{"}
      {isCollapsed && "..."}
      {!isCollapsed && (
        <EnrichmentExecutionDetailsJsonBlock
          data={data[jsonObjectkey]}
          currentPath={[...currentPath, jsonObjectkey]}
        />
      )}
      {valueDisplay === "array" && "]"}
      {valueDisplay === "object" && "}"}
      {!isLastKeyInObject && ","}
    </div>
  );
};

export default EnrichmentExecutionDetailsKeyValuePair;

import CiroNumbersFormSection from "../shared/CiroNumbersFormSection";
import { ISharedWorkflowFormProperties } from "./MobileNumbersWorkflowForm";
import CiroDatePicker from "../shared/CiroDatePicker";
import classNames from "classnames";

interface IFilterCutoffDateOptionProps {
  sharedWorkflowFormProperties: ISharedWorkflowFormProperties;
  isLoadingWorkflow: boolean;
}

const FilterCutoffDateOption = ({
  sharedWorkflowFormProperties,
  isLoadingWorkflow,
}: IFilterCutoffDateOptionProps) => {
  const { watch, setValue } = sharedWorkflowFormProperties;

  const date = watch("date_filter");

  return (
    <CiroNumbersFormSection
      title="Date Filter"
      description="Only process records and calls created after this date."
      isLoading={isLoadingWorkflow}
    >
      <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
        <div className={classNames("ciro-v1-mr-4")}>
          Only process records created after this date:
        </div>
        <CiroDatePicker
          placeholderText=""
          selectedDate={date}
          setSelectedDate={(date) => setValue("date_filter", date)}
        />
      </div>
    </CiroNumbersFormSection>
  );
};

export default FilterCutoffDateOption;

import React from "react";
import { EnrichmentTechniqueEnum } from "../../../__generated__/graphql";
import {
  CiroDropdownButton,
  CiroDropdownButtonItem,
} from "../../shared/CiroDropdownButton";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import LinkArrowIcon from "../../../assets/img/icons/LinkArrowIcon";

interface IEnrichmentStepCardHeaderLink {
  enrichmentTechnique?: EnrichmentTechniqueEnum | null;
}

const getHelpCenterUrl = (
  enrichmentTechnique: EnrichmentTechniqueEnum | null,
): string | null => {
  switch (enrichmentTechnique) {
    case EnrichmentTechniqueEnum.ChatGpt:
      return "https://ciro-hq.notion.site/CiroBot-Generate-Text-fb076dfe441143b59f28f26c77b6b3c8";
    case EnrichmentTechniqueEnum.LlmWebResearch:
      return "https://ciro-hq.notion.site/CiroBot-AI-Scrape-a-Website-1e4bfc5257c641329d93647c7c47781b";
    case EnrichmentTechniqueEnum.SerpApi:
      return "https://ciro-hq.notion.site/Search-Google-da4a59b19ab34e0e87e33feac3aa1d5e";
    case EnrichmentTechniqueEnum.FindContact:
      return "https://ciro-hq.notion.site/Find-LinkedIn-Contact-106516525f6d411d9145c9b2a7bee0a3";
    case EnrichmentTechniqueEnum.FindEmail:
      return "https://ciro-hq.notion.site/Enrich-Emails-with-Hunter-io-7333c1bf55dd49d9843c2cd4dd3e7197";
    default:
      return null;
  }
};

const EnrichmentStepCardHeaderExplainerDropdown = ({
  enrichmentTechnique,
}: IEnrichmentStepCardHeaderLink) => {
  const url = enrichmentTechnique
    ? getHelpCenterUrl(enrichmentTechnique)
    : null;
  if (!url) {
    return null;
  }
  return (
    <CiroDropdownButton
      borderless
      customBgColor="ciro-v1-bg-inherit"
      customPadding={classNames("ciro-v1-pl-0", "ciro-v1-py-2", "ciro-v1-pr-4")}
      label={
        <QuestionMarkCircleIcon
          className={classNames("ciro-v1-h-5", "ciro-v1-text-neutral-500")}
        />
      }
      hideChevron
    >
      <CiroDropdownButtonItem
        onClick={() => {
          window.open(url, "_blank");
        }}
        analyticsField={`clickedEnrichmentStepHelpCenter_${enrichmentTechnique}`}
      >
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-row",
            "ciro-v1-items-center",
          )}
        >
          <div className={classNames("ciro-v1-mr-2", "ciro-v1-text-neutral-600")}>Learn how</div>
          <div className={classNames("ciro-v1-text-orange-400")}>
            <LinkArrowIcon />
          </div>
        </div>
      </CiroDropdownButtonItem>
    </CiroDropdownButton>
  );
};

export default EnrichmentStepCardHeaderExplainerDropdown;

import { EnrichmentTechniqueEnum } from "../../__generated__/graphql";
import StarsIcon from "../../assets/img/icons/StarsIcon";
import GoogleIcon from "../../assets/img/icons/GoogleIcon";
import {
  Bars2Icon,
  MinusCircleIcon,
  PlusCircleIcon,
  PresentationChartBarIcon,
  RectangleGroupIcon,
  TagIcon,
  EnvelopeIcon,
  UserCircleIcon,
  PhoneArrowUpRightIcon,
  ArrowsPointingInIcon,
  CpuChipIcon,
} from "@heroicons/react/24/outline";
import LogoIcon from "../../assets/img/icons/LogoIcon";

const enrichmentTechniqueEnumToIcon = {
  [EnrichmentTechniqueEnum.ChatGpt]: StarsIcon,
  [EnrichmentTechniqueEnum.SerpApi]: GoogleIcon,
  [EnrichmentTechniqueEnum.FindCiroCompany]: LogoIcon,
  [EnrichmentTechniqueEnum.FindPhone]: PhoneArrowUpRightIcon,
  [EnrichmentTechniqueEnum.ReturnValue]: TagIcon,
  [EnrichmentTechniqueEnum.FindCrmAccount]: PresentationChartBarIcon,
  [EnrichmentTechniqueEnum.Coalesce]: ArrowsPointingInIcon,
  [EnrichmentTechniqueEnum.FiltersEquals]: Bars2Icon,
  [EnrichmentTechniqueEnum.FiltersIsNull]: MinusCircleIcon,
  [EnrichmentTechniqueEnum.FiltersIsNotNull]: PlusCircleIcon,
  [EnrichmentTechniqueEnum.FiltersIncludes]: RectangleGroupIcon,
  [EnrichmentTechniqueEnum.FindEmail]: EnvelopeIcon,
  [EnrichmentTechniqueEnum.LlmWebResearch]: CpuChipIcon,
  [EnrichmentTechniqueEnum.FindContact]: UserCircleIcon,
} as { [name: string]: any };

export default enrichmentTechniqueEnumToIcon;

import classNames from "classnames";
import CiroModal from "../shared/CiroModal";
import XIcon from "../../assets/img/icons/XIcon";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { gql, useQuery } from "@apollo/client";
import {
  EnrichmentFlowRunModal_UserAccountQuery,
  EnrichmentFlowRunModal_UserAccountQueryVariables,
} from "../../__generated__/graphql";
import CiroCoinPill from "../shared/CiroCoinPill";
import { InformationCircleIcon, WalletIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import EnrichmentFlowContext from "../../contexts/EnrichmentFlowContext";
import enrichmentTechniqueEnumToIcon from "./enrichmentTechniqueEnumToIcon";

export interface IRunStepParams {
  enrichmentStepId: number | null;
  enrichmentFlowRowIds: number[] | null;
  numRowsToRun: number | null;
}

const EnrichmentFlowRunModal_UserAccount = gql`
  query EnrichmentFlowRunModal_UserAccount {
    userAccount {
      org {
        credit_plan {
          balance
        }
      }
    }
  }
`;

const EnrichmentFlowRunModal = ({
  runStepParams,
  setRunStepParams,
  runStep,
}: {
  runStepParams: IRunStepParams | null;
  setRunStepParams: (v: IRunStepParams | null) => void;
  runStep: (v: IRunStepParams) => void;
}) => {
  const { enrichmentFlowWithSteps, enrichmentFlowRowIds } = useContext(
    EnrichmentFlowContext,
  );
  const { data: userAccountData } = useQuery<
    EnrichmentFlowRunModal_UserAccountQuery,
    EnrichmentFlowRunModal_UserAccountQueryVariables
  >(EnrichmentFlowRunModal_UserAccount);

  if (!runStepParams) {
    return null;
  }

  const enrichmentFlowSteps = runStepParams?.enrichmentStepId
    ? enrichmentFlowWithSteps?.enrichmentSteps.filter(
        (step) => step.id === runStepParams.enrichmentStepId,
      )
    : enrichmentFlowWithSteps?.enrichmentSteps.filter(
        (step) => (step.enrichmentTechniqueDetails.costPerExecution || 0) > 0,
      );

  const balance = userAccountData?.userAccount?.org?.credit_plan?.balance || 0;
  const totalRows =
    runStepParams?.numRowsToRun ||
    runStepParams?.enrichmentFlowRowIds?.length ||
    enrichmentFlowRowIds.length;
  const potentialCostPerRow =
    enrichmentFlowSteps?.reduce(
      (acc, step) =>
        acc + (step.enrichmentTechniqueDetails.costPerExecution || 0),
      0,
    ) || 0;
  const totalPotentialCost = totalRows * potentialCostPerRow;

  return (
    <CiroModal
      isOpen={runStepParams != null}
      onClose={() => setRunStepParams(null)}
    >
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-between",
          "ciro-v1-items-center",
        )}
      >
        <div className={classNames("ciro-v1-text-lg", "ciro-v1-font-semibold")}>
          Enrichment Summary
        </div>
        <CiroButton
          style={CiroButtonStyleEnum.UNSTYLED}
          analyticsField={"Closing Enrichment Flow Run Modal"}
          onClick={() => setRunStepParams(null)}
        >
          <XIcon />
        </CiroButton>
      </div>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-between",
          "ciro-v1-bg-neutral-100",
          "ciro-v1-rounded-lg",
          "ciro-v1-p-4",
          "ciro-v1-mt-4",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-text-neutral-600",
            "ciro-v1-flex",
            "ciro-v1-whitespace-nowrap",
          )}
        >
          <div className={classNames("ciro-v1-h-5", "ciro-v1-w-5")}>
            <WalletIcon />
          </div>
          <div className={classNames("ciro-v1-text-sm", "ciro-v1-pl-2")}>
            Your Balance
          </div>
        </div>
        <CiroCoinPill amount={balance} />
      </div>
      <div
        className={classNames(
          "ciro-v1-mt-4",
          "ciro-v1-border-neutral-200",
          "ciro-v1-border-1",
          "ciro-v1-rounded-lg",
          "ciro-v1-p-4",
        )}
      >
        {enrichmentFlowSteps?.map((step) => {
          const Icon =
            enrichmentTechniqueEnumToIcon[step.enrichment_technique] || null;
          return (
            <div
              key={step.id}
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-justify-between",
                "ciro-v1-text-sm",
              )}
            >
              <div className={classNames("ciro-v1-flex")}>
                {Icon && (
                  <Icon
                    className={classNames(
                      "ciro-v1-w-4",
                      "ciro-v1-h-4",
                      "ciro-v1-mr-2",
                      "ciro-v1-mt-0.5",
                    )}
                  />
                )}
                <div className={classNames("ciro-v1-pr-2")}>
                  {step.enrichmentTechniqueDetails.description}
                </div>
              </div>
              <div>
                <CiroCoinPill
                  amount={step.enrichmentTechniqueDetails.costPerExecution || 0}
                />
              </div>
            </div>
          );
        })}
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-justify-between",
            "ciro-v1-border-t-1",
            "ciro-v1-border-neutral-200",
            "ciro-v1-border-dashed",
            "ciro-v1-py-4",
            "ciro-v1-mt-4",
          )}
        >
          <div
            className={classNames("ciro-v1-font-semibold", "ciro-v1-text-sm")}
          >
            Total
          </div>
          <div
            className={classNames(
              "ciro-v1-text-sm",
              "ciro-v1-flex",
              "ciro-v1-text-neutral-600",
            )}
          >
            {totalRows} rows&nbsp;&#215;&nbsp;
            <CiroCoinPill amount={potentialCostPerRow} />
            &nbsp;=&nbsp;
            <CiroCoinPill
              additionalText="Maximum"
              amount={totalPotentialCost}
            />
          </div>
        </div>
        <div
          className={classNames(
            "ciro-v1-bg-neutral-100",
            "ciro-v1-flex",
            "ciro-v1-text-xs",
            "ciro-v1-items-center",
            "ciro-v1-p-2",
            "ciro-v1-rounded-lg",
            "ciro-v1-text-neutral-500",
          )}
        >
          <InformationCircleIcon className={classNames("ciro-v1-w-5")} />
          <div className={classNames("ciro-v1-pl-2")}>
            The maximum value will be deducted progressively. You will only be
            charged for completed tasks.
          </div>
        </div>
      </div>
      <div className={classNames("ciro-v1-w-full", "ciro-v1-pt-4")}>
        <CiroButton
          analyticsField={"Confirm Run Enrichment Flow"}
          customClassName={classNames("ciro-v1-w-full")}
          onClick={() => {
            runStep(runStepParams);
            setRunStepParams(null);
          }}
          disabled={balance === 0 || totalPotentialCost > balance}
          style={CiroButtonStyleEnum.LOUD}
        >
          Confirm
        </CiroButton>
      </div>
      {totalPotentialCost > balance && (
        <div
          className={classNames(
            "ciro-v1-bg-red-50",
            "ciro-v1-flex",
            "ciro-v1-text-xs",
            "ciro-v1-items-center",
            "ciro-v1-p-2",
            "ciro-v1-mt-4",
            "ciro-v1-rounded-lg",
            "ciro-v1-text-red-900",
          )}
        >
          <InformationCircleIcon className={classNames("ciro-v1-w-5")} />
          <div className={classNames("ciro-v1-pl-2")}>
            You don't have enough credits to complete this enrichment.{" "}
            <a
              className={classNames(
                "ciro-v1-text-red-600",
                "ciro-v1-underline",
              )}
              href="https://www.ciro.io/pricing"
            >
              Upgrade your plan
            </a>{" "}
            to increase your credit limits.
          </div>
        </div>
      )}
    </CiroModal>
  );
};

export default EnrichmentFlowRunModal;

import { useEffect, useState } from "react";
import { analytics } from "../../../utils/vendors";

interface IUseFilterBaseProps {
  searchParams: URLSearchParams;
  filterName: string;
}

const useFilterBaseNumber = <T extends number | null>({
  searchParams,
  filterName,
}: IUseFilterBaseProps): [T, React.Dispatch<T>] => {
  const [filter, setFilter] = useState<T>(
    Number(searchParams.get(filterName) || 0) as T,
  );

  useEffect(() => {
    if (filter) {
      analytics.track("singleFilterUsed", {
        filter: filterName,
        value: filter,
      });
    }
  }, [filter, filterName]);

  return [filter, setFilter];
};

export default useFilterBaseNumber;

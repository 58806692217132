import React, { useState } from "react";
import CiroModal from "../../shared/CiroModal";
import {
  AdminDestroyUserConfirmation_UserAccountFragmentDoc,
  AdminEditUserModal_UserAccountQuery,
  AdminEditUserModal_UserAccountQueryVariables,
} from "../../../__generated__/graphql";
import { gql, useQuery } from "@apollo/client";
import AdminViewUserDetails, {
  AdminViewUserDetails_UserAccount,
} from "./AdminViewUserDetails";
import AdminDestroyUserConfirmation, {
  AdminDestroyUserConfirmation_UserAccount,
} from "./AdminDestroyUserConfirmation";
import { useFragment } from "../../../__generated__";
import { AdminFindUserDropdown_UserAccounts } from "./AdminFindUserDropdown";

// Used in AdminViewUserDetails.tsx
export const AdminEditUserModal_UserAccount = gql`
  query AdminEditUserModal_UserAccount($userAccountId: Int!) {
    userAccount(userAccountId: $userAccountId) {
      email
      id
      organization
      ...AdminViewUserDetails_UserAccount
      ...AdminDestroyUserConfirmation_UserAccount
    }
  }
  ${AdminViewUserDetails_UserAccount}
  ${AdminDestroyUserConfirmation_UserAccount}
`;

interface IAdminEditUserModalProps {
  setUserId: (v: number | null) => void;
  userId: number | null;
}

export enum UserDetailsModalStateEnum {
  VIEW,
  DELETE_CONFIRMATION,
}

const AdminEditUserModal = ({
  setUserId,
  userId,
}: IAdminEditUserModalProps) => {
  const [modalState, setModalState] = useState<UserDetailsModalStateEnum>(
    UserDetailsModalStateEnum.VIEW,
  );
  const { data: userAccountData } = useQuery<
    AdminEditUserModal_UserAccountQuery,
    AdminEditUserModal_UserAccountQueryVariables
  >(AdminEditUserModal_UserAccount, {
    variables: { userAccountId: Number(userId) },
    skip: !userId,
  });

  const destroyUserconfirmationUserAccount = useFragment(
    AdminDestroyUserConfirmation_UserAccountFragmentDoc,
    userAccountData?.userAccount,
  );

  if (!userAccountData?.userAccount) {
    return null;
  }

  const { userAccount } = userAccountData;

  return (
    <CiroModal
      size="LARGE"
      onClose={() => {
        setUserId(null);
      }}
      isOpen={Boolean(userId)}
    >
      {modalState === UserDetailsModalStateEnum.VIEW && (
        <AdminViewUserDetails
          userAccount={userAccount}
          setModalState={setModalState}
        />
      )}
      {modalState === UserDetailsModalStateEnum.DELETE_CONFIRMATION && (
        <AdminDestroyUserConfirmation
          userAccount={destroyUserconfirmationUserAccount}
          handleReturnToLastView={() =>
            setModalState(UserDetailsModalStateEnum.VIEW)
          }
          onClose={() => setUserId(null)}
          refetchQueries={[
            {
              query: AdminFindUserDropdown_UserAccounts,
              variables: {
                showDeleted: false,
              },
            },
            {
              query: AdminFindUserDropdown_UserAccounts,
              variables: {
                showDeleted: true,
              },
            },
          ]}
        />
      )}
    </CiroModal>
  );
};

export default AdminEditUserModal;

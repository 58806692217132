import classNames from "classnames";
import { Link } from "react-router-dom";

export enum CiroTableCellAlignEnum {
  CENTER,
  LEFT,
  RIGHT,
}

interface ICiroTableCellProps {
  align?: CiroTableCellAlignEnum;
  children: any;
  className?: string;
  colSpan?: number;
  href?: string;
  onRowClick?: () => void;
  lastLeft?: boolean;
  lastRight?: boolean;
  padding?: number;
  useFlex?: boolean;
}

const CiroTableCell = ({
  align = CiroTableCellAlignEnum.LEFT,
  children,
  className,
  colSpan = 1,
  href,
  onRowClick,
  lastLeft = false,
  lastRight = false,
  padding = 4,
  useFlex = false,
}: ICiroTableCellProps) => {
  return (
    <td
      align={align as any}
      colSpan={colSpan}
      className={classNames(
        "ciro-v1-font-normal",
        "ciro-v1-table-cell",
        "ciro-v1-text-gray-500",
        "ciro-v1-border-b-1",
        "ciro-v1-border-x-0",
        "ciro-v1-text-sm",
        className,
        {
          "ciro-v1-items-center": align === CiroTableCellAlignEnum.CENTER,
          "ciro-v1-text-center": align === CiroTableCellAlignEnum.CENTER,
          "ciro-v1-text-left": align === CiroTableCellAlignEnum.LEFT,
          "ciro-v1-text-right": align === CiroTableCellAlignEnum.RIGHT,
          "ciro-v1-items-right": align === CiroTableCellAlignEnum.RIGHT,
          "ciro-v1-justify-end": align === CiroTableCellAlignEnum.RIGHT,
        },
        {
          "ciro-v1-rounded-bl-lg": lastLeft,
          "ciro-v1-rounded-br-lg": lastRight,
        },
      )}
    >
      {href ? (
        <Link to={href}>
          <div
            className={classNames(`ciro-v1-p-${padding}`, {
              "ciro-v1-flex": useFlex,
              "ciro-v1-justify-end": align === CiroTableCellAlignEnum.RIGHT,
              "ciro-v1-items-center": align === CiroTableCellAlignEnum.CENTER,
            })}
          >
            {children}
          </div>
        </Link>
      ) : (
        <div
          onClick={() => {
            onRowClick?.();
          }}
          className={classNames(`ciro-v1-p-${padding}`, {
            "ciro-v1-flex": useFlex,
            "ciro-v1-justify-end": align === CiroTableCellAlignEnum.RIGHT,
            "ciro-v1-items-center": align === CiroTableCellAlignEnum.CENTER,
          })}
        >
          {children}
        </div>
      )}
    </td>
  );
};

export default CiroTableCell;

import classNames from "classnames";
import { FormProvider, useWatch } from "react-hook-form";
import {
  EnrichmentStep,
  EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
  EnrichmentStepCardFiltersList_EnrichmentStepFragment,
  EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
  EnrichmentStepTechniqueGetContactCard_StatesQuery,
  EnrichmentStepTechniqueGetContactCard_StatesQueryVariables,
  EnrichmentTechniqueEnum,
} from "../../../../__generated__/graphql";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect } from "react";
import { IEnrichmentTemplateVariable } from "../EnrichmentStepRichTextEditor/EnrichmentStepRichTextContainer";
import EnrichmentStepCardFiltersList, {
  EnrichmentStepCardFiltersListSchema,
  parseEnrichmentStepsForGraphQL,
  parseEnrichmentStepsForList,
} from "../EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import { useFragment } from "../../../../__generated__";
import EnrichmentStepSelector from "../EnrichmentStepSelector/EnrichmentStepSelector";
import { IEnrichmentStepTechniqueCardProps } from ".";
import EnrichmentStepCardContainer from "../EnrichmentStepCardContainer";
import useEnrichmentStepForm from "../../../../reactHooks/enrichmentFlow/useEnrichmentStepForm";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import {
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import CiroCreateableTextInput from "../../../shared/CiroCreateableTextInput";
import {
  LogicTypeEnum,
} from "./FindContactComponents/EnrichmentStepTechniqueFindContactDropdown";
import EnrichmentTechniqueFindContactLogicSelector from "./FindContactComponents/EnrichmentStepTechniqueFindContactLogicSelector";
import CiroCheckbox from "../../../shared/CiroCheckbox";
import CiroDropDown, { formatOptionsForCiroDropdown } from "../../../shared/CiroDropdown";
import { useQuery, gql } from "@apollo/client";

export const LINKEDIN_URL_INPUT_KEY = "linkedinUrl";
export const TITLE_PICKLISTS_LIST_KEY = "titlePicklists";
export const PICKLIST_TITLES_KEY = "titles";
export const PICKLIST_LOGIC_KEY = "logic";
export const CITIES_INPUT_KEY = "cities";
export const STATES_INPUT_KEY = "states";
export const US_ONLY_INPUT_KEY = "usOnly";

export interface IEnrichmentStepTechniqueGetContactCardPicklists {
  logic: LogicTypeEnum;
  titles: string[];
}

export const EnrichmentStepTechniqueGetContactCardSchema = yup
  .object({
    linkedinUrl: yup
      .object({
        key: yup.string(),
        input: yup
          .string()
          // Nullable when sourceEnrichmentStep is just a primitive type
          .nullable(),
        sourceEnrichmentStepId: yup.number().nullable(),
      })
      .required("Linkedin Url is required"),
    cities: yup.array().of(yup.string()).nullable(),
    states: yup.array().of(yup.string()).nullable(),
    usOnly: yup.boolean().nullable(),
    titlePicklists: yup
      .array()
      .of(
        yup.object({
          logic: yup.string().nonNullable(),
          titles: yup.array().of(yup.string()),
        }),
      )
      .nullable(),
    filterEnrichmentSteps: EnrichmentStepCardFiltersListSchema,
  })
  .required()
  .test(({ titlePicklists }) => {
    const titlePicklistErrors = titlePicklists?.map((picklistAndLogic, index) => { 
      const { logic, titles } = picklistAndLogic;
      if (!titles?.length) {
        return new yup.ValidationError(
          "Title picklist must have at least one title",
          "Title picklist must have at least one title",
          `titlePicklist${index}`,
        );
      }
      if (!logic) {
        return new yup.ValidationError(
          "Title picklist must have a logic type",
          "Title picklist must have a logic type",
          `titlePicklist${index}`,
        );
      }
      return null;
    }).filter(Boolean)
    if (titlePicklistErrors && titlePicklistErrors.length) {
      return new yup.ValidationError(
        "One of your title conditions has an error",
        "One of your title conditions has an error",
        "titlePicklistError",
      );
    }
    return true;
  });


export const getDefaultGetContactEnrichmentValues = (
  enrichmentStep: EnrichmentStep,
  enrichmentStepWithFilters?: EnrichmentStepCardFiltersList_EnrichmentStepFragment,
) => {
  const filterEnrichmentSteps = parseEnrichmentStepsForList(
    enrichmentStepWithFilters,
  );

  const getInputOrNull = (key: string) => {
    const filteredInputArray =
      enrichmentStep?.parentEnrichmentStepInputs?.filter(
        (input) => input.key === key,
      );
    if (filteredInputArray?.length) {
      const { key, input, sourceEnrichmentStep } = filteredInputArray[0];
      return {
        key,
        input: input ? JSON.parse(input) : ("" as string),
        sourceEnrichmentStep: sourceEnrichmentStep,
      };
    } else {
      return null;
    }
  };

  const getListInputFromKey = (key: string) => {
    const listInput = enrichmentStep?.parentEnrichmentStepInputs
      ?.filter((input) => input.key === key && input.input)
      .map((input) => input.input)[0];
    return listInput ? (JSON.parse(listInput) as string[]) : [];
  };

  const getBooleanInputFromKey = (key: string) => {
    const booleanInput = enrichmentStep?.parentEnrichmentStepInputs
      ?.filter((input) => input.key === key && input.input)
      .map((input) => input.input)[0];
    return booleanInput ? (JSON.parse(booleanInput) as boolean) : false;
  };

  const getPicklistsInput = () => {
    const picklistsInput = enrichmentStep?.parentEnrichmentStepInputs
      ?.filter((input) => input.key === TITLE_PICKLISTS_LIST_KEY && input.input)
      .map((input) => input.input)[0];
    return picklistsInput
      ? (JSON.parse(
          picklistsInput,
        ) as IEnrichmentStepTechniqueGetContactCardPicklists[])
      : [];
  };

  const defaultValue = {
    linkedinUrl: getInputOrNull(LINKEDIN_URL_INPUT_KEY),
    cities: getListInputFromKey(CITIES_INPUT_KEY),
    states: getListInputFromKey(STATES_INPUT_KEY),
    usOnly: getBooleanInputFromKey(US_ONLY_INPUT_KEY),
    titlePicklists:
      getPicklistsInput() as IEnrichmentStepTechniqueGetContactCardPicklists[],
    filterEnrichmentSteps: filterEnrichmentSteps,
  };

  return defaultValue;
};

const EnrichmentStepTechniqueGetContactCard_States = gql`
  query EnrichmentStepTechniqueGetContactCard_States {
    states
  }
`


const EnrichmentStepTechniqueGetContactEnrichmentCard = ({
  enrichmentStep,
  confirmUpdateEnrichmentStep,
  onClose,
  loading,
  error,
}: IEnrichmentStepTechniqueCardProps) => {
  const enrichmentStepWithFilters = useFragment(
    EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );

    const { data: statesData } = useQuery<
      EnrichmentStepTechniqueGetContactCard_StatesQuery,
      EnrichmentStepTechniqueGetContactCard_StatesQueryVariables
    >(EnrichmentStepTechniqueGetContactCard_States);

  const getDefaultValues = useCallback(() => {
    return getDefaultGetContactEnrichmentValues(
      enrichmentStep as EnrichmentStep,
      enrichmentStepWithFilters as EnrichmentStepCardFiltersList_EnrichmentStepFragment,
    );
  }, [enrichmentStep, enrichmentStepWithFilters]);

  const formMethods = useEnrichmentStepForm({
    resolver: yupResolver(EnrichmentStepTechniqueGetContactCardSchema),
    defaultValues: getDefaultValues(),
  });

  const {
    control,
    formState: { errors, isDirty },
    setValue,
    handleSubmit,
    reset,
  } = formMethods;

  useEffect(() => {
    reset(getDefaultValues());
    return () => {
      reset();
    };
  }, [getDefaultValues, reset]);

  const [
    linkedinUrl,
    cities,
    states,
    usOnly,
    titlePicklists,
    filterEnrichmentSteps,
  ] = useWatch({
    control,
    name: [
      LINKEDIN_URL_INPUT_KEY,
      CITIES_INPUT_KEY,
      STATES_INPUT_KEY,
      US_ONLY_INPUT_KEY,
      TITLE_PICKLISTS_LIST_KEY,
      "filterEnrichmentSteps",
    ],
  });

  const formatAndSubmitRequest = (data: any) => {
    const parentEnrichmentStepInputs = [];
    if (linkedinUrl?.sourceEnrichmentStep?.id || linkedinUrl?.input) {
      parentEnrichmentStepInputs.push({
        key: LINKEDIN_URL_INPUT_KEY,
        required: true,
        input: JSON.stringify(linkedinUrl.input),
        sourceEnrichmentStepId: linkedinUrl?.sourceEnrichmentStep?.id,
      });
    }

    if (titlePicklists?.length) {
      parentEnrichmentStepInputs.push({
        key: TITLE_PICKLISTS_LIST_KEY,
        required: true,
        input: JSON.stringify(data.titlePicklists),
        sourceEnrichmentStepId: null,
      });
    }
    if (cities?.length) {
      parentEnrichmentStepInputs.push({
        key: CITIES_INPUT_KEY,
        required: true,
        input: JSON.stringify(data.cities),
        sourceEnrichmentStepId: null,
      });
    }
    if (states?.length) {
      parentEnrichmentStepInputs.push({
        key: STATES_INPUT_KEY,
        required: true,
        input: JSON.stringify(data.states),
        sourceEnrichmentStepId: null,
      });
    }
    if (usOnly) {
      parentEnrichmentStepInputs.push({
        key: US_ONLY_INPUT_KEY,
        required: true,
        input: JSON.stringify(data.usOnly),
        sourceEnrichmentStepId: null,
      });
    }

    confirmUpdateEnrichmentStep({
      data: {
        id: enrichmentStep?.id || null,
        enrichmentStepInput: {
          enrichment_technique: EnrichmentTechniqueEnum.FindContact,
          // selected_input refers to the display value (which JSON key is displayed in the column)
          selected_input: data.selected_input || null,
          parentEnrichmentStepInputs,
          filterEnrichmentSteps: parseEnrichmentStepsForGraphQL(
            data.filterEnrichmentSteps,
          ),
        },
      },
    });
  };

  return (
    <EnrichmentStepCardContainer
      isDirty={isDirty}
      loading={loading}
      error={error}
      onSave={handleSubmit(formatAndSubmitRequest)}
      enrichmentStep={useFragment(
        EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
        enrichmentStep,
      )}
      onClose={onClose}
    >
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-flex-col",
          "ciro-v1-gap-2",
          "ciro-v1-items-start",
        )}
      >
        <div>
          <EnrichmentStepSelector
            label={"Company LinkedIn URL*"}
            inputError={errors.linkedinUrl?.message}
            stepVariable={{
              stepId: linkedinUrl?.sourceEnrichmentStep?.id || null,
              stepInput: linkedinUrl?.input || null,
            }}
            setStepVariable={(stepVariable: IEnrichmentTemplateVariable) => {
              if (stepVariable.stepId) {
                setValue(
                  `linkedinUrl.sourceEnrichmentStep.id`,
                  stepVariable?.stepId,
                );
              } else {
                setValue(`linkedinUrl.sourceEnrichmentStep`, null);
              }
              setValue(`linkedinUrl.input`, stepVariable?.stepInput);
            }}
          />
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-col",
            "ciro-v1-gap-4",
            "ciro-v1-mt-2",
            "ciro-v1-w-2/3",
          )}
        >
          <CiroCreateableTextInput
            containerWidthClass="ciro-v1-w-full"
            label={"Cities"}
            placeholder="San Francisco, New York, etc."
            value={cities}
            setValue={(newValue) => {
              setValue(`cities`, newValue);
            }}
          />
          <CiroDropDown
            label={"States"}
            labelClassName={classNames("ciro-v1-text-neutral-500")}
            isMulti
            options={formatOptionsForCiroDropdown(statesData?.states || [])}
            value={states}
            onChange={(newValue) => {
              setValue(`states`, newValue);
            }}
          />

          {/* <CiroCreateableTextInput
            containerWidthClass="ciro-v1-w-full"
            label={"States"}
            placeholder="MA, GA, etc."
            value={states}
            setValue={(newValue) => {
              setValue(`states`, newValue);
            }}
          /> */}
          <CiroCheckbox
            className={classNames("ciro-v1-my-2")}
            labelClassName={classNames("ciro-v1-text-neutral-500")}
            checked={usOnly}
            onClick={() => {
              setValue(`usOnly`, !usOnly);
            }}
            label={"Only search US profiles"}
          />
        </div>
        <div
          className={classNames(
            { "ciro-v1-mt-4": Boolean(titlePicklists.length) },
            { "ciro-v1-mb-4": Boolean(titlePicklists.length) },
            "ciro-v1-flex",
            "ciro-v1-flex-col",
            "ciro-v1-gap-4",
            "ciro-v1-w-full",
          )}
        >
          {titlePicklists?.map((picklist, index) => {
            return (
              <EnrichmentTechniqueFindContactLogicSelector
                index={index}
                picklist={picklist}
                setValue={setValue}
                titlePicklists={titlePicklists}
              />
            );
          })}
        </div>
        <div
          className={classNames(
            "ciro-v1-text-xs",
            "ciro-v1-text-rose-500",
            "ciro-v1-mt-4",
          )}
        >
          {Boolean((errors as any)?.titlePicklistError) &&
            "There's an error with one of your title keyword picklists"}
        </div>
        <div>
          <CiroButton
            analyticsField="Add title picklist"
            customClassName={classNames(
              "ciro-v1-flex",
              "ciro-v1-flex-row",
              "ciro-v1-items-center",
              "ciro-v1-gap-1",
              "ciro-v1-text-gray-400",
              "hover:ciro-v1-text-gray-500",
            )}
            onClick={() => {
              setValue("titlePicklists", [
                ...titlePicklists,
                {
                  logic: "ALL_OF",
                  titles: [],
                },
              ]);
            }}
            style={CiroButtonStyleEnum.UNSTYLED}
          >
            <div>Add title logic</div>
            <PlusCircleIcon className={classNames("ciro-v1-w-5")} />
          </CiroButton>
        </div>
      </div>
      <div className={classNames("ciro-v1-mt-12")}>
        <FormProvider {...formMethods}>
          <EnrichmentStepCardFiltersList
            filterEnrichmentSteps={filterEnrichmentSteps}
          />
        </FormProvider>
      </div>
    </EnrichmentStepCardContainer>
  );
};

export default EnrichmentStepTechniqueGetContactEnrichmentCard;

const CoinIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5858 3.28554C9.86466 1.56465 6.3097 1.98719 4.06774 4.29801C1.82578 6.60883 1.51094 10.1749 3.31334 12.8428C5.11574 15.5107 8.54168 16.5496 11.5223 15.3321C14.6245 14.25 17.6245 9.75003 14.6245 5.25003"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.79575 6.32832L11.6718 8.20382C11.8829 8.41486 12.0015 8.70111 12.0015 8.99961C12.0015 9.29812 11.883 9.58441 11.672 9.7955L9.7965 11.6715C9.58546 11.8826 9.2992 12.0013 9.0007 12.0013C8.7022 12.0013 8.41591 11.8828 8.20481 11.6717L6.32879 9.79625C6.11769 9.58522 5.99902 9.29896 5.99902 9.00046C5.99902 8.70196 6.11752 8.41567 6.32857 8.20457L8.20406 6.32855C8.4151 6.11744 8.70136 5.99878 8.99986 5.99878C9.29836 5.99878 9.58465 6.11728 9.79575 6.32832V6.32832Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CoinIcon;

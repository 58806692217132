import { createContext } from "react";

const FilterContext = createContext({
  page: "ACCOUNTS",
  semanticSearchQuery: "",
  setSemanticSearchQuery: (v: string) => {},
  stringifiedParams: "",
  companiesCount: 0,
  showSmbFilters: false,
  setShowSmbFilters: (v: boolean) => {},
} as {
  page: "ACCOUNTS" | "COLLECTION_SEARCH";
  semanticSearchQuery: string | null;
  setSemanticSearchQuery: (v: string) => void;
  stringifiedParams: string;
  companiesCount: number;
  showSmbFilters: boolean;
  setShowSmbFilters: (v: boolean) => void;
});

export default FilterContext;

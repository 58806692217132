import classNames from "classnames";
import pluralize from "pluralize";
import { Link } from "react-router-dom";
import BoxLinkIcon from "../../assets/img/icons/BoxLinkIcon";
import { gql } from "@apollo/client";
import {
  BusinessDetailsMultilocationLinks_CompanyFragment,
  Maybe,
} from "../../__generated__/graphql";
import NumberFormat from "react-number-format";

export const BusinessDetailsMultilocationLinks_Company = gql`
  fragment BusinessDetailsMultilocationLinks_Company on Company {
    pk
    multilocationChildren {
      value {
        id
        locationCount
      }
    }
    multilocationParents {
      value {
        locationCount
        id
        parent {
          pk
          company_name {
            value
          }
        }
      }
    }
  }
`;

interface IBusinessDetailsMultilocationLinksProps {
  company?: Maybe<BusinessDetailsMultilocationLinks_CompanyFragment>;
}

const BusinessDetailsMultilocationLinks = ({
  company,
}: IBusinessDetailsMultilocationLinksProps) => {
  if (!company || !company.multilocationChildren?.value) {
    return null;
  }

  return (
    <>
      <div>
        {(company.multilocationChildren?.value || []).map((multilocation) => {
          const { locationCount, id } = multilocation;
          if (!locationCount) {
            return null;
          }
          return (
            <div>
              <Link
                className={classNames("ciro-v1-text-orange-400")}
                to={`/?multilocation=${id}&locationType=LOCATION`}
              >
                <div
                  className={classNames("ciro-v1-flex", "ciro-v1-items-center")}
                >
                  <div>
                    View{" "}
                    {
                      <NumberFormat
                        value={locationCount}
                        thousandSeparator=","
                        displayType="text"
                      ></NumberFormat>
                    }{" "}
                    child {pluralize("location", locationCount)} in Ciro
                  </div>
                  <span className="ciro-v1-pl-2">
                    <BoxLinkIcon />
                  </span>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      <div>
        {(company.multilocationParents?.value || []).map((multilocation) => {
          const { parent, locationCount } = multilocation;
          if (!parent || !locationCount) {
            return null;
          }

          return (
            <div>
              <Link
                className={classNames("ciro-v1-text-orange-400")}
                to={`?businessId=${parent.pk}`}
              >
                <div
                  className={classNames("ciro-v1-flex", "ciro-v1-items-center")}
                >
                  <div>
                    Parent: {parent.company_name?.value} (
                    {multilocation.locationCount}{" "}
                    {pluralize("location", multilocation.locationCount)} in
                    Ciro)
                  </div>
                  <span className="ciro-v1-pl-2">
                    <BoxLinkIcon />
                  </span>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BusinessDetailsMultilocationLinks;

import { useEffect, useState } from "react";
import { analytics } from "../../../utils/vendors";

interface IUseFilterBaseProps {
  searchParams: URLSearchParams;
  filterName: string;
}

const useFilterBaseString = <T extends string | null>({
  searchParams,
  filterName,
}: IUseFilterBaseProps): [T, React.Dispatch<T>] => {
  const [filter, setFilter] = useState<T>(searchParams.get(filterName) as T);

  useEffect(() => {
    if (filter?.length) {
      analytics.track("singleFilterUsed", {
        filter: filterName,
        value: filter,
      });
    }
  }, [filter, filterName]);

  return [filter, setFilter];
};

export default useFilterBaseString;

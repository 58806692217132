import classNames from "classnames";
import CiroModal from "./CiroModal";
import { useCallback, useEffect, useState } from "react";
import CiroButton, { CiroButtonStyleEnum } from "./CiroButton";
import CiroTextInput from "./CiroTextInput";

export const MAX_DOWNLOAD_LIMIT = 20000;

export interface IAccountSelectionReturnValue {
  value: string;
  limit?: number;
}

interface ICiroAccountSelectionOptionModalProps {
  closeModal: () => void;
  isOpen: boolean;
  setLimit: (v: number) => void;
  textLabel: string;
  title: string;
  maxAccounts: number;
}

const CiroByQueryLimitSelectionModal = ({
  closeModal,
  isOpen,
  setLimit,
  textLabel,
  title,
  maxAccounts,
}: ICiroAccountSelectionOptionModalProps) => {
  const [limitStr, setLimitStr] = useState<string>("0");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setError("");
      setLimitStr("0");
    }
  }, [isOpen]);

  const handleSelectLimit = useCallback(() => {
    const limit = parseInt(limitStr);

    if (isNaN(limit)) {
      setError("Please enter a valid number");
      return;
    }

    if (limit < 1) {
      setError("Please enter a number greater than 0");
      return;
    }

    if (limit > maxAccounts) {
      setError(`Please enter a number less than or equal to ${maxAccounts}`);
      return;
    }

    setLimit(limit);
  }, [limitStr, setLimit, maxAccounts]);

  return (
    <CiroModal size="LARGE" onClose={closeModal} isOpen={isOpen}>
      <div className={classNames("ciro-v1-font-medium", "ciro-v1-text-lg")}>
        {title}
      </div>
      <div className={classNames("ciro-v1-pb-4")}>
        <CiroTextInput
          type="number"
          label={textLabel}
          onChange={(e) => setLimitStr(e.target.value)}
          value={limitStr}
          error={error}
        />
      </div>
      <div className={classNames("ciro-v1-flex", "ciro-v1-justify-end")}>
        <CiroButton
          style={CiroButtonStyleEnum.LOUD}
          analyticsField={"Selected records limit by query"}
          onClick={handleSelectLimit}
        >
          Continue
        </CiroButton>
        <div className={classNames("ciro-v1-ml-2")}>
          <CiroButton
            analyticsField={"Cancel select records limit by query"}
            onClick={closeModal}
          >
            Cancel
          </CiroButton>
        </div>
      </div>
    </CiroModal>
  );
};

export default CiroByQueryLimitSelectionModal;

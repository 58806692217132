import { useCallback, useEffect, useState } from "react";
import { analytics } from "../../../utils/vendors";
import {
  ExportedCompaniesFilterOptionsEnum,
  ParentChildFilterOptionsEnum,
  RangeFilterInput,
} from "../../../__generated__/graphql";
import useFilterBaseStringArray from "../base/useFilterBaseStringArray";
import useFilterBaseNumberArray from "../base/useFilterBaseNumberArray";
import useFilterBaseString from "../base/useFilterBaseString";
import useFilterBaseDate from "../base/useFilterBaseDate";
import useFilterBaseBoolean from "../base/useFilterBaseBoolean";
import useFilterBaseNumber from "../base/useFilterBaseNumber";
import useFilterBaseRange from "../base/useFilterBaseRange";

export interface IFilteredCompanyQueryVariables {
  [v: string]: string | string[];
}

export enum TextTypeFilterEnum {
  KEYWORD = "keyword",
  SEMANTIC = "semantic",
  WEB = "web",
}

export interface IAccountFilters {
  resetAll: (v?: string) => void;

  allIndustryAssociationsFilter: string[];
  allSpecialtiesFilter: string[];
  allTechnologiesFilter: string[];
  businessTypeFilter: string[];
  citiesFilter: string[];
  collectionsFilter: number[];
  existsInCrmFilter: boolean | null;
  exportedCompaniesFilter: ExportedCompaniesFilterOptionsEnum | null;
  googleNumReviewsFilter: RangeFilterInput | null;
  googleStarRatingFilter: RangeFilterInput | null;
  industryAssociationsFilter: string[];
  industryFilter: string[];
  instagramFollowerCountFilter: RangeFilterInput | null;
  lastActivityDateAfterFilter: Date | null;
  lastActivityDateBeforeFilter: Date | null;
  locationsFilter: RangeFilterInput | null;
  multilocationFilter: string[];
  notCitiesFilter: string[];
  notCollectionsFilter: number[];
  notIndustryAssociationsFilter: string[];
  notIndustryFilter: string[];
  parentChildTypeFilter: ParentChildFilterOptionsEnum[];
  notSpecialtiesFilter: string[];
  notStatesFilter: string[];
  notTechnologiesFilter: string[];
  notZipCodesFilter: string[];
  numEmployeesFilter: RangeFilterInput | null;
  offset: number;
  openOpportunityFilter: boolean | null;
  openDaysFilter: string[];
  organizationStatusFilter: string[];
  pmsProviderFilter: string[];
  requiredFieldFilter: string[];
  specialtiesFilter: string[];
  statesFilter: string[];
  technologiesFilter: string[];
  textFilter: string | null;
  textTypeFilter: TextTypeFilterEnum | null;
  userStatusFilter: string[];
  yelpNumReviewsFilter: RangeFilterInput | null;
  yelpStarRatingFilter: RangeFilterInput | null;
  zipCodesFilter: string[];

  setAllIndustryAssociationsFilter: (v: string[]) => void;
  setAllSpecialtiesFilter: (v: string[]) => void;
  setAllTechnologiesFilter: (v: string[]) => void;
  setCitiesFilter: (v: string[]) => void;
  setCollectionsFilter: (v: number[]) => void;
  setExistsInCrmFilter: (v: boolean | null) => void;
  setExportedCompaniesFilter: (
    v: ExportedCompaniesFilterOptionsEnum | null,
  ) => void;
  setGoogleNumReviewsFilter: (v: RangeFilterInput | null) => void;
  setGoogleStarRatingFilter: (v: RangeFilterInput | null) => void;
  setIndustryAssociationsFilter: (v: string[]) => void;
  setIndustryFilter: (v: string[]) => void;
  setInstagramFollowerCountFilter: (v: RangeFilterInput | null) => void;
  setLastActivityDateAfterFilter: (v: Date | null) => void;
  setLastActivityDateBeforeFilter: (v: Date | null) => void;
  setLocationsFilter: (v: RangeFilterInput | null) => void;
  setBusinessTypeFilter: (v: string[] | null) => void;
  setMultilocationFilter: (v: string[]) => void;
  setNotCitiesFilter: (v: string[]) => void;
  setNotCollectionsFilter: (v: number[]) => void;
  setNotIndustryAssociationsFilter: (v: string[]) => void;
  setNotIndustryFilter: (v: string[]) => void;
  setParentChildTypeFilter: (v: ParentChildFilterOptionsEnum[]) => void;
  setNotSpecialtiesFilter: (v: string[]) => void;
  setNotStatesFilter: (v: string[]) => void;
  setNotTechnologiesFilter: (v: string[]) => void;
  setNotZipCodesFilter: (v: string[]) => void;
  setNumEmployeesFilter: (v: RangeFilterInput | null) => void;
  setOffset: (v: number) => void;
  setOpenDaysFilter: (v: string[] | null) => void;
  setOpenOpportunityFilter: (v: Boolean | null) => void;
  setOrganizationStatusFilter: (v: string[]) => void;
  setPmsProviderFilter: (v: string[]) => void;
  setRequiredFieldFilter: (v: string[]) => void;
  setSpecialtiesFilter: (v: string[]) => void;
  setStatesFilter: (v: string[]) => void;
  setTechnologiesFilter: (v: string[]) => void;
  setTextFilter: (v: string | null) => void;
  setTextTypeFilter: (v: string | null) => void;
  setUserStatusFilter: (v: string[]) => void;
  setYelpNumReviewsFilter: (v: RangeFilterInput | null) => void;
  setYelpStarRatingFilter: (v: RangeFilterInput | null) => void;
  setZipCodesFilter: (v: string[]) => void;

  numFiltersApplied: number;
  stringifiedParams: string;
}

const useAccountFilters = ({
  searchParams,
  setSearchParams,
}: {
  searchParams: URLSearchParams;
  setSearchParams: (params: IFilteredCompanyQueryVariables) => void;
}) => {
  // setSearchParams changes each time it's called, which means that the
  // useEffect triggers a double call to segment.
  // To solve for this using stringifiedParams outside of the original useEffect
  const [stringifiedParams, setStringifiedParams] = useState("{}");

  const [allIndustryAssociationsFilter, setAllIndustryAssociationsFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "allIndustryAssociations",
    });

  const [allSpecialtiesFilter, setAllSpecialtiesFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "allSpecialties",
    });

  const [allTechnologiesFilter, setAllTechnologiesFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "allTechnologies",
    });

  const [textFilter, setTextFilter] = useFilterBaseString({
    searchParams,
    filterName: "text",
  });

  const [textTypeFilter, setTextTypeFilter] = useFilterBaseString({
    searchParams,
    filterName: "textType",
  });

  const [citiesFilter, setCitiesFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "cities",
  });

  const [collectionsFilter, setCollectionsFilter] = useFilterBaseNumberArray({
    searchParams,
    filterName: "collections",
  });

  const [existsInCrmFilter, setExistsInCrmFilter] = useFilterBaseBoolean({
    searchParams,
    filterName: "existsInCrm",
  });

  const [exportedCompaniesFilter, setExportedCompaniesFilter] =
    useFilterBaseString({
      searchParams,
      filterName: "exportedCompanies",
    });

  const [googleStarRatingFilter, setGoogleStarRatingFilter] =
    useFilterBaseRange({
      searchParams,
      filterName: "googleStarRating",
    });

  const [googleNumReviewsFilter, setGoogleNumReviewsFilter] =
    useFilterBaseRange({
      searchParams,
      filterName: "googleNumReviews",
    });

  const [industryAssociationsFilter, setIndustryAssociationsFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "industryAssociations",
    });

  const [industryFilter, setIndustryFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "industry",
  });

  const [instagramFollowerCountFilter, setInstagramFollowerCountFilter] =
    useFilterBaseRange({
      searchParams,
      filterName: "instagramFollowerCount",
    });

  const [lastActivityDateAfterFilter, setLastActivityDateAfterFilter] =
    useFilterBaseDate({
      searchParams,
      filterName: "lastActivityDateAfter",
    });

  const [lastActivityDateBeforeFilter, setLastActivityDateBeforeFilter] =
    useFilterBaseDate({
      searchParams,
      filterName: "lastActivityDateBefore",
    });

  const [locationsFilter, setLocationsFilter] = useFilterBaseRange({
    searchParams,
    filterName: "locations",
  });

  const [businessTypeFilter, setBusinessTypeFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "businessType",
  });

  const [multilocationFilter, setMultilocationFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "multilocation",
    });

  const [notCitiesFilter, setNotCitiesFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "notCities",
  });

  const [notCollectionsFilter, setNotCollectionsFilter] =
    useFilterBaseNumberArray({
      searchParams,
      filterName: "notCollections",
    });

  const [notIndustryAssociationsFilter, setNotIndustryAssociationsFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "notIndustryAssociations",
    });

  const [notIndustryFilter, setNotIndustryFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "notIndustry",
  });

  const [parentChildTypeFilter, setParentChildTypeFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "parentChildType",
    });

  const [notSpecialtiesFilter, setNotSpecialtiesFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "notSpecialties",
    });

  const [notStatesFilter, setNotStatesFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "notStates",
  });

  const [notTechnologiesFilter, setNotTechnologiesFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "notTechnologies",
    });

  const [notZipCodesFilter, setNotZipCodesFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "notZipCodes",
  });

  const [numEmployeesFilter, setNumEmployeesFilter] = useFilterBaseRange({
    searchParams,
    filterName: "numEmployees",
  });

  const [openDaysFilter, setOpenDaysFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "openDays",
  });

  const [openOpportunityFilter, setOpenOpportunityFilter] =
    useFilterBaseBoolean({
      searchParams,
      filterName: "openOpportunity",
    });

  const [offset, setOffset] = useFilterBaseNumber({
    searchParams,
    filterName: "offset",
  });

  const [organizationStatusFilter, setOrganizationStatusFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "organizationStatus",
    });

  const [pmsProviderFilter, setPmsProviderFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "pmsProvider",
  });

  const [requiredFieldFilter, setRequiredFieldFilter] =
    useFilterBaseStringArray({
      searchParams,
      filterName: "requiredField",
    });

  const [specialtiesFilter, setSpecialtiesFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "specialties",
  });

  const [statesFilter, setStatesFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "states",
  });

  const [technologiesFilter, setTechnologiesFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "technologies",
  });

  const [userStatusFilter, setUserStatusFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "userStatus",
  });

  const [yelpStarRatingFilter, setYelpStarRatingFilter] = useFilterBaseRange({
    searchParams,
    filterName: "yelpStarRating",
  });

  const [yelpNumReviewsFilter, setYelpNumReviewsFilter] = useFilterBaseRange({
    searchParams,
    filterName: "yelpNumReviews",
  });

  const [zipCodesFilter, setZipCodesFilter] = useFilterBaseStringArray({
    searchParams,
    filterName: "zipCode",
  });

  const [numFiltersApplied, setNumFiltersApplied] = useState(0);

  useEffect(() => {
    let newNumFiltersApplied = 0;
    let updatedParams = {} as IFilteredCompanyQueryVariables;

    if (textFilter) {
      updatedParams.text = textFilter;
    }
    if (textTypeFilter) {
      updatedParams.textType = textTypeFilter;
    }
    if (allIndustryAssociationsFilter.length) {
      updatedParams.allIndustryAssociations = allIndustryAssociationsFilter;
      newNumFiltersApplied++;
    }
    if (allSpecialtiesFilter.length) {
      updatedParams.allSpecialties = allSpecialtiesFilter;
      newNumFiltersApplied++;
    }
    if (allTechnologiesFilter.length) {
      updatedParams.allTechnologies = allTechnologiesFilter;
      newNumFiltersApplied++;
    }
    if (citiesFilter.length) {
      updatedParams.cities = citiesFilter;
      newNumFiltersApplied++;
    }
    if (collectionsFilter.length) {
      updatedParams.collections = collectionsFilter.map(String);
      newNumFiltersApplied++;
    }
    if (existsInCrmFilter != null) {
      updatedParams.existsInCrm = String(existsInCrmFilter);
      newNumFiltersApplied++;
    }
    if (exportedCompaniesFilter) {
      updatedParams.exportedCompanies = exportedCompaniesFilter;
      newNumFiltersApplied++;
    }
    if (googleNumReviewsFilter) {
      const lowerBound = googleNumReviewsFilter.lowerBound;
      const upperBound = googleNumReviewsFilter.upperBound;
      if (lowerBound) {
        updatedParams.googleNumReviewsLowerBoundFilter = String(lowerBound);
      }
      if (upperBound) {
        updatedParams.googleNumReviewsUpperBoundFilter = String(upperBound);
      }
      if (lowerBound || upperBound) {
        newNumFiltersApplied++;
      }
    }
    if (googleStarRatingFilter) {
      const lowerBound = googleStarRatingFilter.lowerBound;
      const upperBound = googleStarRatingFilter.upperBound;
      if (lowerBound) {
        updatedParams.googleStarRatingLowerBoundFilter = String(lowerBound);
      }
      if (upperBound) {
        updatedParams.googleStarRatingUpperBoundFilter = String(upperBound);
      }
      if (lowerBound || upperBound) {
        newNumFiltersApplied++;
      }
    }
    if (industryAssociationsFilter.length) {
      updatedParams.industryAssociations = industryAssociationsFilter;
      newNumFiltersApplied++;
    }
    if (industryFilter.length) {
      updatedParams.industry = industryFilter;
      newNumFiltersApplied++;
    }
    if (instagramFollowerCountFilter) {
      const lowerBound = instagramFollowerCountFilter.lowerBound;
      const upperBound = instagramFollowerCountFilter.upperBound;
      if (lowerBound) {
        updatedParams.instagramFollowerCountLowerBoundFilter =
          String(lowerBound);
      }
      if (upperBound) {
        updatedParams.instagramFollowerCountUpperBoundFilter =
          String(upperBound);
      }
      if (lowerBound || upperBound) {
        newNumFiltersApplied++;
      }
    }
    if (lastActivityDateAfterFilter) {
      updatedParams.lastActivityDateAfter = String(
        lastActivityDateAfterFilter.valueOf(),
      );
      newNumFiltersApplied++;
    }
    if (lastActivityDateBeforeFilter) {
      updatedParams.lastActivityDateBefore = String(
        lastActivityDateBeforeFilter.valueOf(),
      );
      newNumFiltersApplied++;
    }
    if (locationsFilter) {
      const lowerBound = locationsFilter.lowerBound;
      const upperBound = locationsFilter.upperBound;
      if (lowerBound) {
        updatedParams.locationsLowerBoundFilter = String(lowerBound);
      }
      if (upperBound) {
        updatedParams.locationsUpperBoundFilter = String(upperBound);
      }
      if (lowerBound || upperBound) {
        newNumFiltersApplied++;
      }
    }
    if (businessTypeFilter?.length) {
      updatedParams.businessTypeFilter = businessTypeFilter;
      newNumFiltersApplied++;
    }
    if (multilocationFilter.length) {
      updatedParams.multilocation = multilocationFilter;
      newNumFiltersApplied++;
    }
    if (notCitiesFilter.length) {
      updatedParams.notCities = notCitiesFilter;
      newNumFiltersApplied++;
    }
    if (notCollectionsFilter.length) {
      updatedParams.notCollections = notCollectionsFilter.map(String);
      newNumFiltersApplied++;
    }
    if (notIndustryAssociationsFilter.length) {
      updatedParams.notIndustryAssociations = notIndustryAssociationsFilter;
      newNumFiltersApplied++;
    }
    if (notIndustryFilter.length) {
      updatedParams.notIndustry = notIndustryFilter;
      newNumFiltersApplied++;
    }
    if (notSpecialtiesFilter.length) {
      updatedParams.notSpecialties = notSpecialtiesFilter;
      newNumFiltersApplied++;
    }
    if (notStatesFilter.length) {
      updatedParams.notStates = notStatesFilter;
      newNumFiltersApplied++;
    }
    if (notTechnologiesFilter.length) {
      updatedParams.notTechnologies = notTechnologiesFilter;
      newNumFiltersApplied++;
    }
    if (notZipCodesFilter.length) {
      updatedParams.notZipCodes = notZipCodesFilter;
      newNumFiltersApplied++;
    }
    if (parentChildTypeFilter.length) {
      updatedParams.parentChildType = parentChildTypeFilter;
      newNumFiltersApplied++;
    }
    if (numEmployeesFilter) {
      const lowerBound = numEmployeesFilter.lowerBound;
      const upperBound = numEmployeesFilter.upperBound;
      if (lowerBound) {
        updatedParams.numEmployeesLowerBoundFilter = String(lowerBound);
      }
      if (upperBound) {
        updatedParams.numEmployeesUpperBoundFilter = String(upperBound);
      }
      if (lowerBound || upperBound) {
        newNumFiltersApplied++;
      }
    }
    if (openDaysFilter.length) {
      updatedParams.openDays = openDaysFilter;
      newNumFiltersApplied++;
    }
    if (openOpportunityFilter != null) {
      updatedParams.openOpportunity = String(openOpportunityFilter);
      newNumFiltersApplied++;
    }
    if (organizationStatusFilter.length) {
      updatedParams.organizationStatus = organizationStatusFilter;
      newNumFiltersApplied++;
    }
    if (pmsProviderFilter.length) {
      updatedParams.pmsProvider = pmsProviderFilter;
      newNumFiltersApplied++;
    }
    if (requiredFieldFilter.length) {
      updatedParams.requiredField = requiredFieldFilter;
      newNumFiltersApplied++;
    }
    if (specialtiesFilter.length) {
      updatedParams.specialties = specialtiesFilter;
      newNumFiltersApplied++;
    }
    if (statesFilter.length) {
      updatedParams.states = statesFilter;
      newNumFiltersApplied++;
    }
    if (technologiesFilter.length) {
      updatedParams.technologies = technologiesFilter;
      newNumFiltersApplied++;
    }
    if (userStatusFilter.length) {
      updatedParams.userStatus = userStatusFilter;
      newNumFiltersApplied++;
    }
    if (yelpNumReviewsFilter) {
      const lowerBound = yelpNumReviewsFilter.lowerBound;
      const upperBound = yelpNumReviewsFilter.upperBound;
      if (lowerBound) {
        updatedParams.yelpNumReviewsLowerBoundFilter = String(lowerBound);
      }
      if (upperBound) {
        updatedParams.yelpNumReviewsUpperBoundFilter = String(upperBound);
      }
      if (lowerBound || upperBound) {
        newNumFiltersApplied++;
      }
    }
    if (yelpStarRatingFilter) {
      const lowerBound = yelpStarRatingFilter.lowerBound;
      const upperBound = yelpStarRatingFilter.upperBound;
      if (lowerBound) {
        updatedParams.yelpStarRatingLowerBoundFilter = String(lowerBound);
      }
      if (upperBound) {
        updatedParams.yelpStarRatingUpperBoundFilter = String(upperBound);
      }
      if (lowerBound || upperBound) {
        newNumFiltersApplied++;
      }
    }
    if (zipCodesFilter.length) {
      updatedParams.zipCode = zipCodesFilter;
      newNumFiltersApplied++;
    }
    if (offset) {
      updatedParams.offset = String(offset);
    }
    setSearchParams(updatedParams);
    setStringifiedParams(JSON.stringify(updatedParams));
    setNumFiltersApplied(newNumFiltersApplied);
  }, [
    allIndustryAssociationsFilter,
    allSpecialtiesFilter,
    allTechnologiesFilter,
    citiesFilter,
    collectionsFilter,
    existsInCrmFilter,
    exportedCompaniesFilter,
    googleNumReviewsFilter,
    googleStarRatingFilter,
    industryAssociationsFilter,
    industryFilter,
    instagramFollowerCountFilter,
    lastActivityDateAfterFilter,
    lastActivityDateBeforeFilter,
    locationsFilter,
    businessTypeFilter,
    multilocationFilter,
    notCitiesFilter,
    notCollectionsFilter,
    notIndustryAssociationsFilter,
    notIndustryFilter,
    notSpecialtiesFilter,
    notStatesFilter,
    notTechnologiesFilter,
    notZipCodesFilter,
    parentChildTypeFilter,
    numEmployeesFilter,
    offset,
    openDaysFilter,
    openOpportunityFilter,
    organizationStatusFilter,
    pmsProviderFilter,
    requiredFieldFilter,
    setNumFiltersApplied,
    setSearchParams,
    setStringifiedParams,
    specialtiesFilter,
    statesFilter,
    technologiesFilter,
    textFilter,
    textTypeFilter,
    userStatusFilter,
    yelpNumReviewsFilter,
    yelpStarRatingFilter,
    zipCodesFilter,
  ]);

  useEffect(() => {
    analytics.track("accountFiltersChanged", {
      value: JSON.parse(stringifiedParams),
    });
  }, [stringifiedParams]);

  const resetAll = useCallback(
    (forYouStringifiedParams = "{}") => {
      const forYouParams = JSON.parse(forYouStringifiedParams);
      setAllIndustryAssociationsFilter(
        forYouParams.allIndustryAssociations || [],
      );
      setAllSpecialtiesFilter(forYouParams.allSpecialties || []);
      setAllTechnologiesFilter(forYouParams.allTechnologies || []);
      setCitiesFilter(forYouParams.cities || []);
      setCollectionsFilter(forYouParams.collections || []);
      setExistsInCrmFilter(forYouParams.existsInCrm || null);
      setExportedCompaniesFilter(forYouParams.exportedCompanies || null);
      setGoogleNumReviewsFilter(forYouParams.googleNumReviews || null);
      setGoogleStarRatingFilter(forYouParams.googleStarRating || null);
      setIndustryAssociationsFilter(forYouParams.industryAssociations || []);
      setIndustryFilter(forYouParams.industry || []);
      setInstagramFollowerCountFilter(
        forYouParams.instagramFollowerCount || null,
      );
      setLastActivityDateAfterFilter(
        forYouParams.lastActivityDateAfter || null,
      );
      setLastActivityDateBeforeFilter(
        forYouParams.lastActivityDateBefore || null,
      );
      setLocationsFilter(forYouParams.locations || null);
      setBusinessTypeFilter(forYouParams.businessType || null);
      setMultilocationFilter(forYouParams.multilocation || []);
      setNotCitiesFilter(forYouParams.notCities || []);
      setNotCollectionsFilter(forYouParams.notCollections || []);
      setNotIndustryAssociationsFilter(
        forYouParams.notIndustryAssociations || [],
      );
      setNotIndustryFilter(forYouParams.notIndustry || []);
      setNotSpecialtiesFilter(forYouParams.notSpecialties || []);
      setNotStatesFilter(forYouParams.notStates || []);
      setNotTechnologiesFilter(forYouParams.notTechnologies || []);
      setNotZipCodesFilter(forYouParams.notZipCodes || []);
      setNumEmployeesFilter(forYouParams.numEmployees || null);
      setParentChildTypeFilter(forYouParams.parentChildType || []);
      setOffset(0);
      setOpenDaysFilter(forYouParams.openDaysFilter || []);
      setOpenOpportunityFilter(forYouParams.openOpportunity || null);
      setOrganizationStatusFilter(forYouParams.organizationStatus || []);
      setPmsProviderFilter(forYouParams.pmsProvider || []);
      setRequiredFieldFilter(forYouParams.requiredField || []);
      setSpecialtiesFilter(forYouParams.specialties || []);
      setStatesFilter(forYouParams.states || []);
      setTechnologiesFilter(forYouParams.technologies || []);
      setTextFilter(null);
      setTextTypeFilter(null);
      setUserStatusFilter(forYouParams.userStatus || []);
      setYelpNumReviewsFilter(forYouParams.yelpNumReviews || null);
      setYelpNumReviewsFilter(forYouParams.yelpNumReviews || null);
      setYelpStarRatingFilter(forYouParams.yelpStarRating || null);
      setYelpStarRatingFilter(forYouParams.yelpStarRating || null);
      setZipCodesFilter(forYouParams.zipCodes || []);
    },
    [
      setAllIndustryAssociationsFilter,
      setAllSpecialtiesFilter,
      setAllTechnologiesFilter,
      setCitiesFilter,
      setCollectionsFilter,
      setExistsInCrmFilter,
      setExportedCompaniesFilter,
      setGoogleNumReviewsFilter,
      setGoogleStarRatingFilter,
      setIndustryAssociationsFilter,
      setIndustryFilter,
      setInstagramFollowerCountFilter,
      setLastActivityDateAfterFilter,
      setLastActivityDateBeforeFilter,
      setLocationsFilter,
      setBusinessTypeFilter,
      setMultilocationFilter,
      setNotCitiesFilter,
      setNotCollectionsFilter,
      setNotIndustryAssociationsFilter,
      setNotIndustryFilter,
      setNotSpecialtiesFilter,
      setNotStatesFilter,
      setNotTechnologiesFilter,
      setNotZipCodesFilter,
      setParentChildTypeFilter,
      setNumEmployeesFilter,
      setOffset,
      setOpenDaysFilter,
      setOpenOpportunityFilter,
      setOrganizationStatusFilter,
      setPmsProviderFilter,
      setRequiredFieldFilter,
      setSpecialtiesFilter,
      setStatesFilter,
      setTechnologiesFilter,
      setTextFilter,
      setTextTypeFilter,
      setUserStatusFilter,
      setYelpNumReviewsFilter,
      setYelpStarRatingFilter,
      setZipCodesFilter,
    ],
  );

  return {
    resetAll,
    allIndustryAssociationsFilter,
    allSpecialtiesFilter,
    allTechnologiesFilter,
    citiesFilter,
    collectionsFilter,
    existsInCrmFilter,
    exportedCompaniesFilter,
    googleNumReviewsFilter,
    googleStarRatingFilter,
    industryAssociationsFilter,
    industryFilter,
    instagramFollowerCountFilter,
    lastActivityDateAfterFilter,
    lastActivityDateBeforeFilter,
    locationsFilter,
    businessTypeFilter,
    multilocationFilter,
    notCitiesFilter,
    notCollectionsFilter,
    notIndustryAssociationsFilter,
    notIndustryFilter,
    notSpecialtiesFilter,
    notStatesFilter,
    notTechnologiesFilter,
    notZipCodesFilter,
    parentChildTypeFilter,
    numEmployeesFilter,
    offset,
    openDaysFilter,
    openOpportunityFilter,
    organizationStatusFilter,
    pmsProviderFilter,
    requiredFieldFilter,
    specialtiesFilter,
    statesFilter,
    technologiesFilter,
    textFilter,
    textTypeFilter,
    userStatusFilter,
    yelpNumReviewsFilter,
    yelpStarRatingFilter,
    zipCodesFilter,

    setAllIndustryAssociationsFilter,
    setAllSpecialtiesFilter,
    setAllTechnologiesFilter,
    setCitiesFilter,
    setCollectionsFilter,
    setExistsInCrmFilter,
    setExportedCompaniesFilter,
    setGoogleNumReviewsFilter,
    setGoogleStarRatingFilter,
    setIndustryAssociationsFilter,
    setIndustryFilter,
    setInstagramFollowerCountFilter,
    setLastActivityDateAfterFilter,
    setLastActivityDateBeforeFilter,
    setLocationsFilter,
    setBusinessTypeFilter,
    setMultilocationFilter,
    setNotCitiesFilter,
    setNotCollectionsFilter,
    setNotIndustryAssociationsFilter,
    setNotIndustryFilter,
    setNotSpecialtiesFilter,
    setNotStatesFilter,
    setNotTechnologiesFilter,
    setNotZipCodesFilter,
    setParentChildTypeFilter,
    setNumEmployeesFilter,
    setOffset,
    setOpenDaysFilter,
    setOpenOpportunityFilter,
    setOrganizationStatusFilter,
    setPmsProviderFilter,
    setRequiredFieldFilter,
    setSpecialtiesFilter,
    setStatesFilter,
    setTechnologiesFilter,
    setTextFilter,
    setTextTypeFilter,
    setUserStatusFilter,
    setYelpNumReviewsFilter,
    setYelpStarRatingFilter,
    setZipCodesFilter,

    numFiltersApplied,
    stringifiedParams,
  } as IAccountFilters;
};

export default useAccountFilters;

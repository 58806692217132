import { gql } from "@apollo/client";
import CiroTableCell, {
  CiroTableCellAlignEnum,
} from "../../shared/CiroTable/CiroTableCell";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import { MobileNumbersChromeExtensionRow_QueryFragment } from "../../../__generated__/graphql";
import { format } from "date-fns";
import CiroPill from "../../shared/CiroPill";
import classNames from "classnames";
import ChromeIcon from "../../../assets/img/icons/ChromeIcon";
import MobileNumbersDownloadButton from "../MobileNumbersDownloadButton";

export const MobileNumbersChromeExtensionRow_Query = gql`
  fragment MobileNumbersChromeExtensionRow_Query on Query {
    userAccount {
      organization_id
    }
    chromeExtensionTransactions: phoneNumbersRequestTransactions(
      requestType: chrome_extension
    ) {
      id
      created_at
      requestedContactsCount
      newNumbersCount
      numbersCalledCount
      conversationsCreatedCount
      creditsUsed
    }
  }
`;

interface IMobileNumbersChromeExtensionRowProps {
  data: MobileNumbersChromeExtensionRow_QueryFragment;
}

const MobileNumbersChromeExtensionRow = ({
  data,
}: IMobileNumbersChromeExtensionRowProps) => {
  const chromeExtensionTransactions = data.chromeExtensionTransactions;

  if (chromeExtensionTransactions.length === 0) {
    return null;
  }

  const maxCreatedAt = chromeExtensionTransactions.reduce(
    (max, transaction) => {
      return new Date(transaction.created_at) > max
        ? new Date(transaction.created_at)
        : max;
    },
    new Date(chromeExtensionTransactions[0].created_at),
  );

  const totalRequestedContacts = chromeExtensionTransactions.reduce(
    (total, transaction) => {
      return total + transaction.requestedContactsCount;
    },
    0,
  );

  const totalNewNumbers = chromeExtensionTransactions.reduce(
    (total, transaction) => {
      return total + transaction.newNumbersCount;
    },
    0,
  );

  const totalNumbersCalled = chromeExtensionTransactions.reduce(
    (total, transaction) => {
      return total + transaction.numbersCalledCount;
    },
    0,
  );

  const totalConversationsCreated = chromeExtensionTransactions.reduce(
    (total, transaction) => {
      return total + transaction.conversationsCreatedCount;
    },
    0,
  );

  const totalCreditsUsed = chromeExtensionTransactions.reduce(
    (total, transaction) => {
      return total + transaction.creditsUsed;
    },
    0,
  );

  return (
    <CiroTableRow clickable={false}>
      <CiroTableCell>
        {format(new Date(maxCreatedAt), "yyyy-MM-dd HH:mm")}
      </CiroTableCell>
      <CiroTableCell>
        <CiroPill
          fontColor={"ciro-v1-text-blue-800"}
          background={"ciro-v1-bg-blue-100"}
        >
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-items-center",
              "ciro-v1-gap-1",
            )}
          >
            <span className={classNames("ciro-v1-text-blue-600")}>
              <ChromeIcon size={10} />
            </span>
            Extension
          </div>
        </CiroPill>
      </CiroTableCell>
      <CiroTableCell align={CiroTableCellAlignEnum.RIGHT}>
        {totalRequestedContacts}
      </CiroTableCell>
      <CiroTableCell align={CiroTableCellAlignEnum.RIGHT}>
        {totalNewNumbers}
      </CiroTableCell>
      <CiroTableCell
        align={CiroTableCellAlignEnum.RIGHT}
        className={classNames("ciro-v1-hidden", "sm:ciro-v1-table-cell")}
      >
        {totalNumbersCalled > 0 ? totalNumbersCalled : "-"}
      </CiroTableCell>
      <CiroTableCell
        align={CiroTableCellAlignEnum.RIGHT}
        className={classNames("ciro-v1-hidden", "sm:ciro-v1-table-cell")}
      >
        {totalConversationsCreated > 0 ? totalConversationsCreated : "-"}
      </CiroTableCell>
      <CiroTableCell
        className={classNames("ciro-v1-hidden", "sm:ciro-v1-table-cell")}
      >
        -
      </CiroTableCell>
      <CiroTableCell>{totalCreditsUsed}</CiroTableCell>
      <CiroTableCell align={CiroTableCellAlignEnum.RIGHT}>
        <div />
        <MobileNumbersDownloadButton
          ids={chromeExtensionTransactions.map((transaction) => transaction.id)}
        />
      </CiroTableCell>
    </CiroTableRow>
  );
};

export default MobileNumbersChromeExtensionRow;

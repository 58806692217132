const FileUploadIcon = ({
  height = "32",
  width = "32",
}: {
  height?: string;
  width?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
  >
    <path
      d="M17 6.5H13.5C12.3954 6.5 11.5 5.60457 11.5 4.5V1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 19V14"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 14L7 16"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 16L9 14"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 19C15.6569 19 17 17.6569 17 16V6.24264C17 5.44699 16.6839 4.68393 16.1213 4.12132L13.8787 1.87868C13.3161 1.31607 12.553 1 11.7574 1H4C2.34315 1 1 2.34315 1 4V16C1 17.6569 2.34315 19 4 19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 19H5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 19H14"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FileUploadIcon;

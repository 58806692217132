import classNames from "classnames";
import CiroSwitch from "../CiroSwitch";
import { useContext } from "react";
import FilterContext from "../../../contexts/FilterContext";
import { IAccountFilters } from "../../../reactHooks/filters/accounts/useAccountFilters";
import { RangeFilterInput } from "../../../__generated__/graphql";

const CiroSmbFilterToggle = ({
  accountFilters,
}: {
  accountFilters: IAccountFilters;
}) => {
  const { showSmbFilters, setShowSmbFilters } = useContext(FilterContext);

  const emptyRange = {
    lowerBound: null,
    upperBound: null,
  } as RangeFilterInput;

  const {
    setAllIndustryAssociationsFilter,
    setAllTechnologiesFilter,
    setGoogleNumReviewsFilter,
    setGoogleStarRatingFilter,
    setIndustryAssociationsFilter,
    setInstagramFollowerCountFilter,
    setLocationsFilter,
    setNotIndustryAssociationsFilter,
    setNotTechnologiesFilter,
    setNumEmployeesFilter,
    setOpenDaysFilter,
    setPmsProviderFilter,
    setTechnologiesFilter,
    setYelpNumReviewsFilter,
    setYelpStarRatingFilter,
  } = accountFilters;

  const resetSmbFilters = () => {
    setAllIndustryAssociationsFilter([]);
    setAllTechnologiesFilter([]);
    setGoogleNumReviewsFilter(emptyRange);
    setGoogleStarRatingFilter(emptyRange);
    setIndustryAssociationsFilter([]);
    setInstagramFollowerCountFilter(emptyRange);
    setLocationsFilter(emptyRange);
    setNotIndustryAssociationsFilter([]);
    setNotTechnologiesFilter([]);
    setNumEmployeesFilter(emptyRange);
    setOpenDaysFilter([]);
    setPmsProviderFilter([]);
    setTechnologiesFilter([]);
    setYelpNumReviewsFilter(emptyRange);
    setYelpStarRatingFilter(emptyRange);
  };

  return (
    <div
      className={classNames(
        "ciro-v1-w-full",
        "ciro-v1-px-1.5",
        "ciro-v1-py-2",
        "ciro-v1-border-b-1",
      )}
    >
      <div
        className={classNames(
          "ciro-v1-px-2.5",
          "ciro-v1-rounded-md",
          "ciro-v1-justify-start",
          "ciro-v1-items-center",
          "ciro-v1-gap-3",
          "ciro-v1-flex",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-grow",
            "ciro-v1-items-center",
            "ciro-v1-gap-1.5",
            "ciro-v1-flex",
          )}
        >
          <div
            className={classNames(
              "ciro-v1-w-1",
              "ciro-v1-h-1",
              "ciro-v1-bg-orange-500",
              "ciro-v1-rounded-[1px]",
            )}
          />
          <div
            className={classNames(
              "ciro-v1-grow",
              "ciro-v1-shrink",
              "ciro-v1-basis-0",
              "ciro-v1-text-orange-500",
              "ciro-v1-text-xs",
            )}
          >
            SMB Filters
          </div>
          <div className={classNames("test-smb-toggle")}>
            <CiroSwitch
              checked={showSmbFilters}
              onChange={() => {
                if (showSmbFilters) {
                  resetSmbFilters();
                }
                setShowSmbFilters(!showSmbFilters);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CiroSmbFilterToggle;

import React from "react";
import classNames from "classnames";
import { gql, useQuery } from "@apollo/client";
import { IndustryAssociationsFilter_IndustryAssociationsQuery } from "../../__generated__/graphql";
import CiroDropDown from "../shared/CiroDropdown";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

interface IIndustryAssociationsFilterProps {
  allIndustryAssociationsFilter?: string[];
  blurred?: boolean;
  industryAssociationsFilter: string[];
  notIndustryAssociationsFilter?: string[];
  resetOffset: () => void;
  setAllIndustryAssociationsFilter?: (v: string[]) => void;
  setIndustryAssociationsFilter: (v: string[]) => void;
  setNotIndustryAssociationsFilter?: (v: string[]) => void;
}

export const IndustryAssociationsFilter_industryAssociations = gql`
  query IndustryAssociationsFilter_industryAssociations {
    industryAssociations {
      id
      vendor_name
    }
  }
`;

function IndustryAssociationsFilter({
  allIndustryAssociationsFilter,
  blurred = false,
  industryAssociationsFilter,
  notIndustryAssociationsFilter,
  resetOffset,
  setAllIndustryAssociationsFilter,
  setIndustryAssociationsFilter,
  setNotIndustryAssociationsFilter,
}: IIndustryAssociationsFilterProps) {
  const { data: industryAssociationsData } =
    useQuery<IndustryAssociationsFilter_IndustryAssociationsQuery>(
      IndustryAssociationsFilter_industryAssociations,
      {
        fetchPolicy: "cache-first",
      },
    );

  const industryAssociationsOptions = (
    industryAssociationsData?.industryAssociations || []
  ).map((element) => {
    return {
      value: String(element?.id),
      label: String(element?.vendor_name),
    };
  });

  const filterOptions = [
    {
      label: "Include any of:",
      filter: (
        <CiroDropDown
          isMulti
          className="test-industry-associations-filter"
          blurred={blurred}
          options={industryAssociationsOptions}
          value={industryAssociationsFilter}
          onChange={(newSelection) => {
            resetOffset();
            setIndustryAssociationsFilter(newSelection);
          }}
        />
      ),
    },
  ];

  if (allIndustryAssociationsFilter && setAllIndustryAssociationsFilter) {
    filterOptions.push({
      label: "Include all of:",
      filter: (
        <CiroDropDown
          isMulti
          blurred={blurred}
          options={industryAssociationsOptions}
          value={allIndustryAssociationsFilter}
          onChange={(newSelection) => {
            resetOffset();
            setAllIndustryAssociationsFilter(newSelection);
          }}
        />
      ),
    });
  }

  if (notIndustryAssociationsFilter && setNotIndustryAssociationsFilter) {
    filterOptions.push({
      label: "Exclude:",
      filter: (
        <CiroDropDown
          isMulti
          blurred={blurred}
          options={industryAssociationsOptions}
          value={notIndustryAssociationsFilter}
          onChange={(newSelection) => {
            resetOffset();
            setNotIndustryAssociationsFilter(newSelection);
          }}
        />
      ),
    });
  }

  return (
    <CiroFilterModalDropdownSection
      btnClassName="test-trade-industry-associations-dropdown"
      numFiltersApplied={
        (industryAssociationsFilter.length ? 1 : 0) +
        (allIndustryAssociationsFilter?.length ? 1 : 0) +
        (notIndustryAssociationsFilter?.length ? 1 : 0)
      }
      sectionTitle={
        <div>
          Trade Associations
          <span className={classNames("ciro-v1-pl-2")}>
            <CiroTooltipContainer tooltip="Indicates whether a business links to an association on its website">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                color="rgba(0,0,0,.55)"
              />
            </CiroTooltipContainer>
          </span>
        </div>
      }
      filterOptions={filterOptions}
      smbFilter
    />
  );
}

export default IndustryAssociationsFilter;

import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";
import { gql, useQuery } from "@apollo/client";
import {
  VerticalFilter_IndustriesQuery,
  VerticalFilter_IndustriesQueryVariables,
} from "../../__generated__/graphql";

interface IVerticalFilterProps {
  blurred?: boolean;
  defaultOpen?: boolean;
  industryFilter: string[];
  notIndustryFilter: string[];
  resetOffset?: () => void;
  setIndustryFilter: (v: string[]) => void;
  setNotIndustryFilter: (v: string[]) => void;
}

const VerticalFilter_industries = gql`
  query VerticalFilter_industries {
    industries
  }
`;

function VerticalFilter({
  blurred = false,
  defaultOpen = false,
  industryFilter,
  notIndustryFilter,
  resetOffset,
  setIndustryFilter,
  setNotIndustryFilter,
}: IVerticalFilterProps) {
  const { data: industryData } = useQuery<
    VerticalFilter_IndustriesQuery,
    VerticalFilter_IndustriesQueryVariables
  >(VerticalFilter_industries);
  const industryOptions =
    industryData?.industries?.map((industry) => ({
      label: industry,
      value: industry,
    })) || [];

  return (
    <CiroFilterModalDropdownSection
      defaultOpen={defaultOpen}
      numFiltersApplied={
        (industryFilter.length ? 1 : 0) + (notIndustryFilter.length ? 1 : 0)
      }
      sectionTitle="Industry"
      filterOptions={[
        {
          label: "Include any of:",
          filter: (
            <CiroDropDown
              isMulti
              blurred={blurred}
              options={industryOptions}
              value={industryFilter}
              onChange={(newValue) => {
                setIndustryFilter(newValue);
                if (resetOffset) resetOffset();
              }}
            />
          ),
        },
        {
          label: "Exclude:",
          filter: (
            <CiroDropDown
              isMulti
              blurred={blurred}
              options={industryOptions}
              value={notIndustryFilter}
              onChange={(newValue) => {
                setNotIndustryFilter(newValue);
                if (resetOffset) resetOffset();
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default VerticalFilter;

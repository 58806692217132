import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "./CiroButton";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

interface ISelectTemplateButtonProps {
  setTemplateSelected: (v: string | null) => void;
  template: string | null;
  buttonText: string;
  icon: any;
  onClick: () => void;
}

export const SelectTemplateButton = ({
  setTemplateSelected,
  template,
  buttonText,
  icon,
  onClick,
}: ISelectTemplateButtonProps) => {
  return (
    <CiroButton
      analyticsField={"Clicked" + template + "TemplateButton"}
      style={CiroButtonStyleEnum.PRIMARY}
      customClassName={classNames("ciro-v1-w-full", "ciro-v1-mt-2", "ciro-v1-flex", "ciro-v1-flex-row", "ciro-v1-items-center", "ciro-v1-py-3")}
      onClick={() => {
        setTemplateSelected(template);
        onClick();
      }}
    >
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-between",
          "ciro-v1-w-full",
          "ciro-v1-items-center"
        )}
      >
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-items-center",
            "ciro-v1-leading-tight",
            "ciro-v1-text-sm",
            "ciro-v1-text-neutral-700",
          )}
        >
          {icon}
          {buttonText}
        </div>
        <ChevronRightIcon
          className={classNames(
            "ciro-v1-w-4",
            "ciro-v1-h-4",
            "ciro-v1-text-orange-600",
          )}
        />
      </div>
    </CiroButton>
  );
};

import { gql } from "@apollo/client";
import CiroDropDown from "../shared/CiroDropdown";
import { Maybe } from "../../__generated__/graphql";
import { useContext, useMemo } from "react";
import EnrichmentFlowContext from "../../contexts/EnrichmentFlowContext";

export const EnrichmentStepsDropdown_EnrichmentSteps = gql`
  fragment EnrichmentStepsDropdown_EnrichmentSteps on EnrichmentFlow {
    enrichmentSteps {
      id
      name
      enrichment_technique
      enrichmentTechniqueDetails {
        description
        name
        costPerExecution
      }
    }
  }
`;

interface IEnrichmentStepsDropdownProps {
  selectedStepId: Maybe<number>;
  setSelectedStepId: (id: Maybe<number>) => void;
}

const EnrichmentStepsDropdown = ({
  selectedStepId,
  setSelectedStepId,
}: IEnrichmentStepsDropdownProps) => {
  const { enrichmentFlowWithSteps } = useContext(EnrichmentFlowContext);

  const enrichmentFlowOptions = useMemo(() => {
    const options =
      enrichmentFlowWithSteps?.enrichmentSteps.map((step) => {
        return {
          value: step.id,
          label: step.name,
        } as { value: number | null; label: string };
      }) || [];

    options.unshift({
      value: null,
      label: "User Input",
    });

    return options;
  }, [enrichmentFlowWithSteps]);

  return (
    <CiroDropDown
      label="Enrichment Step Source"
      isClearable={false}
      isMulti={false}
      options={enrichmentFlowOptions}
      onChange={(newValue) => {
        setSelectedStepId(newValue);
      }}
      value={selectedStepId}
    />
  );
};

export default EnrichmentStepsDropdown;

export default function randomBytes(size: number) {
  // Check for size parameter validity
  if (typeof size !== "number" || size < 0 || size !== Math.floor(size)) {
    throw new TypeError('The "size" argument must be a non-negative integer');
  }

  // Create a Uint8Array of the desired size
  const bytes = new Uint8Array(size);

  // Use the Web Cryptography API to fill the array with random values
  if (window.crypto && window.crypto.getRandomValues) {
    window.crypto.getRandomValues(bytes);
  } else {
    throw new Error(
      "Secure random number generation is not supported in this environment",
    );
  }

  return bytes;
}

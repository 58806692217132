interface IProfilePhotoIcon {
  width?: number | string;
  height?: number | string;
}

const ProfilePhotoIcon = ({ width = 113, height = 117 }: IProfilePhotoIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 113 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_63_23726)">
        <rect x="8.5" y="8.5" width="96" height="96" rx="48" fill="#212121" />
        <rect
          x="6.5"
          y="6.5"
          width="100"
          height="100"
          rx="50"
          stroke="white"
          strokeWidth="4"
        />
      </g>
      <path
        d="M77 79.5C77 76.0111 77 74.2666 76.5694 72.8471C75.5999 69.6511 73.0989 67.1501 69.9029 66.1806C68.4834 65.75 66.7389 65.75 63.25 65.75H50.75C47.2611 65.75 45.5166 65.75 44.0971 66.1806C40.9011 67.1501 38.4001 69.6511 37.4306 72.8471C37 74.2666 37 76.0111 37 79.5M68.25 45.75C68.25 51.9632 63.2132 57 57 57C50.7868 57 45.75 51.9632 45.75 45.75C45.75 39.5368 50.7868 34.5 57 34.5C63.2132 34.5 68.25 39.5368 68.25 45.75Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_dd_63_23726"
          x="0.5"
          y="0.5"
          width="112"
          height="116"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_63_23726"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_63_23726"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_63_23726"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_63_23726"
            result="effect2_dropShadow_63_23726"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_63_23726"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ProfilePhotoIcon;

import React from "react";
import classNames from "classnames";
import MobileNumbersWorkflowsTable from "../mobileNumbers/MobileNumbersTable/MobileNumbersWorkflowsTable";
import CiroTitle from "../shared/CiroTitle";
import CiroButton from "../shared/CiroButton";
import { useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import {
  HomeWorkflowsTable_WorkflowsQuery,
  HomeWorkflowsTable_WorkflowsQueryVariables,
} from "../../__generated__/graphql";
import SkeletonLoading from "../shared/SkeletonLoading";
import DatabaseIcon from "../../assets/img/icons/DatabaseIcon";
import DashedBorder from "../../assets/img/styling/DashedBorder";

export const HomeWorkflowsTable_Workflows = gql`
  query HomeWorkflowsTable_Workflows {
    organization {
      phoneNumberRequestWorkflows {
        id
      }
    }
  }
`;

const HomeWorkflowsTable = () => {
  const { data: workflowsData, loading: workflowsLoading } = useQuery<
    HomeWorkflowsTable_WorkflowsQuery,
    HomeWorkflowsTable_WorkflowsQueryVariables
  >(HomeWorkflowsTable_Workflows);
  const navigate = useNavigate();

  if (workflowsLoading) {
    return (
      <div className={classNames("ciro-v1-w-full", "ciro-v1-p-5")}>
        <CiroTitle title="My workflows" size="small" />
        <SkeletonLoading numSkeletons={5} skeletonHeight="28px" />
      </div>
    );
  }

  const hasWorkflows =
    workflowsData?.organization?.phoneNumberRequestWorkflows?.length &&
    workflowsData?.organization?.phoneNumberRequestWorkflows?.length > 0;

  const itemsPerPage =
    workflowsData?.organization?.phoneNumberRequestWorkflows?.length &&
    workflowsData?.organization?.phoneNumberRequestWorkflows?.length > 5
      ? 5
      : undefined;

  return (
    <div className={classNames("ciro-v1-w-full", "ciro-v1-p-5")}>
      {!hasWorkflows ? (
        <>
          <CiroTitle title="My workflows" size="small" />
          <div className="ciro-v1-relative ciro-v1-w-full ciro-v1-p-4 ciro-v1-rounded-lg">
            <DashedBorder />
            <div
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-justify-between",
                "ciro-v1-items-center",
              )}
            >
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-items-center",
                  "ciro-v1-text-neutral-500",
                )}
              >
                <DatabaseIcon height="20" width="20" />
                <p className={classNames("ciro-v1-ml-2", "ciro-v1-text-sm")}>
                  Workflows automatically enrich your contacts in your CRM
                </p>
              </div>
              <CiroButton
                analyticsField="add-workflow"
                onClick={() => {
                  navigate(`/enrich-crm/workflow`);
                }}
              >
                <p>
                  + Create new workflow
                </p>
              </CiroButton>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-justify-between",
              "ciro-v1-items-center",
              "ciro-v1-w-full",
              "ciro-v1-mb-4",
            )}
          >
            <CiroTitle title="My workflows" size="small" />
            <CiroButton
              analyticsField="add-workflow"
              onClick={() => {
                navigate(`/enrich-crm/workflow`);
              }}
            >
              + Add new workflow
            </CiroButton>
          </div>
          <MobileNumbersWorkflowsTable itemsPerPage={itemsPerPage} />
        </div>
      )}
    </div>
  );
};

export default HomeWorkflowsTable;

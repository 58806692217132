import LogoIcon from "../../assets/img/icons/LogoIcon";
import classNames from "classnames";
import LoadingBar from "./LoadingBar";

interface ILoadingProps {
  size?: "FULL" | "SMALL";
  customFillPercentage?: number;
  longLoad?: boolean;
  logo?: boolean;
}

interface ILoadingComponentProps {
  customFillPercentage?: number;
  longLoad?: boolean;
  logo?: boolean;
}

const LoadingComponent = ({
  customFillPercentage,
  logo,
  longLoad
}: ILoadingComponentProps) => {
  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-flex-wrap",
        "ciro-v1-justify-center",
        "ciro-v1-flex-col",
        "ciro-v1-items-center",
      )}
    >
      <div className="ciro-v1-pb-3">
        <LogoIcon />
      </div>
      <div className={classNames("ciro-v1-w-20")}>
        <LoadingBar
          customFillPercentage={customFillPercentage}
          longLoad={longLoad}
        />
      </div>
    </div>
  );
};

const Loading = ({
  size = "FULL",
  customFillPercentage,
  logo = true,
  longLoad = false,
}: ILoadingProps) => {
  if (size === "SMALL") {
    return (
      <LoadingComponent
        customFillPercentage={customFillPercentage}
        logo={logo}
        longLoad={longLoad}
      />
    );
  }

  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-flex-row",
        "ciro-v1-content-center",
        "ciro-v1-justify-center",
        "ciro-v1-w-screen",
        "ciro-v1-h-screen",
      )}
    >
      <div
        className={classNames(
          "ciro-v1-flex-wrap",
          "ciro-v1-flex",
          "ciro-v1-items-center",
        )}
      >
        <LoadingComponent
          customFillPercentage={customFillPercentage}
          logo={logo}
          longLoad={longLoad}
        />
      </div>
    </div>
  );
};

export default Loading;

import { useEffect, useState } from "react";
import classNames from "classnames";
import papaparse from "papaparse";
import XIcon from "../../assets/img/icons/XIcon";
import Loading from "../shared/Loading";
import CiroTable from "../shared/CiroTable/CiroTable";
import CiroTableRow from "../shared/CiroTable/CiroTableRow";
import CiroTableHeader, {
  CiroTableHeaderAlignEnum,
} from "../shared/CiroTable/CiroTableHeader";
import CiroTableCell, {
  CiroTableCellAlignEnum,
} from "../shared/CiroTable/CiroTableCell";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroModal from "../shared/CiroModal";
import CiroCheckbox from "../shared/CiroCheckbox";
import CiroErrorMsg from "../shared/forms/CiroErrorMsg";
import CiroTextInput from "../shared/CiroTextInput";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { gql, useMutation } from "@apollo/client";
import {
  EnrichmentFlowUploadCsvModal_CreateFlowMutation,
  EnrichmentFlowUploadCsvModal_CreateFlowMutationVariables,
  EnrichmentFlowUploadCsvModal_PermissionsFragment,
  EnrichmentTechniqueEnum,
} from "../../__generated__/graphql";
import { useNavigate } from "react-router-dom";
import useCsvFileSizeError from "../../reactHooks/enrichmentFlow/useFileError";

export const EnrichmentFlowUploadCSVModal_Permissions = gql`
  fragment EnrichmentFlowUploadCSVModal_Permissions on Permissions {
    enrichmentAllowedRowLimit
  }
`;

export const EnrichmentFlowUploadCSVModal_CreateFlow = gql`
  mutation EnrichmentFlowUploadCSVModal_CreateFlow(
    $initializationSteps: [FlowInitializationStepInput]
    $name: String!
  ) {
    createEnrichmentFlow(
      initializationSteps: $initializationSteps
      name: $name
    ) {
      enrichmentFlow {
        id
      }
      truncated
      success
      error
    }
  }
`;

export const filterCsvHeadersAndData = (papaparseResult: any) => {
  const nonEmptyHeaders = papaparseResult.meta.fields.filter(
    (headerField: any) => {
      // PapaParse will take blank column names and turn them into the strings "_1", "_2", etc.
      // since it uses the suffixes "_1", "_2", etc. to differentiate between columns with the same name.
      const regex = /^_\d+$/;
      return Boolean(headerField) && !regex.test(headerField);
    },
  );

  const dataWithoutEmptyHeaderColumns = papaparseResult.data.map((row: any) => {
    // Only include object keys that are in the nonEmptyHeaders array
    return Object.fromEntries(
      Object.entries(row).filter(([key]) => nonEmptyHeaders.includes(key)),
    );
  });
  return { nonEmptyHeaders, dataWithoutEmptyHeaderColumns };
};

enum EnrichmentFlowUploadCSVViewEnum {
  NAME_ENRICHMENT,
  SELECT_COLUMNS,
  DONE,
}

const EnrichmentFlowCreateFlowFormSchema = yup.object({
  flowName: yup.string().required("Flow name is required"),
});

interface IUploadCSVCardProps {
  csvFile: File | null;
  onClose: () => void;
  permissions?: EnrichmentFlowUploadCsvModal_PermissionsFragment | null;
}

const EnrichmentFlowUploadCSVModal = ({
  csvFile,
  onClose,
  permissions,
}: IUploadCSVCardProps) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [csvLoading, setCsvLoading] = useState(true);
  const [headers, setHeaders] = useState<string[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedHeaders, setSelectedHeaders] = useState<Set<number>>(
    new Set(),
  );
  const [csvData, setCsvData] = useState(null);
  const [flowName, setFlowName] = useState<String>("");
  const navigate = useNavigate();
  const [view, setView] = useState<EnrichmentFlowUploadCSVViewEnum>(
    EnrichmentFlowUploadCSVViewEnum.NAME_ENRICHMENT,
  );

  const [
    createFlow,
    {
      data: createEnrichmentFlowData,
      error: createEnrichmentFlowError,
      loading: createEnrichmentFlowLoading,
    },
  ] = useMutation<
    EnrichmentFlowUploadCsvModal_CreateFlowMutation,
    EnrichmentFlowUploadCsvModal_CreateFlowMutationVariables
  >(EnrichmentFlowUploadCSVModal_CreateFlow);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EnrichmentFlowCreateFlowFormSchema),
    defaultValues: {
      flowName: "",
    },
  });

  const toggleCheck = (checkboxIdx: number) => {
    const newChecked = new Set([...selectedHeaders]);
    newChecked.has(checkboxIdx)
      ? newChecked.delete(checkboxIdx)
      : newChecked.add(checkboxIdx);
    setSelectedHeaders(newChecked);
  };

  const selectAll = () => {
    const newChecked = new Set(Array(headers.length).keys());
    setSelectedHeaders(newChecked);
  };

  const deselectAll = () => {
    setSelectedHeaders(new Set());
  };

  const filterCsvData = (data: any) => {
    const filteredData = data.map((row: any) => {
      const newRow: any = {};
      selectedHeaders.forEach((headerIdx) => {
        const header = headers[headerIdx];
        newRow[header] = row[header];
      });
      return newRow;
    });
    return filteredData;
  };

  const createFlowStepInput = (filteredCsvData: any) => {
    if (filteredCsvData.length === 0) {
      return [];
    }
    const stepHeaders = Array.from(Object.keys(filteredCsvData[0]));
    return stepHeaders.map((stepHeader) => {
      return {
        enrichmentStepInput: {
          name: stepHeader,
          enrichment_technique: "initialize" as EnrichmentTechniqueEnum,
        },
        initializationData: filteredCsvData.map((row: any) => {
          return row[stepHeader as any];
        }),
      };
    });
  };

  useEffect(() => {
    if (!csvFile) {
      return;
    }

    setErrorMsg("");

    papaparse.parse(csvFile, {
      header: true, // Set to true if your CSV has a header row
      skipEmptyLines: "greedy",
      complete: (results: any) => {
        const { nonEmptyHeaders, dataWithoutEmptyHeaderColumns } =
          filterCsvHeadersAndData(results);

        setHeaders(nonEmptyHeaders);
        setTotalRows(results.data?.length);
        setCsvLoading(false);
        setCsvData(dataWithoutEmptyHeaderColumns);
      },
      error: (error: any) => {
        setErrorMsg(
          "Something went wrong. Please consult the Ciro team if you continue to experience issues",
        );
        console.error("Error parsing CSV:", error.message);
      },
    });
  }, [csvFile, headers.length, totalRows, setCsvData]);

  const fileSizeError = useCsvFileSizeError(
    headers,
    totalRows,
    csvFile,
    permissions?.enrichmentAllowedRowLimit || 100,
  );

  return (
    <CiroModal isOpen={Boolean(csvFile)} onClose={onClose}>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-font-medium",
          "ciro-v1-items-center",
          "ciro-v1-justify-between",
          "ciro-v1-mb-4",
        )}
      >
        <span className={classNames("ciro-v1-text-xl")}>
          {view === EnrichmentFlowUploadCSVViewEnum.NAME_ENRICHMENT
            ? "Import CSV"
            : "Select columns"}
        </span>
        <span
          onClick={() => {
            onClose();
          }}
          className={classNames("ciro-v1-cursor-pointer")}
        >
          <XIcon />
        </span>
      </div>
      {(csvLoading || createEnrichmentFlowLoading) && (
        <>
          <Loading size="SMALL" />
        </>
      )}
      {!csvLoading &&
        view === EnrichmentFlowUploadCSVViewEnum.NAME_ENRICHMENT && (
          <>
            <div
              className={classNames(
                "ciro-v1-mb-10",
                "ciro-v1-text-gray-500",
                "ciro-v1-text-sm",
              )}
            >
              Create a name for your enrichment table.
            </div>
            <CiroTextInput
              error={errors.flowName?.message}
              label="Name"
              showErrorAsBorder={true}
              placeholder="Hot 'n Spicy Leads"
              register={register("flowName")}
            />
            <div className={classNames("ciro-v1-mt-10", "ciro-v1-mb-2")}>
              <CiroButton
                analyticsField={"clickedEnrichmentsImportButton"}
                customClassName="ciro-v1-w-full"
                disabled={Boolean(errors.flowName)}
                onClick={handleSubmit((data) => {
                  setView(EnrichmentFlowUploadCSVViewEnum.SELECT_COLUMNS);
                  setFlowName(data.flowName);
                })}
                style={CiroButtonStyleEnum.LOUD}
              >
                Import
              </CiroButton>
            </div>
          </>
        )}
      {!csvLoading &&
        view === EnrichmentFlowUploadCSVViewEnum.SELECT_COLUMNS && (
          <>
            <div
              className={classNames(
                "ciro-v1-mb-10",
                "ciro-v1-text-gray-500",
                "ciro-v1-text-sm",
              )}
            >
              Detected <b>{headers.length} columns</b> in your CSV, select which
              ones you would like to import.
            </div>
            <div
              className={classNames(
                "ciro-v1-overflow-y-scroll",
                "ciro-v1-h-96",
              )}
            >
              <CiroTable>
                <thead>
                  <CiroTableRow clickable={false}>
                    <CiroTableHeader isFirst={true} isLast={false}>
                      CSV Columns
                    </CiroTableHeader>
                    <CiroTableHeader
                      align={CiroTableHeaderAlignEnum.RIGHT}
                      isFirst={false}
                      isLast={true}
                    >
                      <div
                        className={classNames(
                          "ciro-v1-flex",
                          "ciro-v1-justify-end",
                        )}
                      >
                        Select
                        <CiroCheckbox
                          className={classNames("ciro-v1-ml-2")}
                          checked={selectedHeaders.size === headers.length}
                          onClick={
                            selectedHeaders.size === headers.length
                              ? deselectAll
                              : selectAll
                          }
                        />
                      </div>
                    </CiroTableHeader>
                  </CiroTableRow>
                </thead>
                <tbody>
                  {headers.map((header, index) => {
                    return (
                      <CiroTableRow key={index} clickable={false}>
                        <CiroTableCell lastLeft={index === headers.length - 1}>
                          <span className={classNames("ciro-v1-mr-2")}>
                            {index + 1}
                          </span>
                          <span>{header}</span>
                        </CiroTableCell>
                        <CiroTableCell
                          align={CiroTableCellAlignEnum.RIGHT}
                          lastRight={index === headers.length - 1}
                          useFlex
                        >
                          <CiroCheckbox
                            checked={selectedHeaders.has(index)}
                            onClick={() => {
                              toggleCheck(index);
                            }}
                          ></CiroCheckbox>
                        </CiroTableCell>
                      </CiroTableRow>
                    );
                  })}
                </tbody>
              </CiroTable>
            </div>
            <div className={classNames("ciro-v1-mt-10", "ciro-v1-mb-2")}>
              <CiroButton
                analyticsField={"clickedEnrichmentsCreateButton"}
                customClassName="ciro-v1-w-full"
                disabled={Boolean(errorMsg)}
                onClick={() => {
                  const filteredCsvData = filterCsvData(csvData);
                  const flowStepInputs = createFlowStepInput(filteredCsvData);
                  createFlow({
                    variables: {
                      name: flowName as string,
                      initializationSteps: flowStepInputs,
                    },
                  });
                  setView(EnrichmentFlowUploadCSVViewEnum.DONE);
                }}
                style={CiroButtonStyleEnum.LOUD}
              >
                Create
              </CiroButton>
            </div>
          </>
        )}
      {!csvLoading &&
        view === EnrichmentFlowUploadCSVViewEnum.SELECT_COLUMNS &&
        fileSizeError && <CiroErrorMsg error={fileSizeError} />}
      {view === EnrichmentFlowUploadCSVViewEnum.DONE &&
        !createEnrichmentFlowLoading && (
          <div
            className={classNames(
              "ciro-v1-mt-10",
              "ciro-v1-text-gray-500",
              "ciro-v1-text-sm",
            )}
          >
            {createEnrichmentFlowError && (
              <CiroErrorMsg
                error={`An error occurred: ${createEnrichmentFlowError.message}`}
              />
            )}
            {createEnrichmentFlowData?.createEnrichmentFlow?.success && (
              <CiroButton
                analyticsField={"clickedEnrichmentsDoneButton"}
                customClassName="ciro-v1-w-full"
                onClick={() => {
                  navigate(
                    "/lists/" +
                      createEnrichmentFlowData?.createEnrichmentFlow
                        ?.enrichmentFlow?.id +
                      (createEnrichmentFlowData?.createEnrichmentFlow?.truncated
                        ? "?truncated=" + permissions?.enrichmentAllowedRowLimit
                        : ""),
                  );
                }}
                style={CiroButtonStyleEnum.LOUD}
              >
                Done
              </CiroButton>
            )}
          </div>
        )}
    </CiroModal>
  );
};

export default EnrichmentFlowUploadCSVModal;

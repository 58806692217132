import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

const openDayOptions = [
  { value: "MONDAY", label: "Monday" },
  { value: "TUESDAY", label: "Tuesday" },
  { value: "WEDNESDAY", label: "Wednesday" },
  { value: "THURSDAY", label: "Thursday" },
  { value: "FRIDAY", label: "Friday" },
  { value: "SATURDAY", label: "Saturday" },
  { value: "SUNDAY", label: "Sunday" },
  { value: "UNKNOWN", label: "Unknown" },
];

interface IOpenDaysFilterProps {
  openDaysFilter: string[];
  setOpenDaysFilter: (v: string[]) => void;
  resetOffset: () => void;
  blurred?: boolean;
}

function OpenDaysFilter({
  openDaysFilter,
  setOpenDaysFilter,
  resetOffset,
  blurred = false,
}: IOpenDaysFilterProps) {
  return (
    <CiroFilterModalDropdownSection
      btnClassName="open-days-filter-dropdown"
      numFiltersApplied={openDaysFilter.length ? 1 : 0}
      sectionTitle="Open Days"
      filterOptions={[
        {
          label: "Include any of:",
          filter: (
            <CiroDropDown
              blurred={blurred}
              className="open-days-filter"
              isMulti
              onChange={(newValue) => {
                setOpenDaysFilter(newValue);
                resetOffset();
              }}
              options={openDayOptions}
              value={openDaysFilter}
            />
          ),
        },
      ]}
      smbFilter
    />
  );
}

export default OpenDaysFilter;

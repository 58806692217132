import React from "react";
import CiroCard from "./CiroCard";
import classNames from "classnames";
import NoRecordsIcon from "../../assets/img/icons/NoRecordsIcon";

interface ICiroNoRecordsCardProps {
  title: string;
  subtitle: string;
}

const CiroNoRecordsCard = ({ title, subtitle }: ICiroNoRecordsCardProps) => {
  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-justify-center",
        "ciro-v1-mt-32",
      )}
    >
      <div className={classNames("ciro-v1-max-w-sm")}>
        <CiroCard>
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-flex-col",
              "ciro-v1-items-center",
            )}
          >
            <div className={classNames("ciro-v1-pb-5")}>
              <NoRecordsIcon />
            </div>
            <div
              className={classNames("ciro-v1-font-semibold", "ciro-v1-text-lg")}
            >
              {title}
            </div>
            <div className={classNames("ciro-v1-text-sm", "ciro-v1-mt-2")}>
              {subtitle}
            </div>
          </div>
        </CiroCard>
      </div>
    </div>
  );
};

export default CiroNoRecordsCard;

import classNames from "classnames";
import { useWatch } from "react-hook-form";
import { useFragment } from "../../../../__generated__";
import {
  EnrichmentStep,
  EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
  EnrichmentStepCardFiltersList_EnrichmentStepFragment,
  EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
  EnrichmentTechniqueEnum,
} from "../../../../__generated__/graphql";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import {
  EnrichmentStepCardFiltersListSchema,
  parseEnrichmentStepsForList,
} from "../EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import { IEnrichmentStepTechniqueCardProps } from ".";
import EnrichmentStepCardContainer from "../EnrichmentStepCardContainer";
import useEnrichmentStepForm from "../../../../reactHooks/enrichmentFlow/useEnrichmentStepForm";

const RETURN_VALUE_KEY = "value";

export const EnrichmentStepTechniqueReturnValueCardSchema = yup
  .object({
    enrichment_technique: yup
      .string()
      .required("Enrichment Technique is required"),
    parentEnrichmentStepInput: yup
      .object({
        key: yup.string().required("Key is required"),
        input: yup.string().nullable(),
        required: yup.boolean().required("Required is required"),
        sourceEnrichmentStepId: yup.number().nullable(),
        sourceEnrichmentStepName: yup.string().nullable(),
      })
      .required("Input is required"),
    filterEnrichmentSteps: EnrichmentStepCardFiltersListSchema,
  })
  .required();

export const getReturnValueDefaultValues = (
  enrichmentStep: EnrichmentStep,
  enrichmentStepWithFilters?: EnrichmentStepCardFiltersList_EnrichmentStepFragment,
) => {
  const returnValueInput = enrichmentStep?.parentEnrichmentStepInputs.find(
    (stepInput) => {
      return stepInput.key === RETURN_VALUE_KEY;
    },
  );

  const filterEnrichmentSteps = parseEnrichmentStepsForList(
    enrichmentStepWithFilters,
  );

  return (
    {
      enrichment_technique: EnrichmentTechniqueEnum.ReturnValue,
      parentEnrichmentStepInput: {
        key: returnValueInput?.key || RETURN_VALUE_KEY,
        input: returnValueInput?.input || "null",
        required: true,
        sourceEnrichmentStepId:
          returnValueInput?.source_enrichment_step_id || null,
        sourceEnrichmentStepName: returnValueInput?.sourceEnrichmentStep?.name || null,
      },
      filterEnrichmentSteps: filterEnrichmentSteps,
    } || []
  );
};


const EnrichmentStepTechniqueReturnValueCard = ({
  enrichmentStep,
  onClose,
  loading,
  error,
}: IEnrichmentStepTechniqueCardProps) => {
  const enrichmentStepWithFilters = useFragment(
    EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );

  const defaultValues = useMemo(() => {
    return getReturnValueDefaultValues(
      enrichmentStep as EnrichmentStep,
      enrichmentStepWithFilters as EnrichmentStepCardFiltersList_EnrichmentStepFragment,
    );
  }, [enrichmentStep, enrichmentStepWithFilters]);

  const { control, reset } = useEnrichmentStepForm({
    resolver: yupResolver(EnrichmentStepTechniqueReturnValueCardSchema),
    defaultValues,
  });


  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const [parentEnrichmentStepInput] = useWatch({
    control,
    name: ["parentEnrichmentStepInput", "filterEnrichmentSteps"],
  });


  const returnValueRenderedValue = useMemo(() => {
    try {
      const parsedStepName = parentEnrichmentStepInput.sourceEnrichmentStepName;
      const parsedInput = JSON.parse(parentEnrichmentStepInput.input);
      return parsedStepName ? `${parsedStepName} > ${parsedInput}` : parsedInput;
    } catch (e) {
      return parentEnrichmentStepInput.input;
    } 
  }, [parentEnrichmentStepInput.input, parentEnrichmentStepInput.sourceEnrichmentStepName]);

  return (
    <EnrichmentStepCardContainer
      loading={loading}
      error={error}
      enrichmentStep={useFragment(
        EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
        enrichmentStep,
      )}
      onClose={onClose}
    >
      <div
        className={classNames(
          "ciro-v1-h-9",
          "ciro-v1-w-full",
          "ciro-v1-px-3",
          "ciro-v1-py-2",
          "ciro-v1-bg-gray-50",
          "ciro-v1-rounded-md",
          "ciro-v1-justify-start",
          "ciro-v1-items-center",
          "ciro-v1-gap-1.5",
          "ciro-v1-inline-flex",
        )}
      >
        <div
          className={classNames("ciro-v1-text-neutral-900", "ciro-v1-text-sm")}
        >
          Column generated from
        </div>
        <div
          className={classNames(
            "ciro-v1-px-2",
            "ciro-v1-bg-orange-50",
            "ciro-v1-rounded",
          )}
        >
          <div
            className={classNames("ciro-v1-text-orange-500", "ciro-v1-text-sm")}
          >
            {returnValueRenderedValue}
          </div>
        </div>
      </div>
    </EnrichmentStepCardContainer>
  );
};

export default EnrichmentStepTechniqueReturnValueCard;

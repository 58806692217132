import React from "react";
import classNames from "classnames";
import MobileNumbersOverviewCard from "../mobileNumbers/MobileNumbersOverviewCard";
import BlankOverviewCard from "../shared/BlankOverviewCard";
import { gql } from "@apollo/client";

export const HomeStatsOverview_PhoneTransactions = gql`
  fragment HomeStatsOverview_PhoneTransactions on Query {
    phoneNumbersRecoveredCount
    conversationsCreatedCount
    enrichmentFlows {
      count
    }
  }
`;

interface HomeStatsOverviewProps {
  stats: {
    phoneNumbersRecoveredCount: number;
    conversationsCreatedCount: number;
    enrichmentFlows: {
      count: number;
    };
  } | null;
}

const HomeStatsOverview = ({ stats }: HomeStatsOverviewProps) => {
  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-flex-row",
        "ciro-v1-justify-between",
        "ciro-v1-gap-4",
        "ciro-v1-w-full",
        "ciro-v1-p-5",
      )}
    >
      {stats?.phoneNumbersRecoveredCount ? (
        <MobileNumbersOverviewCard
          amount={stats.phoneNumbersRecoveredCount}
          description="Number of contacts enriched"
        />
      ) : (
        <BlankOverviewCard description="Number of contacts enriched" />
      )}
      {stats?.enrichmentFlows?.count ? (
        <MobileNumbersOverviewCard
          amount={stats.enrichmentFlows.count}
          description="Number of lists"
        />
      ) : (
        <BlankOverviewCard description="Number of lists" />
      )}
      {stats?.conversationsCreatedCount ? (
        <MobileNumbersOverviewCard
          amount={stats.conversationsCreatedCount}
          description="Conversations created"
        />
      ) : (
        <BlankOverviewCard description="Conversations created" />
      )}
    </div>
  );
};

export default HomeStatsOverview;

import { useEffect, useState } from "react";
import { EnrichmentStepCard_EnrichmentStepQuery } from "../../../../__generated__/graphql";
import EnrichmentStepRichTextEditor from "./EnrichmentStepTextEditor";

interface IEnrichmentStepRichTextEditor {
  enrichmentStep: EnrichmentStepCard_EnrichmentStepQuery["enrichmentStep"];
  label: string;
  loading: boolean;
  variables: Map<string, IEnrichmentTemplateVariable>;
  variableErrors: any;
  setVariables: (variables: Map<string, IEnrichmentTemplateVariable>) => void;
  template: string | null;
  setTemplate: (template: string) => void;
  templateError?: string;
}

export interface IEnrichmentTemplateVariable {
  stepId: number | null;
  stepInput: string | null;
}

const EnrichmentStepRichTextContainer = ({
  enrichmentStep,
  label,
  variables,
  variableErrors,
  setVariables,
  template,
  setTemplate,
  templateError = "",
  loading,
}: IEnrichmentStepRichTextEditor) => {
  // Hack to deal with resetting the rich text editor when the enrichment step id changes
  /***BEGIN HACK***/
  const [currentEnrichmentStepId, setCurrentEnrichmentStepId] = useState<
    number | undefined
  >(enrichmentStep?.id);
  const [isRefreshing, setIsRefreshing] = useState(false);
  useEffect(() => {
    if (enrichmentStep?.id !== currentEnrichmentStepId && !isRefreshing) {
      setCurrentEnrichmentStepId(enrichmentStep?.id);
      setIsRefreshing(true);
    }
  }, [enrichmentStep?.id, isRefreshing, currentEnrichmentStepId]);

  useEffect(() => {
    if (isRefreshing) {
      setIsRefreshing(false);
    }
  }, [isRefreshing]);

  if (isRefreshing) {
    return null;
  }
  /***END HACK***/

  if (loading) {
    return null;
  }

  return (
    <div>
      <EnrichmentStepRichTextEditor
        label={label}
        templateError={templateError}
        variables={variables}
        variableErrors={variableErrors}
        setVariables={setVariables}
        template={template}
        setTemplate={setTemplate}
      />
    </div>
  );
};

export default EnrichmentStepRichTextContainer;

import React, { useContext, useEffect, useState } from "react";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroModal from "../shared/CiroModal";
import classNames from "classnames";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  AccountsRemoveFromCollectionModal_CollectionsQuery,
  AccountsRemoveFromCollectionModal_CollectionsQueryVariables,
  AccountsRemoveFromCollectionModal_UpsertCollectionMutation,
  AccountsRemoveFromCollectionModal_UpsertCollectionMutationVariables,
  CompaniesFromFiltersInput,
  UpsertCollectionMethodEnum,
} from "../../__generated__/graphql";
import Loading from "../shared/Loading";
import pluralize from "pluralize";
import CiroDropDown from "../shared/CiroDropdown";
import XIcon from "../../assets/img/icons/XIcon";
import AccountsUpsertCollectionResponse from "./AccountsUpsertCollectionResponse";
import CiroByQueryLimitSelectionModal, {
  MAX_DOWNLOAD_LIMIT,
} from "../shared/CiroByQueryLimitSelectionModal";
import CiroByQueryUnlockModal from "../shared/CiroByQueryUnlockModal";
import FilterContext from "../../contexts/FilterContext";
import { AccountsAddToCollectionModal_CollectionsFilter } from "./AccountsAddToCollectionModal";

export const AccountsRemoveFromCollectionModal_Collections = gql`
  query AccountsRemoveFromCollectionModal_Collections($companyPks: [ID!]!) {
    collections(withCompanyPks: $companyPks, editableOnly: true) {
      id
      name
    }
  }
`;

const AccountsRemoveFromCollectionModal_UpsertCollection = gql`
  mutation AccountsRemoveFromCollectionModal_UpsertCollection(
    $removalList: [UpsertCollectionArgs!]!
  ) {
    upsertCollections(changes: $removalList) {
      success
      collection {
        name
      }
      accountsChanged
      message
    }
  }
`;

interface IAccountsRemoveFromCollectionModalProps {
  filteredCompanyQueryVariables?: CompaniesFromFiltersInput;
  isOpen: boolean;
  numberChecked: number;
  selectedCompanyPks: string[];
  setIsOpen: (v: boolean) => void;
  setCheckedCompanies: (v: Set<string>) => void;
}

const AccountsRemoveFromCollectionModal = ({
  filteredCompanyQueryVariables,
  isOpen,
  numberChecked,
  selectedCompanyPks,
  setIsOpen,
  setCheckedCompanies,
}: IAccountsRemoveFromCollectionModalProps) => {
  const { companiesCount } = useContext(FilterContext);
  const [unlockedAccounts, setUnlockedAccounts] = useState(false);
  const [queryLimit, setQueryLimit] = useState(0);

  const [selectedCollections, setSelectedCollections] = useState<string[]>([]);
  const [fetchCompanySpecificCollections, { data: collectionFiltersData }] =
    useLazyQuery<
      AccountsRemoveFromCollectionModal_CollectionsQuery,
      AccountsRemoveFromCollectionModal_CollectionsQueryVariables
    >(AccountsRemoveFromCollectionModal_Collections);

  const [
    removeAccountsFromMultipleCollections,
    {
      data: removedAccountsMutationData,
      loading: removedAccountsMutationLoading,
      reset: resetRemoveAccountsFromMultipleCollectionsData,
    },
  ] = useMutation<
    AccountsRemoveFromCollectionModal_UpsertCollectionMutation,
    AccountsRemoveFromCollectionModal_UpsertCollectionMutationVariables
  >(AccountsRemoveFromCollectionModal_UpsertCollection);

  const usingQueryFilters =
    filteredCompanyQueryVariables && !selectedCompanyPks?.length;

  const handleRemoveAccountsFromMultipleCollections = async () => {
    if (!selectedCollections.length) {
      return;
    }
    const removeAccountsFromMultipleCollectionssArr = selectedCollections.map(
      (collection) => {
        return {
          method: UpsertCollectionMethodEnum.Remove,
          collectionId: Number(collection),
          companyPks: usingQueryFilters ? undefined : selectedCompanyPks,
          companyQuery: usingQueryFilters
            ? {
                filters: filteredCompanyQueryVariables,
                limit: queryLimit,
                offset: 0,
              }
            : undefined,
        };
      },
    );
    removeAccountsFromMultipleCollections({
      variables: {
        removalList: removeAccountsFromMultipleCollectionssArr,
      },
      refetchQueries: [
        AccountsRemoveFromCollectionModal_Collections,
        AccountsAddToCollectionModal_CollectionsFilter,
      ],
    });
  };

  const removedAccountsResponse =
    removedAccountsMutationData?.upsertCollections;
  const successfulResponse = removedAccountsResponse?.some((r) => r.success);

  const closeAndClearChecks = () => {
    if (successfulResponse) {
      setCheckedCompanies(new Set());
    }
    setIsOpen(false);
  }

  useEffect(() => {
    if (isOpen) {
      fetchCompanySpecificCollections({
        variables: {
          companyPks: selectedCompanyPks,
        },
      });
    }
  }, [fetchCompanySpecificCollections, isOpen, selectedCompanyPks]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedCollections([]);
      resetRemoveAccountsFromMultipleCollectionsData();
      setUnlockedAccounts(false);
      setQueryLimit(0);
    }
  }, [
    isOpen,
    resetRemoveAccountsFromMultipleCollectionsData,
    setSelectedCollections,
  ]);

  if (usingQueryFilters && !queryLimit) {
    const totalAccounts = Math.min(
      MAX_DOWNLOAD_LIMIT,
      companiesCount || MAX_DOWNLOAD_LIMIT,
    );
    const limitType =
      totalAccounts === MAX_DOWNLOAD_LIMIT ? "maximum" : "total";
    return (
      <CiroByQueryLimitSelectionModal
        closeModal={closeAndClearChecks}
        isOpen={isOpen}
        setLimit={setQueryLimit}
        textLabel={`How many records do you want to remove from a collection? (${totalAccounts} ${limitType})`}
        title={"Select number of accounts to remove from a collection"}
        maxAccounts={totalAccounts}
      />
    );
  }

  if (
    usingQueryFilters &&
    queryLimit &&
    filteredCompanyQueryVariables.pmsProviderFilter?.length &&
    !unlockedAccounts
  ) {
    return (
      <CiroByQueryUnlockModal
        filteredCompanyQueryVariables={filteredCompanyQueryVariables}
        offset={0}
        numRecordsToUnlock={queryLimit}
        onClose={() => {
          setQueryLimit(0);
          closeAndClearChecks();
        }}
        onCompleted={() => {
          setUnlockedAccounts(true);
        }}
        subtitle="In order to remove these records from a collection, you must unlock them first."
        refetchQueries={[]}
      />
    );
  }

  return (
    <CiroModal onClose={closeAndClearChecks} isOpen={isOpen}>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-font-medium",
          "ciro-v1-items-center",
          "ciro-v1-justify-between",
        )}
      >
        <span>Remove accounts from a collection</span>
        <span
          onClick={closeAndClearChecks}
          className={classNames("ciro-v1-cursor-pointer")}
        >
          <XIcon />
        </span>
      </div>
      {!collectionFiltersData && (
        <div className={classNames(["ciro-v1-py-4"])}>
          <Loading size="SMALL" />
        </div>
      )}
      {collectionFiltersData && (
        <div className={classNames(["ciro-v1-py-4"])}>
          <CiroDropDown
            isMulti
            options={(collectionFiltersData.collections || [])?.map(
              (collection: any) => {
                return {
                  value: String(collection?.id),
                  label: String(collection?.name),
                };
              },
            )}
            value={selectedCollections}
            onChange={(newValue) => {
              setSelectedCollections(newValue);
            }}
          />
          {(removedAccountsResponse || []).map((removedReponse, i) => {
            const isSuccess = Boolean(
              removedReponse.success && removedReponse.accountsChanged,
            );
            const message = removedReponse.success
              ? `Removed ${removedReponse.accountsChanged} ${pluralize("account", Number(removedReponse.accountsChanged))} from ${removedReponse.collection?.name}`
              : removedReponse.message ||
                "Error with removing accounts from collection";
            return (
              <AccountsUpsertCollectionResponse
                key={i}
                isSuccess={isSuccess}
                message={message}
              />
            );
          })}
          {removedAccountsMutationLoading && (
            <div
              className={classNames(
                "ciro-v1-italic",
                "ciro-v1-py-4",
                "ciro-v1-text-sm",
              )}
            >
              Note: Removing a large number of records (5k+) may take a few
              minutes.
            </div>
          )}
          <div
            className={classNames([
              "ciro-v1-flex",
              "ciro-v1-justify-end",
              "ciro-v1-pt-4",
            ])}
          >
            <CiroButton
              style={CiroButtonStyleEnum.INVERTED}
              analyticsField="Remove accounts from collection"
              onClick={handleRemoveAccountsFromMultipleCollections}
              disabled={
                removedAccountsMutationLoading ||
                !selectedCollections.length ||
                Boolean(removedAccountsResponse)
              }
            >
              Remove {pluralize("account", numberChecked)} from{" "}
              {selectedCollections.length}{" "}
              {pluralize("collection", selectedCollections.length)}
            </CiroButton>
          </div>
        </div>
      )}
    </CiroModal>
  );
};

export default AccountsRemoveFromCollectionModal;

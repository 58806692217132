import React, { useCallback, useContext, useMemo } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  AccountsTableHeaderCheckbox_CompanyPksFromFiltersQuery,
  AccountsTableHeaderCheckbox_CompanyPksFromFiltersQueryVariables,
  AccountsTableHeaderCheckbox_CompaniesFromFiltersResponseFragment,
  CompaniesFromFiltersInput,
  Maybe,
  AccountsTable_PermissionsFragment,
} from "../../__generated__/graphql";
import CiroTableHeaderCheckboxContainer from "../shared/CiroTableHeaderCheckbox/CiroTableHeaderCheckboxContainer";
import { BULK_SELECTION_LIMIT } from "../../routes/accounts/Accounts";
import FilterContext from "../../contexts/FilterContext";

export const AccountsTableHeaderCheckbox_CompaniesFromFiltersResponse = gql`
  fragment AccountsTableHeaderCheckbox_CompaniesFromFiltersResponse on CompaniesFromFiltersResponse {
    companies {
      company {
        pk
      }
    }
  }
`;

export const AccountTableHeaderCheckbox_Permissions = gql`
  fragment AccountTableHeaderCheckbox_Permissions on Permissions {
    canSelectAccountsInBulk
  }
`;


const AccountsTableHeaderCheckbox_CompanyPksFromFilters = gql`
  query AccountsTableHeaderCheckbox_CompanyPksFromFilters(
    $filters: CompaniesFromFiltersInput
    $limit: Int
    $offset: Int
  ) {
    companyPksFromFilters(filters: $filters, limit: $limit, offset: $offset) {
      companyPks
      lockedCount
    }
  }
`;

interface IAccountsTableHeaderCheckboxProps {
  checked: Set<string>;
  companiesFromFilter?: Maybe<AccountsTableHeaderCheckbox_CompaniesFromFiltersResponseFragment>;
  filteredCompanyQueryVariables: CompaniesFromFiltersInput;
  numberVisibleChecked: number;
  offset: number;
  setChecked: (v: Set<string>) => void;
  setOffset: (v: number) => void;
  totalNumberChecked: number;
  permissions: Maybe<AccountsTable_PermissionsFragment>;
}

function AccountsTableHeaderCheckbox({
  checked,
  companiesFromFilter,
  filteredCompanyQueryVariables,
  numberVisibleChecked,
  offset,
  setChecked,
  setOffset,
  totalNumberChecked,
  permissions,
}: IAccountsTableHeaderCheckboxProps) {
  const { companiesCount } = useContext(FilterContext);
  const [
    fetchFirstMaxNumberCompanyIds,
    { data: firstMaxNumberCompanyIds, loading: firstMaxNumberCompanyLoading },
  ] = useLazyQuery<
    AccountsTableHeaderCheckbox_CompanyPksFromFiltersQuery,
    AccountsTableHeaderCheckbox_CompanyPksFromFiltersQueryVariables
  >(AccountsTableHeaderCheckbox_CompanyPksFromFilters, {
    fetchPolicy: "network-only",
  });

  const lockedAccounts =
    firstMaxNumberCompanyIds?.companyPksFromFilters?.lockedCount || 0;

  const companies = useMemo(() => {
    return companiesFromFilter?.companies || [];
  }, [companiesFromFilter?.companies]);
  const totalChecked = Object.entries(checked).filter(([, v]) => v).length;

  const passSelectPage = Math.floor(offset / BULK_SELECTION_LIMIT);
  const startIdx = passSelectPage * BULK_SELECTION_LIMIT;

  const canSelectMoreRecords =
    numberVisibleChecked < companiesCount &&
    totalChecked <= BULK_SELECTION_LIMIT;

  const handleTopCheckboxSelection = useCallback(() => {
    const settingToChecked = numberVisibleChecked < companies.length;
    const newChecked = new Set([...checked]);
    // eslint-disable-next-line array-callback-return
    companies.map((key) => {
      settingToChecked
        ? newChecked.add(key.company.pk)
        : newChecked.delete(key.company.pk);
    });
    setChecked(newChecked);
  }, [checked, companies, numberVisibleChecked, setChecked]);

  const unselectVisible = useCallback(() => {
    const newChecked = new Set([...checked]);
    // eslint-disable-next-line array-callback-return
    companies.map((key) => {
      newChecked.delete(key.company.pk);
    });
    setChecked(newChecked);
  }, [checked, companies, setChecked]);

  const unselectAll = useCallback(() => {
    setChecked(new Set());
  }, [setChecked]);

  return (
    <CiroTableHeaderCheckboxContainer
      canSelectAccountsInBulk={permissions?.canSelectAccountsInBulk}
      canSelectMoreRecords={canSelectMoreRecords}
      displayedRecords={companies}
      fetchMaxNumberRecords={() => {
        const limit = Math.min(companiesCount, BULK_SELECTION_LIMIT);

        fetchFirstMaxNumberCompanyIds({
          variables: {
            filters: filteredCompanyQueryVariables,
            limit,
            offset: startIdx,
          },
          onCompleted: ({ companyPksFromFilters }) => {
            const newPks = companyPksFromFilters?.companyPks || [];
            const newChecked = new Set([...checked, ...newPks]);
            setChecked(newChecked);
          },
        });
      }}
      companyQuery={filteredCompanyQueryVariables}
      firstMaxNumberRecordsLoading={firstMaxNumberCompanyLoading}
      handleTopCheckboxSelection={handleTopCheckboxSelection}
      lockedAccounts={lockedAccounts}
      numberVisibleChecked={numberVisibleChecked}
      offset={offset}
      setOffset={setOffset}
      startIdx={startIdx}
      totalCount={companiesCount}
      totalNumberChecked={totalNumberChecked}
      unselectAll={unselectAll}
      unselectVisible={unselectVisible}
    />
  );
}

export default AccountsTableHeaderCheckbox;

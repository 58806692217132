import classNames from "classnames";
import CiroCard from "../shared/CiroCard";
import { gql } from "@apollo/client";
import { ProfileCreditsSummary_OrganizationFragment } from "../../__generated__/graphql";
import { useMemo } from "react";
import { format } from "date-fns";
import CiroCoinPill from "../shared/CiroCoinPill";

export const ProfileCreditsSummary_Organization = gql`
  fragment ProfileCreditsSummary_Organization on Organization {
    credit_plan {
      credit_transactions(after: $creditTransactionsAfter) {
        amount
        method
        category
        subCategory
        created_at
      }
    }
  }
`;

const ProfileCreditsSummary = ({
  org,
}: {
  org?: ProfileCreditsSummary_OrganizationFragment | null;
}) => {
  const groupedCreditTransactions = useMemo(() => {
    return org?.credit_plan?.credit_transactions.reduce(
      (
        acc: {
          date: string;
          categories: {
            [category: string]: {
              [subCategory: string]: {
                amount?: number | null | undefined;
                category?: string | null | undefined;
              };
            };
          };
        }[],
        ct,
      ) => {
        const date = new Date(ct.created_at);
        // Format date as "12, Jun/2024"
        const formattedDate = format(date, "d, MMM/yyyy");
        const category = ct.category || "Manual adjustments to credit balance";
        const subCategory =
          ct.subCategory || "Manual adjustments to credit balance";

        const lastTransaction = acc[acc.length - 1];

        if (lastTransaction?.date === formattedDate) {
          lastTransaction.categories[category] =
            lastTransaction.categories[category] || {};

          lastTransaction.categories[category][subCategory] = lastTransaction
            .categories[category][subCategory] || {
            amount: 0,
            category,
          };

          lastTransaction.categories[category][subCategory].amount =
            (lastTransaction.categories[category][subCategory].amount || 0) +
            (ct.amount || 0);
        } else {
          acc.push({
            date: formattedDate,
            categories: {
              [category]: {
                [subCategory]: {
                  amount: ct.amount,
                  category,
                },
              },
            },
          });
        }

        return acc;
      },
      [],
    );
  }, [org]);

  if (!groupedCreditTransactions) {
    return null;
  }

  return (
    <CiroCard size="small" customClassName={classNames("ciro-v1-w-full")}>
      <div className={classNames("-ciro-v1-mx-4")}>
        <div
          className={classNames(
            "ciro-v1-font-semibold",
            "ciro-v1-px-2",
            "ciro-v1-pb-4",
          )}
        >
          Credits Summary
        </div>
        {groupedCreditTransactions?.map(
          (groupedCreditTransaction, groupedCreditTransactionsIdx) => {
            const categoryLength = Object.keys(
              groupedCreditTransaction.categories,
            ).length;
            return Object.keys(groupedCreditTransaction.categories).map(
              (category, categoryIdx) => {
                const subcategoryLength = Object.keys(
                  groupedCreditTransaction.categories[category],
                ).length;
                return (
                  <div key={groupedCreditTransaction.date}>
                    <div
                      className={classNames(
                        "ciro-v1-bg-neutral-100",
                        "ciro-v1-flex",
                        "ciro-v1-justify-between",
                        "ciro-v1-px-4",
                        "ciro-v1-py-2",
                        "ciro-v1-text-neutral-500",
                        "ciro-v1-text-sm",
                      )}
                    >
                      <div>{category}</div>
                      <div>{groupedCreditTransaction.date}</div>
                    </div>
                    {Object.keys(
                      groupedCreditTransaction.categories[category],
                    ).map((subCategory, subCategoryIdx) => {
                      const amount =
                        groupedCreditTransaction.categories[category][
                          subCategory
                        ].amount || 0;
                      return (
                        <div
                          className={classNames(
                            "ciro-v1-flex",
                            "ciro-v1-justify-between",
                            "ciro-v1-p-4",
                            "ciro-v1-text-sm",
                            {
                              "ciro-v1-pb-2":
                                subcategoryLength === subCategoryIdx + 1 &&
                                categoryLength === categoryIdx + 1 &&
                                groupedCreditTransactions.length ===
                                  groupedCreditTransactionsIdx + 1,
                            },
                          )}
                        >
                          <div>{subCategory}</div>
                          <div>
                            <CiroCoinPill
                              amount={Math.abs(amount)}
                              customColor={
                                amount < 0
                                  ? "ciro-v1-text-neutral-600"
                                  : "ciro-v1-text-green-600"
                              }
                              customBackgroundColor={"ciro-v1-bg-transparent"}
                              additionalText={amount < 0 ? undefined : "+"}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              },
            );
          },
        )}
      </div>
    </CiroCard>
  );
};

export default ProfileCreditsSummary;

import classNames from "classnames";
import LinkArrowIcon from "../../assets/img/icons/LinkArrowIcon";
import { analytics } from "../../utils/vendors";

interface ICiroLinkProps {
  href?: string;
  shouldOpenInNewTab?: boolean;
  children?: any;
}

const CiroLink = ({
  href,
  children,
  shouldOpenInNewTab = true,
}: ICiroLinkProps) => {
  const handleClick = () => {
    analytics.track("linkClicked", {
      href,
    });
  };
  return (
    <a
      className={classNames(
        "ciro-v1-no-underline",
        "ciro-v1-text-orange-400",
        "hover:ciro-v1-text-orange-500",
      )}
      onClick={handleClick}
      href={href}
      {...(shouldOpenInNewTab ? { target: "_blank", rel: "noreferrer" } : {})}
    >
      {children || <LinkArrowIcon />}
    </a>
  );
};

export default CiroLink;
